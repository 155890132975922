import { useEffect, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { NavItem, NavLink, Nav } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ReactSortable } from "react-sortablejs"
import { useLastMenuTabs } from "@hooks/useLastMenuTabs"
import { useSelector } from "react-redux"
const MenuTabs = () => {
  // ** State
  const [menuDataState, setMenuDataState] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { lastMenuTabs, setLastMenuTabs } = useLastMenuTabs()
  const storeDeveloper = useSelector((state) => state.developer)

  //** ComponentDidMount
  useEffect(() => {
    let oldState = localStorage.getItem("lastMenuChachedState")
    if (oldState) {
      let newState = oldState === "true"
      setMenuDataState(newState)
    }
  }, [])

  const onSort = (newState, sortable, store) => {
    if (
      store.dragging &&
      store.dragging.props &&
      JSON.stringify(lastMenuTabs) !== JSON.stringify(newState)
    ) {
      setLastMenuTabs(null, null, newState)
    }
  }

  const removeNavItem = (id) => {
    let filteredData = lastMenuTabs.filter((item) => item.id !== id)
    setLastMenuTabs(null, null, filteredData)
  }

  function removeQueryStringsIfExist(url) {
    if (url) {
      if (url?.indexOf("?") !== -1) {
        return url.split("?")[0]
      }
      return url
    }
  }

  const isActive = (url) => {
    // console.log(url, window.location?.pathname)
    return (
      removeQueryStringsIfExist(window.location?.pathname) ===
      removeQueryStringsIfExist(url)
    )
  }

  const hideOnPage = () => {
    return window.location?.pathname === "/chat"
  }

  return (
    menuDataState === true &&
    lastMenuTabs &&
    !storeDeveloper.editMode &&
    !hideOnPage() && (
      <Fragment>
        <Nav tabs className="d-none d-md-flex mb-3 justify-content-end">
          <ReactSortable
            list={lastMenuTabs}
            setList={onSort}
            className="last-menu"
            style={{
              display: "inherit"
            }}
          >
            {lastMenuTabs?.map((item, index) => {
              return (
                <NavItem
                  key={item.id}
                  style={{
                    fontSize: "12px",
                    background: isActive(item?.navLink)
                      ? "rgba(1, 1, 1, 0.1)"
                      : "transparent"
                  }}
                >
                  <NavLink
                    // to={item.navLink ?? "/"}
                    active={isActive(item?.navLink)}
                    onClick={(e) => {
                      // set permissionname Can for component
                      sessionStorage.setItem("current_page_perm", item.resource)
                      if (
                        item?.navLink?.length === 0 ||
                        item?.navLink === "#" ||
                        item?.disabled === true
                      ) {
                        e.preventDefault()
                      }

                      let url = item.navLink ?? "/"
                      // navigate(url, { replace: false })
                      // console.log(url)
                      navigate(url)
                      // toggle(item?.id)
                    }}
                  >
                    {t(item?.title ?? "")}
                    <span
                      className="last-menu-close"
                      onClick={() => removeNavItem(item.id)}
                    >
                      x
                    </span>
                  </NavLink>
                </NavItem>
              )
            })}
          </ReactSortable>
        </Nav>
      </Fragment>
    )
  )
}

export default MenuTabs
