// ** Logo
import logo from "@src/assets/images/logo/logo.png"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import RandomQuoteLoader from "@src/shared/RandomQuoteLoader"

const SpinnerComponent = ({ isLogin, isFromDynPage }) => {
  const storeDomainClaims = useSelector((state) => state.domain_claims)

  const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query)
    const [matches, setMatches] = useState(mediaMatch.matches)

    useEffect(() => {
      const handler = (e) => setMatches(e.matches)
      mediaMatch.addListener(handler)
      return () => mediaMatch.removeListener(handler)
    })
    return matches
  }
  const isLargeScreen = useMediaQuery("(min-width: 500px)")

  return (
    <div
      style={{
        height: isFromDynPage ? "65vh" : "100vh"
      }}
      className="fallback-spinner app-loader"
    >
      <img
        className="fallback-logo"
        src={
          storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoLoginBig ??
          storeDomainClaims.data?.unit?.unit_app_attributes?.appLogo ??
          storeDomainClaims.data.app?.logo?.appLogoLoginBig ??
          storeDomainClaims.data.app?.logo?.appLogo ??
          logo
        }
        style={{
          maxWidth:
            (storeDomainClaims.data?.unit?.unit_app_attributes
              ?.appLogoLoginBig ||
              storeDomainClaims.data.app?.logo?.appLogoLoginBig) &&
            !isLargeScreen
              ? "85%"
              : "auto"
        }}
        alt="logo"
        height={
          (storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoLoginBig ||
            storeDomainClaims.data.app?.logo?.appLogoLoginBig) &&
          !isLargeScreen
            ? "auto"
            : "85px"
        }
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
      <RandomQuoteLoader
        isLogin={isLogin}
        styles={{
          fontWeight: 400,
          fontSize: "1.3rem"
        }}
      />
    </div>
  )
}

export default SpinnerComponent
