// ** React Imports
import { useEffect } from "react"
import { NavLink } from "react-router-dom"

// ** Icons Imports
import { Disc, X, Circle } from "react-feather"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"
import { useSelector } from "react-redux"

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover
  } = props

  const defLogo = require("@src/assets/images/logo/logo.svg").default

  // ** Vars
  const user = getUserData()
  const storeDomainClaims = useSelector((state) => state.domain_claims)
  // ** Reset open group

  function getFaviconEl() {
    return document.getElementById("favicon")
  }

  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  const logoResp = storeDomainClaims.data?.app?.logo
  if (
    logoResp?.favicon ||
    storeDomainClaims?.data?.unit?.unit_app_attributes?.favicon
  ) {
    const favicon = getFaviconEl()
    favicon.href =
      storeDomainClaims?.data?.unit?.unit_app_attributes?.favicon ??
      logoResp.favicon
  }

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink
            to={user ? getHomeRouteForLoggedInUser(user.role_name) : "/"}
            className="navbar-brand"
          >
            <span className="brand-logo">
              {(storeDomainClaims.data?.unit?.unit_app_attributes?.appLogo ||
                storeDomainClaims.data.app.logo?.appLogo) && (
                <img
                  src={
                    storeDomainClaims.data?.unit?.unit_app_attributes
                      ?.appLogo ?? storeDomainClaims.data.app.logo?.appLogo
                  }
                  alt="logo"
                  height="32px"
                />
              )}
            </span>
            {!storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoText &&
              !storeDomainClaims.data?.app?.logo?.appLogoText &&
              storeDomainClaims.data.app?.name && (
                <h2 className="brand-text mb-0">
                  {storeDomainClaims.data.app.name}
                </h2>
              )}
            {(storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoText ||
              storeDomainClaims.data?.app?.logo?.appLogoText) && (
              <img
                style={{
                  width: "48%",
                  position: "absolute",
                  height: "auto",
                  left: !menuHover && menuCollapsed ? "180px" : "68px"
                  // top: "30px"
                }}
                src={
                  storeDomainClaims.data.unit.unit_app_attributes.appLogoText ??
                  storeDomainClaims.data?.app?.logo?.appLogoText
                }
                alt="logo_text"
              />
            )}
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler />
            <X
              onClick={() => setMenuVisibility(false)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
