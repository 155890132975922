import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getRespData, getSeries } from "@utils"

const DonurChart = ({
  pageAttr,
  currentAttr,
  item,
  handleChangeFilterFromChild
}) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const areaColors = item?.chartOpt?.colors ?? {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7"
  }

  // ** Chart Options
  const options = {
    chart: {
      zoom: {
        enabled: false
      },
      parentHeightOffset: 0,
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          download: false
          // download:
          //   '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">file_download</span>'
          // customIcons: [
          //   {
          //     icon: '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">refresh</span>',
          //     // index: 4,
          //     title: t("Refresh Data"),
          //     class: "custom-icon",
          //     click: function (chart, options, e) {
          //       // console.log("clicked custom-icon")
          //       return handleChangeFilterFromChild({
          //         item: currentAttr
          //       })
          //     }
          //   }
          // ]
        }
      }
    },
    legend: {
      show: true,
      position: "bottom"
    },
    labels: getRespData(item, "categories", store),
    colors: getRespData(item, "color", store) ?? [
      areaColors.series3,
      areaColors.series2,
      areaColors.series1
    ],
    dataLabels: {
      enabled: false,
      formatter(val) {
        return `${val}`
      }
    },
    // tooltip: {
    //   shared: false,
    //   y: {
    //     formatter: function (val) {
    //       return item?.chartOpt?.tooltip_suffix
    //         ? `${val} ${t(item?.chartOpt?.tooltip_suffix)}`
    //         : ""
    //     }
    //   }
    // },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "2rem",
              fontFamily: "Montserrat"
            },
            value: {
              fontSize: "1rem",
              fontFamily: "Montserrat",
              formatter(val) {
                return `${val}`
              }
            }
            // total: {
            //   show: true,
            //   fontSize: "1.5rem",
            //   label: "Operational"
            //   // formatter() {
            //   //   return "31%"
            //   // }
            // }
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380
          },
          legend: {
            position: "bottom"
          }
        }
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "1.5rem"
                  },
                  value: {
                    fontSize: "1rem"
                  },
                  total: {
                    fontSize: "1.5rem"
                  }
                }
              }
            }
          }
        }
      }
    ]
  }

  // ** Chart Series
  const series = getSeries(item, store, true)

  return (
    series && (
      <Chart
        options={options}
        series={series}
        type="donut"
        height={item?.chartOpt?.height ?? "100%"}
      />
    )
  )
}

export default DonurChart
