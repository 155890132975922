// ** I18n Imports
import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Cache from "i18next-localstorage-cache"

i18n

  // Enables the i18next backend
  .use(Backend)
  .use(Cache)
  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    cache: {
      // turn on or off
      enabled: true,
      // prefix for stored languages
      // prefix: "i18next_res_",
      // expiration
      expirationTime: 7 * 24 * 60 * 60 * 1000
      // language versions
      // versions: {}
    },
    // lng: "id",
    debug: process.env.NODE_ENV === "development",
    backend: {
      /* translation file path */
      // loadPath: `../../locales/{{lng}}.json`
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`
      // loadPath: `${process.env.REACT_APP_SSO_URL}/static/locales/{{lng}}.json`
    },
    fallbackLng: "id",
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    }
  })

export default i18n
