// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
// ** Axios Imports
import axios from "axios"
const axiosSso = useJwt.ssoAxiosInterceptor

export const getUserProfile = createAsyncThunk("appChat/getTasks", async () => {
  const response = await axios.get("/apps/chat/users/profile-user")
  return response.data
})

export const getChatContacts = createAsyncThunk(
  "appChat/getChatContacts",
  async () => {
    // const contacts_chats_loading = getState()["chat"]["contacts_chats_loading"]
    // console.log(contacts_chats_loading)
    // if (contacts_chats_loading) {
    //   return rejectWithValue({})
    // }

    const response = await axiosSso.get("/api/UserChat")
    return response.data
  }
)

export const selectChat = createAsyncThunk(
  "appChat/selectChat",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const response = await axiosSso.get(`/api/UserChat/${id}`)

    const contacts_chats_loading = getState()["chat"]["contacts_chats_loading"]
    const contacts_chats_success_last_fetch =
      getState()["chat"]["contacts_chats_success_last_fetch"]
    if (!contacts_chats_loading) {
      const now = new Date()
      const lastFetch = contacts_chats_success_last_fetch
      const differenceInMinutes = (now - lastFetch) / 1000 / 60

      if (differenceInMinutes > 0.16667 || !lastFetch) {
        dispatch(getChatContacts())
      }
    }

    return response.data.data
  }
)

export const selectChatFromCache = createAsyncThunk(
  "appChat/selectChatFromCache",
  async (id, { dispatch }) => {
    return id
  }
)

export const sendMsg = createAsyncThunk(
  "appChat/sendMsg",
  async (obj, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axiosSso.post(`/api/UserChat/${obj.receiver}`, {
        ...obj
      })
      // const selectedUser = getState()["chat"].selectedUser
      // setTimeout(() => {
      //   // if (!Object.keys(selectedUser).length) {
      //   dispatch(selectChat(obj.receiver))
      //   // }
      //   // dispatch(getChatContacts())
      // }, 200)

      return response.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const newIncomingChat = createAsyncThunk(
  "appChat/newIncomingChat",
  (obj, { dispatch, getState, rejectWithValue }) => {
    try {
      // console.log(obj)
      // setTimeout(() => {
      // dispatch(selectChat(obj.sender))
      // dispatch(getChatContacts())
      // }, 200)

      return obj
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setIncomingMessageState = createAsyncThunk(
  "appChat/setIncomingMessageState",
  (obj, { dispatch, getState, rejectWithValue }) => {
    try {
      return obj
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const chatDelivered = createAsyncThunk(
  "appChat/chatDelivered",
  async (obj, { dispatch, getState, rejectWithValue }) => {
    try {
      // console.log(obj)
      return obj
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const chatRead = createAsyncThunk(
  "appChat/chatRead",
  async (obj, { dispatch, getState, rejectWithValue }) => {
    try {
      // console.log(obj)
      return obj
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const appChatSlice = createSlice({
  name: "appChat",
  initialState: {
    chats: [],
    contacts: [],
    contacts_chats_loading: false,
    contacts_chats_success_last_fetch: null,
    userProfile: {},
    selectedUser: {},
    selectedUser_loading: false,
    selectedUsers_loading: [],
    selectedUsers: [],
    incoming_message_load: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })

      .addCase(getChatContacts.pending, (state, action) => {
        state.contacts_chats_loading = true
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.chats = action.payload.chats
        state.contacts = action.payload.contacts
        state.contacts_chats_loading = false
        state.contacts_chats_success_last_fetch = new Date()
      })
      .addCase(getChatContacts.rejected, (state, action) => {
        // console.log(action.payload)
        state.chats = action.payload?.chats
        state.contacts = action.payload?.contacts
        state.contacts_chats_loading = false
      })

      .addCase(selectChat.pending, (state, action) => {
        state.selectedUsers_loading.push(parseInt(action?.meta?.arg ?? "0"))
        state.selectedUser_loading = true
        if (state.selectedUsers) {
          // const stateNew = current(state)
          // console.log(
          //   stateNew?.selectedUsers,
          //   parseInt(action?.meta?.arg ?? "0")
          // )
          if (state.selectedUsers?.[parseInt(action?.meta?.arg ?? "0")]) {
            state.selectedUser =
              state.selectedUsers[parseInt(action?.meta?.arg ?? "0")]
          }
          // else {
          //   state.selectedUser = {}
          // }
        }
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        // console.log(action.payload)
        // state.selectedUser = {
        //   ...(state.selectedUser ?? {}),
        //   ...action.payload
        // }
        state.selectedUser = action.payload
        state.selectedUser_loading = false

        /////CHAT DATA CACHE ARRAY
        if (action.payload?.contact?.id) {
          if (state.selectedUsers) {
            state.selectedUsers[action.payload?.contact?.id] = action.payload
          } else {
            state.selectedUsers.push({
              [action.payload?.contact?.id]: action.payload
            })
          }

          /////CHAT LIST
          if (state.chats) {
            const objIndex = state.chats.findIndex(
              (obj) => obj.current === action.payload?.contact?.id
            )

            if (objIndex !== -1) {
              state.chats[objIndex].unseenMsgs = 0
            }
          }
        }

        const index = state.selectedUsers_loading?.indexOf(
          action.payload?.contact?.id
        )
        if (index > -1) {
          state.selectedUsers_loading.splice(index, 1)
        }
      })
      .addCase(selectChat.rejected, (state, action) => {
        state.selectedUser_loading = false
        const index = state.selectedUsers_loading.indexOf(
          action.payload?.contact?.id
        )
        if (index > -1) {
          state.selectedUsers_loading.splice(index, 1)
        }
      })

      .addCase(selectChatFromCache.fulfilled, (state, action) => {
        state.selectedUser = JSON.parse(
          JSON.stringify(state.selectedUsers[action.payload])
        )

        /////CHAT LIST
        if (state.chats) {
          const objIndex = state.chats.findIndex(
            (obj) => obj.current === state.selectedUser?.contact?.id
          )

          if (objIndex !== -1) {
            state.chats[objIndex].unseenMsgs = 0
          }
        }
      })

      .addCase(sendMsg.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (
          Object.keys(state.selectedUser).length &&
          state.selectedUser?.chat
        ) {
          state.selectedUser.chat.push(action.payload.data)
          state.selectedUsers[action.payload?.data?.receiver]?.chat?.push(
            action.payload.data
          )
        }

        if (state.chats) {
          // const stateNew = current(state)
          // console.log(stateNew.chats)
          // console.log(action.payload.data)

          const objIndex = state.chats.findIndex(
            (obj) => obj.current === action.payload.data.receiver
          )

          if (objIndex !== -1) {
            const newPayload = {
              ...action.payload.data,
              ...{ current: action.payload.data.receiver }
            }

            // console.log(newPayload)
            state.chats[objIndex] = { ...state.chats[objIndex], ...newPayload }
          }
        }
      })
      .addCase(newIncomingChat.fulfilled, (state, action) => {
        // console.log(action.payload)
        const receiver = parseInt(action.payload?.receiver)
        const sender = parseInt(action.payload?.sender)
        const id = parseInt(action.payload?.id)

        const newPayload = {
          id: id,
          message: action.payload.message,
          sender: action.payload.sender,
          receiver: receiver,
          current: sender,
          from_me: false
        }

        if (
          Object.keys(state.selectedUser).length &&
          state.selectedUser?.chat
        ) {
          state.selectedUser.chat.push(newPayload)

          /////CHAT DATA CACHE ARRAY
          state.selectedUsers[action.payload?.sender]?.chat?.push(newPayload)
        }

        /////CHAT LIST
        if (state.chats) {
          // const stateNew = current(state)
          // console.log(stateNew.chats)
          // console.log(action.payload.data)

          const objIndex = state.chats.findIndex(
            (obj) => obj.current === sender
          )

          if (objIndex !== -1) {
            // console.log(newPayload)
            const newPayloadChatList = {
              ...newPayload,
              ...{
                timestamp: new Date(),
                isOnline: true,
                current_data_isOnline: true,
                unseenMsgs:
                  state.selectedUser?.contact?.id !== sender
                    ? (state.chats[objIndex]?.unseenMsgs ?? 0) + 1
                    : state.chats[objIndex]?.unseenMsgs
              }
            }
            // console.log(stateNew.selectedUser, newPayloadChatList)
            state.chats[objIndex] = {
              ...state.chats[objIndex],
              ...newPayloadChatList
            }
          }
        }
        state.incoming_message_load = true
      })

      .addCase(setIncomingMessageState.fulfilled, (state, action) => {
        state.incoming_message_load = false
      })

      .addCase(chatDelivered.fulfilled, (state, action) => {
        // const stateNew = current(state)
        // console.log(action.payload)
        const userId = parseInt(action.payload?.receiver)
        const messageId = parseInt(action.payload?.id)

        /////CHAT DATA SELECTED
        if (
          Object.keys(state.selectedUser).length &&
          state.selectedUser?.chat
        ) {
          const objIndexChat = state.selectedUser.chat.findIndex(
            (obj) => obj.id === messageId
          )

          if (objIndexChat !== -1) {
            // console.log(stateNew.selectedUser.chat[objIndexChat])
            state.selectedUser.chat[objIndexChat].deliveredAt =
              action.payload?.deliveredAt
            state.selectedUser.chat[objIndexChat]["from_me"] = true
          }
        }

        /////CHAT DATA CACHE ARRAY
        if (state.selectedUsers[userId]) {
          const objIndexChat = state.selectedUsers[userId].chat.findIndex(
            (obj) => obj.id === messageId
          )

          if (objIndexChat !== -1) {
            state.selectedUsers[userId].chat[objIndexChat].deliveredAt =
              action.payload?.deliveredAt
            state.selectedUsers[userId].chat[objIndexChat]["from_me"] = true
          }
        }

        /////CHAT LIST
        if (state.chats) {
          const objIndex = state.chats.findIndex(
            (obj) => obj.current === userId
          )

          if (objIndex !== -1) {
            state.chats[objIndex].deliveredAt = action.payload?.deliveredAt
            state.chats[objIndex]["from_me"] = true
          }
        }
      })

      .addCase(chatRead.fulfilled, (state, action) => {
        const userId = parseInt(action.payload?.receiver)
        const messageId = parseInt(action.payload?.id)

        // const stateNew = current(state)
        // console.log(action.payload)
        if (
          Object.keys(state.selectedUser).length &&
          state.selectedUser?.chat
        ) {
          const objIndexChat = state.selectedUser.chat.findIndex(
            (obj) => obj.id === messageId
          )

          if (objIndexChat !== -1) {
            // console.log(stateNew.selectedUser.chat[objIndexChat])
            if (action.payload?.deliveredAt) {
              state.selectedUser.chat[objIndexChat].deliveredAt =
                action.payload?.deliveredAt
            }
            state.selectedUser.chat[objIndexChat].readAt =
              action.payload?.readAt
            state.selectedUser.chat[objIndexChat]["from_me"] = true
          }
        }

        /////CHAT DATA CACHE ARRAY
        if (state.selectedUsers[userId]) {
          const objIndexChat = state.selectedUsers[userId].chat.findIndex(
            (obj) => obj.id === messageId
          )

          if (objIndexChat !== -1) {
            if (action.payload?.deliveredAt) {
              state.selectedUsers[userId].chat[objIndexChat].deliveredAt =
                action.payload?.deliveredAt
            }
            state.selectedUsers[userId].chat[objIndexChat].readAt =
              action.payload?.readAt
            state.selectedUsers[userId].chat[objIndexChat]["from_me"] = true
          }
        }

        /////CHAT LIST
        if (state.chats) {
          const objIndex = state.chats.findIndex(
            (obj) => obj.current === userId
          )
          // console.log(state.chats[objIndex])
          if (objIndex !== -1) {
            if (action.payload?.deliveredAt) {
              state.chats[objIndex].deliveredAt = action.payload?.deliveredAt
            }
            state.chats[objIndex].readAt = action.payload?.readAt
            state.chats[objIndex]["from_me"] = true
          }
        }
      })
  }
})

export default appChatSlice.reducer
