// ** React Imports
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// ** Custom Components
import Avatar from "@components/avatar"
import { useLocation, useNavigate } from "react-router-dom"
// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Bell, X, Check, AlertTriangle, Info, Lock } from "react-feather"

// ** Reactstrap Imports
import {
  Button,
  Badge,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  ButtonDropdown
} from "reactstrap"

import { useTranslation } from "react-i18next"

import { setSelectedData, getDataById } from "@src/shared/dynamic_pages/store"
import { badgeDecrement } from "@src/redux/notifications"
import { renderIcon } from "../../../../views/apps/menu/store/my_menu"

const NotificationDropdown = () => {
  // ** Hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.notifications)
  const navigate = useNavigate()
  const storeMenu = useSelector((state) => state.my_menu)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }
  const location = useLocation()
  // ** Notification Array
  const notificationsArray = [
    // {
    //   img: require('@src/assets/images/portrait/small/avatar-s-15.jpg').default,
    //   subtitle: 'Won the monthly best seller badge.',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>Congratulation Sam 🎉</span>winner!
    //     </p>
    //   )
    // },
    // {
    //   img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
    //   subtitle: 'You have 10 unread messages.',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>New message</span>&nbsp;received
    //     </p>
    //   )
    // },
    // {
    //   avatarContent: 'MD',
    //   color: 'light-danger',
    //   subtitle: 'MD Inc. order updated',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>Revised Order 👋</span>&nbsp;checkout
    //     </p>
    //   )
    // },
    // {
    //   title: <h6 className='fw-bolder me-auto mb-0'>System Notifications</h6>,
    //   switch: (
    //     <div className='form-check form-switch'>
    //       <Input type='switch' name='customSwitch' id='exampleCustomSwitch' defaultChecked />
    //     </div>
    //   )
    // },
    // {
    //   avatarIcon: <X size={14} />,
    //   color: 'light-danger',
    //   subtitle: 'USA Server is down due to hight CPU usage',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>Server down</span>&nbsp;registered
    //     </p>
    //   )
    // },
    // {
    //   avatarIcon: <Check size={14} />,
    //   color: 'light-success',
    //   subtitle: 'Last month sales report generated',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>Sales report</span>&nbsp;generated
    //     </p>
    //   )
    // },
    // {
    //   avatarIcon: <AlertTriangle size={14} />,
    //   color: 'light-warning',
    //   subtitle: 'BLR Server using high memory',
    //   title: (
    //     <p className='media-heading'>
    //       <span className='fw-bolder'>High memory</span>&nbsp;usage
    //     </p>
    //   )
    // }
  ]

  // ** Function to render Notifications
  /*eslint-disable */

  const renderNotificationItems = (data) => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false
        }}
      >
        {data?.map((item, index) => {
          let icon = <Info size={14} />
          let color = "light-primary"
          if (item?.id_notificationsType === 1) {
            icon = <Lock size={14} />
            color = "light-warning"
          } else if (item?.id_notificationsType === 2) {
            icon = renderIcon("material:task_alt", "", 18)
            color = "primary"
            if (item?.title.includes("Selesai")) {
              icon = renderIcon("material:thumb_up_alt", "", 18)
              color = "success"
            }
          }
          return (
            <a
              key={index}
              className="d-flex"
              href={item.switch ? "#" : "/"}
              onClick={(e) => {
                if (!item.switch) {
                  e.preventDefault()

                  dispatch(
                    getDataById({
                      id: item.id,
                      storeName: "my_info_notifications",
                      endpointName: "api/my_info/notifications",
                      baseUrl: "sso"
                    })
                  ).then((_) => {
                    dispatch(badgeDecrement({ data: item }))
                    setDropdownOpen()
                    if (!item.clickActionUrl) {
                      navigate(
                        `dyn_pages/_admin_/notifications/view/${item.id}?refresh=1`
                      )
                    } else {
                      // console.log(item.clickActionUrl)
                      const menuObj = storeMenu.dataMyMenu.find((el) => {
                        let menuLink = ""
                        // console.log(el.routePath)
                        // console.log(item.clickActionUrl?.includes("/edit/"), item.clickActionUrl?.split("/edit")[0])
                        if (item.clickActionUrl?.indexOf("/edit/")) {
                          menuLink = item.clickActionUrl?.split("/edit")[0]
                        } else if (item.clickActionUrl?.indexOf("/view/")) {
                          menuLink = item.clickActionUrl?.split("/view")[0]
                        } else if (item.clickActionUrl?.indexOf("/add/")) {
                          menuLink = item.clickActionUrl?.split("/add")[0]
                        }

                        return el.routePath === menuLink
                      })
                      // console.log(relPageId)
                      if (menuObj) {
                        sessionStorage.setItem(
                          "current_page_perm",
                          menuObj.permission_subject
                        )
                      }
                      navigate(`${item.clickActionUrl}`)
                    }
                  })

                  // dispatch(
                  //   setSelectedData({
                  //     rowData: item,
                  //     storeName: "my_info_notifications"
                  //   })
                  // ).then(() => {
                  //   dispatch(badgeDecrement({ data: item }))
                  //   navigate(`dyn_pages/my_info/notifications/view/${item.id}`)
                  // })
                }
              }}
            >
              <div
                className={classnames("list-item d-flex", {
                  "align-items-start": !item.switch,
                  "align-items-center": item.switch
                })}
              >
                {!item.switch ? (
                  <Fragment>
                    <div className="me-1">
                      <Avatar
                        icon={icon}
                        color={color}

                        // {...(item.img
                        //   ? { img: item.img, imgHeight: 32, imgWidth: 32 }
                        //   : item.avatarContent
                        //   ? {
                        //       content: item.avatarContent,
                        //       color: item.color
                        //     }
                        //   : item.avatarIcon
                        //   ? {
                        //       icon: item.avatarIcon,
                        //       color: item.color
                        //     }
                        //   : null)}
                      />
                    </div>
                    <div className="list-item-body flex-grow-1">
                      <p className="media-heading">
                        <span className="fw-bolder"> {item.title}</span>
                      </p>
                      <small className="notification-text">
                        {item.description}
                      </small>
                    </div>
                    {!item?.read_date && (
                      <Badge tag="div" color="danger" pill>
                        {t("New")}
                      </Badge>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {item.title}
                    {item.switch}
                  </Fragment>
                )}
              </div>
            </a>
          )
        })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */

  return (
    //   <UncontrolledDropdown
    //   tag="li"
    //   className="dropdown-notification nav-item me-25"
    // >
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      tag="li"
      className="dropdown-notification nav-item me-25"
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={21} />
        {store?.data?.length > 0 && store?.data?.[0].count_unread > 0 && (
          <Badge pill color="danger" className="badge-up">
            {store.data[0].count_unread <= 20
              ? store.data[0].count_unread
              : "20+"}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0">
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 me-auto">
              {t("Notifications")}
            </h4>
            {store?.data?.length > 0 && store.data?.[0].count_unread > 0 && (
              <Badge tag="div" color="light-primary" pill>
                {store?.data[0]?.count_unread} {t("Unread")}
              </Badge>
            )}
          </DropdownItem>
        </li>
        {renderNotificationItems(store?.data)}
        <li className="dropdown-menu-footer">
          <Button
            onClick={(e) => {
              toggleDropdown()
              return navigate("dyn_pages/_admin_/notifications")
            }}
            color="primary"
            block
          >
            {t("View all notifications")}
          </Button>
        </li>
      </DropdownMenu>
    </ButtonDropdown>

    // </UncontrolledDropdown>
  )
}

export default NotificationDropdown
