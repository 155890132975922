import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"

export const getDataList = createAsyncThunk(
  `employees/getDataList`,
  async () => {
    const response = await useJwt.hrAxiosInterceptor.get(
      `Employee?birthday_now=1`
    )

    localStorage.setItem("birthdayNow", JSON.stringify(response.data.data))

    return {
      data: response.data.data
    }
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: "employees",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///ALL
      .addCase(getDataList.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.status = "succeeded"
        state.isLoading = false
        state.data = action.payload.data
      })
      .addCase(getDataList.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })
  }
})

export default StoreSlice.reducer
