// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"

export const setEditMode = createAsyncThunk(
  "setEditMode",
  async (params, { getState }) => {
    const state = !getState()["developer"].editMode
    sessionStorage.setItem("editMode", state)
    return state
  }
)

export const setEditModeJson = createAsyncThunk(
  "setEditModeJson",
  async ({ storeName, pageAttr }, { getState }) => {
    const state = !getState()["developer"].editModeJson[storeName]
    return { storeName, pageAttr, state }
  }
)

export const setLastState = createAsyncThunk(
  "setLastState",
  async ({ storeName, lastState }, { getState }) => {
    return { storeName, lastState }
  }
)

export const setEditModeJsonAttr = createAsyncThunk(
  "setEditModeJsonAttr",
  async ({ storeName, pageAttr }, { getState }) => {
    return { storeName, pageAttr }
  }
)

export const setOriginalJsonAttr = createAsyncThunk(
  "setOriginalJsonAttr",
  async ({ storeName, pageAttr }, { getState }) => {
    return { storeName, pageAttr }
  }
)

export const setChangedJsonAttr = createAsyncThunk(
  "setChangedJsonAttr",
  async ({ storeName, pageAttr }, { getState }) => {
    return { storeName, pageAttr }
  }
)

export const setAppNeedUpdate = createAsyncThunk(
  "setAppNeedUpdate",
  async (state, { getState }) => {
    return state
  }
)

const initialEditMode = () => {
  const item = window.sessionStorage.getItem("editMode")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const DeveloperSlice = createSlice({
  name: "developer",
  initialState: {
    editMode: initialEditMode(),
    editModeJson: {},
    pageAttributes: {},
    pageAttributesLastState: {},
    pageAttributesChanged: {},
    pageAttributesBackup: {},
    isLoading: false,
    appNeedUpdate: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setEditMode.fulfilled, (state, action) => {
        state.editMode = action.payload
        state.editModeJson = {}
        state.pageAttributes = {}
        state.pageAttributesChanged = {}
        state.pageAttributesBackup = {}
      })
      .addCase(setEditModeJson.fulfilled, (state, action) => {
        let pageAttributesCopy = { ...state.pageAttributes }
        let editModeJsonCopy = { ...state.editModeJson }

        if (action.payload?.pageAttr) {
          pageAttributesCopy[action.payload?.storeName] =
            action.payload?.pageAttr
        }

        if (action.payload.state === false) {
          pageAttributesCopy[action.payload?.storeName] = null
        }
        editModeJsonCopy[action.payload?.storeName] = action.payload.state
        state.pageAttributes = pageAttributesCopy
        state.editModeJson = editModeJsonCopy
      })
      .addCase(setLastState.fulfilled, (state, action) => {
        let pageAttributesCopy = { ...state.pageAttributesLastState }
        pageAttributesCopy[action.payload?.storeName] = {
          ...(pageAttributesCopy[action.payload?.storeName] ?? {}),
          ...action.payload?.lastState
        }
        state.pageAttributesLastState = pageAttributesCopy
      })
      .addCase(setEditModeJsonAttr.fulfilled, (state, action) => {
        let pageAttributesCopy = { ...state.pageAttributes }
        pageAttributesCopy[action.payload?.storeName] = action.payload?.pageAttr
        state.pageAttributes = pageAttributesCopy
      })
      .addCase(setChangedJsonAttr.fulfilled, (state, action) => {
        let pageAttributesCopy = { ...state.pageAttributesChanged }
        pageAttributesCopy[action.payload?.storeName] = action.payload?.pageAttr
        state.pageAttributesChanged = pageAttributesCopy
      })
      .addCase(setOriginalJsonAttr.fulfilled, (state, action) => {
        let pageAttributesCopy = { ...state.pageAttributesBackup }
        pageAttributesCopy[action.payload?.storeName] = action.payload?.pageAttr
        state.pageAttributesBackup = pageAttributesCopy
      })
      .addCase(setAppNeedUpdate.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.appNeedUpdate = action.payload
      })
  }
})

export default DeveloperSlice.reducer
