/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
import { Skeleton } from "@mui/material"
import {
  neutralizeBack,
  revivalBack,
  renderInputOnGrid,
  getDepthValue,
  disableBtnCondition
} from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import {
  Edit2,
  Minus,
  MoreVertical,
  Plus,
  Save,
  Trash2,
  X
} from "react-feather"
import { useTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { useSkin } from "@hooks/useSkin"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import CustomHeader from "./CustomHeaders"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getDataById,
  getDataList,
  resetSelectedData,
  setSelectedData,
  putData,
  setDataById
} from "./store"

import { NoDataDisplay } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"
import {
  Card,
  CardImg,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  CardTitle,
  CardSubtitle,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupText
} from "reactstrap"

import DynamicRow from "@src/utility/generateForm/dynamicRow"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import classnames from "classnames"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import img1 from "@src/assets/images/slider/04.jpg"
import InputNumber from "rc-input-number"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const GridCard = ({
  pageAttr,
  storeName,
  filtersCustomAdd,
  handleFiltersChangeToParent,
  customHeaderSet,
  lookupMode,
  lookupMode_fieldName,
  lookupMode_defaultValue,
  lookupMode_onChange,
  lookupMode_isMulti
}) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)
  const { skin } = useSkin()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState(
    store.pageData[storeName]?.params?.search ?? ""
  )
  const [currentPage, setCurrentPage] = useState(
    store.pageData[storeName]?.params?.page ?? 1
  )
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(
    store.pageData[storeName]?.params?.length ?? 9
  )
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const paramss = useParams()
  const storeUnit = useSelector((state) => state.units)
  const handleAddEdit = (rowData) => {
    const pageRedirect =
      pageAttr?.crudOpt?.rowClickEvent === "view"
        ? "view"
        : !ability.can("update", sessionStorage.getItem("current_page_perm")) &&
          !ability.can("post", sessionStorage.getItem("current_page_perm"))
        ? "view"
        : "edit"
    if (rowData) {
      setPageType(pageRedirect)
      ///if Modal
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        setLoadingText(
          `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
        )
        setLoadingModal(true)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // setLoading(false)
          setShowModal(true)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          ).then((res) => {
            setLoadingModal(false)
          })
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              setLoadingModal(false)
              setShowModal(true)
            }
          )
        }
      } else {
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          let navigatePath = `${location.pathname}/${pageRedirect}/${rowData.id}`
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          ).then(() => {
            navigate(navigatePath)
          })
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              return navigate(
                `${location.pathname}/${pageRedirect}/${rowData.id}`
              )
            }
          )
        }
      }
    } else {
      setPageType("add")
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        dispatch(resetSelectedData(storeName)).then((r) => {
          setLoadingModal(false)
          setLoading(false)
          setShowModal(true)
        })
      } else {
        dispatch(resetSelectedData(storeName)).then((r) => {
          navigate(`${location.pathname}/add`)
        })
      }
    }
  }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    // console.log("here")
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res?.payload?.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const handleDeleteSelected = async () => {
    const countSelectedCopy = selectedRows.length
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You will delete")} ${countSelectedCopy} ${t(
        "selected data. Deleted data cannot be recovered"
      )}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${countSelectedCopy} ${t(
        pageAttr.pageTitle
      )}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(
        `${t("Deleting")} ${countSelectedCopy} Data ${t(pageAttr.pageTitle)}`
      )
      setLoading(true)
      const values = selectedRows.map((a) => a.id)
      // console.log(values)
      dispatch(
        deleteSelectedRowData({
          id: values,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setSelectedRows([])
          setToggleCleared(!toggleCleared)
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${countSelectedCopy} ${t(
              pageAttr.pageTitle
            )}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  /////PAGINATION SERVERSIDE
  //search
  const handleDebounceFn = (inputValue) => {
    // console.log(inputValue)
    setLoading(true)
    handleFiltersChangeToParent({
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir,
      search: inputValue,
      sort,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustom
    })
    setLoading(false)
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])

  const handleFilter = (event) => {
    setSearchTerm(event)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      debounceFn(event)
    } else {
      let storeData = JSON.parse(
        JSON.stringify(store.pageData[storeName]?.data)
      )

      const searchField = pageAttr?.crudOpt?.data?.dynamicColumnsSearchFields
      let filterData = []
      if (event.length) {
        filterData = storeData.filter((item) => {
          for (let key in item) {
            if (searchField) {
              if (
                (key,
                searchField.indexOf(key) > -1 &&
                  item[key].toLowerCase().includes(event.toLowerCase()))
              ) {
                return item
              }
            } else {
              if (
                `${item[key]}`.toLowerCase().includes(event.toLowerCase()) &&
                !key.includes("id", "Id_", "id_")
              ) {
                return item
              }
            }
          }
        })
        setFilteredData(filterData)
        setSearchTerm(event)
      }
    }
  }

  const handlePagination = (page) => {
    // console.log(page)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        length: rowsPerPage,
        page: page.selected + 1,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     length: rowsPerPage,
      //     page: page.selected + 1,
      //     ...filtersCustom
      //   })
      // )
    }
    setCurrentPage(page.selected + 1)
  }

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        length: value,
        page: currentPage,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     length: value,
      //     page: currentPage,
      //     ...filtersCustom
      //   })
      // )
    }
    setRowsPerPage(value)
  }

  const handleFiltersChange = (value, par_name) => {
    filtersCustom[par_name] = value
    handleFiltersChangeToParent({
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir,
      sort,
      search: searchTerm,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustom
    })
    // dispatch(
    //   getDataList({
    //     storeName,
    //     endpointName: pageAttr?.crudOpt?.endpointName,
    //     sort_dir,
    //     sort,
    //     search: searchTerm,
    //     page: currentPage,
    //     length: rowsPerPage,
    //     ...filtersCustom
    //   })
    // )
  }

  const handleSort = (column, sortDirection) => {
    setSortDir(sortDirection)
    setSort(column.sortField)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        page: currentPage,
        length: rowsPerPage,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     page: currentPage,
      //     length: rowsPerPage,
      //     ...filtersCustom
      //   })
      // )
    }
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const CustomPagination = () => {
    if (!pageAttr.crudOpt?.hideTablePagination) {
      const count = Number(
        Math.ceil(
          pageAttr?.crudOpt?.serverSideGrid
            ? store.pageData[storeName]?.total / rowsPerPage
            : store.pageData[storeName]?.data?.length / rowsPerPage
        )
      )
      const countFitered = Number(Math.ceil(filteredData.length / rowsPerPage))
      return (
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          pageCount={searchTerm.length ? countFitered : count || 1}
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => {
            handlePagination(page)
          }}
          breakLabel="..."
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          activeClassName="active"
          pageClassName="page-item"
          breakClassName="page-item"
          nextLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextClassName="page-item next-item"
          previousClassName="page-item prev-item"
          containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
        />
      )
    } else {
      return null
    }
  }

  const getAutoColumn = (type = "dt_column") => {
    let result = []
    // console.log(store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns)
    if (
      store.pageAttributes[storeName]?.crudOpt?.dynamicColumnsFieldsAuto ||
      !store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns
    ) {
      if (store.pageData[storeName]?.data?.length > 0) {
        const columnsKeyExist = store.pageAttributes[
          storeName
        ]?.crudOpt?.data?.dynamicColumns?.map((obj) => {
          return obj?.fieldName
        })

        const globalProperties =
          store.pageAttributes[storeName]?.crudOpt
            ?.dynamicColumnsGlobalProperties

        Object.keys(store.pageData[storeName]?.data?.[0])?.map((existKey) => {
          if (!columnsKeyExist?.includes(existKey) && existKey !== "id") {
            const columnTitle = existKey.replace(/([A-Z])/g, " $1")
            let columnTitleEnd =
              columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)

            if (columnTitleEnd?.includes(".")) {
              columnTitleEnd = columnTitleEnd?.split(".")?.[-1]
            }

            const column = {
              fieldName: `${existKey}`,
              columnName: t(`${columnTitleEnd?.replaceAll("_", " ")}`),
              ...globalProperties
            }
            if (type === "pageAttr_column") {
              result.push(column)
            } else {
              result.push(renderDtColumnProp(column))
            }
          }
        })
      }
    } else {
      result = store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns
    }
    return result
  }

  const paginationComponentOptions = {
    rowsPerPageText: t("Show"),
    rangeSeparatorText: t("of")
    // selectAllRowsItem: true,
    // selectAllRowsItemText: "Todos"
  }

  const getTotals = (data, key) => {
    let total = 0
    data.forEach((item) => {
      // console.log(item[key])
      total += item[key]
    })
    return total
  }

  const dataToRender = () => {
    const filters = {
      search: searchTerm
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (!loading) {
      let dataGrid = store.pageData[storeName]?.data
      if (dataGrid) {
        let dataGridCopy = JSON.parse(JSON.stringify(dataGrid))
        if (dataGridCopy.length > 0) {
          // for subtotal
          let subtotal_fields = {}
          pageAttr?.crudOpt?.data?.dynamicColumns.some((el) => {
            if (el.hasOwnProperty("subtotal")) {
              subtotal_fields[el.fieldName] = getTotals(
                dataGridCopy,
                el.fieldName
              )
            }
          })
          // console.log(subtotal_fields)
          // console.log(
          //   "subtotal present ?",
          //   Object.keys(subtotal_fields).length > 0
          // )

          if (Object.keys(subtotal_fields).length > 0) {
            let newRecord = {}
            // console.log(subtotal_fields)
            Object.keys(dataGridCopy[0]).map((key) => {
              // console.log(subtotal_fields[key])
              newRecord[key] = subtotal_fields[key] ?? ""
            })
            // console.log(newRecord)
            dataGridCopy.push(newRecord)
          }

          return dataGridCopy
        } else if (dataGrid.length === 0 && isFiltered) {
          return []
        }
      }
    } else {
      return Array(10).fill({})
    }
  }

  const [serverSideProps, setServerSideProps] = useState({})
  const storeParent = useSelector(
    (state) => state?.[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  )

  const getData = () => {
    setLoading(true)
    setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)
    // if (pageAttr?.crudOpt?.serverSideGrid) {
    //   setCurrentPage(store.pageData[storeName].params?.page)
    // }
    dispatch(
      pageAttr?.crudOpt?.serverSideGrid
        ? getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            sort_dir,
            sort,
            search: searchTerm,
            page: currentPage,
            length: rowsPerPage,
            ...filtersCustom
          })
        : getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            ...filtersCustom
          })
    ).then((x) => {
      setLoading(false)
      if (pageAttr?.crudOpt?.serverSideGrid) {
        setServerSideProps({
          sortServer: true,
          paginationServer: true,
          paginationTotalRows: store.pageData[storeName]?.total,
          paginationComponent: CustomPagination
          // paginationComponentOptions: paginationComponentOptions
        })
      }
    })
  }

  /////EDITABLE ROW
  const [inputOnGrid, setInputOnGrid] = useState({})
  const [inputOnGridState, setInputOnGridState] = useState({})
  const [loadingPostPut, setLoadingPostPut] = useState(false)

  const childElements = document?.querySelectorAll(".input-editable-row")
  childElements.forEach(function (element) {
    element.addEventListener("click", function (event) {
      event.stopPropagation()
      // console.log("Button clicked")
    })
  })

  const successToast = () => {
    const msg =
      pageType !== "add" ? t("Successfully Updated") : t("Successfully Created")
    return toast.success(`${msg} ${t(pageAttr?.pageTitle)}`, {
      style: {
        padding: "16px",
        color: colors.success.main,
        border: `1px solid ${colors.success.main}`
      },
      iconTheme: {
        primary: colors.success.main
      }
    })
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    } else {
      errorFieldsMsg =
        res?.payload?.error?.response?.data?.message ?? "Error response"
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const reloadOtherPage = (type) => {
    // console.log(type)
    if (type === "edit") {
      if (pageAttr?.crudOpt?.afterEditReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterEditReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            // console.log(store_to_reset)
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              // dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }
    } else if (type === "add") {
      if (pageAttr?.crudOpt?.afterAddReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterAddReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            // console.log(store_to_reset)
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              // dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }
    }
  }

  const defaultPayload = {
    storeName: storeName,
    endpointName: pageAttr?.crudOpt?.endpointName,
    baseUrl: pageAttr?.crudOpt?.baseUrl,
    callback_page: window.location.href
  }

  const updateDataToApi = (
    data,
    afterChangeEventType = null,
    afterChangeEventInputId = null,
    afterChangeEventInputProp = null
  ) => {
    data = { ...data, ...defaultPayload }
    setLoadingPostPut(!loadingPostPut)
    dispatch(putData(data))
      .then((res) => {
        console.log(res)
        setLoadingPostPut(!loadingPostPut)
        if (res?.meta?.requestStatus === "fulfilled") {
          let newData = data
          console.log(newData)
          if (afterChangeEventInputId) {
            newData = { ...data, ...(res?.payload?.data?.data ?? {}) }
          }
          console.log(newData)
          dispatch(
            setDataById({
              rowData: newData,
              storeName: storeName
            })
          ).then(async (re) => {
            if (afterChangeEventType) {
              // console.log(
              //   afterChangeEventInputProp.target.closest(".rdt_TableRow")
              // )

              // temukan semua elemen baris pada tabel
              const rows = document?.querySelectorAll(".rdt_TableRow")

              // cari baris setelah baris yang berisi tombol Save yang diklik
              let nextRow = null
              rows?.forEach((row, index) => {
                const currentId = `row-${(afterChangeEventInputId ?? "_")
                  .split("_")
                  ?.pop()}`
                if (row?.id === currentId) {
                  nextRow = rows[index + 1]
                }
              })
              // jika baris berikutnya ditemukan, klik elemen dengan id yang diinginkan
              if (nextRow) {
                const currentIdTrim = afterChangeEventInputId
                  .replace(/[0-9]/g, "")
                  .replace("grid_input_", "cell_edit_")
                // console.log(currentIdTrim)
                const targetElement = nextRow.querySelector(
                  `[id^="${currentIdTrim}"]`
                )
                if (targetElement) {
                  // console.log(targetElement)
                  // console.log(targetElement.value)
                  // targetElement.value = ""
                  targetElement.click()
                  // const intervalId = setInterval(() => {
                  //   const element = document.getElementById(
                  //     "grid_input_emptyWeight_10"
                  //   )
                  //   if (element) {
                  //     clearInterval(intervalId)
                  //     element.focus()
                  //     console.log(element)
                  //     // lakukan sesuatu dengan element
                  //   }
                  // }, 100)
                  // const observer = new MutationObserver((mutationsList) => {
                  //   for (let mutation of mutationsList) {
                  //     if (
                  //       mutation.type === "childList" &&
                  //       mutation.addedNodes.length > 0
                  //     ) {
                  //       console.log(mutation)
                  //       const input =
                  //         mutation.addedNodes[0].querySelector(
                  //           'input[type="text"]'
                  //         )
                  //       if (input) {
                  //         input.focus()
                  //         observer.disconnect()
                  //         break
                  //       }
                  //     }
                  //   }
                  // })

                  // observer.observe(targetElement, { childList: true })
                }
              }
            }
          })

          reloadOtherPage("edit")
          successToast()
        } else if (res?.meta?.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  const handleEditOnRow = (row, column) => {
    // console.log(inputOnGrid)
    // console.log(inputOnGridState)
    // console.log(
    //   !(inputOnGridState[column?.fieldName] ?? []).includes(row?.id),
    //   !Array.isArray(inputOnGridState[column?.fieldName])
    // )
    // console.log("here", inputOnGrid)
    if (!inputOnGrid[column?.fieldName]) {
      inputOnGrid[column?.fieldName] = [
        { id: row?.id, value: row[column.fieldName] }
      ]
    } else {
      const inputOnGridCopy = { ...inputOnGrid }
      const prevColumnData = inputOnGrid[column.fieldName] || []
      const existingColumnData = prevColumnData.find(
        (obj) => obj.id === row?.id
      )
      if (existingColumnData) {
        if (existingColumnData.value !== row[column.fieldName]) {
          const updatedColumnData = {
            ...existingColumnData,
            value: row[column.fieldName]
          }
          const updatedColumnIndex = prevColumnData.findIndex(
            (obj) => obj.id === row?.id
          )
          inputOnGrid[column.fieldName][updatedColumnIndex] = updatedColumnData
        }
      } else {
        inputOnGrid[column.fieldName] = [
          ...(prevColumnData ?? []),
          { id: row?.id, value: row[column.fieldName] }
        ]
      }
      // setInputOnGrid(inputOnGridCopy)
    }

    if (!inputOnGridState) {
      setInputOnGridState({ [column?.fieldName]: [row?.id] })
    } else {
      if (!(inputOnGridState[column?.fieldName] ?? []).includes(row?.id)) {
        // setInputOnGridState((prevState) => {
        //   const newInputOnGridState = { ...prevState }
        //   const fieldValue = newInputOnGridState[column?.fieldName] ?? []

        //   if (!Array.isArray(fieldValue)) {
        //     newInputOnGridState[column?.fieldName] = [row?.id]
        //   } else {
        //     newInputOnGridState[column?.fieldName] = [...fieldValue, row?.id]
        //   }

        //   return newInputOnGridState
        // })

        if (!Array.isArray(inputOnGridState[column?.fieldName])) {
          inputOnGridState[column?.fieldName] = [row?.id]
        } else {
          inputOnGridState[column?.fieldName].push(row?.id)
        }
      }
    }

    dispatch(setSelectedData({ rowData: row, storeName: storeName }))
    // console.log(inputOnGrid)
    // console.log(inputOnGridState)
  }

  const handleSaveOnRow = (
    e,
    row,
    column,
    afterChangeEventType = null,
    afterChangeEventInputId = null
  ) => {
    const selectedVal = inputOnGrid[column?.fieldName]?.find(
      (c) => c.id === row?.id
    )?.value
    let index = inputOnGridState[column?.fieldName]?.findIndex(
      (c) => c.id === row?.id
    )
    // console.log(e, row, column, index)
    if (row[column.fieldName] !== selectedVal) {
      if (index !== undefined && inputOnGridState[column?.fieldName]) {
        inputOnGridState[column.fieldName].splice(index, 1)
        setInputOnGridState((prevData) => ({
          ...prevData,
          [column?.fieldName]: (prevData[column?.fieldName] ?? []).filter(
            (id) => id !== row?.id
          )
        }))
      }

      let newDataTemp = { ...row, [column.fieldName]: selectedVal }

      // check if extraPayload is exists
      const extraPayload = column?.editableInputOpt?.payloadExtra
      if (extraPayload) {
        extraPayload.map((extra) => {
          newDataTemp[extra?.alias] = tempAsyncOptValues?.[
            column?.fieldName
          ]?.find((c) => c?.value === selectedVal)?.[extra?.fieldName]
        })
      }

      updateDataToApi(
        newDataTemp,
        afterChangeEventType,
        afterChangeEventInputId,
        e
      )
    }
  }

  const handleCancelSaveOnRow = (e, row, column) => {
    let index = inputOnGridState[column?.fieldName]?.findIndex(
      (c) => c.id === row?.id
    )
    if (index !== undefined && inputOnGridState[column?.fieldName]) {
      inputOnGridState[column.fieldName].splice(index, 1)
      setInputOnGridState((prevData) => ({
        ...prevData,
        [column?.fieldName]: (prevData[column?.fieldName] ?? []).filter(
          (id) => id !== row?.id
        )
      }))
    }

    // console.log(inputOnGridState, inputOnGrid, selectedVal)
  }

  const changeInputOnGrid = (id, fieldName, newValue) => {
    let index = inputOnGrid[fieldName]?.findIndex((c) => c.id === id)

    if (index !== -1) {
      inputOnGrid[fieldName][index]["value"] = newValue
      setInputOnGrid((prevState) => {
        const newState = { ...prevState }
        // const index = newState[fieldName].findIndex((c) => c.id === id)
        try {
          newState[fieldName][index]["value"] = newValue
        } catch (error) {
          console.error(error)
        }
        return newState
      })
    } else {
      // if id does not exist in inputOnGrid[fieldName], push newValue
      inputOnGrid[fieldName] = [{ id: id, value: newValue }]
      setInputOnGrid((prevState) => {
        const newState = { ...prevState }
        newState[fieldName] = [
          ...(newState[fieldName] || []),
          { id: id, value: newValue }
        ]
        return newState
      })
    }
    // console.log(index, inputOnGrid)
  }

  const renderInputOnGrid = (row, column, inputOnGridChild) => {
    const fieldName = column?.fieldName
    const fieldLabel = column?.columnName
    const fieldNameEl = column?.fieldName?.replace(/\./g, "_")
    const id = row?.id
    const value = row[fieldName]
    const item = column?.editableInputOpt

    let placeholder = `Enter ${t(
      column?.columnName ?? column?.fieldName ?? ""
    )}`
    // console.log(inputOnGrid)
    const currentStateVal = (inputOnGrid[fieldName] ?? []).find(
      (c) => c.id === id
    )?.value
    const id_element = `grid_input_${fieldNameEl}_${id}`
    const id_element_save = `grid_input_btn_save_${fieldNameEl}_${id}`
    const id_element_cancel = `grid_input_btn_cancel_${fieldNameEl}_${id}`
    // console.log(value, inputOnGridChild, inputOnGrid, currentStateVal)
    let btnSave =
      value !== currentStateVal ? (
        <Fragment>
          <Button.Ripple
            id={id_element_save}
            className="btn-icon"
            outline
            color="secondary"
            onClick={(event) => {
              event.stopPropagation()
              handleSaveOnRow(event, row, column)
            }}
          >
            <Save size={16} />
          </Button.Ripple>
          <UncontrolledTooltip placement="bottom" target={id_element_save}>
            {t("Save Changes")}
          </UncontrolledTooltip>
        </Fragment>
      ) : (
        <Fragment>
          <Button.Ripple
            id={id_element_save}
            className="btn-icon"
            outline
            color="secondary"
            onClick={(event) => {
              event.stopPropagation()
              handleCancelSaveOnRow(event, row, column)
            }}
          >
            <X size={16} />
          </Button.Ripple>
          <UncontrolledTooltip placement="bottom" target={id_element_save}>
            {t("Cancel Edit")}
          </UncontrolledTooltip>
        </Fragment>
      )
    const defaultValuex = !column?.editableInputOpt
      ?.afterEditNextRowSameInputClearValue
      ? row[column?.fieldName]
      : ""

    const handleKeyPress = (event, row, column, id_element) => {
      if (event.key === "Enter") {
        // console.log("Enter key pressed")
        const afterChangeEventType = "nextRow"
        const afterChangeEventInputId = id_element
        handleSaveOnRow(
          event,
          row,
          column,
          afterChangeEventType,
          afterChangeEventInputId
        )
      }
    }

    if (column?.editableInputOpt?.type === "number") {
      return (
        <InputGroup>
          <Input
            type="number"
            // value={defaultValuex}
            defaultValue={defaultValuex}
            // defaultValue={inputOnGrid[column?.fieldName][row?.id]?.value}
            // value={inputOnGrid[column?.fieldName][row?.id]?.value}
            onClick={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              event.stopPropagation()
              // console.log(event.target.value)
              const newValue =
                column?.editableInputOpt?.valueType === "int"
                  ? parseInt(event.target.value ?? 0)
                  : event.target.value ?? 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
              // let index = inputOnGrid[column?.fieldName]?.findIndex(
              //   (c) => c.id === row?.id
              // )

              // if (
              //   index !== undefined &&
              //   inputOnGrid[column?.fieldName] &&
              //   inputOnGrid[column.fieldName][index]
              // ) {
              //   inputOnGrid[column.fieldName][index]["value"] = newValue
              //   setInputOnGrid((prevState) => {
              //     const newState = { ...prevState }
              //     const index = newState[column.fieldName].findIndex(
              //       (c) => c.id === row.id
              //     )
              //     if (index !== undefined && newState[column.fieldName]) {
              //       newState[column.fieldName][index]["value"] = newValue
              //     }
              //     return newState
              //   })
              // } else {
              //   // if id does not exist in inputOnGrid[column?.fieldName], push newValue
              //   inputOnGrid[column.fieldName] = [
              //     { id: row?.id, value: newValue }
              //   ]
              //   setInputOnGrid((prevState) => {
              //     const newState = { ...prevState }
              //     newState[column.fieldName] = [
              //       ...(newState[column.fieldName] || []),
              //       { id: row?.id, value: newValue }
              //     ]
              //     return newState
              //   })
              // }
              // console.log(index, inputOnGrid)
            }}
            id={id_element}
            placeholder={placeholder}
          />
          {btnSave}
        </InputGroup>
      )
    } else if (column?.editableInputOpt?.type === "number-btn") {
      let rules = { ...column?.editableInputOpt?.rules }
      let defaultRules = {}
      if (rules?.maxLength && !rules?.maxLength?.message) {
        let max = rules?.maxLength?.value ?? rules?.maxLength
        defaultRules = {
          ...defaultRules,
          maxLength: {
            value: max,
            message: `Max. length is ${max}`
          }
        }
      }

      if (rules?.minLength && !rules?.minLength?.message) {
        let min = rules?.minLength?.value ?? rules?.minLength ?? 0
        defaultRules = {
          ...defaultRules,
          minLength: {
            value: min,
            message: `Min. length is ${min}`
          }
        }
      }
      let mixRules = { ...rules, ...defaultRules }
      return (
        <InputGroup
          style={{
            boxShadow: "none"
          }}
        >
          <InputNumber
            className="input-lg input-editable-row"
            style={{
              width: "100%"
            }}
            min={mixRules?.minLength?.value ?? 0}
            max={mixRules?.maxLength?.value}
            defaultValue={defaultValuex}
            // upHandler={(event) => {
            //   console.log(event)
            // }}
            // onClick={(event, tes) => {
            //   console.log(event, tes)
            //   // event.stopPropagation()
            // }}
            onChange={(e, tes) => {
              // event.stopPropagation()
              // console.log(tes)
              const val = e
              const newValue =
                column?.editableInputOpt?.valueType === "int"
                  ? parseInt(val ?? 0)
                  : val ?? 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            id={id_element}
            placeholder={placeholder}
            upHandler={<Plus />}
            downHandler={<Minus />}
          />
          {btnSave}
        </InputGroup>
      )
    } else if (
      column?.editableInputOpt?.type === "boolean" ||
      column?.type === "boolean"
    ) {
      return (
        <InputGroup
          style={{
            justifyContent: "flex-end"
          }}
        >
          <InputGroupText>
            <Input
              type="checkbox"
              defaultChecked={defaultValuex === true}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                // console.log(event, event.target.checked)
                const newValue = event.target.checked
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              id={id_element}
            />
          </InputGroupText>
          {btnSave}
        </InputGroup>
      )
    } else if (column?.editableInputOpt?.type === "currency") {
      return (
        <InputGroup>
          {/* <InputGroupText>
            {column?.editableInputOpt?.icon ? (
              renderIcon(column?.editableInputOpt?.icon, "", 14)
            ) : localStorage.getItem("i18nextLng") === "id" ? (
              <FontAwesomeIcon className="align-middle" icon="rupiah-sign" />
            ) : (
              <Paid size={14} />
            )}
          </InputGroupText> */}
          <Cleave
            className="form-control"
            value={defaultValuex}
            placeholder={
              column?.editableInputOpt?.placeholder
                ? t(column?.editableInputOpt?.placeholder)
                : `10.000`
            }
            options={{
              numeral: true
              // numeralDecimalMark: ",",
              // numeralDecimalScale: 2,
              // delimiter: ".",
              // numeralPositiveOnly: true
            }}
            id={id_element}
            onClick={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              event.stopPropagation()
              const newValue =
                event.target.rawValue !== ""
                  ? parseFloat(event.target.rawValue)
                  : 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
          />
          {btnSave}
        </InputGroup>
      )
    } else {
      if (item?.dataFrom?.api && item?.type === "dropdown") {
        return (
          <InputGroup>
            <Select
              id={id_element}
              isClearable={!item?.rules?.required}
              className={classnames("react-select react-select-on-grid")}
              styles={{
                width: "89%"
              }}
              autoFocus
              placeholder={
                item.placeholder
                  ? t(item.placeholder)
                  : `${t("Select")} ${t(fieldLabel)}`
              }
              noOptionsMessage={() => t("No Options Found")}
              loadingMessage={() => t("Loading...")}
              classNamePrefix="select"
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                const newValue = event?.value
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              onFocus={(e) => {
                e.preventDefault()
                e.stopPropagation()
                return loadDropdownFromApi(fieldName, e, item)
              }}
              isLoading={tempAsyncOptLoading[fieldName] ?? false}
              options={tempAsyncOptValues[fieldName]}
              defaultValue={defaultValuex}
              menuPortalTarget={document.body}
              menuPlacement="auto"
            />
            {btnSave}
          </InputGroup>
        )
      } else if (item?.dataFrom?.value && item?.type === "dropdown") {
        let componentsInput = renderAvatarImgIcon(item)
        return (
          <InputGroup>
            <Select
              id={id_element}
              isClearable={!item?.rules?.required}
              className={classnames("react-select react-select-on-grid")}
              styles={{
                width: "89%"
              }}
              autoFocus
              placeholder={
                item.placeholder
                  ? t(item.placeholder)
                  : `${t("Select")} ${t(fieldLabel)}`
              }
              noOptionsMessage={() => t("No Options Found")}
              loadingMessage={() => t("Loading...")}
              classNamePrefix="select"
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                const newValue = event?.value
                // console.log(newValue)
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              isLoading={false}
              options={item?.dataFrom?.value ?? []}
              defaultValue={item?.dataFrom?.value?.find(
                (item) => item.selected
              )}
              value={item?.dataFrom?.value.find(
                (c) => c.value === defaultValuex
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              {...componentsInput}
            />
            {btnSave}
          </InputGroup>
        )
      } else {
        return (
          <InputGroup>
            <Input
              type="text"
              autoFocus
              defaultValue={defaultValuex}
              id={id_element}
              placeholder={placeholder}
              onInput={(event) => {
                event.stopPropagation()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                const newValue = event.target.value
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              onKeyPress={(event) => {
                event.stopPropagation()
                handleKeyPress(event, row, column, id_element)
              }}
            />
            {btnSave}
          </InputGroup>
        )
      }
    }
  }

  useEffect(() => {
    // setCurrentPage(store.pageData[storeName]?.params?.page ?? 1)
    // setRowsPerPage(store.pageData[storeName]?.params?.length ?? 10)
    // setSearchTerm(store.pageData[storeName]?.params?.search ?? "")
    // console.log(storeName)
    // console.log(storeParent)
    // console.log(store.pageData)
    if (
      (!store.pageData[storeName]?.data || pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      getData()
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)

        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (
            !store.pageData[storeName]?.isLoading &&
            store.pageData[storeName]?.status !== "failed"
          ) {
            getData()
          }
        }
      }

      if (pageAttr?.crudOpt?.serverSideGrid) {
        setServerSideProps({
          sortServer: true,
          paginationServer: true,
          paginationTotalRows: store.pageData[storeName]?.total,
          paginationComponent: CustomPagination
          // paginationComponentOptions: paginationComponentOptions
        })
      }

      if (pageAttr?.crudOpt?.data?.dynamicColumns) {
        const inputOnGridCopy = { ...inputOnGrid }
        const dataStore = store.pageData[storeName]?.data
        // console.log(dataStore)
        dataStore?.forEach((data) => {
          pageAttr?.crudOpt?.data?.dynamicColumns
            ?.filter((item) => item.editable)
            ?.forEach((it) => {
              const prevColumnData = inputOnGridCopy[it.fieldName] || []
              const existingColumnData = prevColumnData.find(
                (obj) => obj.id === data?.id
              )
              if (existingColumnData) {
                if (existingColumnData.value !== data[it.fieldName]) {
                  const updatedColumnData = {
                    ...existingColumnData,
                    value: data[it.fieldName]
                  }
                  const updatedColumnIndex = prevColumnData.findIndex(
                    (obj) => obj.id === data?.id
                  )
                  inputOnGridCopy[it.fieldName][updatedColumnIndex] =
                    updatedColumnData
                }
              } else {
                inputOnGridCopy[it.fieldName] = [
                  ...(prevColumnData ?? []),
                  { id: data?.id, value: data[it.fieldName] }
                ]
              }
            })
        })

        setInputOnGrid(inputOnGridCopy)
        setLoading(false)
        // console.log(inputOnGrid)
      }

      setLoading(false)
    }
    // console.log(store)
  }, [dispatch, sort_dir, sort, currentPage])

  const conditionalRowStyles = [
    {
      when: (row) => !row.id,
      style: {
        // backgroundColor: "#f3f2f7",
        // borderBottom: "1px solid",
        // borderColor: "#ebe9f1",
        minHeight: 0,
        height: "38px"
      }
      // classNames: ["rdt_TableHeadRow", "rdt_TableCol"]
    }
  ]

  const getImgField = (row) => {
    // console.log(row)
    let fieldValue = row?.[pageAttr?.crudOpt?.gridImgField]
    // if (fieldValue) {
    //   return fieldValue
    // }
    return (
      fieldValue ?? getDepthValue(row, pageAttr?.crudOpt?.gridImgField) ?? img1
    )
  }

  const getTitleField = (row, field) => {
    let fieldValue = row?.[field]
    return fieldValue ?? getDepthValue(row, field) ?? img1
  }

  return (
    <Fragment>
      <CustomHeader
        pageAttr={pageAttr}
        searchTerm={searchTerm}
        rowsPerPage={rowsPerPage}
        handleFilter={handleFilter}
        handlePerPage={handlePerPage}
        handleAddEdit={handleAddEdit}
        selectedRow={selectedRows}
        handleDeleteSelected={handleDeleteSelected}
        handleFiltersChange={handleFiltersChange}
        filtersCustomAdd={filtersCustomAdd}
        storeName={storeName}
        customHeaderSet={customHeaderSet}
      />
      <Row className="match-height">
        {!loading ? (
          store.pageData[storeName]?.data?.length > 0 ? (
            (searchTerm.length && !pageAttr?.crudOpt?.serverSideGrid
              ? filteredData
              : store.pageData[storeName]?.data
            )?.map((row, index) => {
              // console.log(pageAttr)
              return (
                <Col
                  key={`col_${row.id ?? index}`}
                  lg={pageAttr?.crudOpt?.gridSizeLg ?? "4"}
                  md={pageAttr?.crudOpt?.gridSizeMd ?? "6"}
                  sm={pageAttr?.crudOpt?.gridSizeSm ?? "12"}
                >
                  <Card
                    // onClick={
                    //   pageAttr?.crudOpt?.rowClickEvent
                    //     ? () => {
                    //         handleAddEdit(row)
                    //       }
                    //     : null
                    // }
                    // style={{
                    //   cursor: pageAttr?.crudOpt?.rowClickEvent
                    //     ? "pointer"
                    //     : "inherit",
                    //   ...(pageAttr?.crudOpt?.gridStyle ?? {})
                    // }}
                    onClick={() => {
                      handleAddEdit(row)
                    }}
                    style={{
                      cursor: "pointer",
                      ...(skin === "dark"
                        ? pageAttr?.crudOpt?.gridStyle_dark ??
                          pageAttr?.crudOpt?.gridStyle ??
                          {}
                        : pageAttr?.crudOpt?.gridStyle ?? {})
                    }}
                    className={classnames("card-app-design grid-card-item", {
                      "bg-light-danger": selectedRows.some(
                        (el) => el.id === row.id
                      ),
                      "bg-light-primary border-primary2 shadow-none":
                        pageAttr?.isViewOnForm
                    })}
                  >
                    {pageAttr?.crudOpt?.gridImgField && (
                      // <CardImg
                      //   style={{
                      //     height: "160px",
                      //     width: "100%",
                      //     margin: "auto",
                      //     ...(pageAttr?.crudOpt?.gridImgStyle ?? {})
                      //   }}
                      //   top
                      //   src={getImgField(row)}
                      //   alt="Card Img"
                      // />
                      <div
                        className=""
                        style={{
                          height: "160px",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundColor: "rgba(65, 134, 224, 0.12)",
                          ...(pageAttr?.crudOpt?.gridImgStyle ?? {}),
                          width: "100%",
                          margin: "auto",
                          backgroundImage: `url(${getImgField(row)})`,
                          backgroundRepeat: "no-repeat"
                        }}
                      ></div>
                    )}
                    <div
                      className="d-flex text-end align-items-center"
                      style={{
                        position: "absolute",
                        // right: "10px",
                        // top: "20px"
                        right: "0px",
                        top: "2px"
                      }}
                    >
                      {pageAttr?.crudOpt?.deleteSelection &&
                        (!row.disableEdit || !row.disableDelete) &&
                        ability.can(
                          "delete",
                          sessionStorage.getItem("current_page_perm")
                        ) &&
                        (paramss?.pageType !== "view" ||
                          !pageAttr?.disableBtnDeleteFromParent) && (
                          <div className="form-check form-check-inline mt-25 me-0">
                            <Input
                              onChange={(e) => {
                                e.stopPropagation()
                                if (!e.target.checked) {
                                  const filtered = selectedRows.filter(
                                    function (el) {
                                      return el.id !== row.id
                                    }
                                  )
                                  setSelectedRows(filtered)
                                } else {
                                  setSelectedRows([...selectedRows, row])
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              type="checkbox"
                            />
                          </div>
                        )}

                      {(!store.pageAttributes[storeName]?.crudOpt
                        ?.disabledEdit ||
                        !store.pageAttributes[storeName]?.crudOpt
                          ?.disabledDelete) &&
                        paramss?.pageType !== "view" &&
                        (ability.can(
                          "update",
                          sessionStorage.getItem("current_page_perm")
                        ) ||
                          ability.can(
                            "delete",
                            sessionStorage.getItem("current_page_perm")
                          )) &&
                        (!row.disableEdit ||
                          !row.disableDelete ||
                          !store.pageAttributes[storeName]?.crudOpt
                            ?.disableBtnEditFromParent ||
                          !store.pageAttributes[storeName]?.crudOpt
                            ?.disableBtnDeleteFromParent) && (
                          <UncontrolledDropdown className="chart-dropdown">
                            <DropdownToggle
                              onClick={(e) => e.stopPropagation()}
                              color=""
                              className="bg-transparent btn-sm border-0 p-50"
                            >
                              <MoreVertical
                                size={18}
                                className="cursor-pointer"
                              />
                            </DropdownToggle>
                            <DropdownMenu end>
                              {ability.can(
                                "update",
                                sessionStorage.getItem("current_page_perm")
                              ) &&
                                !store.pageAttributes[storeName]?.crudOpt
                                  ?.disabledEdit &&
                                !row.disableEdit && (
                                  <DropdownItem
                                    className="w-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      handleAddEdit(row)
                                    }}
                                  >
                                    <Edit2 size={16} />
                                    <span className="align-middle ms-25">
                                      {t("Edit")}
                                    </span>
                                  </DropdownItem>
                                )}

                              {ability.can(
                                "delete",
                                sessionStorage.getItem("current_page_perm")
                              ) &&
                                !store.pageAttributes[storeName]?.crudOpt
                                  ?.disabledDelete &&
                                !row.disableDelete && (
                                  <DropdownItem
                                    className="w-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      handleDelete(row?.id)
                                    }}
                                  >
                                    <Trash2 size={16} />
                                    <span className="align-middle ms-25">
                                      {t("Delete")}
                                    </span>
                                  </DropdownItem>
                                )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                    </div>
                    <CardBody
                      style={{
                        ...(pageAttr?.crudOpt?.gridBodyStyle ?? {})
                      }}
                    >
                      {pageAttr?.crudOpt?.gridTitleField && (
                        <CardTitle
                          style={{
                            ...(pageAttr?.crudOpt?.gridTitleStyle ?? {}),
                            marginBottom: "1rem"
                          }}
                          tag="h4"
                        >
                          {getTitleField(
                            row,
                            pageAttr?.crudOpt?.gridTitleField
                          )}
                        </CardTitle>
                      )}

                      {pageAttr?.crudOpt?.gridSubTitleField && (
                        <CardSubtitle
                          className="text-muted"
                          style={{
                            ...(pageAttr?.crudOpt?.gridSubTitleStyle ?? {})
                          }}
                        >
                          {getTitleField(
                            row,
                            pageAttr?.crudOpt?.gridSubTitleField
                          )}
                        </CardSubtitle>
                      )}

                      {getAutoColumn("pageAttr_column")?.map((column) =>
                        !column?.inline_value ? (
                          <div
                            key={`row_${column.fieldName}`}
                            className="design-group mb-50 pt-50"
                          >
                            {column?.hideColumnName !== false &&
                              !column?.hideColumnName && (
                                <h6 className="section-label mb-25">
                                  {t(column.columnName)}
                                </h6>
                              )}
                            <div className="mb-0 text-wrap">
                              {!column?.editable ? (
                                <DynamicRow
                                  row={row}
                                  column={column}
                                  clickToDetailEvent={handleAddEdit}
                                />
                              ) : !row.id ||
                                row?.id === 0 ||
                                String(row?.id)?.includes("xx") ||
                                !(
                                  inputOnGridState[column.fieldName] ?? []
                                ).includes(row?.id) ? (
                                <DynamicRow
                                  row={row}
                                  column={column}
                                  clickToDetailEvent={handleEditOnRow}
                                />
                              ) : disableBtnCondition(
                                  pageAttr?.crudOpt,
                                  row,
                                  "edit-grid",
                                  storeUnit?.data,
                                  pageType ?? useParams()?.pageType,
                                  pageAttr
                                ) ? (
                                <DynamicRow
                                  row={row}
                                  column={column}
                                  clickToDetailEvent={clickEditableOnDisabled}
                                  disabledEditable={true}
                                />
                              ) : (
                                renderInputOnGrid(row, column, inputOnGrid)
                              )}
                            </div>
                          </div>
                        ) : (
                          <Row
                            key={`row_${column.fieldName}`}
                            className="design-group mb-50 pt-50 align-items-center"
                          >
                            <Col xs={6} lg={6}>
                              {column?.hideColumnName !== false &&
                                !column?.hideColumnName && (
                                  <h6 className="section-label mb-25">
                                    {t(column.columnName)}
                                  </h6>
                                )}
                            </Col>
                            <Col
                              xs={6}
                              lg={6}
                              className="mb-0 text-wrap text-end"
                            >
                              <DynamicRow
                                row={row}
                                column={column}
                                clickToDetailEvent={handleAddEdit}
                              />
                            </Col>
                          </Row>
                        )
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )
            })
          ) : (
            <NoDataDisplay />
          )
        ) : (
          [1, 2, 3, 4, 5, 6].map((row, i) => {
            return (
              <Col
                key={`col_${i}`}
                lg={pageAttr?.crudOpt?.gridSizeLg ?? "4"}
                md={pageAttr?.crudOpt?.gridSizeMd ?? "6"}
                sm={pageAttr?.crudOpt?.gridSizeSm ?? "12"}
              >
                <Skeleton
                  className="card card-app-design w-100"
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height="100%"
                >
                  {pageAttr?.crudOpt?.gridImgField && (
                    <div
                      className=""
                      style={{
                        height: "160px",
                        ...(pageAttr?.crudOpt?.gridImgStyle ?? {}),
                        width: "100%",
                        margin: "auto",
                        background: "none"
                      }}
                    ></div>
                  )}
                  <CardBody className="w-100">
                    {store.pageAttributes[
                      storeName
                    ]?.crudOpt?.data?.dynamicColumns?.map((column) => (
                      <div
                        key={`row_${column.fieldName}`}
                        className="design-group mb-2 pt-25"
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "20px"
                          }}
                        ></div>
                      </div>
                    ))}
                  </CardBody>
                </Skeleton>
              </Col>
            )
          })
        )}
      </Row>

      {pageAttr?.crudOpt?.serverSideGrid && (
        <Row end="true">
          <CustomPagination />
        </Row>
      )}

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </Fragment>
  )
}

export default GridCard
