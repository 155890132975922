import { AbilityContext } from "@src/utility/context/Can"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import { getUserData, parseStrAndField, getDomainInfo } from "@utils"
import { Fragment, useContext, useState } from "react"
import {
  CornerDownRight,
  Edit2,
  Eye,
  MoreVertical,
  Trash2
} from "react-feather"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getBaseUrl,
  getDataList,
  resetData,
  setDataFrame,
  setDataFrameHtml,
  setDataFrameHtmlModal,
  setDataFrameLoading
} from "@src/shared/dynamic_pages/store"
import { QRCodeSVG } from "qrcode.react"
import { renderToString } from "react-dom/server"
import toast from "react-hot-toast"
import Moment from "moment"
import "moment/locale/id"

const GlobalActionsTable = ({
  row,
  pageAttr,
  handleDelete,
  handleEdit,
  disabledAction = [],
  onlyText = false,
  withText = false,
  outline = false,
  rowClickEvent = "edit",
  storeName
}) => {
  const { t, i18n } = useTranslation()
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()
  const storeAll = useSelector((state) => state.dynamicStore)
  const dispatch = useDispatch()
  const dynamicColumnsExtraActionsButton =
    pageAttr?.crudOpt?.dynamicColumnsExtraActionsButton
  Moment.locale(i18n.language)

  const [currentLoadingFrameIndex, setCurrentLoadingFrameIndex] = useState(null)

  const extraBtnPrefixId = `btn-extra-action-${row?.id}`

  function getDepthValue(obj, path, defaultValue = null) {
    let props
    if (typeof obj === "undefined") return defaultValue
    if (typeof path === "string") {
      props = path.split(".").reverse()
    } else {
      props = path
    }
    if (path.length === 0) return obj || defaultValue
    let current = props.pop()
    return getDepthValue(obj?.[current], props, defaultValue)
  }

  const scrollToDiv = (divId) => {
    const element = document.getElementById(divId)
    // console.log(element)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  const userData = getUserData()
  const userDataField = [
    { key: "__userData.name__", value: userData?.name },
    { key: "__userData.avatar__", value: userData?.avatar },
    { key: "__userData.email__", value: userData?.email },
    { key: "__userData.username__", value: userData?.username },
    { key: "__userData.isManager__", value: userData?.isManager },
    { key: "__userData.id__", value: userData?.id }
  ]

  const domainData = getDomainInfo()
  // console.log(domainData)
  const appDataField = [
    { key: "__appData.logo__", value: domainData?.app?.logo?.appLogo },
    { key: "__appData.name__", value: domainData?.app?.name }
  ]
  const unitDataField = [
    { key: "__unitData.logo__", value: domainData?.unit?.attributes?.logo },
    { key: "__unitData.name__", value: domainData?.unit?.name },
    { key: "__unitData.code__", value: domainData?.unit?.code }
  ]

  function formatDate(type) {
    const datetime = new Date()
    if (type === 1) {
      // 23/01/2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })
    } else if (type === 2) {
      // 23/01/2024 21:25
      const option2 = `${formatDate(1)} ${datetime.toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit"
      })}`
      return option2
    } else if (type === 3) {
      // 23 Januari 2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "long",
        year: "numeric"
      })
    } else if (type === 4) {
      // 23 Januari 2024 Jam 21:25
      const option4 = `${formatDate(3)} Jam ${datetime.toLocaleTimeString(
        "id-ID",
        { hour: "2-digit", minute: "2-digit" }
      )}`
      return option4
    } else if (type === 5) {
      // 23 Jan 2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "short",
        year: "numeric"
      })
    } else if (type === 6) {
      // 23-01-2024
      return datetime
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\//g, "-")
    } else if (type === 7) {
      // 23-01-2024 21:25
      const option7 = `${formatDate(6)} ${datetime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
      })}`
      return option7
    } else if (type === 8) {
      // 2024-01-23
      return datetime.toISOString().split("T")[0]
    } else if (type === 9) {
      // 2024/01/23
      return datetime
        .toLocaleDateString("en-CA", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\-/g, "/")
    } else {
      // Default format (type not recognized)
      return "Invalid type"
    }
  }

  const formattedDate = [
    { key: "__currentDate__", value: formatDate(1) },
    { key: "__currentDate1__", value: formatDate(1) },
    { key: "__currentDate2__", value: formatDate(2) },
    { key: "__currentDate3__", value: formatDate(3) },
    { key: "__currentDate4__", value: formatDate(4) },
    { key: "__currentDate5__", value: formatDate(5) },
    { key: "__currentDate6__", value: formatDate(6) },
    { key: "__currentDate7__", value: formatDate(7) },
    { key: "__currentDate8__", value: formatDate(8) },
    { key: "__currentDate9__", value: formatDate(9) }
  ]

  const extraBtnShowCondition = (crudOpt, row = null) => {
    let result = false
    if (crudOpt?.showIf) {
      crudOpt?.showIf?.map((item, index) => {
        let field = item?.field
        let operator = item?.operator
        let value = item?.value

        let currentRowValue = null
        let evalStr = null

        if (field?.includes("__userData")) {
          userDataField?.map((itemUser) => {
            if (field === itemUser.key) {
              currentRowValue = itemUser?.value
            }
          })

          // if (field === "__userData.name__") {
          //   currentRowValue = userData?.name
          // } else if (field === "__userData.isManager__") {
          //   currentRowValue = userData?.isManager
          // } else if (field === "__userData.email__") {
          //   currentRowValue = userData?.email
          // } else if (field === "__userData.id__") {
          //   currentRowValue = userData?.id
          // } else if (field === "__userData.username__") {
          //   currentRowValue = userData?.username
          // }

          evalStr = `'${currentRowValue}' ${operator} ${value}`
          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (item?.showIf_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        } else {
          currentRowValue = field?.includes(".")
            ? getDepthValue(row, field)
            : row?.[field]

          evalStr = `'${currentRowValue}' ${operator} ${value}`

          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (item?.showIf_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        }
      })
    } else {
      result = true
    }
    return result
  }

  const enabledBtnViewCondition = (crudOpt, row = null) => {
    const paramss = useParams()
    let result = false
    if (crudOpt?.enabledViewBtnCondition) {
      crudOpt?.enabledViewBtnCondition?.map((item) => {
        let field = item?.field
        let operator = item?.operator
        let value = item?.value

        let currentRowValue = null
        let evalStr = null

        if (field?.includes("__userData")) {
          // const userData = getUserData()
          userDataField?.map((itemUser) => {
            if (field === itemUser.key) {
              currentRowValue = itemUser?.value
            }
          })
          // if (field === "__userData.name__") {
          //   currentRowValue = userData?.name
          // } else if (field === "__userData.isManager__") {
          //   currentRowValue = userData?.isManager
          // } else if (field === "__userData.email__") {
          //   currentRowValue = userData?.email
          // } else if (field === "__userData.id__") {
          //   currentRowValue = userData?.id
          // } else if (field === "__userData.username__") {
          //   currentRowValue = userData?.username
          // }

          evalStr = `'${currentRowValue}' ${operator} ${value}`
          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (crudOpt?.enabledViewBtnCondition_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        } else {
          currentRowValue = field?.includes(".")
            ? getDepthValue(row, field)
            : row?.[field]

          evalStr = `'${currentRowValue}' ${operator} ${value}`

          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (crudOpt?.enabledViewBtnCondition_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        }
      })
    }

    if (
      !ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      !ability.can("post", sessionStorage.getItem("current_page_perm"))
    ) {
      result = true
    }

    if (paramss?.pageType === "view") {
      result = true
    }

    return result
  }

  function hasColorClass(className) {
    let result = ""
    if (className && !className?.includes("flat")) {
      result = "has-color"
    }
    return result
  }

  const getParamsFromField1 = (
    staticUrl,
    paramsFromFields,
    appendToEndpointPath,
    row
  ) => {
    let params = ""
    let paramsArr = []
    if (paramsFromFields) {
      paramsFromFields?.map((it, idx) => {
        let paramKey = it?.paramName
        // let paramValue = row?.[it?.fieldName]
        let paramValue = parseStrAndField(row, it?.fieldName)
        params += `&${paramKey}=${paramValue}`
        paramsArr.push(`${paramKey}=${paramValue}`)
      })
    }
    if (appendToEndpointPath) {
      // let appendToEndpointPathObj = appendToEndpointPath?.split(",")
      appendToEndpointPath?.map((it, idx) => {
        let paramKey = it?.paramName
        let paramValue = parseStrAndField(row, it)
        params += `/${paramValue}`
        paramsArr.push(`${paramValue}`)
      })
    }

    const isQueryStr = staticUrl?.includes("?")

    let newStaticUrl = !appendToEndpointPath
      ? `${staticUrl}${isQueryStr ? "" : "?"}${paramsArr.join("&")}`
      : `${staticUrl}${isQueryStr ? "/" : "/"}${paramsArr.join("/")}`

    let staticUrlFix = !appendToEndpointPath
      ? newStaticUrl
          .replace("&&", "&")
          .replace("/?&", "?")
          .replace("??", "?")
          .replace("/?", "?")
          .replace("/&", "&")
          .replace("?&", "?")
          .replace("&?", "&")
          .replace("?&", "")
      : newStaticUrl
    return staticUrlFix
  }

  const getParamsFromField = (row, paramsFromFields) => {
    let params = ""
    paramsFromFields?.map((it, idx) => {
      let paramKey = it?.paramName
      let paramValue = row?.[it?.fieldName]
      params += `${paramKey}=${paramValue}&`
    })
    return params
  }

  const renderLoopElements = (arrayKey, htmlTextClean, data) => {
    const hasLoop = htmlTextClean.includes(arrayKey)
    // console.log(hasLoop)
    if (hasLoop) {
      // Define the regular expression pattern
      let pattern = new RegExp(`\\${arrayKey}(.*?)\\${arrayKey}`, "s")

      // Use the match method to find matches
      let loopPartEl = htmlTextClean.match(pattern)

      let loopPartElMixed = ""
      // console.log(arrayKey, htmlTextClean, data, loopPartEl)
      if (loopPartEl) {
        data?.map((value) => {
          let newLoopPartEl = loopPartEl[1]
          // console.log(newLoopPartEl)
          Object.keys(value).forEach((keyChild) => {
            // console.log(keyChild)
            newLoopPartEl = newLoopPartEl?.replaceAll(
              `{${keyChild}}`,
              value[keyChild]
            )

            ////QR TYPE VARIABLE
            let qrField = `{${keyChild}:qrcode}`
            if (newLoopPartEl.includes(qrField)) {
              console.log("qrcode exist")
              // let qrFieldAttr = `{${keyChild}:qrcode|`
              // if (newLoopPartEl.includes(qrField)) {
              //   console.log("qrcode attr exist")
              //   let qrFieldAttrParse =
              // }
              let qrField_svg = new QRCodeSVG({
                size: "100%",
                value: value?.[keyChild] ?? "",
                content: value?.[keyChild] ?? "",
                join: "true",
                container: "svg-viewbox"
              })
              const svgString = renderToString(qrField_svg)
              newLoopPartEl = newLoopPartEl?.replaceAll(qrField, svgString)
            }
          })

          /////ADD UNIQUE KEYS TO ELEMENTS
          // Temukan posisi setelah <tr> atau <div> atau <ul>
          const openingTagEndIndex = newLoopPartEl.indexOf(">") - 1
          // Tentukan nilai untuk atribut key
          const timestamp = new Date().getTime()
          let idEl = timestamp
          const keyAttributeValue = `cell-name-${idEl}`
          // Sisipkan atribut key ke dalam string
          const modifiedString = `${newLoopPartEl.slice(
            0,
            openingTagEndIndex + 1
          )} key="${keyAttributeValue}"${newLoopPartEl.slice(
            openingTagEndIndex + 1
          )}`
          newLoopPartEl = modifiedString
          loopPartElMixed = loopPartElMixed + newLoopPartEl
        })
      }

      loopPartElMixed = loopPartElMixed?.replaceAll(arrayKey, "")
      htmlTextClean = htmlTextClean.replace(loopPartEl[0], loopPartElMixed)
    }
    return htmlTextClean
  }

  function replaceSimplePlaceholders(html, data) {
    const simplePattern = /\{data\.([^\}]+)\}/g
    return html.replace(simplePattern, (_, path) => {
      let value = ""
      if (path?.includes(":qrcode")) {
        const valueX = getDepthValue(data, path?.replace(":qrcode", ""))
        if (valueX) {
          // console.log(valueX)
          let qrField_svg = new QRCodeSVG({
            size: "100%",
            content: valueX ?? "",
            value: valueX ?? "",
            join: "true",
            container: "svg-viewbox"
          })
          value = renderToString(qrField_svg)
        }
      } else if (path?.includes(":date")) {
        const valueX = getDepthValue(data, path?.replace(":date", ""))
        if (valueX) {
          value = Moment(valueX).format("DD MMM YYYY")
        }
      } else if (path?.includes(":map")) {
        const valueX = getDepthValue(data, path?.replace(":map", ""))
        if (valueX) {
          value = valueX
        }
      } else {
        const valueX = getDepthValue(data, path)
        if (
          valueX !== undefined &&
          valueX !== "None" &&
          valueX !== "none" &&
          valueX !== "null" &&
          valueX !== null
        )
          value = valueX
      }
      // console.log(value)
      return value
    })
  }

  function replacePlaceholders(html, data) {
    let keys = Object.keys(data)
    keys.forEach((key) => {
      const value = getDepthValue(data, key) // Dapat mengambil nilai nested atau non-nested
      // const placeholder = `{data.${key}}` // Placeholder yang dinamis berdasarkan key
      // html = html.replaceAll(placeholder, value)
      if (Array.isArray(value)) {
        const arrayKey = `[data.${key}]`
        html = renderLoopElements(arrayKey, html, value)
      }
    })
    return html
  }

  const fetchHtmlFile = async (path, item, row) => {
    try {
      const timestamp = new Date().getTime()
      let url = `${window.location.origin}/pageAttributes/${path}?timestamp=${timestamp}`
      // console.log(url)
      const response = await fetch(url)
      const htmlText = await response.text()
      // setHtmlContent(htmlText);
      let htmlTextClean = htmlText?.replace(/(\n|\s{3,})/g, " ")

      // check if css exist
      let urlCss = `${window.location.origin}/pageAttributes/${path?.replace(
        ".html",
        ".css"
      )}?timestamp=${timestamp}`
      const responseCss = await fetch(urlCss)
      const CssText = await responseCss.text()
      // console.log(CssText)
      if (CssText) {
        // getCssLinkTag(CssText)

        // Check if <head> exists in the HTML string
        const hasHead = htmlTextClean.includes("<head>")

        // If <head> doesn't exist, create it
        if (!hasHead) {
          htmlTextClean = htmlTextClean.replace(
            '<meta charset="utf-8" />',
            '<head><meta charset="utf-8" /></head>'
          )
        }

        // Check if <style> exists in the HTML string
        const hasStyle = htmlTextClean.includes("<style>")

        // If <style> doesn't exist, create it
        if (!hasStyle) {
          const styleTagPosition = htmlTextClean.indexOf("</head>")
          const modifiedHtmlString = `${htmlTextClean.slice(
            0,
            styleTagPosition
          )}<style>${CssText}</style>${htmlTextClean.slice(styleTagPosition)}`

          htmlTextClean = modifiedHtmlString
        }
      }

      // const userData = getUserData()
      ////replace with data
      let dataToReport = item?.renderCustomHtml?.dataFrom?.value

      if (item?.renderCustomHtml?.dataFrom?.api) {
        let finUrl = item?.renderCustomHtml?.dataFrom?.api
        let paramsFromFields = item?.renderCustomHtml?.paramsFromFields
        let appendToEndpointPath = item?.renderCustomHtml?.appendToEndpointPath
        const staticUrlFix = getParamsFromField1(
          finUrl,
          paramsFromFields,
          appendToEndpointPath,
          row
        )

        dataToReport = await getBaseUrl(null, staticUrlFix)
          .get(`${staticUrlFix}`)
          .then((res) => {
            const keySelector = item?.renderCustomHtml?.dataFrom?.resp_key
            return keySelector
              ? getDepthValue(res?.data, keySelector)
              : keySelector === ""
              ? res?.data
              : res?.data?.data ?? null
          })
      }
      // console.log(dataToReport)
      const hasData =
        htmlTextClean.includes("{data.") || htmlTextClean.includes("[data")
      if (hasData) {
        let keys = []
        if (Array.isArray(dataToReport)) {
          const arrayKey = `[data]`
          htmlTextClean = renderLoopElements(
            arrayKey,
            htmlTextClean,
            dataToReport
          )
        } else {
          // keys = Object.keys(dataToReport)
          // keys.forEach((key) => {
          //   const currentValue = key.includes(".")
          //     ? getDepthValue(dataToReport, key)
          //     : dataToReport[key]
          //   htmlTextClean = htmlTextClean.replaceAll(
          //     key.includes(".") ? `{data.}` : `{data.${key}}`,
          //     currentValue
          //   )
          //   if (Array.isArray(currentValue)) {
          //     const arrayKey = `[data.${key}]`
          //     htmlTextClean = renderLoopElements(
          //       arrayKey,
          //       htmlTextClean,
          //       currentValue
          //     )
          //   }
          // })
          htmlTextClean = replaceSimplePlaceholders(htmlTextClean, dataToReport)
          htmlTextClean = replacePlaceholders(htmlTextClean, dataToReport)
        }
      }

      // __USERDATA
      if (htmlTextClean?.includes("__userData")) {
        userDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __UNITDATA
      if (htmlTextClean?.includes("__unitData")) {
        unitDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __APPDATA
      if (htmlTextClean?.includes("__appData")) {
        appDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __COMMON
      if (htmlTextClean?.includes("__currentDate")) {
        formattedDate.map((item) => {
          htmlTextClean = htmlTextClean?.replaceAll(`{${item.key}}`, item.value)
        })
      }

      return htmlTextClean
    } catch (error) {
      console.error("Error fetching HTML file:", error)
    }
  }

  const renderExtraButton = (row) => {
    const oncLickExtraBtn = (e, item, index) => {
      e.preventDefault()
      let staticUrl = item?.clickLinkUrl?.static
      let fieldUrl = item?.clickLinkUrl?.field
      let widgetId = item?.clickLinkSlideToWidget?.widget_id
      dispatch(
        setDataFrameLoading({
          storeName: storeName,
          data: { [row?.id]: true }
        })
      )
      setCurrentLoadingFrameIndex(index)
      let renderCustomHtml = item?.renderCustomHtml
      if (!renderCustomHtml) {
        let paramsFromFields = item?.clickLinkUrl?.paramsFromFields
        let appendToEndpointPath = item?.clickLinkUrl?.appendToEndpointPath

        if (staticUrl && !fieldUrl && !widgetId) {
          let params = ""

          let staticUrlFix = getParamsFromField1(
            staticUrl,
            paramsFromFields,
            appendToEndpointPath,
            row
          )
          // let paramsArr = []
          // if (paramsFromFields) {
          //   paramsFromFields?.map((it, idx) => {
          //     let paramKey = it?.paramName
          //     // let paramValue = row?.[it?.fieldName]
          //     let paramValue = parseStrAndField(
          //       row,
          //       it?.fieldName
          //     )
          //     params += `&${paramKey}=${paramValue}`
          //     paramsArr.push(`${paramKey}=${paramValue}`)
          //   })
          // }

          // if (appendToEndpointPath) {
          //   // let appendToEndpointPathObj = appendToEndpointPath?.split(",")
          //   appendToEndpointPath?.map((it, idx) => {
          //     let paramKey = it?.paramName
          //     let paramValue = parseStrAndField(row, it)
          //     params += `/${paramValue}`
          //     paramsArr.push(`${paramValue}`)
          //   })
          // }

          // const isQueryStr = staticUrl?.includes("?")

          // let newStaticUrl = !appendToEndpointPath
          //   ? `${staticUrl}${
          //       isQueryStr ? "" : "?"
          //     }${paramsArr.join("&")}`
          //   : `${staticUrl}${
          //       isQueryStr ? "/" : "/"
          //     }${paramsArr.join("/")}`

          // let staticUrlFix = !appendToEndpointPath
          //   ? newStaticUrl
          //       .replace("&&", "&")
          //       .replace("/?&", "?")
          //       .replace("??", "?")
          //       .replace("/?", "?")
          //       .replace("/&", "&")
          //       .replace("?&", "?")
          //       .replace("&?", "&")
          //       .replace("?&", "")
          //   : newStaticUrl

          staticUrlFix = staticUrlFix?.replace(/\?$/, "")

          if (
            staticUrl.startsWith("http://") ||
            staticUrl.startsWith("https://")
          ) {
            // console.log(staticUrlFix)

            if (item?.clickLinkUrl?.viewContentAs === "iframe") {
              let dataFrameParams = {
                storeName: storeName,
                data: staticUrlFix
              }
              // console.log("setDataFrame", dataFrameParams)
              dispatch(setDataFrame(dataFrameParams)).then((ress) => {
                dispatch(
                  setDataFrameLoading({
                    storeName: storeName,
                    data: { [row?.id]: false }
                  })
                )
                const el_id = `data_frame_${storeName}`
                setTimeout(() => {
                  // console.log(`scrolling to ${el_id}`)
                  scrollToDiv(el_id)
                }, 200)
              })
            } else {
              dispatch(
                setDataFrameLoading({
                  storeName: storeName,
                  data: { [row?.id]: false }
                })
              )
              window.open(staticUrlFix, "_blank", "noreferrer")
            }
          } else {
            dispatch(
              setDataFrameLoading({
                storeName: storeName,
                data: { [row?.id]: false }
              })
            )
            navigate(staticUrlFix)
          }
        } else if (fieldUrl && !staticUrl && !widgetId) {
          let params = ""
          // console.log(fieldUrl.includes("."))
          let fieldUrlFix = fieldUrl.includes(".")
            ? getDepthValue(row, fieldUrl)
            : row?.[fieldUrl]
          // console.log(fieldUrlFix)
          if (fieldUrlFix) {
            // console.log(fieldUrlFix)
            if (paramsFromFields) {
              params = getParamsFromField(row, paramsFromFields)
            }
            fieldUrlFix = `${fieldUrlFix}?${params.replace(/&$/, "")}`
            fieldUrlFix = fieldUrlFix?.replace(/\?$/, "")
            dispatch(
              setDataFrameLoading({
                storeName: storeName,
                data: { [row?.id]: false }
              })
            )
            if (
              fieldUrlFix.startsWith("http://") ||
              fieldUrlFix.startsWith("https://")
            ) {
              // console.log(fieldUrlFix)
              window.open(fieldUrlFix, "_blank", "noreferrer")
            } else {
              navigate(fieldUrlFix)
            }
          }
        } else if (!fieldUrl && !staticUrl && widgetId) {
          let params = ""
          if (paramsFromFields) {
            params = getParamsFromField(row, paramsFromFields)
          }
          dispatch(
            setDataFrameLoading({
              storeName: storeName,
              data: { [row?.id]: false }
            })
          )
          setTimeout(() => {
            scrollToDiv(`widget_${widgetId}`)
          }, 400)
        }
      } else {
        // console.log("renderCustomHtml")
        const htmlFile = item?.renderCustomHtml?.file
        // console.log(htmlFile)
        if (htmlFile) {
          // console.log(htmlFile)
          let dataHtml = fetchHtmlFile(htmlFile, item, row)
            .then((res) => {
              let dataFrameParams = {
                storeName: storeName,
                data: res
              }
              if (item?.renderCustomHtml?.viewContentAs === "modal") {
                // console.log("setDataFrame", dataFrameParams)
                dispatch(setDataFrameHtmlModal(dataFrameParams)).then(
                  (ress) => {
                    // const el_id = `data_frame_${storeName}`
                    // setTimeout(() => {
                    //   // console.log(`scrolling to ${el_id}`)
                    //   scrollToDiv(el_id)
                    // }, 200)

                    dispatch(
                      setDataFrameLoading({
                        storeName: storeName,
                        data: { [row?.id]: false }
                      })
                    )
                  }
                )
              } else {
                // console.log("setDataFrame", dataFrameParams)
                dispatch(setDataFrameHtml(dataFrameParams)).then((ress) => {
                  dispatch(
                    setDataFrameLoading({
                      storeName: storeName,
                      data: { [row?.id]: false }
                    })
                  )
                  const el_id = `data_frame_${storeName}`
                  setTimeout(() => {
                    // console.log(`scrolling to ${el_id}`)
                    scrollToDiv(el_id)

                    let map
                    let marker
                    const mapElement = document.getElementById("map-report")
                    if (mapElement) {
                      let textContent = mapElement.textContent
                      if (textContent) {
                        let locStr = textContent?.split(",")
                        map = L.map("map-report", {
                          center: locStr,
                          zoom: storeAll.mapOptions[storeName]?.zoom ?? 13,
                          zoomControl: false,
                          attributionControl: false,
                          dragging: false,
                          doubleClickZoom: false,
                          scrollWheelZoom: false,
                          boxZoom: false,
                          keyboard: false,
                          tap: false,
                          touchZoom: false
                        })
                        L.tileLayer(
                          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                          {
                            maxZoom: 19
                          }
                        ).addTo(map)
                        marker = L.marker(locStr).addTo(map)

                        // const lat = 1.234
                        // const lng = 2.345

                        // if (marker) {
                        //   map.removeLayer(marker)
                        // }

                        // map.setView([lat, lng], 13)
                      }
                    }
                  }, 200)
                })
              }
            })
            .catch((error) => {
              dispatch(
                setDataFrameLoading({
                  storeName: storeName,
                  data: {
                    data: { [row?.id]: false }
                  }
                })
              )
              console.error(error)
              toast.error(`file html tidak ditemukan di folder ${htmlFile}`, {
                style: {
                  padding: "16px",
                  color: colors.danger.main,
                  border: `1px solid ${colors.danger.main}`
                },
                iconTheme: {
                  primary: colors.danger.main
                }
              })
            })

          // console.log(dataHtml)
        }
      }

      const clickLinkFillGlobalFilter = item?.clickLinkFillGlobalFilter
      // console.log(clickLinkFillGlobalFilter)
      if (clickLinkFillGlobalFilter) {
        const targetId = clickLinkFillGlobalFilter?.widget_id
        const element = document.getElementById(`widget_${targetId}`)
        if (element) {
          const store_to_reset = element.getAttribute("storename")

          let params = {}
          let paramsFromFields =
            item?.clickLinkFillGlobalFilter?.paramsFromFields

          if (paramsFromFields) {
            paramsFromFields?.map((it, idx) => {
              let paramKey = it?.paramName
              let paramValue = row?.[it?.fieldName]
              params[`${paramKey}`] = paramValue
            })
          }

          storeAll.pageAttributes[
            store_to_reset
          ]?.options?.data?.dynamicCard?.map((x, index) => {
            // console.log(x)
            if (x?.children) {
              x.children?.map((child, index) => {
                // console.log(storeAll)
                // console.log(store)
                const widget_id =
                  child?.chart_id ??
                  child?.cardId ??
                  child?.pageId ??
                  child?.crudOpt?.pageId ??
                  index

                const targetAttr = child

                const linkToGlobalFilterName =
                  child?.linkToGlobalFilter ??
                  child?.crudOpt?.linkToGlobalFilter ??
                  child?.dataFrom?.linkToGlobalFilter

                // console.log(widget_id, targetId)

                if (child?.type === "global_filter" && widget_id === targetId) {
                  // console.log(targetAttr)
                  // if (storeAll?.pageData?.[store_to_reset]?.data) {
                  //   dispatch(resetData(store_to_reset))
                  // }
                  // dispatch(
                  //   getDataList({
                  //     ...(storeAll?.pageData?.[store_to_reset]
                  //       ?.params ?? {}),
                  //     ...params,
                  //     storeName: store_to_reset,
                  //     endpointName:
                  //     child?.crudOpt
                  //         ?.endpointName,
                  //     baseUrl:
                  //     child?.crudOpt
                  //         ?.baseUrl
                  //   })
                  // )
                }

                // else if (child?.crudOpt && targetId === linkToGlobalFilterName) {
                //   const child_widget_id =
                //   child?.chart_id ??
                //   child?.cardId ??
                //   child?.pageId ??
                //   child?.crudOpt?.pageId ??
                //   index
                // }
              })
            } else {
              const widget_id =
                x?.chart_id ??
                x?.cardId ??
                x?.pageId ??
                x?.crudOpt?.pageId ??
                index

              const targetAttr = x
              const linkToGlobalFilterName =
                x?.linkToGlobalFilter ??
                x?.crudOpt?.linkToGlobalFilter ??
                x?.dataFrom?.linkToGlobalFilter

              // console.log(widget_id, targetId)

              if (x?.type === "global_filter" && widget_id === targetId) {
                // console.log(targetAttr)
                // console.log(storeAll)
              } else if (x?.crudOpt && targetId === linkToGlobalFilterName) {
                const childStoreName = `${
                  x?.crudOpt?.baseUrl ?? x?.dataFrom?.baseUrl ?? x?.baseUrl
                }_${widget_id}`

                // console.log(params, x)
                // console.log(storeAll, childStoreName)

                if (storeAll?.pageData?.[childStoreName]?.data) {
                  dispatch(resetData(childStoreName))
                }

                dispatch(
                  getDataList({
                    ...(storeAll?.pageData?.[childStoreName]?.params ?? {}),
                    ...params,
                    storeName: childStoreName,
                    endpointName: x?.crudOpt?.endpointName,
                    baseUrl: x?.crudOpt?.baseUrl
                  })
                )
              }

              // const linkToGlobalFilterName =
              //   x?.linkToGlobalFilter ??
              //   x?.crudOpt?.linkToGlobalFilter ??
              //   x?.dataFrom?.linkToGlobalFilter
              // if (linkToGlobalFilterName) {
              //   const widget_id = x?.chart_id ?? x?.cardId
              //   // console.log(params?.storeName, linkToGlobalFilterName)
              //   if (params?.storeName === linkToGlobalFilterName) {
              //     isLinkedToChilds = true
              //     if (x?.crudOpt) {
              //       const store_to_reset = `${
              //         x?.crudOpt?.baseUrl ?? x?.dataFrom?.baseUrl ?? x?.baseUrl
              //       }_${widget_id}`
              //       // console.log(store_to_reset)
              //       // if (storeAll?.pageData?.[store_to_reset]?.data) {
              //       resetDataByStore(store_to_reset, params)
              //       // }
              //     } else {
              //       const store_to_reset = `${widget_id}`
              //       const copyParams = { ...params }
              //       delete copyParams.storeName
              //       copyParams["item"] = x
              //       handleChangeFilterFromChild(copyParams)
              //     }
              //   }
              // }
            }
          })

          dispatch(
            setDataFrameLoading({
              storeName: storeName,
              data: { [row?.id]: false }
            })
          )
        }
      }
    }

    return dynamicColumnsExtraActionsButton
      ? dynamicColumnsExtraActionsButton?.map((item, index) => {
          return extraBtnShowCondition(item, row) ? (
            pageAttr?.crudOpt?.simpleActionButton ? (
              <DropdownItem
                className="text-end"
                key={`${extraBtnPrefixId}-${index}`}
                onClick={(e) => oncLickExtraBtn(e, item, index)}
                href="/"
                tag="a"
              >
                <span className="align-middle me-50">
                  {t(
                    item?.text && item?.text !== ""
                      ? item?.text
                      : item?.tooltip ?? ""
                  )}
                </span>
                {item?.icon && renderIcon(item?.icon, "align-middle", 16)}
              </DropdownItem>
            ) : (
              <Fragment key={`${extraBtnPrefixId}-fragment-${index}`}>
                <Button.Ripple
                  key={`${extraBtnPrefixId}-${index}`}
                  disabled={
                    // currentLoadingFrameIndex !== index &&
                    // storeAll.dataFrameLoading?.[storeName]?.[row?.id]
                    storeAll.dataFrameLoading?.[storeName]
                      ? Object.values(
                          storeAll.dataFrameLoading?.[storeName]
                        ).includes(true)
                      : false
                  }
                  outline={
                    item?.outline &&
                    /^(flat-|gradient-|relief-)/.test(item?.color) === false
                  }
                  id={`${extraBtnPrefixId}-${index}`}
                  className={`btn-icon ms-25 extra-btn-table } ${
                    !item?.text ? "rounded-circle " : ""
                  }`}
                  color={item?.color ? item?.color : "flat-secondary"}
                  onClick={(e) => oncLickExtraBtn(e, item, index)}
                  // dataFrameLoading
                >
                  {storeAll.dataFrameLoading?.[storeName]?.[row?.id] &&
                    currentLoadingFrameIndex === index && (
                      <Spinner color="primary" size="sm" />
                    )}
                  {(!storeAll.dataFrameLoading?.[storeName]?.[row?.id] ||
                    currentLoadingFrameIndex !== index) && (
                    <Fragment>
                      {item?.icon && renderIcon(item?.icon, "align-middle", 16)}
                      {item?.text && (
                        <span className="ms-25 align-middle">
                          {t(item?.text)}
                        </span>
                      )}
                    </Fragment>
                  )}
                </Button.Ripple>
                {!pageAttr?.isViewOnRow &&
                  !storeAll.dataFrameLoading?.[storeName]?.[row?.id] && (
                    <UncontrolledTooltip
                      key={`${extraBtnPrefixId}-tooltip-${index}`}
                      placement="left"
                      target={`${extraBtnPrefixId}-${index}`}
                    >
                      {item?.tooltip ? t(item?.tooltip) : t(item?.text)}
                    </UncontrolledTooltip>
                  )}
              </Fragment>
            )
          ) : null
        })
      : null
  }

  const renderDeleteButton = (row) => {
    const ButtonOrMenu = pageAttr?.crudOpt?.simpleActionButton ? (
      <DropdownItem
        className="text-end"
        onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}
        href="/"
        tag="a"
      >
        <span className="align-middle me-50">
          {t(pageAttr?.crudOpt?.btnEditText ?? "Delete")}
        </span>
        {pageAttr?.crudOpt?.btnEditIcon ? (
          renderIcon(pageAttr?.crudOpt?.btnEditIcon, "align-middle", 16)
        ) : (
          <Trash2 size={16} />
        )}
      </DropdownItem>
    ) : (
      <Fragment>
        <Button.Ripple
          outline={outline}
          id={`btn-delete-${row.id}`}
          className={`btn-action-table ms-25 btn-icon ${
            !outline ? "rounded-circle " : ""
          }`}
          color={!outline ? "flat-secondary" : "secondary"}
          onClick={(e) => {
            e.preventDefault()
            handleDelete(row.id)
          }}
        >
          {!onlyText && <Trash2 size={16} />}
          {(onlyText || withText) && (
            <span className="align-middle ms-25">{t("Delete")}</span>
          )}
        </Button.Ripple>
        {!pageAttr?.isViewOnRow && (
          <UncontrolledTooltip placement="left" target={`btn-delete-${row.id}`}>
            {t("Delete")}
          </UncontrolledTooltip>
        )}
      </Fragment>
    )

    return ability.can("delete", sessionStorage.getItem("current_page_perm")) &&
      !disabledAction.includes("delete") &&
      !pageAttr?.onDashboard
      ? ButtonOrMenu
      : null
  }

  const renderEditButton = (row) => {
    const ButtonOrMenu = pageAttr?.crudOpt?.simpleActionButton ? (
      <DropdownItem
        className="text-end"
        onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}
        href="/"
        tag="a"
      >
        <span className="align-middle me-50">
          {t(pageAttr?.crudOpt?.btnEditText ?? "Edit")}
        </span>
        {pageAttr?.crudOpt?.btnEditIcon ? (
          renderIcon(pageAttr?.crudOpt?.btnEditIcon, "align-middle", 16)
        ) : (
          <Edit2 size={16} />
        )}
      </DropdownItem>
    ) : (
      <Fragment>
        <Button.Ripple
          outline={outline}
          className={`btn-action-table ms-25 btn-icon ${
            !outline ? "rounded-circle " : ""
          }`}
          color={!outline ? "flat-secondary" : "secondary"}
          id={`btn-edit-${row.id}`}
          onClick={(e) => {
            e.preventDefault()
            handleEdit(row)
          }}
        >
          {!onlyText &&
            (pageAttr?.crudOpt?.btnEditIcon ? (
              renderIcon(pageAttr?.crudOpt?.btnEditIcon, "align-middle", 16)
            ) : (
              <Edit2 size={16} />
            ))}
          {(onlyText || withText) && (
            <span className="align-middle ms-25">
              {t(pageAttr?.crudOpt?.btnEditText ?? "Edit")}
            </span>
          )}
        </Button.Ripple>
        {!pageAttr?.isViewOnRow && (
          <UncontrolledTooltip placement="left" target={`btn-edit-${row.id}`}>
            {t(pageAttr?.crudOpt?.btnEditText ?? "Edit")}
          </UncontrolledTooltip>
        )}
      </Fragment>
    )

    return rowClickEvent !== "view" &&
      ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      !disabledAction.includes("update") &&
      !pageAttr?.onDashboard
      ? ButtonOrMenu
      : null
  }

  const renderViewButton = (row) => {
    const ButtonOrMenu = pageAttr?.crudOpt?.simpleActionButton ? (
      <DropdownItem
        className="text-end"
        onClick={(e) => {
          e.preventDefault()
          handleEdit(row, null, "view")
        }}
        href="/"
        tag="a"
      >
        <span className="align-middle me-50">
          {t(pageAttr?.crudOpt?.btnEditText ?? "View Detail")}
        </span>
        {pageAttr?.crudOpt?.btnEditIcon ? (
          renderIcon(pageAttr?.crudOpt?.btnEditIcon, "align-middle", 16)
        ) : (
          <Eye size={16} />
        )}
      </DropdownItem>
    ) : (
      <Fragment>
        <Button.Ripple
          outline={outline}
          className={`btn-action-table ms-25 btn-icon ${
            !outline ? "rounded-circle " : ""
          }`}
          color={!outline ? "flat-secondary" : "secondary"}
          id={`btn-view-${row.id}`}
          onClick={(e) => {
            e.preventDefault()
            handleEdit(row, null, "view")
          }}
        >
          {!onlyText && <Eye size={16} />}
          {(onlyText || withText) && (
            <span className="align-middle ms-25">{t("View")}</span>
          )}
        </Button.Ripple>
        {!pageAttr?.isViewOnRow && (
          <UncontrolledTooltip placement="left" target={`btn-view-${row.id}`}>
            {t("View Detail")}
          </UncontrolledTooltip>
        )}
      </Fragment>
    )

    return (rowClickEvent === "view" && !disabledAction.includes("view")) ||
      (enabledBtnViewCondition(pageAttr?.crudOpt, row) &&
        !pageAttr?.crudOpt?.saveAsNewBtn &&
        !pageAttr?.crudOpt?.data?.dynamicForm?.some(
          (item) => item.type === "selectTable"
        ) &&
        !pageAttr?.onDashboard)
      ? ButtonOrMenu
      : null
  }

  const renderAddChildButton = (row) => {
    const ButtonOrMenu = pageAttr?.crudOpt?.simpleActionButton ? (
      <DropdownItem
        onClick={(e) => {
          e.preventDefault()
          handleEdit(null, {
            [pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"]: row?.id
          })
        }}
        href="/"
        tag="a"
      >
        {pageAttr?.crudOpt?.btnEditIcon ? (
          renderIcon(pageAttr?.crudOpt?.btnEditIcon, "align-middle", 16)
        ) : (
          <CornerDownRight size={16} />
        )}
        <span className="align-middle ms-25">
          {t(pageAttr?.crudOpt?.btnEditText ?? "Add Child")}
        </span>
      </DropdownItem>
    ) : (
      <Fragment>
        <Button.Ripple
          outline={outline}
          id={`btn-add-child-${row.id}`}
          className={`btn-action-table btn-icon ${
            !outline ? "rounded-circle " : ""
          }`}
          color={!outline ? "flat-secondary" : "secondary"}
          onClick={(e) => {
            e.preventDefault()
            handleEdit(null, {
              [pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"]: row?.id
            })
          }}
        >
          {!onlyText && <CornerDownRight size={16} />}
          {/* {!onlyText && renderIcon("fontAwesome:folder-tree", "", 16)} */}
          {/* {!onlyText && renderIcon("material:subdirectory_arrow_right", "", 16)} */}
          {(onlyText || withText) && (
            <span className="align-middle ms-25">{t("Add Child")}</span>
          )}
        </Button.Ripple>
        {!pageAttr?.isViewOnRow && (
          <UncontrolledTooltip
            placement="left"
            target={`btn-add-child-${row.id}`}
          >
            {t("Add Child")}
          </UncontrolledTooltip>
        )}
      </Fragment>
    )

    return ability.can("post", sessionStorage.getItem("current_page_perm")) &&
      !disabledAction.includes("add-child") &&
      pageAttr?.crudOpt?.gridTheme === "table_tree" &&
      !pageAttr?.onDashboard
      ? ButtonOrMenu
      : null
  }

  return !pageAttr?.crudOpt?.simpleActionButton ? (
    <div className="column-action text-center">
      {renderExtraButton(row)}
      {renderDeleteButton(row)}
      {renderEditButton(row)}
      {renderViewButton(row)}
      {renderAddChildButton(row)}

      {/* {rowClickEvent === "view" || !disabledAction.includes("view") ? (
        <Fragment>
          <Button.Ripple
            outline={outline}
            className={`btn-icon ms-1 ${!outline ? "rounded-circle " : ""}`}
            color={!outline ? "flat-info" : "info"}
            id={`btn-view-${row.id}`}
            onClick={(e) => {
              e.preventDefault()
              handleEdit(row)
            }}
          >
            {!onlyText && <Eye size={16} />}
            {(onlyText || withText) && (
              <span className="align-middle ms-25">{t("View")}</span>
            )}
          </Button.Ripple>
          <UncontrolledTooltip placement="left" target={`btn-view-${row.id}`}>
            {t("View Detail")}
          </UncontrolledTooltip>
        </Fragment>
      ) : null} */}
    </div>
  ) : (
    <UncontrolledButtonDropdown className="dropdown-icon-wrapper btn-icon p-0">
      <DropdownToggle
        color="flat-secondary"
        caret
        className="btn-icon dropdown-toggle-split p-25"
      >
        <MoreVertical className="align-middle" size={12} />
      </DropdownToggle>
      <DropdownMenu>
        {renderExtraButton(row)}
        {renderEditButton(row)}
        {renderViewButton(row)}
        {renderAddChildButton(row)}
        {renderDeleteButton(row)}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

export default GlobalActionsTable
