import { Fragment } from "react"
import Breadcrumbs from "@components/breadcrumbs"
import { Alert, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

const BlankPage = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <div className="content-header row align-items-center">
        {/* <Breadcrumbs title="Layout Empty" data={[{ title: "Layout Empty" }]} /> */}
        <div className="app-user-list">
          <Row>
            <Col>
              <Alert color="primary">
                <div className="alert-body">
                  {/* <span className="fw-bold">Info: </span> */}
                  <span>
                    {t(
                      "I'm sorry, the page you are trying to access has not been set up by our developer team. It is possible that the page is still under production or the settings have not been configured properly. We apologize for any inconvenience this may have caused. For further information, please contact our developer team via the email or phone number provided on our website. We will work to resolve this issue as soon as possible to ensure you have seamless access to the page. Thank you for your understanding."
                    )}
                    {/* {" "}
            <a href="/">{t("Layout empty documentation")}</a>{" "}
            {t("for more details")}. */}
                  </span>
                </div>
              </Alert>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default BlankPage
