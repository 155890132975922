// ** React Imports
import { useEffect, useState, useCallback } from "react"

// ** Third Party Components
import classnames from "classnames"
import * as Icon from "react-feather"
// ** Reactstrap Imports
import { NavItem, NavLink, Spinner } from "reactstrap"

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux"
import { handleSearchQuery, getGlobalSearch } from "@store/navbar"
import debounce from "lodash.debounce"
// ** Custom Components
import Autocomplete from "@components/autocomplete"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
// ** Configs

// file images
import doc from "@src/assets/images/icons/file-icons/doc.png"
import docx1 from "@src/assets/images/icons/file-icons/docx1.png"
import docx2 from "@src/assets/images/icons/file-icons/docx2.png"
import excel from "@src/assets/images/icons/file-icons/excel.png"
import jpeg from "@src/assets/images/icons/file-icons/jpeg.png"
import jpg from "@src/assets/images/icons/file-icons/jpg.png"
import pdf from "@src/assets/images/icons/file-icons/pdf.png"
import png from "@src/assets/images/icons/file-icons/png.png"
import txt from "@src/assets/images/icons/file-icons/txt.png"
import mp3 from "@src/assets/images/icons/file-icons/mp3.png"

import { useTranslation } from "react-i18next"
const NavbarSearch = () => {
  // ** Hooks
  const { t } = useTranslation()

  // ** Store Vars
  const dispatch = useDispatch()

  // ** States
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [querySearch, setQuerySearch] = useState(null)
  const [navbarSearch, setNavbarSearch] = useState(false)
  const storeDomain = useSelector((state) => state.domain_claims)
  const storeMyMenu = useSelector((state) => state.my_menu)
  // const storeMenu = useSelector((state) => state.menu)

  const getDefaultSuggestion = () => {
    // console.log(storeMenu)
    // console.log(storeMyMenu)
    const suggestionDataDefault = storeMyMenu.dataMyMenu
      .filter(
        (item) =>
          !item.is_separator &&
          !item.is_header &&
          !item.is_hidden &&
          item.routePath
      )
      .map((itemx) => ({
        title: itemx.title,
        icon: itemx.icon,
        link: itemx.routePath
      }))
    // console.log(suggestionDataDefault)
    const dataSuggest = {
      groupTitle: "Pages",
      data: suggestionDataDefault ?? []
    }
    return dataSuggest ?? {}
  }
  // ** ComponentDidMount
  useEffect(() => {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   setSuggestions(data.searchArr)
    // })
    if (storeMyMenu.dataMyMenu) {
      // console.log([getDefaultSuggestion()])
      setSuggestions([getDefaultSuggestion()])
    }
  }, [storeMyMenu.dataMyMenu])

  async function doSearch(query) {
    setLoading(true)
    dispatch(getGlobalSearch(query))
      .then((res) => {
        // console.log(res)
        // console.log(storeMenu)
        // console.log(storeMyMenu)
        let mixedSuggestions = res?.payload?.data ?? []
        mixedSuggestions.push(getDefaultSuggestion())
        setSuggestions(mixedSuggestions)
        setLoading(false)
      })
      .catch(function (error) {
        console.error(error)
        setLoading(false)
      })
  }

  const debouncedSearchQuery = useCallback(debounce(doSearch, 1000), [])

  // ** Removes query in store
  const handleClearQueryInStore = () => dispatch(handleSearchQuery(""))

  // ** Function to handle external Input click
  const handleExternalClick = () => {
    if (navbarSearch === true) {
      setNavbarSearch(false)
      handleClearQueryInStore()
    }
  }

  // ** Function to clear input value
  const handleClearInput = (setUserInput) => {
    if (!navbarSearch) {
      setUserInput("")
      handleClearQueryInStore()
    }
  }

  // const onFocus = (e) => {
  //   console.log("onfosuc")
  //   setSuggestions([getDefaultSuggestion()])
  // }

  // ** Function to close search on ESC & ENTER Click
  const onKeyDown = (e) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setTimeout(() => {
        setNavbarSearch(false)
        handleClearQueryInStore()
      }, 1)
    }
  }

  // ** Function to handle search suggestion Click
  const handleSuggestionItemClick = () => {
    setNavbarSearch(false)
    handleClearQueryInStore()
  }

  // ** Function to handle search list Click
  const handleListItemClick = (func, link, e) => {
    if (link.startsWith("http://") || link.startsWith("https://")) {
      window.open(link, "_blank", "noreferrer")
    } else {
      func(link, e)
      setTimeout(() => {
        setNavbarSearch(false)
      }, 1)
      handleClearQueryInStore()
    }
  }

  return (
    <NavItem className="nav-search" onClick={() => setNavbarSearch(true)}>
      <NavLink className="nav-link-search">
        <Icon.Search className="ficon" />
      </NavLink>
      <div
        className={classnames("search-input", {
          open: navbarSearch === true
        })}
      >
        <div className="search-input-icon">
          <Icon.Search />
        </div>
        {navbarSearch ? (
          <Autocomplete
            className="form-control"
            suggestions={suggestions}
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            suggestionLimit={10}
            defaultSuggestions={true}
            placeholder={`${t("Explore")} ${
              storeDomain?.data?.unit?.unit_app_attributes?.app_name_alias ??
              storeDomain?.data?.app?.name
            }...`}
            autoFocus={true}
            onSuggestionItemClick={handleSuggestionItemClick}
            externalClick={handleExternalClick}
            clearInput={(userInput, setUserInput) =>
              handleClearInput(setUserInput)
            }
            onKeyDown={onKeyDown}
            // onFocus={onFocus}
            onChange={(e) => {
              const query = e.target.value
              setQuerySearch(query)
              dispatch(handleSearchQuery(query))
              debouncedSearchQuery(query)
            }}
            customRender={(
              item,
              i,
              filteredData,
              activeSuggestion,
              onSuggestionItemClick,
              onSuggestionItemHover
            ) => {
              const IconTag = Icon[item.icon ? item.icon : "X"]
              const getFileType = item.link
                ? String(item.link)?.split(".")?.pop()
                : null
              const isFileType = [
                "pdf",
                "xls",
                "doc",
                "txt",
                "xlsx",
                "docx",
                "png",
                "jpg",
                "jpeg",
                "mp3"
              ].includes(getFileType)
              let fileTypeImg = doc
              if (getFileType === "pdf") {
                fileTypeImg = pdf
              } else if (getFileType === "txt") {
                fileTypeImg = txt
              } else if (getFileType === "docx") {
                fileTypeImg = docx2
              } else if (getFileType === "doc") {
                fileTypeImg = docx1
              } else if (getFileType === "xls") {
                fileTypeImg = excel
              } else if (getFileType === "xlsx") {
                fileTypeImg = excel
              } else if (getFileType === "jpg") {
                fileTypeImg = jpg
              } else if (getFileType === "jpeg") {
                fileTypeImg = jpeg
              } else if (getFileType === "png") {
                fileTypeImg = png
              } else if (getFileType === "mp3") {
                fileTypeImg = mp3
              }

              return (
                <li
                  className={classnames("suggestion-item", {
                    active: filteredData.indexOf(item) === activeSuggestion
                  })}
                  key={i}
                  onClick={(e) =>
                    handleListItemClick(onSuggestionItemClick, item.link, e)
                  }
                  onMouseEnter={() =>
                    onSuggestionItemHover(filteredData.indexOf(item))
                  }
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="item-container d-flex align-items-center">
                      {item.icon ? (
                        renderIcon(item.icon, "", 26)
                      ) : isFileType ? (
                        <img
                          src={fileTypeImg}
                          height="36"
                          width="28"
                          alt={t(item.title ?? "")}
                        />
                      ) : item.img ? (
                        <img
                          className="rounded-circle"
                          src={item.img}
                          height="28"
                          width="28"
                          alt={t(item.title)}
                        />
                      ) : (
                        renderIcon("fontAwesome:circle", "", 26)
                      )}
                      <div className="item-info ms-1">
                        <p className="align-middle mb-0">{t(item?.title)}</p>
                        {item?.subtitle ? (
                          <small className="text-muted">{item.subtitle}</small>
                        ) : null}
                      </div>
                    </div>
                    {item.text_right ? (
                      <div className="meta-container">
                        <small className="text-muted">{item.text_right}</small>
                      </div>
                    ) : null}
                  </div>
                </li>
              )
            }}
          />
        ) : null}
        {loading && (
          <div
            className="search-input-close"
            style={{
              right: "4rem"
            }}
          >
            <Spinner color="primary" />
          </div>
        )}
        <div className="search-input-close">
          <Icon.X
            className="ficon"
            onClick={(e) => {
              e.stopPropagation()
              setNavbarSearch(false)
              handleClearQueryInStore()
            }}
          />
        </div>
      </div>
    </NavItem>
  )
}

export default NavbarSearch
