import "gantt-task-react/dist/index.css"
import { Fragment, useContext, useState } from "react"
import { useParams } from "react-router-dom"
// type ViewSwitcherProps = {
//   isChecked: boolean,
//   onViewListChange: (isChecked: boolean) => void,
//   onViewModeChange: (viewMode: ViewMode) => void
// }
import { disableBtnCondition } from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Button, Col, Input, Label, Row } from "reactstrap"

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked = false,
  pageAttr,
  store,
  storeName,
  handleAddEdit,
  setPageType,
  currentViewFirst
}) => {
  const { t } = useTranslation()
  const [currentView, setCurrentView] = useState(currentViewFirst ?? "Day")
  const ability = useContext(AbilityContext)
  const storeMyUnit = useSelector((state) => state.units)
  const btnClick = (viewName) => {
    // e.preventDefault()
    // console.log(ViewMode)
    setCurrentView(viewName)
    onViewModeChange(viewName)
  }
  const paramss = useParams()
  const getRequiredFilterState = () => {
    // console.log(store.pageData[storeName]?.params)
    let mixParams = store.pageData[storeName]?.params
    // console.log(mixParams)
    let isLoadState = true

    /////prevent load data if filter include required attr and undefined
    if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
      pageAttr?.crudOpt?.data?.dynamicColumnsFilter
        .filter((x) => x?.rules?.required === true)
        .map((y) => {
          // eslint-disable-next-line no-unneeded-ternary
          isLoadState = mixParams?.[y.fieldName] ? true : false
        })
    }

    return isLoadState
  }

  return (
    <div
      // style={{
      //   padding: "4px 16px 4px 24px"
      // }}
      className="align-items-center w-100 p-3 p-md-0 pe-md-1 ps-md-1"
    >
      <Row>
        <Col xs={12} lg={4} className="d-flex align-items-center">
          {store.pageData[storeName]?.data?.length > 0 && (
            <div className="form-check form-switch">
              <Input
                defaultChecked={isChecked}
                type="switch"
                name="customSwitch"
                id="exampleCustomSwitch"
                onClick={() => onViewListChange(!isChecked)}
              />
              <Label for="exampleCustomSwitch" className="form-check-label">
                {t("Show Task List")}
              </Label>
            </div>
          )}
        </Col>

        <Col
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          xs={12}
          lg={8}
        >
          {/* <Button.Ripple
            color="secondary"
            className="ms-md-1 mb-1 mt-1"
            outline
          >
            {t("Hour")}
          </Button.Ripple> */}

          {/* <Button.Ripple color="secondary" className="ms-1" outline>
            {t("Quarter of Day")}
          </Button.Ripple>
          <Button.Ripple color="secondary" className="ms-1" outline>
            {t("Half of Day")}
          </Button.Ripple> */}
          {store.pageData[storeName]?.data?.length > 0 && (
            <Fragment>
              <Button
                className="ms-md-1 mb-1 mt-1"
                outline={currentView !== "Day"}
                color={currentView !== "Day" ? "secondary" : "relief-primary"}
                onClick={(e) => btnClick("Day")}
              >
                {t("Day")}
              </Button>
              <Button
                color={currentView !== "Week" ? "secondary" : "relief-primary"}
                className="ms-md-1 mb-1 mt-1"
                outline={currentView !== "Week"}
                onClick={(e) => btnClick("Week")}
              >
                {t("Week")}
              </Button>
              <Button
                color={currentView !== "Month" ? "secondary" : "relief-primary"}
                className="ms-md-1 mb-1 mt-1"
                outline={currentView !== "Month"}
                onClick={(e) => btnClick("Month")}
              >
                {t("Month")}
              </Button>
              <Button
                color={currentView !== "Year" ? "secondary" : "relief-primary"}
                className="ms-md-1 mb-1 mt-1"
                outline={currentView !== "Year"}
                onClick={(e) => btnClick("Year")}
              >
                {t("Year")}
              </Button>
            </Fragment>
          )}
          {ability.can("post", sessionStorage.getItem("current_page_perm")) &&
          !disableBtnCondition(
            pageAttr?.crudOpt,
            null,
            "add",
            storeMyUnit.data,
            paramss?.pageType
          ) &&
          getRequiredFilterState() ? (
            <Button
              className="add-permission ms-md-1 mb-1 mt-1"
              color="primary"
              // onClick={toggleSidebar}
              onClick={(e) => {
                setPageType("add")
                handleAddEdit(null)
              }}
            >
              {t("Add New")} {t(pageAttr.pageTitle)}
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
    // <div className="ViewContainer">
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.Hour)}
    //   >
    //     Hour
    //   </button>
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.QuarterDay)}
    //   >
    //     Quarter of Day
    //   </button>
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.HalfDay)}
    //   >
    //     Half of Day
    //   </button>
    //   <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
    //     Day
    //   </button>
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.Week)}
    //   >
    //     Week
    //   </button>
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.Month)}
    //   >
    //     Month
    //   </button>
    //   <button
    //     className="Button"
    //     onClick={() => onViewModeChange(ViewMode.Year)}
    //   >
    //     Year
    //   </button>
    //   <div className="Switch">
    //     <label className="Switch_Toggle">
    //       <input
    //         type="checkbox"
    //         defaultChecked={isChecked}
    //         onClick={() => onViewListChange(!isChecked)}
    //       />
    //       <span className="Slider" />
    //     </label>
    //     Show Task List
    //   </div>
    // </div>
  )
}
