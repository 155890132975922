/* eslint-disable no-mixed-operators */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
import { getDepthValue, neutralizeBack, revivalBack } from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from "react"
import { Edit2, RefreshCw, Trash2 } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getDataById,
  getDataList,
  putDataTree,
  resetSelectedData,
  setDataList,
  setSelectedData,
  expandCollapseAllTree,
  setViewOnlyTree,
  lazyLoadByParent,
  setOrgChartData
} from "./store"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "@mui/material"
import SortableTree, {
  toggleExpandedForAll
} from "@nosferatu500/react-sortable-tree"
import { NoDataDisplay } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import toast from "react-hot-toast"
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip
} from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useSkin } from "@hooks/useSkin"
import { Fullscreen, FullscreenExit } from "@mui/icons-material"
import html2canvas from "html2canvas"
const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const TreeView = ({ pageAttr, storeName, filtersCustomAdd }) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
  const parentIdField = pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = pageAttr?.crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const codeField = pageAttr?.crudOpt?.treeviewAddSubIncrementField
  const indexField = pageAttr?.crudOpt?.treeviewIndexField ?? "index" ?? "order"
  const iconField = pageAttr?.crudOpt?.treeviewIconField ?? "icon"
  const { skin } = useSkin()
  const defaultPosition = {
    x: window.innerWidth < 600 ? 0 : 25,
    y: window.innerWidth < 600 ? 200 : 140
  }
  const [viewOnly, setViewOnly] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [position, setPosition] = useState(defaultPosition)
  const [dragStart, setDragStart] = useState(defaultPosition)
  const [dragging, setDragging] = useState(false)
  const divRef = useRef(null)

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")

  const requestFullscreen = () => {
    const element = document.getElementById("tree_container")
    // setOldStyleBackgroundColor(element.style.backgroundColor)
    // if (element) {
    //   element.style.backgroundColor = "#1e1e1e"
    // }
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = () => {
    // if (oldStyleBackgroundColor) {
    //   const element = document.getElementById("card_json_dev")
    //   if (element) {
    //     element.style.backgroundColor = oldStyleBackgroundColor
    //   }
    // }

    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    setIsFullscreen(false)
  }

  const zoomIn = () => {
    if (zoomLevel < 1.5) {
      setZoomLevel(zoomLevel + 0.1)
      // Ensure y position doesn't go below 0
      setPosition((prevPosition) => ({
        ...prevPosition,
        y: store.pageDataTempFilterTreeCurrent[storeName]
          ? 140
          : defaultPosition.y
      }))
    }
  }

  const zoomOut = () => {
    if (zoomLevel > 0.5) {
      setZoomLevel(zoomLevel - 0.1)
      // Ensure y position doesn't go below 0
      setPosition((prevPosition) => ({
        ...prevPosition,
        y: store.pageDataTempFilterTreeCurrent[storeName]
          ? 140
          : defaultPosition.y
      }))
    }
  }

  const handleMouseDown = (e) => {
    // Check if the click occurred inside the SortableTree component
    const isInsideSortableTree = e.target.closest(".rst__nodeContent")
    const isInsideSortableTree2 = e.target.closest(".rst__lineHalfVerticalTop")
    const isInsideSortableTree3 = e.target.closest(
      ".rst__lineHalfHorizontalRight"
    )

    if (
      !isInsideSortableTree &&
      !isInsideSortableTree2 &&
      !isInsideSortableTree3
    ) {
      setDragging(true)
      setDragStart({ x: e.clientX, y: e.clientY })
    }
  }

  const handleMouseUp = () => {
    setDragging(false)
  }

  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaX = e.clientX - dragStart.x
      const deltaY = e.clientY - dragStart.y

      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY
      })

      setDragStart({ x: e.clientX, y: e.clientY })
    }
  }

  const handleViewOnly = (node) => {
    // console.log(node)
    setViewOnly(node)
    setViewOnlyToStore({ ...node })
    setPosition({
      ...defaultPosition,
      y: 140
    })
  }

  const toggleModalView = () => {
    setViewOnly(null)
    setViewOnlyToStore(null)
  }

  const setViewOnlyToStore = (data) => {
    let newData = data
    if (data) {
      let newData = { ...data }
      newData[parentIdField] = null
    }

    dispatch(setViewOnlyTree({ data: newData, storeName: storeName }))
      .then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            setOrgChartData({
              data: data ? [newData] : store.pageDataTempFilterTree[storeName],
              storeName: storeName
            })
          )
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  const handleFiltersChange = (value, par_name) => {
    filtersCustom[par_name] = value
    dispatch(
      getDataList({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        page: currentPage,
        length: rowsPerPage,
        ...filtersCustom
      })
    )
  }

  const handleAddEdit = (rowData, editMode = true, addChild = false) => {
    // console.log(rowData)
    if (rowData && editMode && !addChild) {
      setPageType("edit")
      ///if Modal
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        setLoadingText(
          `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
        )
        setLoadingModal(true)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // setLoading(false)
          setShowModal(true)
          dispatch(getDataById(rowData.id, storeName)).then((res) => {
            setLoadingModal(false)
          })
        } else {
          // console.log("setSelectedData")
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              setLoadingModal(false)
              setShowModal(true)
            }
          )
        }
      } else {
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          navigate(`${location.pathname}/edit/${rowData.id}`)
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              navigate(`${location.pathname}/edit/${rowData.id}`)
            }
          )
        }
      }
    } else {
      if (addChild) {
        // console.log(rowData)
        ////FIELDS VALUE AUTOS ADD SUB
        let newData = {}
        if (rowData[pageAttr?.crudOpt?.treeviewAddParentIdField ?? idField]) {
          newData[parentIdField] =
            rowData[pageAttr?.crudOpt?.treeviewAddParentIdField ?? idField]
        }

        if (pageAttr?.crudOpt?.treeviewAddSubParentFields) {
          pageAttr.crudOpt.treeviewAddSubParentFields.map((item) => {
            if (pageAttr.crudOpt?.treeviewAddSubIncrementField !== item) {
              newData[item] = rowData[item]
            }
          })
        }

        ////CODE INCREMENT
        if (codeField) {
          const oldValuesArr =
            rowData?.children?.map((obj) => obj[codeField]) ?? []
          // console.log(oldValuesArr)
          let maxValue = 1
          if (oldValuesArr?.length > 0) {
            oldValuesArr.map((item) => {
              if (item?.includes(".")) {
                const newDataFieldArr = item.split(".")
                if (newDataFieldArr.length > 0) {
                  const oldNumber =
                    newDataFieldArr.pop() !== ""
                      ? newDataFieldArr.pop()
                      : newDataFieldArr[newDataFieldArr.length - 1]
                  // console.log(oldNumber)
                  const inc = parseInt(oldNumber ?? "0", 10)
                  if (inc > maxValue) {
                    maxValue = inc
                  }
                  const newInc = String(inc + 1).padStart(
                    pageAttr.crudOpt?.treeviewAddSubIncrementLength ??
                      (oldNumber?.length > 0 ? oldNumber?.length : 2) ??
                      2,
                    "0"
                  )
                  newData[codeField] =
                    `${rowData[codeField]}.${newInc}.`.replace("..", ".")
                }
              }
            })
          } else {
            const newInc = String(maxValue).padStart(
              pageAttr.crudOpt?.treeviewAddSubIncrementLength ?? 2,
              "0"
            )
            newData[codeField] = `${rowData[codeField]}.${newInc}.`.replace(
              "..",
              "."
            )
          }
        }

        setPageType("add-child")
        dispatch(
          setSelectedData({ rowData: newData, storeName: storeName })
        ).then((res) => {
          if (!pageAttr?.crudOpt?.detailPage || relPageId) {
            setLoadingModal(false)
            setLoading(false)
            setShowModal(true)
          } else {
            if (newData) {
              let str = []
              for (let p in newData) {
                if (newData.hasOwnProperty(p)) {
                  str.push(
                    `${encodeURIComponent(p)}=${encodeURIComponent(newData[p])}`
                  )
                }
              }
              const query = str.join("&")
              navigate(`${location.pathname}/add?${query}`)
            } else {
              navigate(`${location.pathname}/add`)
            }
          }
        })
      } else {
        setPageType("add")
        if (!pageAttr?.crudOpt?.detailPage || relPageId) {
          dispatch(resetSelectedData(storeName)).then((r) => {
            setLoadingModal(false)
            setLoading(false)
            setShowModal(true)
          })
        } else {
          dispatch(resetSelectedData(storeName)).then((r) => {
            navigate(`${location.pathname}/add`)
          })
        }
      }
    }
  }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    // console.log("here")
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res?.payload?.message ?? "", {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const handleDeleteSelected = async () => {
    const countSelectedCopy = selectedRows.length
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You will delete")} ${countSelectedCopy} ${t(
        "selected data. Deleted data cannot be recovered"
      )}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${countSelectedCopy} ${t(
        pageAttr.pageTitle
      )}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(
        `${t("Deleting")} ${countSelectedCopy} Data ${t(pageAttr.pageTitle)}`
      )
      setLoading(true)
      const values = selectedRows.map((a) => a.id)
      // console.log(values)
      dispatch(
        deleteSelectedRowData({
          id: values,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setSelectedRows([])
          setToggleCleared(!toggleCleared)
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${countSelectedCopy} ${t(
              pageAttr.pageTitle
            )}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  /////TREE FUNCTION
  const [searchString, setSearchString] = useState("")
  const [searchFocusIndex, setSearchFocusIndex] = useState(0)
  const [searchFoundCount, setSearchFoundCount] = useState(null)
  const [treeHeight, setTreeHeight] = useState(450)

  const customSearchMethod = ({ node, searchQuery }) => {
    const title = node[titleField] ?? "".toLowerCase()
    const titleTranslate = t(node[titleField] ?? "").toLowerCase()
    // console.log(searchQuery, title.toLowerCase())
    return (
      (searchQuery && title.indexOf(searchQuery) > -1) ||
      (searchQuery && titleTranslate.indexOf(searchQuery) > -1)
    )
  }

  const handleChange = (tree) => {
    if (tree) {
      dispatch(
        setDataList({
          storeName: storeName,
          data: tree
        })
      )
    }
  }

  const onVisibilityToggle = (treeData) => {
    // console.log("onVisibilityToggle")
    // console.log(treeData)
  }

  const nodeClicked = (event, treeData) => {
    if (pageAttr?.crudOpt?.serverSideGrid) {
      if (
        event?.target?.className === "rst__expandButton" ||
        event?.target?.className === "rst__collapseButton"
      ) {
        // console.log(event, event?.target?.className === "rst__expandButton")
        // console.log("nodeClicked")
        // console.log(treeData)
        // console.log(store?.isLoadingNode)
        if (event?.target?.className === "rst__expandButton") {
          if (
            treeData?.children?.[0]?.isPlaceholder ||
            pageAttr?.crudOpt?.getAlwaysGrid
          ) {
            let currentId = treeData?.[idField]
            dispatch(
              lazyLoadByParent({
                currentId: currentId,
                storeName: storeName,
                endpointName: pageAttr?.crudOpt?.endpointName,
                parentIdField: parentIdField
              })
            ).then((response) => {
              // console.log(response)
              // console.log(store?.isLoadingNode)
            })
          }
        }
      }
    }
  }

  // const nodeClicked2 = (treeData) => {
  //   console.log("nodeClicked2")
  //   // console.log(treeData)
  //   // console.log(store?.isLoadingNode)
  //   let currentId = treeData?.[idField]
  //   dispatch(
  //     lazyLoadByParent({
  //       currentId: currentId,
  //       storeName: storeName,
  //       endpointName: pageAttr?.crudOpt?.endpointName,
  //       parentIdField: parentIdField
  //     })
  //   ).then((response) => {
  //     // console.log(response)
  //     // console.log(store?.isLoadingNode)
  //   })
  // }

  const onMoveNode = ({
    treeData,
    node,
    nextParentNode,
    prevPath,
    prevTreeIndex,
    nextPath,
    nextTreeIndex
  }) => {
    // console.log(treeData)
    // console.log(node)
    // console.log(nextParentNode)
    // console.log(prevPath)
    // console.log(prevTreeIndex)
    // console.log(nextPath)
    // console.log(nextTreeIndex)
    const data = {
      [idField]: node[idField],
      [titleField]: node[titleField],
      [parentIdField]: nextParentNode[idField],
      [indexField]: nextTreeIndex ?? 1
    }
    updateDataToApi(data, true)
  }

  const updateDataToApi = (data, notReload = false) => {
    data["notReload"] = notReload
    data["storeName"] = storeName
    data["endpointName"] = pageAttr?.crudOpt?.endpointName
    setLoadingText(
      `${t("Updating")} ${t(pageAttr?.pageTitle)} ${t("Detail")} Data`
    )
    setLoadingPostPut(!loadingPostPut)
    dispatch(putDataTree(data))
      .then((res) => {
        setLoadingPostPut(!loadingPostPut)
        if (res.meta.requestStatus === "fulfilled") {
          setShowModal(false)
          toast.success(
            `${t("Successfully Updated")} ${t(pageAttr?.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  /////PAGINATION SERVERSIDE
  //search
  const handleDebounceFn = (inputValue) => {
    setLoading(true)

    if (inputValue?.length > 0) {
      let endpointNameFirst = pageAttr?.crudOpt?.endpointName
      const hasScheme = endpointNameFirst.includes("://")
      const urlObj = new URL(
        endpointNameFirst,
        hasScheme ? undefined : "http://wekdut.com"
      )
      urlObj.searchParams.delete("parent_id")
      let endpointName = urlObj.toString()
      if (!hasScheme) {
        endpointName = endpointName.replace("http://wekdut.com", "")
      }

      dispatch(
        getDataList({
          storeName,
          endpointName,
          sort_dir,
          search: inputValue,
          sort,
          // page: currentPage,
          length: rowsPerPage,
          ...filtersCustom
        })
      ).then((x) => {
        setLoading(false)
      })
    } else {
      dispatch(
        getDataList({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          ...filtersCustom
        })
      ).then((x) => {
        setLoading(false)
      })
    }
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])
  const handleFilter = (event) => {
    setSearchTerm(event)
    // console.log(event)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      debounceFn(event)
    }

    // else {
    //   if (event.length && store.pageData[storeName]?.data) {
    //     let storeData = JSON.parse(
    //       JSON.stringify(store.pageData[storeName]?.data)
    //     )
    //     if (storeData) {
    //       const searchField =
    //         pageAttr?.crudOpt?.data?.dynamicColumnsSearchFields ?? []
    //       let filterData = []
    //       filterData = storeData.filter((item) => {
    //         for (let key in item) {
    //           if (item[key]) {
    //             if (searchField) {
    //               if (
    //                 (key,
    //                 searchField.indexOf(key) > -1 &&
    //                   `${item[key]}`
    //                     ?.toLowerCase()
    //                     ?.includes(event.toLowerCase()))
    //               ) {
    //                 return item
    //               }
    //             } else {
    //               if (item[key].toLowerCase().includes(event.toLowerCase())) {
    //                 return item
    //               }
    //             }
    //           }
    //         }
    //       })
    //       setFilteredData(filterData)
    //     }
    //     setSearchTerm(event)
    //   }
    // }
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  // const [serverSideProps, setServerSideProps] = useState({})

  useEffect(() => {
    // console.log("useEffect")
    // console.log(storeName)
    // console.log(store.pageData)
    if (
      (!store.pageData[storeName]?.data || pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      setLoading(true)
      setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)
      // console.log("Loading Data 1")
      dispatch(
        pageAttr?.crudOpt?.serverSideGrid
          ? getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              sort_dir,
              sort,
              search: searchTerm,
              page: currentPage,
              length: rowsPerPage,
              ...filtersCustom
            })
          : getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              ...filtersCustom
            })
      ).then((x) => {
        // console.log(x)
        setLoading(false)
        dispatch(
          setOrgChartData({ data: x?.payload?.data, storeName: storeName })
        )
        // if (pageAttr?.crudOpt?.serverSideGrid) {
        //   setServerSideProps({
        //     sortServer: true,
        //     paginationServer: true,
        //     paginationTotalRows: store.pageData[storeName]?.total,
        //     paginationComponent: CustomPagination
        //     // paginationComponentOptions: paginationComponentOptions
        //   })
        // }
      })
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)
        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            dispatch(
              pageAttr?.crudOpt?.serverSideGrid
                ? getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    sort_dir,
                    sort,
                    search: searchTerm,
                    page: currentPage,
                    length: rowsPerPage,
                    ...filtersCustom
                  })
                : getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    ...filtersCustom
                  })
            )
          }
        }
      }
      // console.log("Loading Data 2")
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!store.pageData[storeName]?.data) {
      return
    }

    const makeBlob = () => {
      const element = document.getElementById("treeview_data")

      element.classList.add("treeview_data")
      element.style.boxShadow = "none"

      html2canvas(element).then((canvas) => {
        // const context = canvas.getContext("2d")
        // const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
        // const data = pixels.data
        // let x, y, idx

        // let minX = canvas.width,
        //   minY = canvas.height,
        //   maxX = 0,
        //   maxY = 0

        // // Scan through all pixels
        // for (y = 0; y < canvas.height; y++) {
        //   for (x = 0; x < canvas.width; x++) {
        //     idx = (y * canvas.width + x) * 4
        //     if (data[idx + 3] > 0) {
        //       // Checking alpha value
        //       minX = Math.min(minX, x)
        //       maxX = Math.max(maxX, x)
        //       minY = Math.min(minY, y)
        //       maxY = Math.max(maxY, y)
        //     }
        //   }
        // }

        // // Create new cropped canvas
        // const width = maxX - minX + 1
        // const height = maxY - minY + 1
        // const croppedCanvas = document.createElement("canvas")
        // croppedCanvas.width = width
        // croppedCanvas.height = height
        // const croppedCtx = croppedCanvas.getContext("2d")

        // croppedCtx.drawImage(
        //   canvas,
        //   minX,
        //   minY,
        //   width,
        //   height,
        //   0,
        //   0,
        //   width,
        //   height
        // )

        canvas.toBlob((blob) => {
          myCallback(blob)
          element.classList.remove("treeview_data")
        }, "image/png")
      })
    }

    const myCallback = (blob) => {
      let url = window.URL.createObjectURL(blob)
      let filename = `${t(pageAttr?.pageTitle ?? "Treeview")}.png`
      let a = document.createElement("a")
      a.style = "display: none"
      a.href = url
      a.download = filename

      // IE 11
      if (window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename)
        return
      }

      document.body.appendChild(a)
      requestAnimationFrame(() => {
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
    }

    const blobButton = document.getElementById("blobButton")
    if (blobButton) {
      blobButton?.addEventListener("click", makeBlob)
    }

    return () => {
      if (blobButton) {
        blobButton?.removeEventListener("click", makeBlob)
      }
    }
  }, [store.pageData[storeName]?.data])

  useEffect(() => {
    if (store.pageData[storeName]?.data) {
      const countNodes = (nodes) => {
        let total = nodes.length
        nodes.forEach((node) => {
          if (node.children && node.children.length > 0) {
            total += countNodes(node.children)
          }
        })
        return total
      }

      let totalNodes = countNodes(store.pageData[storeName]?.data)
      let totalHeight = totalNodes * 90
      setTreeHeight(totalHeight)
    }
  }, [store.pageData[storeName]?.data])

  const colorsClass = [
    "primary",
    "info",
    "success",
    "warning",
    "secondary",
    "danger"
  ]

  const genLoading = () => {
    return [
      ...[1, 2, 3, 4, 5].map((_) => ({
        title: (
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            width="45vw"
            height={25}
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
          />
        ),
        // content: (
        //   <Skeleton
        //     sx={{ bgcolor: colors.dark.light }}
        //     animation="wave"
        //     width="40vw"
        //     height={20}
        //     style={{
        //       border: "none",
        //       marginBottom: "5px",
        //       transform: "scale(1, 1)"
        //     }}
        //   />
        // ),
        meta: (
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            width="6vw"
            height={20}
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
          />
        ),
        color: colorsClass[Math.floor(Math.random() * colorsClass.length)],
        customContent: (
          <div className="d-flex justify-content-between flex-wrap">
            {pageAttr?.crudOpt?.data?.dynamicColumns.map((column) => (
              <div
                key={`rel_field_${column.columnName}`}
                className="d-flex align-items-center"
              >
                <div>
                  <span className="mb-0 me-2 text-muted">
                    <Skeleton
                      sx={{ bgcolor: colors.dark.light }}
                      animation="wave"
                      width="6vw"
                      height={20}
                      style={{
                        border: "none",
                        marginBottom: "1px",
                        transform: "scale(1, 1)"
                      }}
                    />
                  </span>
                  <div className="">
                    <Skeleton
                      sx={{ bgcolor: colors.dark.light }}
                      animation="wave"
                      width="6vw"
                      height={20}
                      style={{
                        border: "none",
                        marginBottom: "1px",
                        transform: "scale(1, 1)"
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex flex-wrap align-items-center cursor-pointer mt-sm-0 mt-50">
              <Fragment>
                <Skeleton
                  sx={{ bgcolor: colors.dark.light }}
                  animation="wave"
                  className="ms-2 me-1"
                  width={90}
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    transform: "scale(1, 1)"
                  }}
                  height={40}
                />
                <Skeleton
                  sx={{ bgcolor: colors.dark.light }}
                  animation="wave"
                  width={90}
                  height={40}
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    transform: "scale(1, 1)"
                  }}
                />
              </Fragment>
            </div>
          </div>
        )
      }))
    ]
  }

  const isServerSideLoading = (node) => {
    return (
      pageAttr?.crudOpt?.serverSideGrid &&
      (store?.isLoadingNode?.[storeName]?.includes(node?.[idField]) ||
        store?.isLoadingNode?.[storeName]?.includes(node?.[parentIdField]) ||
        store?.isLoadingNode?.[storeName]?.includes(node?.parent_id) ||
        node?.isPlaceholder)
    )
  }

  const getNodeStyle = (node) => {
    let styles = {
      fontWeight:
        node?.children?.length &&
        node?.expanded &&
        !node?.children?.[0]?.isPlaceholder &&
        !node?.isPlaceholder
          ? `bold`
          : "normal",
      backgroundColor: pageAttr?.crudOpt?.treeviewStyleBgField
        ? pageAttr?.crudOpt?.treeviewStyleBgField?.includes(".")
          ? getDepthValue(node, pageAttr?.crudOpt?.treeviewStyleBgField)
          : node?.[pageAttr?.crudOpt?.treeviewStyleBgField]
        : node?.children?.length && node?.expanded
        ? skin === "dark"
          ? "rgb(29 39 68) !important"
          : "rgb(242 249 255)"
        : "transparent",
      color: pageAttr?.crudOpt?.treeviewStyleColorField
        ? pageAttr?.crudOpt?.treeviewStyleColorField?.includes(".")
          ? getDepthValue(node, pageAttr?.crudOpt?.treeviewStyleColorField)
          : node?.[pageAttr?.crudOpt?.treeviewStyleColorField]
        : null,
      ...(pageAttr?.crudOpt?.treeviewStyle ?? {})
      // border: isServerSideLoading(node)
      //   ? "solid 1px #e9e9e9"
      //   : "inherit"
    }

    if (isServerSideLoading(node)) {
      styles.backgroundColor = "transparent"
      styles.fontWeight = "normal"
      if (node?.isPlaceholder) {
        styles.backgroundColor = "#f4f4f4"
        styles["border"] = "none"
      }
    }
    return styles
  }

  const serverSideProps = ({ node, searchQuery }) => {
    let result = {}
    if (!pageAttr?.crudOpt?.serverSideGrid) {
      result = {
        searchMethod: customSearchMethod({ node, searchQuery }),
        searchQuery: searchTerm,
        searchFocusOffset: searchFocusIndex,
        searchFinishCallback: (matches) => {
          setSearchFoundCount(matches.length)
          setSearchFocusIndex(
            matches.length > 0 ? searchFocusIndex % matches.length : 0
          )
        }
      }
    }
    console.log(pageAttr?.crudOpt?.serverSideGrid, result)
    return result
  }

  return (
    <Fragment>
      {/* <UILoader
        blocking={store.pageData[storeName]?.isLoading}
        loader={<Loader />}
      > */}
      <Card id="tree_container" className="overflow-hidden ">
        <div
          className="react-dataTable react-dataTable-selectable-rows tree-container"
          style={{
            // height:
            //   store.pageData[storeName]?.isLoading ||
            //   !store.pageData[storeName]?.data
            //     ? 450
            //     : 10000,
            height: `${treeHeight}px`,
            // overflow: "auto",
            margin: 20
          }}
        >
          {store.pageData[storeName]?.data?.length > 0 && (
            <div
              style={{
                position: window.innerWidth < 600 ? "inherit" : "absolute",
                paddingRight: "25px",
                paddingLeft: 0,
                zIndex: 1,
                left: 0
              }}
              className="content-header-right text-md-end col-md-12 col-12 mb-0 pb-0 pb-md-2 mt-0 px-md-2 px-1"
            >
              <Row className="align-items-center">
                <Col md={6} xs={12}>
                  <div className="d-flex align-items-center mb-sm-0 mb-1">
                    <label className="mb-0" htmlFor="search-invoice">
                      {t("Search")}:
                    </label>
                    <Input
                      id="search-invoice"
                      className="ms-50"
                      type="text"
                      value={searchTerm}
                      onChange={(e) => handleFilter(e.target.value)}
                    />
                  </div>
                </Col>
                <Col className="p-0 p-md-1" md={6} xs={12}>
                  <div className="d-md-flex align-items-center text-center text-md-right justify-content-end mb-sm-0 mb-0 mb-md-1">
                    <Button
                      id="blobButton"
                      outline
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      color="secondary"
                      disabled={!store.pageData[storeName]?.data}
                    >
                      {renderIcon("material:file_download", "", 14)}
                    </Button>
                    <UncontrolledTooltip placement="bottom" target="blobButton">
                      {t("Export to Image")}
                    </UncontrolledTooltip>

                    <Button
                      onClick={(e) =>
                        !isFullscreen ? requestFullscreen() : exitFullscreen()
                      }
                      outline
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      color="secondary"
                    >
                      {!isFullscreen ? <Fullscreen /> : <FullscreenExit />}
                    </Button>

                    <ButtonGroup
                      className={`expand-collapse-all ms-50 mb-75 mb-md-0 ${
                        pageAttr?.isViewOnRow
                          ? "mt-sm-50 mt-50"
                          : "mt-sm-0 mt-0"
                      }`}
                    >
                      <Button
                        id="zoom-out-btn-crud"
                        outline
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault()
                          zoomOut()
                        }}
                      >
                        {renderIcon("material:zoom_out", "", 15)}
                      </Button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="zoom-out-btn-crud"
                      >
                        {t("Zoom Out")}
                      </UncontrolledTooltip>

                      <Button
                        id="zoom-in-btn-crud"
                        outline
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault()
                          zoomIn()
                        }}
                      >
                        {renderIcon("material:zoom_in", "", 15)}
                      </Button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="zoom-in-btn-crud"
                      >
                        {t("Zoom In")}
                      </UncontrolledTooltip>
                    </ButtonGroup>

                    <ButtonGroup
                      className={`expand-collapse-all ms-50 mb-75 mb-md-0 ${
                        pageAttr?.isViewOnRow
                          ? "mt-sm-50 mt-50 me-1 "
                          : "mt-sm-0 mt-0"
                      }`}
                    >
                      <Button
                        id="collapse-all-btn-crud"
                        outline
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault()
                          // console.log(event)
                          // toggleExpandedForAll(
                          //   store.pageData[storeName]?.data,
                          //   false
                          // )
                          dispatch(
                            expandCollapseAllTree({
                              value: false,
                              storeName: storeName
                            })
                          ).then((res) => {
                            // console.log(res)
                          })
                        }}
                      >
                        {renderIcon("material:unfold_less", "", 15)}
                      </Button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="collapse-all-btn-crud"
                      >
                        {t("Collapse All")}
                      </UncontrolledTooltip>

                      <Button
                        id="expand-all-btn-crud"
                        outline
                        color="secondary"
                        onClick={(e) => {
                          // console.log(event)
                          e.preventDefault()
                          // toggleExpandedForAll(store.pageData[storeName]?.data)
                          dispatch(
                            expandCollapseAllTree({
                              value: true,
                              storeName: storeName
                            })
                          ).then((res) => {
                            // console.log(res)
                          })
                        }}
                      >
                        {renderIcon("material:unfold_more", "", 15)}
                      </Button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="expand-all-btn-crud"
                      >
                        {t("Expand All")}
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              {/* <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">

                
              </div>
              <div className="d-flex align-items-center mb-sm-0 mb-1 me-1"></div> */}
            </div>
          )}

          {store.pageDataTempFilterTreeCurrent[storeName] && (
            <div
              style={{
                position: "absolute",
                left: "25px",
                top: "85px"
              }}
              className="d-flex align-items-center align-middle mb-1"
            >
              <Button
                id="btn-back-crud"
                className="me-50 btn-sm"
                color="flat-secondary"
                onClick={(e) => {
                  e.preventDefault()
                  toggleModalView()
                }}
              >
                {renderIcon("material:arrow_back", "", 15)}
              </Button>
              <h5 className="m-0">
                <span style={{ fontWeight: 300 }}>
                  {`${t("View Only Childrens Of")}`}{" "}
                </span>
                <span className="fw-bolder">
                  {store.pageDataTempFilterTreeCurrent[storeName]?.[titleField]}
                </span>
              </h5>
            </div>
          )}

          {store.pageData[storeName]?.data?.length > 0 ? (
            <div
              ref={divRef}
              id="treeview_data"
              style={{
                overflow: "auto",
                height: "100%",
                width: "100%"
                // backgroundColor: "lightblue",
                // marginTop: zoomLevel !== 1 ? "100px" : 0
                // padding: "10px"
              }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              <SortableTree
                style={{
                  cursor: dragging ? "grabbing" : "grab",
                  // position: zoomLevel === 1 ? "relative" : "absolute",
                  position: "absolute",
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                  height: "100%",
                  width: "100%",
                  transform: `scale(${zoomLevel})`,
                  transition: "transform 0.3s ease"
                }}
                // style={{
                //   paddingBottom: "300px"
                // }}
                isVirtualized={false}
                treeData={
                  store.pageData[storeName]?.filteredData ??
                  store.pageData[storeName]?.data
                }
                onChange={handleChange}
                onVisibilityToggle={onVisibilityToggle}
                // onVisibilityToggle={(args) => onVisibilityToggle(args)}
                onMoveNode={onMoveNode}
                canDrag={
                  !pageAttr?.crudOpt?.treeviewDisabledDrag &&
                  ability.can(
                    "update",
                    sessionStorage.getItem("current_page_perm")
                  )
                }
                canDrop={
                  !pageAttr?.crudOpt?.treeviewDisabledDrop &&
                  ability.can(
                    "update",
                    sessionStorage.getItem("current_page_perm")
                  )
                }
                // {...serverSideProps}
                searchMethod={customSearchMethod}
                searchQuery={searchTerm}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={(matches) => {
                  // console.log(matches)
                  if (!pageAttr?.crudOpt?.serverSideGrid) {
                    setSearchFoundCount(matches.length)
                    setSearchFocusIndex(
                      matches.length > 0 ? searchFocusIndex % matches.length : 0
                    )
                  }
                }}
                rowHeight={
                  pageAttr?.crudOpt?.treeviewRowHeight ??
                  pageAttr?.crudOpt?.treeviewStyle?.height
                }
                onlyExpandSearchedNodes={searchTerm?.length > 0}
                // maxDepth={4}
                generateNodeProps={({ node, path }) => ({
                  onClick: (event) => nodeClicked(event, node),
                  style: { ...getNodeStyle(node) },
                  title: pageAttr?.crudOpt?.data?.dynamicColumns ? (
                    pageAttr?.crudOpt?.data?.dynamicColumns.map((column) => {
                      return (
                        <div
                          key={`keyss-${column.fieldName}`}
                          style={{
                            fontWeight:
                              node?.children?.length &&
                              node?.expanded &&
                              !isServerSideLoading(node)
                                ? `bold`
                                : 400,
                            paddingRight: "5px"
                          }}
                        >
                          {!node?.isPlaceholder && (
                            <DynamicRow
                              key={`DynamicRow-key-${column.fieldName}`}
                              row={node}
                              column={column}
                              clickToDetailEvent={handleAddEdit}
                            />
                          )}
                          {isServerSideLoading(node) && (
                            <Fragment>
                              {node?.isPlaceholder && (
                                <small>{t("Fetch Children Data...")}</small>
                              )}
                              <Spinner
                                style={{
                                  position: "absolute",
                                  right: "15px",
                                  top: "17px"
                                }}
                                size="sm"
                                className="ms-50"
                                color="secondary"
                              />
                            </Fragment>
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <Fragment>
                      {iconField && node[iconField] && (
                        <div>{renderIcon(node[iconField], "")} </div>
                      )}
                      <div
                        key={`key-${column.fieldName}`}
                        style={{
                          // paddingLeft: node?.children?.length ? 0 : 10,
                          fontWeight:
                            !isServerSideLoading(node) && node?.children?.length
                              ? `bold`
                              : 400
                          // textTransform: node?.children?.length
                          //   ? `uppercase`
                          //   : "",
                          // color: node?.children?.length ? "#a6a4b0" : "inherit"
                        }}
                      >
                        {isServerSideLoading(node)
                          ? t("Fetch Children Data...")
                          : t(node[titleField])}
                      </div>
                    </Fragment>
                  ),

                  buttons: !isServerSideLoading(node)
                    ? [
                        node?.children?.length > 0 &&
                          !store.pageDataTempFilterTreeCurrent[storeName] &&
                          node?.[parentIdField] !== null && (
                            <Fragment>
                              <Button.Ripple
                                className="btn-icon rounded-circle"
                                color="flat-secondary"
                                id={`btn-view-only-${node[idField]}`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleViewOnly(node)
                                }}
                              >
                                {renderIcon("material:open_in_full", "", 15)}
                              </Button.Ripple>
                              <UncontrolledTooltip
                                placement="left"
                                target={`btn-view-only-${node[idField]}`}
                              >
                                {t("View Only This Node")}
                              </UncontrolledTooltip>
                            </Fragment>
                          ),

                        !pageAttr?.crudOpt?.disabledAdd &&
                          (pageAttr?.crudOpt?.rowDisabledAddFieldName?.includes(
                            "."
                          )
                            ? !getDepthValue(
                                node,
                                pageAttr?.crudOpt?.rowDisabledAddFieldName
                              )
                            : !node[
                                pageAttr?.crudOpt?.rowDisabledAddFieldName
                              ]) &&
                          ability.can(
                            "post",
                            sessionStorage.getItem("current_page_perm")
                          ) && (
                            <Fragment>
                              <Button.Ripple
                                className="btn-icon rounded-circle"
                                // color="flat-secondary"
                                color={
                                  pageAttr?.crudOpt
                                    ?.treeviewStyleBtnClassNameField
                                    ? pageAttr?.crudOpt?.treeviewStyleBtnClassNameField?.includes(
                                        "."
                                      )
                                      ? getDepthValue(
                                          node,
                                          pageAttr?.crudOpt
                                            ?.treeviewStyleBtnClassNameField
                                        ) ?? "flat-secondary"
                                      : node?.[
                                          pageAttr?.crudOpt
                                            ?.treeviewStyleBtnClassNameField
                                        ] ?? "flat-secondary"
                                    : "flat-secondary"
                                }
                                id={`btn-add-${node[idField]}`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleAddEdit(node, false, true)
                                  // toggleSidebar(node)
                                }}
                              >
                                {/* <Plus size={16} /> */}
                                <FontAwesomeIcon
                                  className="align-middle"
                                  icon="folder-tree"
                                />
                              </Button.Ripple>
                              <UncontrolledTooltip
                                placement="left"
                                target={`btn-add-${node[idField]}`}
                              >
                                {t("Add Child")}
                              </UncontrolledTooltip>
                            </Fragment>
                          ),

                        !pageAttr?.crudOpt?.disabledEdit &&
                          (pageAttr?.crudOpt?.rowDisabledEditFieldName?.includes(
                            "."
                          )
                            ? !getDepthValue(
                                node,
                                pageAttr?.crudOpt?.rowDisabledEditFieldName
                              )
                            : !node[
                                pageAttr?.crudOpt?.rowDisabledEditFieldName
                              ]) &&
                          ability.can(
                            "update",
                            sessionStorage.getItem("current_page_perm")
                          ) && (
                            <Fragment>
                              <Button.Ripple
                                className="btn-icon rounded-circle"
                                // color="flat-secondary"
                                color={
                                  pageAttr?.crudOpt
                                    ?.treeviewStyleBtnClassNameField
                                    ? pageAttr?.crudOpt?.treeviewStyleBtnClassNameField?.includes(
                                        "."
                                      )
                                      ? getDepthValue(
                                          node,
                                          pageAttr?.crudOpt
                                            ?.treeviewStyleBtnClassNameField
                                        ) ?? "flat-secondary"
                                      : node?.[
                                          pageAttr?.crudOpt
                                            ?.treeviewStyleBtnClassNameField
                                        ] ?? "flat-secondary"
                                    : "flat-secondary"
                                }
                                id={`btn-edit-${node[idField]}`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleAddEdit(node)
                                }}
                              >
                                <Edit2 size={16} />
                              </Button.Ripple>
                              <UncontrolledTooltip
                                placement="left"
                                target={`btn-edit-${node[idField]}`}
                              >
                                {t("Edit")}
                              </UncontrolledTooltip>
                            </Fragment>
                          ),

                        !pageAttr?.crudOpt?.disabledDelete &&
                        (pageAttr?.crudOpt?.rowDisabledDeleteFieldName?.includes(
                          "."
                        )
                          ? !getDepthValue(
                              node,
                              pageAttr?.crudOpt?.rowDisabledDeleteFieldName
                            )
                          : !node[
                              pageAttr?.crudOpt?.rowDisabledDeleteFieldName
                            ]) &&
                        ability.can(
                          "delete",
                          sessionStorage.getItem("current_page_perm")
                        ) &&
                        !node?.children?.length ? (
                          <Fragment>
                            <Button.Ripple
                              id={`btn-delete-${node[idField]}`}
                              className="btn-icon rounded-circle"
                              // color="flat-secondary"
                              color={
                                pageAttr?.crudOpt
                                  ?.treeviewStyleBtnClassNameField
                                  ? pageAttr?.crudOpt?.treeviewStyleBtnClassNameField?.includes(
                                      "."
                                    )
                                    ? getDepthValue(
                                        node,
                                        pageAttr?.crudOpt
                                          ?.treeviewStyleBtnClassNameField
                                      ) ?? "flat-secondary"
                                    : node?.[
                                        pageAttr?.crudOpt
                                          ?.treeviewStyleBtnClassNameField
                                      ] ?? "flat-secondary"
                                  : "flat-secondary"
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                handleDelete(node.id)
                              }}
                            >
                              <Trash2 size={16} />
                            </Button.Ripple>
                            <UncontrolledTooltip
                              placement="left"
                              target={`btn-delete-${node[idField]}`}
                            >
                              {t("Delete")}
                            </UncontrolledTooltip>
                          </Fragment>
                        ) : null

                        // pageAttr?.crudOpt?.serverSideGrid &&
                        // !node?.children?.length ? (
                        //   <Fragment>
                        //     <Button.Ripple
                        //       id={`btn-fetch-${node[idField]}`}
                        //       className="btn-icon rounded-circle"
                        //       color="flat-secondary"
                        //       onClick={(e) => {
                        //         e.preventDefault()
                        //         nodeClicked2(node)
                        //       }}
                        //     >
                        //       <RefreshCw size={16} />
                        //     </Button.Ripple>
                        //     <UncontrolledTooltip
                        //       placement="left"
                        //       target={`btn-fetch-${node[idField]}`}
                        //     >
                        //       {t("Fetch Childrens Data")}
                        //     </UncontrolledTooltip>
                        //   </Fragment>
                        // ) : null
                      ]
                    : []
                })}
              />
            </div>
          ) : (
            <NoDataDisplay />
          )}
        </div>
      </Card>
      {/* </UILoader> */}

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </Fragment>
  )
}

export default TreeView
