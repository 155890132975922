// ** Third Party Components
import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getRespData, getSeries } from "@utils"

const BarChart = ({
  pageAttr,
  currentAttr,
  item,
  handleChangeFilterFromChild
}) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const areaColors = item?.chartOpt?.colors ?? {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7"
  }

  // ** Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          download:
            '<span class="material-icons-outlined ms-25 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">file_download</span>'
          // customIcons: [
          //   {
          //     icon: '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">refresh</span>',
          //     // index: 4,
          //     title: t("Refresh Data"),
          //     class: "custom-icon",
          //     click: function (chart, options, e) {
          //       // console.log("clicked custom-icon")
          //       return handleChangeFilterFromChild({
          //         item: currentAttr
          //       })
          //     }
          //   }
          // ]
        }
      }
    },

    // title: {
    //   text: t(currentAttr?.title ?? ""),
    //   align: "left",
    //   margin: 10,
    //   offsetX: 0,
    //   offsetY: 10,
    //   floating: false,
    //   style: {
    //     fontSize: "14px",
    //     fontWeight: "bold",
    //     color: "#666666"
    //   }
    // },
    // subtitle: {
    //   text: t(currentAttr?.subTitle ?? ""),
    //   align: "left",
    //   margin: 10,
    //   offsetX: 0,
    //   offsetY: 30,
    //   floating: false,
    //   style: {
    //     fontSize: "12px",
    //     color: "#c6c6c6"
    //   }
    // },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        ...(item?.chartOpt?.plotOptions?.bar ?? {})
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
      curve: "straight"
    },
    legend: {
      // position: "top",
      // horizontalAlign: "end",
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 5,
        vertical: 10
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },

    xaxis: {
      categories: getRespData(item, "categories", store),
      title: {
        text: item?.chartOpt?.xaxis_title ? t(item?.chartOpt?.xaxis_title) : ""
      }
    },
    colors: getRespData(item, "color", store) ?? [
      areaColors.series3,
      areaColors.series2,
      areaColors.series1
    ],
    // fill: {
    //   opacity: 1,
    //   type: "solid"
    //   // colors: getRespData(item, "color") ?? [
    //   //   areaColors.series3,
    //   //   areaColors.series2,
    //   //   areaColors.series1
    //   // ]
    // },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return item?.chartOpt?.tooltip_suffix
            ? `${val} ${t(item?.chartOpt?.tooltip_suffix)}`
            : ""
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0)
        }
      },
      title: {
        text: item?.chartOpt?.yaxis_title ? t(item?.chartOpt?.yaxis_title) : ""
      }
    }
  }

  // ** Chart Series
  const series = getSeries(item, store)
  return (
    series && (
      <Chart
        options={options}
        series={series}
        type="bar"
        height={item?.chartOpt?.height ?? "100%"}
      />
    )
  )
}
export default BarChart
