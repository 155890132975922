import { useEffect, Fragment, useState, forwardRef, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
// ** Third Party Components
import Spinner from "@src/@core/components/spinner/Fallback-spinner"
import { useTranslation } from "react-i18next"
// ** Reactstrap Imports
import { Card, Input } from "reactstrap"
import { setStorePageAttributes } from "@src/shared/dynamic_pages/store"
import Table from "@src/shared/dynamic_pages/Table"
import DynamicPage from "@src/shared/dynamic_pages"
// ** Bootstrap Checkbox Component
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const InputsDropdownTable = ({
  item,
  data,
  onChange,
  selected,
  endPointName
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [pageattr, setpageattr] = useState(null)
  const store = useSelector((state) => state.dynamicStore)
  const [storename, setStorename] = useState(`${item?.fieldName}_dropdowntable`)
  const [loadingFirst, setLoadingFirst] = useState(false)

  // const dynamic_temp_state = useSelector((state) => state.dynamic_temp_state)
  // console.log(dynamic_temp_state.parent_data_relation)

  // console.log(tableOpt?.crudOpt?.maxPageLength)

  const getpageattr = async (sourceType) => {
    const tableOpt = item?.dataFrom?.tableOpt
    const initialpageattr = {
      pageId: storename,
      pageTitle: item?.fieldLabel ?? tableOpt?.pageTitle,
      pageType: "crud",
      isViewOnForm: true,
      isSeletTable: true,
      isDropdownTable: true,
      crudOpt: {
        endpointName:
          endPointName ??
          item?.dataFrom?.api ??
          tableOpt?.crudOpt?.endpointName,
        baseUrl: item?.dataFrom?.baseUrl ?? tableOpt?.crudOpt?.baseUrl,
        multiselectToCurrentForm:
          item?.dataFrom?.multiselectToCurrentForm ?? true,
        multiselectToCurrentFormType:
          item?.dataFrom?.multiselectToCurrentFormType,
        initialFiltersCondition: tableOpt?.crudOpt?.initialFiltersCondition,
        dynamicColumnsGroupByKey: tableOpt?.crudOpt?.dynamicColumnsGroupByKey,
        dynamicColumnsGroupByLabel:
          tableOpt?.crudOpt?.dynamicColumnsGroupByLabel,
        serverSideGrid:
          item?.dataFrom?.isAutoComplete ?? tableOpt?.crudOpt?.serverSideGrid,
        detailFromGrid: true,
        // gridTheme: tableOpt?.crudOpt?.gridTheme ?? "table",
        getApiSummaryData: false,
        getAlwaysGrid:
          tableOpt?.crudOpt?.getAlwaysGrid ??
          item?.dataFrom?.api_getAlways ??
          false,
        deleteSelection: false,
        addSelection: true,
        maxPageLength: tableOpt?.crudOpt?.maxPageLength ?? 10,
        hideBtnSwitchLayout: tableOpt?.crudOpt?.hideBtnSwitchLayout ?? true,
        hideTableSearchInput: tableOpt?.crudOpt?.hideTableSearchInput ?? false,
        hideTablePageLengthInfo:
          tableOpt?.crudOpt?.hideTablePageLengthInfo ?? true,
        hideTablePagination: tableOpt?.crudOpt?.hideTablePagination ?? false,
        disabledEdit: true,
        disabledDelete: true,
        disabledAdd: true,
        data: {
          ...(tableOpt?.crudOpt?.data?.dynamicColumns?.length
            ? { dynamicColumns: tableOpt?.crudOpt?.data?.dynamicColumns }
            : {})
        }
      }
    }
    setpageattr(initialpageattr)
    dispatch(
      setStorePageAttributes({
        storeName: storename,
        data: initialpageattr
      })
    )
    setLoadingFirst(false)
  }

  const handleChangeFilterFromChild = (selectedData, reportMode = false) => {
    // console.log(selectedData)
    onChange(selectedData?.selectedRows?.[0])
  }

  const loadingFirstOnce = useRef(false)
  useEffect(() => {
    if (loadingFirstOnce.current === false) {
      // if (!pageattr || !store.pageAttributes[storename]) {
      //   setLoadingFirst(true)
      //   if (!store.pageAttributes[storename]) {
      //     getpageattr()
      //   } else {
      //     setpageattr(store.pageAttributes[storename])
      //     setLoadingFirst(false)
      //   }
      // }
      // console.log(endPointName)

      setLoadingFirst(true)
      getpageattr()
      setLoadingFirst(false)
      loadingFirstOnce.current = true
    }
  }, [pageattr, store.pageAttributes[storename], store.pageData[storename]])

  return pageattr && store.pageAttributes[storename] ? (
    <Fragment>
      <Card
        className="mb-0"
        style={{
          boxShadow: "none"
        }}
      >
        <div className="selectable-input">
          {item?.dataFrom?.isAutoComplete ||
          item?.dataFrom?.tableOpt?.crudOpt?.serverSideGrid ? (
            <DynamicPage
              key={storename}
              initialPageId={storename}
              initialPageAttr={pageattr}
              handleSelectedDropdownTableChange={handleChangeFilterFromChild}
              selectedDataTable={selected ? [selected] : null}
            />
          ) : (
            <Table
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              pageAttr={pageattr}
              // filtersCustomAdd={}
              storeName={storename}
              selectedDataTable={selected ? [selected] : null}
            />
          )}
        </div>
      </Card>
    </Fragment>
  ) : (
    <Spinner />
  )
}

export default InputsDropdownTable
