import { useEffect, Fragment, useState, forwardRef, useRef, lazy } from "react"
import { useDispatch, useSelector } from "react-redux"
// ** Third Party Components
import Spinner from "@src/@core/components/spinner/Fallback-spinner"
import { useTranslation } from "react-i18next"
// ** Reactstrap Imports
import { Card, Input } from "reactstrap"
import {
  setStorePageAttributes,
  setDataList
} from "@src/shared/dynamic_pages/store"
import Table from "@src/shared/dynamic_pages/Table"
// ** Bootstrap Checkbox Component
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const DynamicPage = lazy(() => import("@src/shared/dynamic_pages"))

const InputsTable = ({
  item,
  data,
  onChange,
  endPointName,
  resetSelectTable
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [pageattr, setpageattr] = useState(null)
  const store = useSelector((state) => state.dynamicStore)
  const [storename, setstorename] = useState(`${item?.fieldName}_inputtable`)
  const [loadingFirst, setLoadingFirst] = useState(false)

  // console.log(item, data)
  // const dynamic_temp_state = useSelector((state) => state.dynamic_temp_state)
  // console.log(dynamic_temp_state.parent_data_relation)

  // console.log(tableOpt?.crudOpt?.maxPageLength)
  const getpageattr = async (sourceType) => {
    const tableOpt = item?.dataFrom?.tableOpt ?? item?.tableOpt ?? item
    const initialpageattr = {
      pageId: storename,
      // pageTitle: item?.fieldLabel ?? tableOpt?.pageTitle,
      isViewOnForm: true,
      isViewOnRow: false,
      isInputTable: true,
      pageType: "crud",
      crudOpt: {
        ...(tableOpt?.crudOpt ?? {}),
        endpointName:
          endPointName ??
          item?.dataFrom?.api ??
          tableOpt?.crudOpt?.endpointName,
        baseUrl: item?.dataFrom?.baseUrl ?? tableOpt?.crudOpt?.baseUrl,
        // initialFiltersCondition: tableOpt?.crudOpt?.initialFiltersCondition,
        // dynamicColumnsGroupByKey: tableOpt?.crudOpt?.dynamicColumnsGroupByKey,
        // dynamicColumnsGroupByLabel:
        //   tableOpt?.crudOpt?.dynamicColumnsGroupByLabel,
        serverSideGrid:
          item?.dataFrom?.isAutoComplete ??
          tableOpt?.crudOpt?.serverSideGrid ??
          false,
        detailFromGrid: true,
        getApiSummaryData: false,
        getAlwaysGrid:
          tableOpt?.crudOpt?.getAlwaysGrid ??
          item?.dataFrom?.api_getAlways ??
          false,
        deleteSelection: false,
        addSelection: false,
        maxPageLength: tableOpt?.crudOpt?.maxPageLength ?? 10,
        hideBtnSwitchLayout: tableOpt?.crudOpt?.hideBtnSwitchLayout ?? true,
        hideTableSearchInput: tableOpt?.crudOpt?.hideTableSearchInput ?? false,
        hideTablePageLengthInfo:
          tableOpt?.crudOpt?.hideTablePageLengthInfo ?? true,
        hideTablePagination: tableOpt?.crudOpt?.hideTablePagination ?? false,
        disabledEdit: true,
        disabledDelete: true,
        disabledAdd: true,
        data: {
          ...(tableOpt?.crudOpt?.data ?? {}),
          dynamicColumns:
            tableOpt?.crudOpt?.data?.dynamicColumns ??
            item?.dynamicColumns ??
            []
        }
      }
    }
    setpageattr(initialpageattr)
    dispatch(
      setStorePageAttributes({
        storeName: storename,
        data: initialpageattr
      })
    ).then((_) =>
      dispatch(
        setDataList({
          data: initialDataNew,
          storeName: storename
        })
      )
    )
    setLoadingFirst(false)
  }

  const initialPageIdDynamic = `inputtable_${storename}`
  const initialDataNew = JSON.parse(JSON.stringify(data))
  // const handleChangeFilterFromChild = (selectedData, reportMode = false) => {
  //   // console.log(selectedData)
  //   onChange(selectedData?.selectedRows)
  // }

  const loadingFirstOnce = useRef(false)
  useEffect(() => {
    if (loadingFirstOnce.current === false) {
      if (!pageattr || !store.pageAttributes[storename]) {
        setLoadingFirst(true)
        if (!store.pageAttributes[storename]) {
          getpageattr()
        } else {
          setpageattr(store.pageAttributes[storename])

          dispatch(
            setDataList({
              data: initialDataNew,
              storeName: storename
            })
          )
          setLoadingFirst(false)
        }
      }

      setLoadingFirst(false)
      loadingFirstOnce.current = true
    }
  }, [pageattr, store.pageAttributes[storename], store.pageData[storename]])

  // const newInitialPageAttr = {
  //   ...relationsCrudData,
  //   isViewOnRow: true,
  //   pageId: initialPageIdDynamic,
  //   relationsCrudKey: initialPageIdDynamic
  // }

  return !resetSelectTable &&
    pageattr &&
    store.pageAttributes[storename] &&
    store.pageData[storename] !== undefined ? (
    <DynamicPage
      key={`inputtable_${storename}`}
      initialPageId={storename}
      initialPageAttr={pageattr}
      initialData={initialDataNew}
    />
  ) : (
    <Spinner />
  )
}

export default InputsTable
