import DynamicPage from "@src/shared/dynamic_pages"
import { useState } from "react"
import Spinner from "@src/@core/components/spinner/Fallback-spinner"

const InputsRelationsCrud = ({
  initialPageId,
  initialPageAttr,
  pageType,
  resetSelectTable,
  disableBtnAddFromParent,
  disableBtnEditFromParent,
  disableBtnDeleteFromParent,
  disableBtnAddCurrent = false
}) => {
  // console.log(
  //   disableBtnAddFromParent,
  //   disableBtnEditFromParent,
  //   disableBtnDeleteFromParent
  // )
  const [newInitialPageAttr, setNewInitialPageAttr] = useState({
    ...initialPageAttr,
    disableBtnAddFromParent: disableBtnAddFromParent,
    disableBtnAddCurrent: disableBtnAddCurrent,
    disableBtnEditFromParent: disableBtnEditFromParent,
    disableBtnDeleteFromParent: disableBtnDeleteFromParent,
    isViewOnForm: true,
    relationsCrudKey: initialPageId,
    isFromAddPage: pageType === "add"
  })

  // console.log(newInitialPageAttr)

  return !resetSelectTable ? (
    <DynamicPage
      key={initialPageId}
      initialPageId={initialPageId}
      initialPageAttr={newInitialPageAttr}
    />
  ) : (
    <Spinner />
  )
}

export default InputsRelationsCrud
