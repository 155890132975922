// ** React Imports
import ReactDOM from "react-dom"
import { useState, useEffect, useRef, Fragment } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

// ** Custom Components
import Avatar from "@components/avatar"

import TextareaAutosize from "react-textarea-autosize"
// ** Store & Actions
import { sendMsg, selectChat } from "./store"
import { useDispatch } from "react-redux"

// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  MessageSquare,
  Menu,
  PhoneCall,
  Video,
  Search,
  MoreVertical,
  Mic,
  Image,
  Send
} from "react-feather"

// ** Reactstrap Imports
import {
  Form,
  Label,
  Input,
  Button,
  InputGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupText,
  UncontrolledDropdown,
  CardText
} from "reactstrap"
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import "moment-timezone"
import "moment/locale/id"

import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import {
  formatDateToMonthShort,
  isObjEmpty,
  convertHtmlToEmoji,
  convertToClickableLinks
} from "@utils"
import { Loader } from "../../shared/CommonComponents"
import SpinnerComponent from "../../@core/components/spinner/Fallback-spinner"

const ChatLog = (props) => {
  // ** Props & Store
  const {
    handleUser,
    handleUserSidebarRight,
    handleSidebar,
    store,
    userSidebarLeft,
    userData,
    clickSideBar,
    refreshPage,
    activeUserChat
  } = props
  const {
    userProfile,
    selectedUser,
    selectedUser_loading,
    selectedUsers,
    contacts
  } = store
  const { t, i18n } = useTranslation()
  // ** Refs & Dispatch
  const chatArea = useRef(null)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [selectedContact, setSelectedContact] = useState(null)

  // ** State
  const [msg, setMsg] = useState("")

  // ** Scroll to chat bottom
  const scrollToBottom = () => {
    const chatContainer = chatArea.current
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }

  // ** If user chat is not empty scrollToBottom
  useEffect(() => {
    if (activeUserChat) {
      setSelectedContact(
        contacts?.find((contact) => contact.id === activeUserChat)
      )
    }

    const selectedUserLen = Object.keys(selectedUser).length
    let timeoutId

    if (selectedUserLen || activeUserChat) {
      timeoutId = setTimeout(() => {
        scrollToBottom()
      }, 50)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [selectedUser, activeUserChat])

  const checkMsgContent = (msg) => {
    let result = convertHtmlToEmoji(msg)
    result = convertToClickableLinks(result)
    // return result
    return <span dangerouslySetInnerHTML={{ __html: result.join("") }} />
  }

  // ** Formats chat data based on sender
  const formattedChatData = () => {
    let chatLog = []
    if (selectedUser.chat) {
      chatLog = selectedUser?.chat
    }

    const formattedChatLog = []
    let chatMessageSenderId = chatLog[0] ? chatLog[0]?.sender : undefined
    let msgGroup = {
      senderId: chatMessageSenderId,
      messages: []
    }
    chatLog.forEach((msg, index) => {
      if (chatMessageSenderId === msg?.sender) {
        msgGroup.messages.push({
          id: msg.id,
          msg: msg.message,
          time: msg.timestamp,
          readAt: msg.readAt,
          deliveredAt: msg.deliveredAt
        })
      } else {
        chatMessageSenderId = msg?.sender
        formattedChatLog.push(msgGroup)
        msgGroup = {
          senderId: msg?.sender,
          messages: [
            {
              id: msg.id,
              msg: msg.message,
              time: msg.timestamp,
              readAt: msg.readAt,
              deliveredAt: msg.deliveredAt
            }
          ]
        }
      }
      if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
    })
    return formattedChatLog
  }

  // ** Renders user chat
  const renderChats = () => {
    return formattedChatData().map((item, index) => {
      return (
        <div
          key={index}
          className={classnames("chat", {
            "chat-left": item?.senderId !== userData?.id
          })}
        >
          <div className="chat-avatar">
            <Avatar
              imgWidth={36}
              imgHeight={36}
              className="box-shadow-1 cursor-pointer"
              img={
                item?.senderId === userData?.id
                  ? userProfile?.avatar ?? defaultAvatar
                  : selectedUser?.contact?.avatar ?? defaultAvatar
              }
            />
          </div>

          <div className="chat-body">
            {item.messages.map((chat) => (
              <div key={chat.id} className="chat-content">
                <p>
                  {checkMsgContent(chat.msg)}{" "}
                  <span
                    style={{
                      fontSize: "9px",
                      color: "rgb(183 183 183)"
                    }}
                  >
                    {formatDateToMonthShort(chat?.time ?? new Date())}
                  </span>
                  {item?.senderId === userData?.id
                    ? chat?.readAt
                      ? renderIcon("material:done_all", "ms-25 text-warning", 9)
                      : chat?.deliveredAt
                      ? renderIcon("material:done_all", "ms-25", 9)
                      : renderIcon("material:done", "ms-25", 9)
                    : null}
                </p>
              </div>
            ))}
          </div>
        </div>
      )
    })
  }

  // ** Opens right sidebar & handles its data
  const handleAvatarClick = (obj) => {
    handleUserSidebarRight()
    handleUser(obj)
  }

  // ** On mobile screen open left sidebar on Start Conversation Click
  const handleStartConversation = () => {
    if (
      !Object.keys(selectedUser).length &&
      !userSidebarLeft &&
      window.innerWidth < 992
    ) {
      handleSidebar()
    }
  }

  // ** Sends New Msg
  const handleSendMsg = (e) => {
    e.preventDefault()
    if (msg.trim().length) {
      // console.log(selectedUser)
      // dispatch(sendMsg({ ...selectedUser, message: msg }))
      // dispatch(sendMsg({ receiver: selectedUser?.contact?.id, message: msg }))
      // navigate(`/chat?current=${selectedUser?.contact?.id}`)
      setMsg("")
      dispatch(
        sendMsg({ receiver: selectedUser?.contact?.id, message: msg })
      ).then(() => {
        // window.location.href = `/chat?current=${selectedUser?.contact?.id}`
        // navigate(`/chat`)
        // refreshPage(
        //   `/chat?current=${selectedUser?.contact?.id}&fromSideBar=true`
        // )
        let element = document.getElementById(
          `chat_list_${selectedUser?.contact?.id}`
        )
        // console.log(element)
        if (element) {
          // setTimeout(() => {
          element.click()
          // }, 200)
        }
        // return clickSideBar(selectedUser?.contact?.id)
      })

      // simulateMouseClick(element)

      // setTimeout(() => {
      //   // const fromSideBar = searchParams.get("fromSideBar")
      //   // const refresh = searchParams.get("refresh")

      //   searchParams.delete("current")
      //   if (searchParams.has("fromSideBar")) {
      //     searchParams.delete("fromSideBar")
      //   }
      //   if (searchParams.has("refresh")) {
      //     searchParams.delete("refresh")
      //   }

      //   let url = `/chat?current=${selectedUser?.contact?.id}`
      //   // if (!fromSideBar) {
      //   url = `${url}&refresh=true`
      //   // }
      //   navigate(url)
      // }, 700)
    }
  }

  const handleFocus = () => {
    // let url = `/chat?current=${selectedUser?.contact?.id}`
    // // if (!fromSideBar) {
    // // url = `${url}&refresh=true`
    // // }
    // navigate(url)
  }

  const selectedUserContactData = {
    ...selectedContact,
    ...selectedUser?.contact
  }

  // ** ChatWrapper tag based on chat's length
  const ChatWrapper =
    Object.keys(selectedUser).length && selectedUser.chat
      ? PerfectScrollbar
      : "div"

  return (
    <div className="chat-app-window">
      <div
        className={classnames("start-chat-area", {
          "d-none": Object.keys(selectedUserContactData).length
        })}
      >
        <div className="start-chat-icon mb-1">
          <MessageSquare />
        </div>
        <h4
          className="sidebar-toggle start-chat-text"
          onClick={handleStartConversation}
        >
          {t("Start Conversation")}
        </h4>
      </div>
      {Object.keys(selectedUserContactData).length ? (
        <div
          className={classnames("active-chat", {
            "d-none": !selectedUserContactData
          })}
        >
          <div className="chat-navbar">
            <header className="chat-header">
              <div className="d-flex align-items-center">
                <div
                  className="sidebar-toggle d-block d-lg-none me-1"
                  onClick={handleSidebar}
                >
                  <Menu size={21} />
                </div>
                <Avatar
                  imgHeight="36"
                  imgWidth="36"
                  img={
                    selectedContact?.avatar ??
                    selectedUser?.contact?.avatar ??
                    defaultAvatar
                  }
                  // status={selectedUser?.contact?.status}
                  status={
                    selectedUserContactData?.isOnline === true
                      ? "online"
                      : "offline"
                  }
                  className="avatar-border user-profile-toggle m-0 me-1"
                  // onClick={() => handleAvatarClick(selectedUser?.contact)}
                />
                <div className="chat-info flex-grow-1 mt-25">
                  <h6 className="mb-0">
                    {selectedContact?.name ?? selectedUser?.contact?.name}
                  </h6>
                  {selectedUserContactData?.last_request_time &&
                    (selectedUserContactData?.isOnline === false ? (
                      <CardText className="text-truncate">
                        {t("Last Online at")}{" "}
                        <Moment fromNow locale={i18n.language ?? "id"}>
                          {selectedUserContactData?.last_request_time}
                        </Moment>
                      </CardText>
                    ) : selectedUserContactData?.isOnlineOtherApp ? (
                      <Fragment>
                        <span>{t("Online di App")} </span>
                        <b>{selectedUserContactData?.app_name ?? ""}</b>
                      </Fragment>
                    ) : (
                      t("Online")
                    ))}
                </div>
              </div>
              <div className="d-flex align-items-center">
                {/* <PhoneCall
                  size={18}
                  className="cursor-pointer d-sm-block d-none me-1"
                />
                <Video
                  size={18}
                  className="cursor-pointer d-sm-block d-none me-1"
                />
                <Search
                  size={18}
                  className="cursor-pointer d-sm-block d-none"
                />
                <UncontrolledDropdown className="more-options-dropdown">
                  <DropdownToggle
                    className="btn-icon"
                    color="transparent"
                    size="sm"
                  >
                    <MoreVertical size="18" />
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem href="/" onClick={(e) => e.preventDefault()}>
                      View Contact
                    </DropdownItem>
                    <DropdownItem href="/" onClick={(e) => e.preventDefault()}>
                      Mute Notifications
                    </DropdownItem>
                    <DropdownItem href="/" onClick={(e) => e.preventDefault()}>
                      Block Contact
                    </DropdownItem>
                    <DropdownItem href="/" onClick={(e) => e.preventDefault()}>
                      Clear Chat
                    </DropdownItem>
                    <DropdownItem href="/" onClick={(e) => e.preventDefault()}>
                      Report
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </div>
            </header>
          </div>

          <PerfectScrollbar
            className="user-chats"
            options={{ wheelPropagation: false }}
            // ref={chatArea}
            containerRef={(el) => (chatArea.current = el)}
          >
            {selectedUser.chat && userData ? (
              <div className="chats">{renderChats()}</div>
            ) : selectedUser_loading ? (
              <div className="start-chat-area w-100 h-100">
                <Fragment>
                  <SpinnerComponent style={{ height: "inherit" }} />
                </Fragment>
              </div>
            ) : null}
          </PerfectScrollbar>

          {!selectedUser?.contact?.isOnlineOtherApp ? (
            <Form
              style={{ minHeight: "65px" }}
              className="chat-app-form"
              onSubmit={handleSendMsg}
            >
              <InputGroup className="input-group-merge me-1 form-send-message">
                {/* <InputGroupText>
                <Mic className="cursor-pointer" size={14} />
              </InputGroupText> */}
                <TextareaAutosize
                  maxRows={2}
                  style={{
                    width: "100%"
                  }}
                  className="form-control my-25"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  // onFocus={handleFocus}
                  // defaultValue={lastMessage}
                  autoFocus
                  // placeholder={t("Type your message or use speech to text")}
                  placeholder={t("Type your message")}
                />
                {/* <InputGroupText>
                <Label className="attachment-icon mb-0" for="attach-doc">
                  <Image className="cursor-pointer text-secondary" size={14} />
                  <input type="file" id="attach-doc" hidden />
                </Label>
              </InputGroupText> */}
              </InputGroup>

              <Button className="send" color="primary">
                <Send size={14} className="d-lg-none" />
                <span className="d-none d-lg-block">{t("Send")}</span>
              </Button>
            </Form>
          ) : (
            <div
              style={{ minHeight: "65px" }}
              className="chat-app-form text-danger fw-bolder"
            >
              {t("Currently User is in another app, unable to send messages")}.
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ChatLog
