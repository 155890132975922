/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
import {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  forwardRef,
  lazy,
  Suspense
} from "react"
import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AbilityContext } from "@src/utility/context/Can"
import ModalAddEdit from "./modal"
import { neutralizeBack, revivalBack } from "@src/utility/Utils"
import GlobalActionsTable from "@src/shared/GlobaActionsTable"
import {
  getDataList,
  getDataById,
  setSelectedData,
  resetSelectedData,
  deleteData,
  deleteSelectedRowData
} from "./store"
import { useDispatch, useSelector } from "react-redux"
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import debounce from "lodash.debounce"
import { ChevronDown } from "react-feather"

import {
  NoDataDisplay,
  Loader,
  TableSkeletonLoading
} from "@src/shared/CommonComponents"
import UILoader from "@components/ui-loader"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Input,
  Button,
  Alert
} from "reactstrap"

import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import DynamicRow from "@src/utility/generateForm/dynamicRow"

import { renderIcon } from "../../views/apps/menu/store/my_menu"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

// ** Table Header
const CustomHeader = ({
  pageAttr,
  handleAddEdit,
  handlePerPage,
  rowsPerPage,
  handleFilter,
  searchTerm,
  selectedRow,
  handleDeleteSelected,
  handleFiltersChange,
  filtersCustomAdd,
  storeName
}) => {
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()

  const [dynamicColumnsFilter, setDynamicColumnsFilter] = useState(
    pageAttr?.crudOpt?.data?.dynamicColumnsFilter
  )
  const [dynamicFilterComponents, setDynamicFilterComponents] = useState({})
  const [dynamicFilterDatas, setDynamicFilterDatas] = useState({})
  const store = useSelector((state) => state.dynamicStore)

  const handleChangeInputs = (value, fieldName, isMulti = false) => {
    fieldName = `filter_${fieldName}`
    if (isMulti) {
      if (dynamicFilterDatas[fieldName]) {
        const index = dynamicFilterDatas[fieldName].indexOf(value)
        if (index !== -1) {
          dynamicFilterDatas[fieldName].splice(index, 1)
        } else {
          dynamicFilterDatas[fieldName].push(value)
        }
      } else {
        dynamicFilterDatas[fieldName] = [value]
      }
    } else {
      dynamicFilterDatas[fieldName] = value
    }
    return handleFiltersChange(dynamicFilterDatas[fieldName], fieldName)
  }

  const getRequiredFilterState = () => {
    let mixParams = store.pageData[storeName]?.params
    let isLoadState = true

    /////prevent load data if filter include required attr and undefined
    if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
      pageAttr?.crudOpt?.data?.dynamicColumnsFilter
        .filter((x) => x?.rules?.required === true)
        .map((y) => {
          // eslint-disable-next-line no-unneeded-ternary
          isLoadState = mixParams?.[y.fieldName] ? true : false
        })
    }

    return isLoadState
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 mt-1 ms-50 mb-2 px-1">
      <Row>
        <Col xs={12} lg={4} className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            {store.pageData[storeName]?.data?.length > 0 &&
              pageAttr?.crudOpt?.serverSideGrid &&
              !pageAttr.crudOpt?.hideTablePageLengthInfo &&
              getRequiredFilterState() && (
                <Fragment>
                  <label htmlFor="rows-per-page">{t("Show")}</label>
                  <Input
                    className="mx-50"
                    type="select"
                    id="rows-per-page"
                    value={rowsPerPage}
                    onChange={handlePerPage}
                    style={{ width: "5rem" }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Input>
                  <label htmlFor="rows-per-page">{t("Entries")}</label>
                </Fragment>
              )}
          </div>
        </Col>

        <Col
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          xs={12}
          lg={8}
        >
          {selectedRow?.length > 0 &&
          ability.can("delete", sessionStorage.getItem("current_page_perm")) ? (
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <Button
                className="delete-users"
                color="danger"
                onClick={handleDeleteSelected}
              >
                {t("Delete")} {selectedRow.length} {t(pageAttr.pageTitle)}
              </Button>
            </div>
          ) : null}

          {!pageAttr.crudOpt?.hideTableSearchInput && getRequiredFilterState() && (
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                {t("Search")}:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                onChange={(e) => handleFilter(e.target.value)}
              />
            </div>
          )}

          {!pageAttr?.crudOpt?.disabledAdd &&
          ability.can("post", sessionStorage.getItem("current_page_perm")) &&
          getRequiredFilterState() ? (
            <Button
              className="add-permission mt-sm-0 mt-1"
              color="primary"
              // onClick={toggleSidebar}
              onClick={(e) => {
                handleAddEdit(null)
              }}
            >
              {t("Add New")} {t(pageAttr.pageTitle)}
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

const List = ({
  pageAttr,
  storeName,
  filtersCustomAdd,
  handleFiltersChangeToParent,
  lookupMode,
  lookupMode_fieldName,
  lookupMode_defaultValue,
  lookupMode_onChange,
  lookupMode_isMulti
}) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState(
    store.pageData[storeName]?.params?.search ?? ""
  )
  const [currentPage, setCurrentPage] = useState(
    store.pageData[storeName]?.params?.page ?? 1
  )
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(
    store.pageData[storeName]?.params?.length ?? 10
  )
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const handleAddEdit = (rowData) => {
    const pageRedirect =
      pageAttr?.crudOpt?.rowClickEvent === "view" ? "view" : "edit"
    // console.log(rowData)
    if (rowData) {
      setPageType(pageRedirect)
      ///if Modal
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        setLoadingText(
          `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
        )
        setLoadingModal(true)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // setLoading(false)
          setShowModal(true)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          ).then((res) => {
            setLoadingModal(false)
          })
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              setLoadingModal(false)
              setShowModal(true)
            }
          )
        }
      } else {
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          )
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
            }
          )
        }
      }
    } else {
      setPageType("add")
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        dispatch(resetSelectedData(storeName)).then((r) => {
          setLoadingModal(false)
          setLoading(false)
          setShowModal(true)
        })
      } else {
        dispatch(resetSelectedData(storeName)).then((r) => {
          navigate(`${location.pathname}/add`)
        })
      }
    }
  }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    // console.log("here")
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const handleDeleteSelected = async () => {
    const countSelectedCopy = selectedRows.length
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You will delete")} ${countSelectedCopy} ${t(
        "selected data. Deleted data cannot be recovered"
      )}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${countSelectedCopy} ${t(
        pageAttr.pageTitle
      )}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(
        `${t("Deleting")} ${countSelectedCopy} Data ${t(pageAttr.pageTitle)}`
      )
      setLoading(true)
      const values = selectedRows.map((a) => a.id)
      // console.log(values)
      dispatch(
        deleteSelectedRowData({
          id: values,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setSelectedRows([])
          setToggleCleared(!toggleCleared)
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${countSelectedCopy} ${t(
              pageAttr.pageTitle
            )}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  /////PAGINATION SERVERSIDE
  //search
  const handleDebounceFn = (inputValue) => {
    // console.log(inputValue)
    setLoading(true)
    handleFiltersChangeToParent({
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir,
      search: inputValue,
      sort,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustom
    })
    setLoading(false)
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])

  const handleFilter = (event) => {
    setSearchTerm(event)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      debounceFn(event)
    } else {
      let storeData = JSON.parse(
        JSON.stringify(store.pageData[storeName]?.data)
      )

      const searchField = pageAttr?.crudOpt?.data?.dynamicColumnsSearchFields
      let filterData = []
      if (event.length) {
        filterData = storeData.filter((item) => {
          for (let key in item) {
            if (searchField) {
              if (
                (key,
                searchField.indexOf(key) > -1 &&
                  item[key].toLowerCase().includes(event.toLowerCase()))
              ) {
                return item
              }
            } else {
              if (
                `${item[key]}`.toLowerCase().includes(event.toLowerCase()) &&
                !key.includes("id", "Id_", "id_")
              ) {
                return item
              }
            }
          }
        })
        setFilteredData(filterData)
        setSearchTerm(event)
      }
    }
  }

  const handlePagination = (page) => {
    // console.log(page)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        length: rowsPerPage,
        page: page.selected + 1,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     length: rowsPerPage,
      //     page: page.selected + 1,
      //     ...filtersCustom
      //   })
      // )
    }
    setCurrentPage(page.selected + 1)
  }

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        length: value,
        page: currentPage,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     length: value,
      //     page: currentPage,
      //     ...filtersCustom
      //   })
      // )
    }
    setRowsPerPage(value)
  }

  const handleFiltersChange = (value, par_name) => {
    filtersCustom[par_name] = value
    handleFiltersChangeToParent({
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir,
      sort,
      search: searchTerm,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustom
    })
    // dispatch(
    //   getDataList({
    //     storeName,
    //     endpointName: pageAttr?.crudOpt?.endpointName,
    //     sort_dir,
    //     sort,
    //     search: searchTerm,
    //     page: currentPage,
    //     length: rowsPerPage,
    //     ...filtersCustom
    //   })
    // )
  }

  const handleSort = (column, sortDirection) => {
    setSortDir(sortDirection)
    setSort(column.sortField)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        page: currentPage,
        length: rowsPerPage,
        ...filtersCustom
      })
      // dispatch(
      //   getDataList({
      //     storeName,
      //     endpointName: pageAttr?.crudOpt?.endpointName,
      //     sort_dir,
      //     sort,
      //     search: searchTerm,
      //     page: currentPage,
      //     length: rowsPerPage,
      //     ...filtersCustom
      //   })
      // )
    }
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const CustomPagination = () => {
    if (!pageAttr.crudOpt?.hideTablePagination) {
      const count = Number(
        Math.ceil(
          pageAttr?.crudOpt?.serverSideGrid
            ? store.pageData[storeName]?.total / rowsPerPage
            : store.pageData[storeName]?.data?.length / rowsPerPage
        )
      )
      const countFitered = Number(Math.ceil(filteredData.length / rowsPerPage))
      return (
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          pageCount={searchTerm.length ? countFitered : count || 1}
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => {
            handlePagination(page)
          }}
          breakLabel="..."
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          activeClassName="active"
          pageClassName="page-item"
          breakClassName="page-item"
          nextLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextClassName="page-item next-item"
          previousClassName="page-item prev-item"
          containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
        />
      )
    } else {
      return null
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: t("Show"),
    rangeSeparatorText: t("of")
    // selectAllRowsItem: true,
    // selectAllRowsItemText: "Todos"
  }

  const columns = [
    ...store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns.map(
      (column) => ({
        name: t(column.columnName),
        sortField: column?.sortFieldName ?? column.fieldName,
        center: false,
        right: column?.type === "currency",
        minWidth: column?.minWidth ?? "20px",
        maxWidth: column?.maxWidth ?? "100%",
        sortable: column?.sortable === undefined ? true : column?.sortable,
        className: column?.className ?? "",
        conditionalCellStyles: [
          {
            when: (row) => !row.id,
            style: {
              height: "38px"
            }
          }
        ],
        selector: (row) => (!row.id ? row[column.fieldName] : null),
        cell: (row) => (
          <DynamicRow
            row={row}
            column={column}
            clickToDetailEvent={handleAddEdit}
          />
        )
      })
    ),
    (!store.pageAttributes[storeName]?.crudOpt?.disabledEdit ||
      !store.pageAttributes[storeName]?.crudOpt?.disabledDelete) &&
      ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      ability.can("delete", sessionStorage.getItem("current_page_perm")) && {
        name: t("Actions"),
        center: true,
        width: "140px",
        conditionalCellStyles: [
          {
            when: (row) => !row.id,
            style: {
              height: "38px"
            }
          }
        ],
        cell: (row) =>
          row?.id && (
            <GlobalActionsTable
              row={row}
              handleDelete={handleDelete}
              handleEdit={handleAddEdit}
              disabledAction={[
                store.pageAttributes[storeName]?.crudOpt?.disabledEdit
                  ? "update"
                  : "",
                store.pageAttributes[storeName]?.crudOpt?.disabledDelete
                  ? "delete"
                  : ""
              ]}
            />
          )
      }
  ]

  const tableColumns = useMemo(
    () =>
      loading || store.pageData[storeName].isLoading
        ? columns
            .filter((x) => x.name)
            .map((column) => ({
              name: t(column.name),
              ...column,
              sortable: false,
              cell: (row) => <TableSkeletonLoading column={column} />
            }))
        : columns
            .filter((x) => x.name)
            .map((column) => ({
              name: t(column.name),
              ...column
            })),
    [loading, columns]
  )

  const getTotals = (data, key) => {
    let total = 0
    data.forEach((item) => {
      // console.log(item[key])
      total += item[key]
    })
    return total
  }

  const dataToRender = () => {
    const filters = {
      search: searchTerm
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (!loading) {
      let dataGrid = store.pageData[storeName]?.data
      if (dataGrid) {
        let dataGridCopy = JSON.parse(JSON.stringify(dataGrid))
        if (dataGridCopy.length > 0) {
          // for subtotal
          let subtotal_fields = {}
          pageAttr?.crudOpt?.data?.dynamicColumns.some((el) => {
            if (el.hasOwnProperty("subtotal")) {
              subtotal_fields[el.fieldName] = getTotals(
                dataGridCopy,
                el.fieldName
              )
            }
          })
          // console.log(subtotal_fields)
          // console.log(
          //   "subtotal present ?",
          //   Object.keys(subtotal_fields).length > 0
          // )

          if (Object.keys(subtotal_fields).length > 0) {
            let newRecord = {}
            // console.log(subtotal_fields)
            Object.keys(dataGridCopy[0]).map((key) => {
              // console.log(subtotal_fields[key])
              newRecord[key] = subtotal_fields[key] ?? ""
            })
            // console.log(newRecord)
            dataGridCopy.push(newRecord)
          }

          return dataGridCopy
        } else if (dataGrid.length === 0 && isFiltered) {
          return []
        }
      }
    } else {
      return Array(10).fill({})
    }
  }

  const [serverSideProps, setServerSideProps] = useState({})
  const storeParent = useSelector(
    (state) => state?.[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  )

  const getData = () => {
    setLoading(true)
    setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)
    // if (pageAttr?.crudOpt?.serverSideGrid) {
    //   setCurrentPage(store.pageData[storeName].params?.page)
    // }
    dispatch(
      pageAttr?.crudOpt?.serverSideGrid
        ? getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            sort_dir,
            sort,
            search: searchTerm,
            page: currentPage,
            length: rowsPerPage,
            ...filtersCustom
          })
        : getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            ...filtersCustom
          })
    ).then((x) => {
      setLoading(false)
      if (pageAttr?.crudOpt?.serverSideGrid) {
        setServerSideProps({
          sortServer: true,
          paginationServer: true,
          paginationTotalRows: store.pageData[storeName]?.total,
          paginationComponent: CustomPagination
          // paginationComponentOptions: paginationComponentOptions
        })
      }
    })
  }

  useEffect(() => {
    // setCurrentPage(store.pageData[storeName]?.params?.page ?? 1)
    // setRowsPerPage(store.pageData[storeName]?.params?.length ?? 10)
    // setSearchTerm(store.pageData[storeName]?.params?.search ?? "")
    // console.log(storeName)
    // console.log(storeParent)
    // console.log(store.pageData)
    if (
      (!store.pageData[storeName]?.data || pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      getData()
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)

        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray =
          pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            getData()
          }
        }
      }

      if (pageAttr?.crudOpt?.serverSideGrid) {
        setServerSideProps({
          sortServer: true,
          paginationServer: true,
          paginationTotalRows: store.pageData[storeName]?.total,
          paginationComponent: CustomPagination
          // paginationComponentOptions: paginationComponentOptions
        })
      }
      setLoading(false)
    }
    // console.log(store)
  }, [dispatch, store.pageData[storeName], sort_dir, sort, currentPage])

  const conditionalRowStyles = [
    {
      when: (row) => !row.id,
      style: {
        // backgroundColor: "#f3f2f7",
        // borderBottom: "1px solid",
        // borderColor: "#ebe9f1",
        minHeight: 0,
        height: "38px"
      }
      // classNames: ["rdt_TableHeadRow", "rdt_TableCol"]
    }
  ]

  return (
    <Fragment>
      <CustomHeader
        pageAttr={pageAttr}
        searchTerm={searchTerm}
        rowsPerPage={rowsPerPage}
        handleFilter={handleFilter}
        handlePerPage={handlePerPage}
        handleAddEdit={handleAddEdit}
        selectedRow={selectedRows}
        handleDeleteSelected={handleDeleteSelected}
        handleFiltersChange={handleFiltersChange}
        filtersCustomAdd={filtersCustomAdd}
        storeName={storeName}
      />

      <Card className="overflow-hidden">
        <div className="react-dataTable react-dataTable-selectable-rows">
          {!store.pageData[storeName]?.isLoading ? (
            store.pageData[storeName]?.data?.map((row) => {
              return (
                <CardBody>
                  {store.pageAttributes[
                    storeName
                  ]?.crudOpt?.data?.dynamicColumns.map((column) => (
                    <div className="design-group mb-2 pt-50">
                      <h6 className="section-label">{t(column.columnName)}</h6>
                      <CardText className="mb-2">
                        <DynamicRow
                          row={row}
                          column={column}
                          clickToDetailEvent={handleAddEdit}
                        />
                      </CardText>
                    </div>
                  ))}

                  {(!store.pageAttributes[storeName]?.crudOpt?.disabledEdit ||
                    !store.pageAttributes[storeName]?.crudOpt
                      ?.disabledDelete) &&
                    ability.can(
                      "update",
                      sessionStorage.getItem("current_page_perm")
                    ) &&
                    ability.can(
                      "delete",
                      sessionStorage.getItem("current_page_perm")
                    ) && (
                      <div className="d-grid text-end">
                        <GlobalActionsTable
                          row={row}
                          handleDelete={handleDelete}
                          handleEdit={handleAddEdit}
                          disabledAction={[
                            store.pageAttributes[storeName]?.crudOpt
                              ?.disabledEdit
                              ? "update"
                              : "",
                            store.pageAttributes[storeName]?.crudOpt
                              ?.disabledDelete
                              ? "delete"
                              : ""
                          ]}
                        />
                      </div>
                    )}
                </CardBody>
              )
            })
          ) : (
            <div className="d-grid"></div>
          )}
        </div>
      </Card>

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </Fragment>
  )
}

export default List
