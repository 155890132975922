// ** React Imports
import { useState, useEffect, Fragment } from "react"

import { useNavigate } from "react-router-dom"
// ** Third Party Components
import classnames from "classnames"
import { X, Search } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"

import AppChat from "@src/views/chat"

// ** Utils
import { formatDateToMonthShort, isObjEmpty, convertHtmlToEmoji } from "@utils"
// ** Reactstrap Imports
import { Input, CardText, InputGroup, InputGroupText, Badge } from "reactstrap"
// ** Custom Components
import Avatar from "@components/avatar"
// ** Styles
import "@styles/react/libs/react-select/_react-select.scss"

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux"
import { getChatContacts } from "@src/views/chat/store"
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"
import { MessageRounded } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import "moment-timezone"
import "moment/locale/id"
import { badgeChatDecrement } from "@store/notifications"
import { Skeleton } from "@mui/material"

const FloatingChat = (props) => {
  const { t, i18n } = useTranslation()
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state.chat)
  const {
    chats,
    contacts,
    userProfile,
    contacts_chats_loading,
    contacts_chats_success_last_fetch
  } = store

  const storeNotification = useSelector((state) => state.notifications)
  // // ** State
  const [openCustomizer, setOpenCustomizer] = useState(false)
  const [query, setQuery] = useState("")
  const [about, setAbout] = useState("")
  const [active, setActive] = useState(0)
  const [status, setStatus] = useState("online")
  const [filteredChat, setFilteredChat] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])

  // ** Toggles Customizer
  const handleToggle = (e) => {
    e.preventDefault()
    setOpenCustomizer(!openCustomizer)
    if (!openCustomizer) {
      if (!contacts_chats_loading) {
        const now = new Date()
        const lastFetch = contacts_chats_success_last_fetch
        const differenceInMinutes = (now - lastFetch) / 1000 / 60

        if (differenceInMinutes > 1 || !lastFetch) {
          dispatch(getChatContacts())
        }
      }
    }
  }

  // ** Handles User Chat Click
  const handleUserClick = (id, data = null) => {
    if (data?.unseenMsgs > 0) {
      dispatch(badgeChatDecrement({ count: data?.unseenMsgs }))
    }

    setOpenCustomizer(!openCustomizer)
    sessionStorage.setItem("current", id)
    sessionStorage.setItem("fromSideBar", true)
    navigate(`/chat`)
    // dispatch(selectChat(id))
    // setActive(id)
    // if (sidebar === true) {
    //   handleSidebar()
    // }
  }

  // ** Handles Filter
  const handleFilter = (e) => {
    setQuery(e.target.value)
    const searchFilterFunction = (contact) =>
      contact?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
    const filteredChatsArr = chats.filter(searchFilterFunction)
    const filteredContactssArr = contacts.filter(searchFilterFunction)
    setFilteredChat([...filteredChatsArr])
    setFilteredContacts([...filteredContactssArr])
  }

  // ** Renders Chat
  const renderChats = () => {
    if (chats && chats.length) {
      if (query.length && !filteredChat.length) {
        return (
          <li className="no-results show">
            <h6 className="mb-0">{t("No Chats Found")}</h6>
          </li>
        )
      } else {
        const arrToMap =
          query.length && filteredChat.length ? filteredChat : chats

        return arrToMap.map((item) => {
          // const time = formatDateToMonthShort(
          //   item?.message ? item?.timestamp : new Date()
          // )

          const time = (
            <Moment fromNow locale={i18n.language ?? "id"}>
              {item?.message ? item?.timestamp : new Date()}
            </Moment>
          )

          return (
            <li
              key={item.current}
              onClick={() => handleUserClick(item.current, item)}
              className={classnames("align-items-center", {
                active: active === item.current
              })}
            >
              <Avatar
                img={item?.current_data_avatar ?? defaultAvatar}
                imgHeight="42"
                imgWidth="42"
                status={
                  item?.current_data_isOnline === true ? "online" : "offline"
                }
              />
              <div className="chat-info flex-grow-1">
                <div className="mb-0 fw-bolder">{item.current_data_name}</div>
                <CardText className="text-truncate">
                  {/* {item?.lastMessage
                    ? item?.lastMessage.message
                    : chats[chats.length - 1].message} */}
                  {convertHtmlToEmoji(item?.message, false)}
                </CardText>
              </div>
              <div className="chat-meta text-wrap">
                <small
                  style={{ fontSize: "9px" }}
                  className="float-end text-end mb-25 chat-time ms-25"
                >
                  {time}
                </small>
                {item?.unseenMsgs >= 1 ? (
                  <Badge className="float-end" color="danger" pill>
                    {item?.unseenMsgs}
                  </Badge>
                ) : null}
              </div>
            </li>
          )
        })
      }
    } else {
      if (contacts_chats_loading && !contacts_chats_success_last_fetch) {
        return [1, 2, 3, 4, 5].map((item) => {
          return (
            <li key={item} className="align-items-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
              <div className="chat-info mt-0 flex-grow-1">
                <Skeleton
                  animation="wave"
                  height={30}
                  width={140}
                  className="mb-0"
                />
                <Skeleton
                  animation="wave"
                  height={16}
                  width={200}
                  className="card-text text-truncate"
                />
              </div>
              <div className="chat-meta text-nowrap">
                <small className="float-end mb-25 chat-time ms-25">
                  <Skeleton animation="wave" height={16} width={35} />
                </small>
              </div>
            </li>
          )
        })
      } else {
        return (
          <li className="no-results show">
            <h6 className="mb-0">{t("No Chats Found")}</h6>
          </li>
        )
      }
    }
  }

  // ** Renders Contact
  const renderContacts = () => {
    if (contacts && contacts.length) {
      if (query.length && !filteredContacts.length) {
        return (
          <li className="no-results show align-items-center">
            <h6 className="mb-0">{t("No Contacts Found")}</h6>
          </li>
        )
      } else {
        const arrToMap =
          query.length && filteredContacts.length ? filteredContacts : contacts
        return arrToMap.map((item) => {
          return (
            <li
              className="align-items-center"
              key={item.id}
              onClick={() => handleUserClick(item.id)}
            >
              <Avatar
                img={item.avatar ?? defaultAvatar}
                imgHeight="42"
                imgWidth="42"
                status={item?.isOnline === true ? "online" : "offline"}
              />
              <div className="chat-info flex-grow-1">
                <div className="mb-0 fw-bolder">{item.name}</div>
                {item?.unit_name && (
                  <div style={{ fontSize: "10px" }} className="text-truncate">
                    {item.unit_name}
                  </div>
                )}
                {item?.last_request_time &&
                  (item?.isOnline === false ? (
                    <div style={{ fontSize: "11px" }} className="text-truncate">
                      {t("Last Online at")}{" "}
                      <Moment locale={i18n.language ?? "id"} fromNow>
                        {item?.last_request_time}
                      </Moment>
                    </div>
                  ) : (
                    <div style={{ fontSize: "11px" }} className="text-truncate">
                      {item?.isOnlineOtherApp ? (
                        <Fragment>
                          <span>{t("Online di App")} </span>
                          <b>{item?.app_name ?? ""}</b>
                        </Fragment>
                      ) : (
                        t("Online")
                      )}
                    </div>
                  ))}
              </div>
            </li>
          )
        })
      }
    } else {
      if (contacts_chats_loading && !contacts_chats_success_last_fetch) {
        return [1, 2, 3, 4, 5].map((item) => {
          return (
            <li key={item} className="align-items-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
              <div className="chat-info mt-0 flex-grow-1">
                <Skeleton
                  animation="wave"
                  height={30}
                  width={140}
                  className="mb-0"
                />
                <Skeleton
                  animation="wave"
                  height={16}
                  width={200}
                  className="card-text text-truncate"
                />
              </div>
              <div className="chat-meta text-nowrap">
                <small className="float-end mb-25 chat-time ms-25">
                  <Skeleton animation="wave" height={16} width={35} />
                </small>
              </div>
            </li>
          )
        })
      } else {
        return null
      }
    }
  }

  // ** Get data on Mount
  // useEffect(() => {
  //   dispatch(getChatContacts())
  // }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem("userData") !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")))
    }
  }, [])

  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return store ? (
    <div
      style={{
        borderTopLeftRadius: "30px",
        // borderBottomLeftRadius: "30px",
        marginBottom: "70px"
      }}
      className={classnames(
        "floating-chat d-none d-lg-block chat-application",
        {
          open: openCustomizer
        }
      )}
    >
      <a
        style={{
          opacity:
            storeNotification.count_unseen_chat > 0 || openCustomizer ? 1 : 0.1
        }}
        href="/"
        className="customizer-toggle d-flex align-items-center justify-content-center"
        onClick={handleToggle}
      >
        {/* <Settings size={14} className="spinner" /> */}
        {storeNotification.count_unseen_chat > 0 && (
          <Badge
            style={{
              top: "27px",
              right: "23px"
            }}
            pill
            color="danger"
            className="badge-up badge-glow"
          >
            {storeNotification.count_unseen_chat}
          </Badge>
        )}
        <MessageRounded size={14} />
      </a>
      <div className="sidebar-left">
        <div className="sidebar">
          <div
            className="sidebar-content"
            style={{
              borderTopLeftRadius: "30px",
              borderBottomLeftRadius: "30px"
            }}
          >
            <div className="chat-fixed-search">
              <div className="d-flex align-items-center w-100">
                <div
                  className="sidebar-profile-toggle"
                  // onClick={handleUserSidebarLeft}
                >
                  {userData ? (
                    <Avatar
                      className="avatar-border"
                      img={userAvatar}
                      status={status}
                      imgHeight="42"
                      imgWidth="42"
                    />
                  ) : null}
                </div>
                <InputGroup className="input-group-merge ms-1 w-100">
                  <InputGroupText className="round">
                    <Search className="text-muted" size={14} />
                  </InputGroupText>
                  <Input
                    value={query}
                    className="round"
                    placeholder={t("Search or start a new chat")}
                    onChange={handleFilter}
                  />
                </InputGroup>
                <a
                  style={{
                    position: "inherit",
                    padding: "0px 0px 0px 15px"
                  }}
                  href="/"
                  className="customizer-close"
                  onClick={handleToggle}
                >
                  <X />
                </a>
              </div>
            </div>
            <PerfectScrollbar
              // className="customizer-content"
              style={{
                height: "100vh"
              }}
              className="customizer-content chat-user-list-wrapper list-group"
              options={{ wheelPropagation: false }}
            >
              <h4 className="chat-list-title">{t("Chats")}</h4>
              <ul className="chat-users-list chat-list media-list">
                {renderChats()}
              </ul>
              <h4 className="chat-list-title">{t("Contacts")}</h4>
              <ul
                style={{ marginBottom: "8rem" }}
                className="chat-users-list contact-list media-list"
              >
                {renderContacts()}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default FloatingChat
