/* eslint-disable no-unused-expressions */
import GlobalActionsTable from "@src/shared/GlobaActionsTable"

import UILoader from "@components/ui-loader"
import {
  ErrorFetchData,
  Loader,
  NoDataDisplay,
  TableSkeletonLoading,
  TableSkeletonLoadingAll
} from "@src/shared/CommonComponents"
import { getDepthValue, neutralizeBack, revivalBack } from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { setDataCache, setLastApiPar } from "@store/dynamicTempState"
import {
  disableBtnCondition,
  renderDropdownItem,
  renderAvatarImgIcon
} from "@utils"
import "cleave.js/dist/addons/cleave-phone.us"
import Cleave from "cleave.js/react"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useRef
} from "react"
import DataTable from "react-data-table-component"
import { ChevronDown, Minus, Plus, Save, X } from "react-feather"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip
} from "reactstrap"
import CustomHeader from "./CustomHeaders"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getBaseUrl,
  getDataById,
  getDataList,
  putData,
  resetData,
  resetSelectedData,
  resetDataListFiltered,
  setDataById,
  setExpandedData,
  setInitialDataAdd,
  setSelectedData,
  setcurrentExpandedData,
  removeDataById,
  setParamsNoServerSide,
  setDataListFiltered
} from "./store"

import DynamicRow from "@src/utility/generateForm/dynamicRow"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import classnames from "classnames"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import DynamicForm from "../../utility/generateForm/dynamicForm"
import { useLastMenuTabs } from "@hooks/useLastMenuTabs"
import InputNumber from "rc-input-number"
import { Menu, Item, useContextMenu, animation } from "react-contexify"
const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const DynamicPage = lazy(() => import("@src/shared/dynamic_pages"))

const List = forwardRef((props, ref) => {
  const {
    pageAttr,
    storeName,
    filtersCustomAdd,
    handleFiltersChangeToParent,
    resetState,
    customHeaderSet,
    selectedDataTable,
    handleSelectedDropdownTable,
    headerMode, ////map-page
    onRowClickedToMap, ////map-page
    rowHighlight, ////map-page
    setRowHighlight, ////map-page
    initialData
  } = props
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const queryParams = new URLSearchParams(location.search)
  const search_qs = queryParams.get("search")

  const { moduleId, pageId, id, relPageId } = useParams()
  const dispatch = useDispatch()
  const { lastMenuTabs, setLastMenuTabs } = useLastMenuTabs()
  const store = useSelector((state) => state.dynamicStore)
  const storeUnit = useSelector((state) => state.units)
  const storeDomain = useSelector((state) => state.domain_claims)

  const dynamicTempState = useSelector((state) => state.dynamic_temp_state)
  // const storeParent = useSelector(
  //   (state) => state?.[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  // )

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])
  const tableRefDiv = useRef(null)
  const tableRef = useRef(null)
  // const [isLoadedTableDom, setIsLoadedTableDom] = useState(false)
  // const handleLoadTableRefDiv = () => {
  //   setIsLoadedTableDom(true)
  // }

  const { colors } = useContext(ThemeColors)

  const [searchTerm, setSearchTerm] = useState(
    searchParams.get("search") ??
      store.pageData[storeName]?.params?.search ??
      ""
  )
  const [currentPage, setCurrentPage] = useState(
    store.pageData[storeName]?.params?.page ??
      store.pageData[storeName]?.params?.[
        pageAttr?.crudOpt?.serverSideGridResp?.["page"] ?? "page"
      ] ??
      getDepthValue(
        store.pageData[storeName]?.params,
        pageAttr?.crudOpt?.serverSideGridResp?.["page"]
      ) ??
      1
  )
  const [sort, setSort] = useState(
    store.pageData[storeName]?.params?.sort ?? ""
  )
  const [sortDir, setSortDir] = useState(
    store.pageData[storeName]?.params?.sort_dir ?? "desc"
  )
  const [rowsPerPage, setRowsPerPage] = useState(
    store.pageData[storeName]?.params?.length ??
      pageAttr?.crudOpt?.maxPageLength ??
      10
    // 50
  )
  const [selectedRows, setSelectedRows] = useState(selectedDataTable ?? [])
  const [selectedRowsSelected, setSelectedRowsSelected] = useState({})

  // const [filtersCustomAdd, setfiltersCustomAdd] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const [inputOnGrid, setInputOnGrid] = useState({})
  const [inputOnGridState, setInputOnGridState] = useState({})
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingPutGrid, setLoadingPutGrid] = useState({})
  useImperativeHandle(ref, () => ({
    handleAddEdit
  }))

  const successToast = () => {
    const msg =
      pageType !== "add" ? t("Successfully Updated") : t("Successfully Created")
    return toast.success(`${msg} ${t(pageAttr?.pageTitle)}`, {
      style: {
        padding: "16px",
        color: colors.success.main,
        border: `1px solid ${colors.success.main}`
      },
      iconTheme: {
        primary: colors.success.main
      }
    })
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    } else {
      errorFieldsMsg =
        res?.payload?.error?.response?.data?.message ?? "Error response"
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const isContainsSearchableInput =
    pageAttr?.crudOpt?.data?.dynamicColumns?.some(
      (item) => item?.searchableInput === true
    )

  const isDisplayContextMenu =
    store.pageAttributes[storeName]?.crudOpt?.dynamicColumnsContextMenu

  const isAutoColumns =
    store.pageAttributes[storeName]?.crudOpt?.dynamicColumnsFieldsAuto ||
    !store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns

  const isContainsDynamicColumnsFilterRequired =
    pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.some((x) => {
      // if (x?.fieldName === "id_unit" && storeUnit?.data?.length === 1) {
      //   return (
      //     x?.rules?.required === true ||
      //     (x?.required === true && storeUnit?.data[0]?.children_count > 0)
      //   )
      // }
      return x?.rules?.required === true || x?.required === true
    })

  const isContainsEditableColumns =
    pageAttr?.crudOpt?.data?.dynamicColumns?.some(
      (item) => item?.editable === true
    )

  const isContainsExtraButtonDataFromGridSelection =
    pageAttr?.crudOpt?.extraButton?.some(
      (is) => is?.renderCustomHtml?.dataFrom === "grid_selection"
    )

  ////FIXED COLUMN
  function setFixCoumnZIndex() {
    let fixedColumnElement = document.querySelector(
      ".react-dataTable-fixed-column"
    )

    let horizontalScrollElement = document.querySelector(".react-dataTable")

    // console.log(fixedColumnElement)

    if (fixedColumnElement && horizontalScrollElement) {
      document.addEventListener("scroll", function () {
        if (
          document.documentElement.scrollTop > 0 ||
          document.body.scrollTop > 0 ||
          horizontalScrollElement.scrollLeft > 0
        ) {
          fixedColumnElement.classList.add("scrolled")
        } else {
          fixedColumnElement.classList.remove("scrolled")
        }
      })
    }
  }

  ///changes filter on selecTable Input
  const handleChangeFilterOnlyThisTable = (params) => {
    if (pageAttr?.isSeletTable) {
      let mixParams = { ...params }
      // if (pageAttr?.crudOpt?.serverSideGrid) {
      let isLoadStateArr = []
      let isLoadState = true

      /////prevent load data if filter include required attr and undefined
      if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
        pageAttr?.crudOpt?.data?.dynamicColumnsFilter
          .filter((x) => x?.rules?.required === true || x?.required === true)
          .map((y) => {
            // eslint-disable-next-line no-unneeded-ternary
            isLoadState = mixParams?.[y.fieldName] ? true : false
            isLoadStateArr.push(mixParams?.[y.fieldName] && true)
          })
      }

      let checkMixParams = JSON.parse(JSON.stringify(mixParams))
      if (isLoadStateArr?.every((value) => value === true)) {
        if (
          JSON.stringify(checkMixParams) !==
            JSON.stringify(store.pageData[storeName]?.params) ||
          (pageAttr?.crudOpt?.getAlwaysGrid &&
            !store.pageData[storeName]?.isLoading)
        ) {
          console.log("handleChangeFilterOnlyThisTable")
          dispatch(getDataList(mixParams))
        }
      } else {
        console.log("resetData")
        if (store.pageData[storeName]?.data) {
          dispatch(resetData(storeName))
        }
      }

      if (pageAttr?.crudOpt?.serverSideGrid) {
        mixParams.page = 1
        mixParams.sort = "id"
        mixParams.sort_dir = "desc"
        mixParams.search = ""
        mixParams.length = pageAttr?.crudOpt?.maxPageLength ?? 10
      }
    } else {
      // console.log(params)
    }
  }

  const reloadOtherPage = (type) => {
    // console.log(type)
    if (type === "edit") {
      if (pageAttr?.crudOpt?.afterEditReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterEditReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            // console.log(store_to_reset)
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }
    } else if (type === "add") {
      if (pageAttr?.crudOpt?.afterAddReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterAddReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            // console.log(store_to_reset)
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }
    }
  }

  const defaultPayload = {
    storeName: storeName,
    endpointName: pageAttr?.crudOpt?.endpointName,
    baseUrl: pageAttr?.crudOpt?.baseUrl,
    callback_page: window.location.href
  }

  const setLoadingPutGridChange = (stateVal, id, fieldName) => {
    let loadingPutGridCopy = { ...loadingPutGrid }
    // let currentLoadingPutGrid = loadingPutGridCopy[fieldName] ?? []
    // if (stateVal) {
    //   currentLoadingPutGrid.push(id)
    // } else {
    //   const index = currentLoadingPutGrid.indexOf(id)
    //   if (index > -1) {
    //     currentLoadingPutGrid.splice(index, 1)
    //   }
    // }
    // setLoadingPutGrid(loadingPutGridCopy)
    if (loadingPutGrid) {
      let currentLoadingPutGrid = loadingPutGrid[fieldName] ?? []
      if (currentLoadingPutGrid.length > 0) {
        if (stateVal) {
          loadingPutGrid[fieldName].push(id)
        } else {
          const index = loadingPutGrid[fieldName].indexOf(id)
          if (index > -1) {
            loadingPutGrid[fieldName].splice(index, 1)
          }
        }
      } else {
        if (stateVal) {
          loadingPutGrid[fieldName] = [id]
        }
      }
    } else {
      if (stateVal) {
        loadingPutGrid[fieldName] = [id]
      }
    }

    // console.log(loadingPutGrid)
  }

  const updateDataToApi = (
    data,
    afterChangeEventType = null,
    afterChangeEventInputId = null,
    afterChangeEventInputProp = null,
    column
  ) => {
    data = { ...data, ...defaultPayload }
    // setLoadingPostPut(!loadingPostPut)
    // console.log(column)
    setLoadingPutGridChange(true, data?.id, column?.fieldName)
    dispatch(putData(data))
      .then((res) => {
        // setLoadingPostPut(!loadingPostPut)
        if (res.meta.requestStatus === "fulfilled") {
          let newData = data
          if (afterChangeEventInputId) {
            newData = { ...data, ...(res?.payload?.data?.data ?? {}) }
          }
          dispatch(
            setDataById({
              rowData: newData,
              storeName: storeName
            })
          ).then(async (re) => {
            setLoadingPutGridChange(false, data?.id, column?.fieldName)

            if (afterChangeEventType) {
              // console.log(
              //   afterChangeEventInputProp.target.closest(".rdt_TableRow")
              // )

              // temukan semua elemen baris pada tabel
              const rows = document?.querySelectorAll(".rdt_TableRow")

              // cari baris setelah baris yang berisi tombol Save yang diklik
              let nextRow = null
              rows?.forEach((row, index) => {
                const currentId = `row-${(afterChangeEventInputId ?? "_")
                  .split("_")
                  ?.pop()}`
                if (row?.id === currentId) {
                  nextRow = rows[index + 1]
                }
              })
              // jika baris berikutnya ditemukan, klik elemen dengan id yang diinginkan
              if (nextRow) {
                const currentIdTrim = afterChangeEventInputId
                  .replace(/[0-9]/g, "")
                  .replace("grid_input_", "cell_edit_")
                // console.log(currentIdTrim)
                const targetElement = nextRow.querySelector(
                  `[id^="${currentIdTrim}"]`
                )
                if (targetElement) {
                  // console.log(targetElement)
                  // console.log(targetElement.value)
                  // targetElement.value = ""
                  targetElement.click()
                  // const intervalId = setInterval(() => {
                  //   const element = document.getElementById(
                  //     "grid_input_emptyWeight_10"
                  //   )
                  //   if (element) {
                  //     clearInterval(intervalId)
                  //     element.focus()
                  //     console.log(element)
                  //     // lakukan sesuatu dengan element
                  //   }
                  // }, 100)
                  // const observer = new MutationObserver((mutationsList) => {
                  //   for (let mutation of mutationsList) {
                  //     if (
                  //       mutation.type === "childList" &&
                  //       mutation.addedNodes.length > 0
                  //     ) {
                  //       console.log(mutation)
                  //       const input =
                  //         mutation.addedNodes[0].querySelector(
                  //           'input[type="text"]'
                  //         )
                  //       if (input) {
                  //         input.focus()
                  //         observer.disconnect()
                  //         break
                  //       }
                  //     }
                  //   }
                  // })

                  // observer.observe(targetElement, { childList: true })
                }
              }
            }
          })
          setLoadingPutGridChange(false, data?.id, column?.fieldName)
          reloadOtherPage("edit")
          successToast()
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
          setLoadingPutGridChange(false, data?.id, column?.fieldName)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
        setLoadingPutGridChange(false, data?.id, column?.fieldName)
      })
  }

  const handleAddEdit = (
    rowData,
    initialDataAdd = null,
    redirectPage = null
  ) => {
    handleCloseContextMenu()
    const pageRedirect =
      redirectPage ?? pageAttr?.crudOpt?.rowClickEvent === "view"
        ? "view"
        : !ability.can("update", sessionStorage.getItem("current_page_perm")) &&
          !ability.can("post", sessionStorage.getItem("current_page_perm"))
        ? "view"
        : "edit"
    // console.log(rowData)
    if (rowData) {
      setPageType(pageRedirect)
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        setLoadingText(
          `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
        )
        setLoadingModal(true)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          setShowModal(true)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName:
                pageAttr?.crudOpt?.endpointName_GET ??
                pageAttr?.crudOpt?.endpointName
            })
          ).then(() => {
            setLoadingModal(false)
          })
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              setLoadingModal(false)
              setShowModal(true)
            }
          )
        }
      } else {
        let navigatePath = `${location.pathname}/${pageRedirect}/${rowData.id}`
        setLastMenuTabs(null, `${navigatePath}`, null)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName:
                pageAttr?.crudOpt?.endpointName_GET ??
                pageAttr?.crudOpt?.endpointName
            })
          )
          // .then(() => {
          //   navigate(navigatePath)
          // })
          navigate(navigatePath)
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName }))
          // .then(
          //   () => {
          //     navigate(navigatePath)
          //   }
          // )
          navigate(navigatePath)
        }
      }
    } else {
      setPageType("add")
      // setPageType("add-child")\
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        return dispatch(resetSelectedData(storeName)).then(() => {
          if (initialDataAdd) {
            dispatch(
              setInitialDataAdd({ data: initialDataAdd, storeName })
            ).then((re) => {
              setLoadingModal(false)
              setLoading(false)
              setShowModal(true)
            })
          } else {
            setLoadingModal(false)
            setLoading(false)
            setShowModal(true)
          }
        })
      } else {
        return dispatch(resetSelectedData(storeName)).then(() => {
          if (initialDataAdd) {
            let str = []
            for (let p in initialDataAdd) {
              if (initialDataAdd.hasOwnProperty(p)) {
                str.push(
                  `${encodeURIComponent(p)}=${encodeURIComponent(
                    initialDataAdd[p]
                  )}`
                )
              }
            }
            const query = str.join("&")
            setLastMenuTabs(null, `${location.pathname}/add?${query}`, null)
            return navigate(`${location.pathname}/add?${query}`)
          } else {
            setLastMenuTabs(null, `${location.pathname}/add`, null)
            return navigate(`${location.pathname}/add`)
          }
        })
      }
    }
  }

  //// FILTER ON TABLE HEADER
  const filtersComponents = (tableHeaderMode = false) => (
    <DynamicForm
      dynamicForm={pageAttr?.crudOpt?.data?.dynamicColumnsFilter.map((obj) => ({
        ...obj,
        isFromFilter: true
      }))}
      storeName={storeName}
      pageAttr={pageAttr}
      handleFiltersChangeToParent={
        pageAttr?.isSeletTable
          ? handleChangeFilterOnlyThisTable
          : handleFiltersChangeToParent
      }
      filterMode={true}
      tableHeaderMode={tableHeaderMode}
      defaultFormValues={{
        ...store.pageData[storeName]?.params
      }}
    />
  )

  ///AUTO MAX WIDTH COLUMNS
  // const useDynamicWidths = (data) => {
  //   const [widths, setWidths] = useState({})

  //   useEffect(() => {
  //     if (data && pageAttr?.crudOpt?.columnsAutoMaxWidth) {
  //       const dynamicColumns = pageAttr?.crudOpt?.data?.dynamicColumns ?? []
  //       const fieldExtraWidth = ["currency", "datetime"]
  //       const dataPerPixel = 8.5
  //       const minColumnPixel = 60
  //       const newWidths = data?.reduce((acc, item) => {
  //         Object.keys(item).forEach((key) => {
  //           const currentColumn = dynamicColumns?.find(
  //             (column) => column.fieldName === key
  //           )
  //           const fieldName = currentColumn?.fieldName ?? key

  //           const fieldLabel = currentColumn?.columnName ?? key ?? "  "
  //           let fieldLabelLength = fieldLabel.length ?? 1

  //           const fieldValue = (item[fieldName] ?? "  ")?.toString()?.trim()
  //           let fieldValueLength = fieldValue?.length ?? 1

  //           if (fieldExtraWidth.includes(currentColumn?.type)) {
  //             fieldLabelLength = fieldLabelLength + 6
  //             fieldValueLength = fieldValueLength + 6
  //           } else if (currentColumn?.lookupValue) {
  //             fieldLabelLength = fieldLabelLength + 4
  //             fieldValueLength = fieldValueLength + 4
  //           }
  //           // console.log(fieldLabelLength, fieldValueLength)
  //           // console.log(
  //           //   fieldLabel,
  //           //   key,
  //           //   item[fieldName],
  //           //   item[key],
  //           //   fieldValueLength
  //           // ) // pixels per character

  //           let lengthFixed =
  //             fieldLabelLength > fieldValueLength
  //               ? fieldLabelLength + 3
  //               : fieldValueLength

  //           let lengthFixedWithPx = lengthFixed * dataPerPixel
  //           if (lengthFixedWithPx < minColumnPixel) {
  //             lengthFixedWithPx = minColumnPixel // minimal column width
  //           }
  //           acc[fieldName] = Math.max(acc[fieldName] || 0, lengthFixedWithPx)
  //         })
  //         return acc
  //       }, {})
  //       // console.log(newWidths)
  //       setWidths(newWidths)
  //     }
  //   }, [data])

  //   return widths
  // }

  ///FILTER EVERY COLUMNS
  const [filterText, setFilterText] = useState("")
  const [filterColumn, setFilterColumn] = useState("name") // You can manage multiple filters if needed

  // const filteredItems = useMemo(() => {
  //   return data.filter(
  //     (item) =>
  //       item[filterColumn] &&
  //       item[filterColumn].toLowerCase().includes(filterText.toLowerCase())
  //   )
  // }, [data, filterText, filterColumn])

  const [searchTerms, setSearchTerms] = useState({})
  const [searchTermsInputFocus, setSearchTermsInputFocus] = useState()
  const handleColumnSearchInput = (newData, fieldName) => {
    let data = { ...newData }
    let searchTermsCopy = { ...searchTerms, ...data }
    setSearchTerms(searchTermsCopy)
    setSearchTermsInputFocus(fieldName)

    // console.log(searchTermsCopy)
    if (searchTermsCopy) {
      let storeData = JSON.parse(
        JSON.stringify(store.pageData[storeName]?.data)
      )
      const filteredItems = storeData.filter((item) =>
        Object.keys(searchTermsCopy)?.every((key) => {
          const itemValue = (item[key] ?? "")?.toString()?.toLowerCase()
          const searchTermValue = (searchTermsCopy[key] ?? "")?.toLowerCase()
          return itemValue?.includes(searchTermValue)
        })
      )
      // console.log(filteredItems, storeData?.[0])
      // setFilteredData(filteredItems?.length > 0 ? filteredItems : storeData)
      setFilteredData(filteredItems)
    }
  }

  const FilterComponent = ({ column }) => {
    // console.log(column)
    // return (
    //   <input
    //     className="w-100 mb-25"
    //     type="text"
    //     placeholder={placeholder}
    //     aria-label="Search Input"
    //     value={filterText}
    //     onChange={onFilter}
    //     // style={{ margin: "10px", padding: "8px" }}
    //   />
    // )
    const genDynamicForm = (item, fieldName2, searchTermsInputFocus2) => {
      let result = {
        fieldName: fieldName2,
        columnName: t(`${item.columnName ?? getColumnTitle(item.fieldName)}`),
        autoFocus: searchTermsInputFocus2 === fieldName
      }

      if (item?.lookupValue) {
        result["type"] = "dropdown"
        result["dataFrom"] = { value: item?.lookupValue }
      }
      return result
    }

    const fieldName = column?.fieldName
    return column?.searchableInput === true ? (
      <DynamicForm
        onClick={(e) => e.stopPropagation()}
        key={`searchinput_table_head_${storeName}_${fieldName}`}
        // pageAttr={pageAttr}
        dynamicForm={[genDynamicForm(column, fieldName, searchTermsInputFocus)]}
        storeName={storeName}
        // handleReset={handleReset}
        // handleFormSubmit={handleFormSubmit}
        defaultFormValues={searchTerms}
        filterMode={true}
        pageType={"edit"}
        cardClassNames="m-0 p-0 bg-transparent only-input-dynamic-form"
        cardBodyClassNames="m-0 p-0"
        onlyInputMode={true}
        handleFormChangeToParent={(data) =>
          handleColumnSearchInput(data, fieldName)
        }
      />
    ) : (
      <div
        style={{
          height: "35px"
        }}
      ></div>
    )
  }

  /////EDITABLE ROW
  if (isContainsEditableColumns) {
    const childElements = document?.querySelectorAll(".input-editable-row")
    childElements?.forEach(function (element) {
      element.addEventListener("click", function (event) {
        event.stopPropagation()
        // console.log("Button clicked")
      })
    })
  }

  const handleEditOnRow = (row, column) => {
    // handleCloseContextMenu()
    // console.log(inputOnGrid)
    // console.log(inputOnGridState)
    // console.log(
    //   !(inputOnGridState[column?.fieldName] ?? []).includes(row?.id),
    //   !Array.isArray(inputOnGridState[column?.fieldName])
    // )
    // console.log("here", inputOnGrid)
    if (!inputOnGrid[column?.fieldName]) {
      inputOnGrid[column?.fieldName] = [
        { id: row?.id, value: row[column.fieldName] }
      ]
    } else {
      const inputOnGridCopy = { ...inputOnGrid }
      const prevColumnData = inputOnGrid[column.fieldName] || []
      const existingColumnData = prevColumnData.find(
        (obj) => obj.id === row?.id
      )
      if (existingColumnData) {
        if (existingColumnData.value !== row[column.fieldName]) {
          const updatedColumnData = {
            ...existingColumnData,
            value: row[column.fieldName]
          }
          const updatedColumnIndex = prevColumnData.findIndex(
            (obj) => obj.id === row?.id
          )
          inputOnGrid[column.fieldName][updatedColumnIndex] = updatedColumnData
        }
      } else {
        inputOnGrid[column.fieldName] = [
          ...(prevColumnData ?? []),
          { id: row?.id, value: row[column.fieldName] }
        ]
      }
      // setInputOnGrid(inputOnGridCopy)
    }

    if (!inputOnGridState) {
      setInputOnGridState({ [column?.fieldName]: [row?.id] })
    } else {
      if (!(inputOnGridState[column?.fieldName] ?? []).includes(row?.id)) {
        // setInputOnGridState((prevState) => {
        //   const newInputOnGridState = { ...prevState }
        //   const fieldValue = newInputOnGridState[column?.fieldName] ?? []

        //   if (!Array.isArray(fieldValue)) {
        //     newInputOnGridState[column?.fieldName] = [row?.id]
        //   } else {
        //     newInputOnGridState[column?.fieldName] = [...fieldValue, row?.id]
        //   }

        //   return newInputOnGridState
        // })

        if (!Array.isArray(inputOnGridState[column?.fieldName])) {
          inputOnGridState[column?.fieldName] = [row?.id]
        } else {
          inputOnGridState[column?.fieldName].push(row?.id)
        }
      }
    }
    // console.log(row)
    dispatch(setSelectedData({ rowData: row, storeName: storeName }))
    // console.log(inputOnGrid)
    // console.log(inputOnGridState)
  }

  const handleSaveOnRow = (
    e,
    row,
    column,
    afterChangeEventType = null,
    afterChangeEventInputId = null
  ) => {
    const selectedVal = inputOnGrid[column?.fieldName]?.find(
      (c) => c.id === row?.id
    )?.value
    let index = inputOnGridState[column?.fieldName]?.findIndex(
      (c) => c.id === row?.id
    )
    if (row[column.fieldName] !== selectedVal) {
      if (index !== undefined && inputOnGridState[column?.fieldName]) {
        inputOnGridState[column.fieldName].splice(index, 1)
        setInputOnGridState((prevData) => ({
          ...prevData,
          [column?.fieldName]: (prevData[column?.fieldName] ?? []).filter(
            (id) => id !== row?.id
          )
        }))
      }

      let newDataTemp = { ...row, [column.fieldName]: selectedVal }

      // check if extraPayload is exists
      const extraPayload = column?.editableInputOpt?.payloadExtra
      if (extraPayload) {
        extraPayload.map((extra) => {
          newDataTemp[extra?.alias] = tempAsyncOptValues?.[
            column?.fieldName
          ]?.find((c) => c?.value === selectedVal)?.[extra?.fieldName]
        })
      }

      // console.log(newDataTemp)

      // .then((result) => console.log(result))

      updateDataToApi(
        newDataTemp,
        afterChangeEventType,
        afterChangeEventInputId,
        e,
        column
      )
    }
  }

  const handleCancelSaveOnRow = (e, row, column) => {
    let index = inputOnGridState[column?.fieldName]?.findIndex(
      (c) => c.id === row?.id
    )
    if (index !== undefined && inputOnGridState[column?.fieldName]) {
      inputOnGridState[column.fieldName].splice(index, 1)
      setInputOnGridState((prevData) => ({
        ...prevData,
        [column?.fieldName]: (prevData[column?.fieldName] ?? []).filter(
          (id) => id !== row?.id
        )
      }))
    }

    // console.log(inputOnGridState, inputOnGrid, selectedVal)
  }

  const changeInputOnGrid = (id, fieldName, newValue) => {
    let index = inputOnGrid[fieldName]?.findIndex((c) => c.id === id)

    if (index !== -1) {
      inputOnGrid[fieldName][index]["value"] = newValue
      setInputOnGrid((prevState) => {
        const newState = { ...prevState }
        // const index = newState[fieldName].findIndex((c) => c.id === id)
        try {
          newState[fieldName][index]["value"] = newValue
        } catch (error) {
          console.error(error)
        }
        return newState
      })
    } else {
      // if id does not exist in inputOnGrid[fieldName], push newValue
      inputOnGrid[fieldName] = [{ id: id, value: newValue }]
      setInputOnGrid((prevState) => {
        const newState = { ...prevState }
        newState[fieldName] = [
          ...(newState[fieldName] || []),
          { id: id, value: newValue }
        ]
        return newState
      })
    }
    // console.log({ id: id, value: newValue }, inputOnGrid)
  }

  const renderInputOnGrid = (row, column, inputOnGridChild) => {
    const fieldName = column?.fieldName
    const fieldLabel = column?.columnName
    const fieldNameEl = column?.fieldName?.replace(/\./g, "_")
    const id = row?.id
    const value = row[fieldName]
    const item = column?.editableInputOpt

    let placeholder = `Enter ${column?.columnName ?? column?.fieldName}`
    // console.log(inputOnGrid)
    const currentStateVal = (inputOnGrid[fieldName] ?? []).find(
      (c) => c.id === id
    )?.value
    const id_element = `grid_input_${fieldNameEl}_${id}`
    const id_element_save = `grid_input_btn_save_${fieldNameEl}_${id}`
    const id_element_cancel = `grid_input_btn_cancel_${fieldNameEl}_${id}`
    // console.log(value, inputOnGridChild, inputOnGrid, currentStateVal)

    // const index = (loadingPutGrid?.[column?.fieldName] ?? []).indexOf(row?.id)
    let btnSave =
      value !== currentStateVal ? (
        <Fragment>
          <Button.Ripple
            style={{
              width:
                column?.editableInputOpt?.type === "number-btn"
                  ? "10% !important"
                  : "auto"
            }}
            id={id_element_save}
            className="btn-icon"
            outline
            color="secondary"
            onClick={(event) => {
              event.stopPropagation()
              handleSaveOnRow(event, row, column)
            }}
          >
            <Save size={16} />
          </Button.Ripple>
          <UncontrolledTooltip placement="bottom" target={id_element_save}>
            {t("Save Changes")}
          </UncontrolledTooltip>
        </Fragment>
      ) : (
        <Fragment>
          <Button.Ripple
            style={{
              width:
                column?.editableInputOpt?.type === "number-btn"
                  ? "10% !important"
                  : "auto"
            }}
            id={id_element_save}
            className="btn-icon"
            outline
            color="secondary"
            onClick={(event) => {
              event.stopPropagation()
              handleCancelSaveOnRow(event, row, column)
            }}
          >
            <X size={16} />
          </Button.Ripple>
          <UncontrolledTooltip placement="bottom" target={id_element_save}>
            {t("Cancel Edit")}
          </UncontrolledTooltip>
        </Fragment>
      )
    const defaultValuex = !column?.editableInputOpt
      ?.afterEditNextRowSameInputClearValue
      ? row[column?.fieldName]
      : ""
    // console.log(defaultValuex)
    const handleKeyPress = (event, row, column, id_element) => {
      if (event.key === "Enter") {
        // console.log("Enter key pressed")
        const afterChangeEventType = "nextRow"
        const afterChangeEventInputId = id_element
        handleSaveOnRow(
          event,
          row,
          column,
          afterChangeEventType,
          afterChangeEventInputId
        )
      }
    }
    // console.log(loadingPutGrid, column, row)

    // if (index === -1) {
    if (column?.editableInputOpt?.type === "number") {
      return (
        <InputGroup>
          <Input
            type="number"
            // value={defaultValuex}
            defaultValue={defaultValuex}
            // defaultValue={inputOnGrid[column?.fieldName][row?.id]?.value}
            // value={inputOnGrid[column?.fieldName][row?.id]?.value}
            onClick={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              event.stopPropagation()
              // console.log(event.target.value)
              const newValue =
                column?.editableInputOpt?.valueType === "int"
                  ? parseInt(event.target.value ?? 0)
                  : event.target.value ?? 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
              // let index = inputOnGrid[column?.fieldName]?.findIndex(
              //   (c) => c.id === row?.id
              // )

              // if (
              //   index !== undefined &&
              //   inputOnGrid[column?.fieldName] &&
              //   inputOnGrid[column.fieldName][index]
              // ) {
              //   inputOnGrid[column.fieldName][index]["value"] = newValue
              //   setInputOnGrid((prevState) => {
              //     const newState = { ...prevState }
              //     const index = newState[column.fieldName].findIndex(
              //       (c) => c.id === row.id
              //     )
              //     if (index !== undefined && newState[column.fieldName]) {
              //       newState[column.fieldName][index]["value"] = newValue
              //     }
              //     return newState
              //   })
              // } else {
              //   // if id does not exist in inputOnGrid[column?.fieldName], push newValue
              //   inputOnGrid[column.fieldName] = [
              //     { id: row?.id, value: newValue }
              //   ]
              //   setInputOnGrid((prevState) => {
              //     const newState = { ...prevState }
              //     newState[column.fieldName] = [
              //       ...(newState[column.fieldName] || []),
              //       { id: row?.id, value: newValue }
              //     ]
              //     return newState
              //   })
              // }
              // console.log(index, inputOnGrid)
            }}
            id={id_element}
            placeholder={placeholder}
          />
          {btnSave}
        </InputGroup>
      )
    } else if (column?.editableInputOpt?.type === "number-btn") {
      let rules = { ...column?.editableInputOpt?.rules }
      let defaultRules = {}
      if (rules?.maxLength && !rules?.maxLength?.message) {
        let max = rules?.maxLength?.value ?? rules?.maxLength
        defaultRules = {
          ...defaultRules,
          maxLength: {
            value: max,
            message: `Max. length is ${max}`
          }
        }
      }

      if (rules?.minLength && !rules?.minLength?.message) {
        let min = rules?.minLength?.value ?? rules?.minLength ?? 0
        defaultRules = {
          ...defaultRules,
          minLength: {
            value: min,
            message: `Min. length is ${min}`
          }
        }
      }
      let mixRules = { ...rules, ...defaultRules }

      return (
        <InputGroup
          style={{
            boxShadow: "none"
          }}
        >
          <InputNumber
            className="input-lg input-editable-row-table"
            style={{
              width: "97% !important"
            }}
            min={mixRules?.minLength?.value ?? 0}
            max={mixRules?.maxLength?.value}
            defaultValue={defaultValuex}
            onChange={(e) => {
              const val = e
              const newValue =
                column?.editableInputOpt?.valueType === "int"
                  ? parseInt(val ?? 0)
                  : val ?? 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            id={id_element}
            placeholder={placeholder}
            upHandler={<Plus />}
            downHandler={<Minus />}
          />
          {btnSave}
        </InputGroup>
      )
    } else if (
      column?.editableInputOpt?.type === "boolean" ||
      column?.type === "boolean"
    ) {
      return (
        <InputGroup
          style={{
            justifyContent: "flex-end"
          }}
        >
          <InputGroupText>
            <Input
              type="checkbox"
              defaultChecked={defaultValuex === true}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                // console.log(event, event.target.checked)
                const newValue = event.target.checked
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              id={id_element}
            />
          </InputGroupText>
          {btnSave}
        </InputGroup>
      )
    } else if (column?.editableInputOpt?.type === "currency") {
      return (
        <InputGroup>
          {/* <InputGroupText>
            {column?.editableInputOpt?.icon ? (
              renderIcon(column?.editableInputOpt?.icon, "", 14)
            ) : localStorage.getItem("i18nextLng") === "id" ? (
              <FontAwesomeIcon className="align-middle" icon="rupiah-sign" />
            ) : (
              <Paid size={14} />
            )}
          </InputGroupText> */}
          <Cleave
            className="form-control"
            value={defaultValuex}
            placeholder={
              column?.editableInputOpt?.placeholder
                ? t(column?.editableInputOpt?.placeholder)
                : `10.000`
            }
            options={{
              numeral: true
              // numeralDecimalMark: ",",
              // numeralDecimalScale: 2,
              // delimiter: ".",
              // numeralPositiveOnly: true
            }}
            id={id_element}
            onClick={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              event.stopPropagation()
              const newValue =
                event.target.rawValue !== ""
                  ? parseFloat(event.target.rawValue)
                  : 0
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
          />
          {btnSave}
        </InputGroup>
      )
    } else {
      if (item?.dataFrom?.api && item?.type === "dropdown") {
        return (
          <InputGroup>
            <Select
              id={id_element}
              // isClearable={!item?.rules?.required}
              className={classnames("react-select react-select-on-grid")}
              styles={{
                width: "89%"
              }}
              autoFocus
              placeholder={
                item.placeholder
                  ? t(item.placeholder)
                  : `${t("Select")} ${t(fieldLabel)}`
              }
              noOptionsMessage={() => t("No Options Found")}
              loadingMessage={() => t("Loading...")}
              classNamePrefix="select"
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                // event.stopPropagation()
                const newValue = event?.value
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              onFocus={(e) => {
                e.preventDefault()
                e.stopPropagation()
                return loadDropdownFromApi(fieldName, e, item)
              }}
              isLoading={tempAsyncOptLoading[fieldName] ?? false}
              options={tempAsyncOptValues[fieldName]}
              defaultValue={defaultValuex}
              menuPortalTarget={document.body}
              menuPlacement="auto"
            />
            {btnSave}
          </InputGroup>
        )
      } else if (item?.dataFrom?.value && item?.type === "dropdown") {
        let componentsInput = renderAvatarImgIcon(item)
        return (
          <InputGroup>
            <Select
              id={id_element}
              // isClearable={!item?.rules?.required}
              className={classnames("react-select react-select-on-grid")}
              styles={{
                width: "89%"
              }}
              autoFocus
              placeholder={
                item.placeholder
                  ? t(item.placeholder)
                  : `${t("Select")} ${t(fieldLabel)}`
              }
              noOptionsMessage={() => t("No Options Found")}
              loadingMessage={() => t("Loading...")}
              classNamePrefix="select"
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                // event.stopPropagation()
                const newValue = event?.value
                // console.log(newValue)
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              isLoading={false}
              options={item?.dataFrom?.value ?? []}
              defaultValue={item?.dataFrom?.value?.find(
                (item) => item.value === (item.selected ?? defaultValuex)
              )}
              // value={item?.dataFrom?.value.find(
              //   (c) => c.value === defaultValuex
              // )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              {...componentsInput}
            />
            {btnSave}
          </InputGroup>
        )
      } else {
        return (
          <InputGroup>
            <Input
              type="text"
              autoFocus
              defaultValue={defaultValuex}
              id={id_element}
              placeholder={placeholder}
              onInput={(event) => {
                event.stopPropagation()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={(event) => {
                event.stopPropagation()
                const newValue = event.target.value
                changeInputOnGrid(row?.id, column?.fieldName, newValue)
              }}
              onKeyPress={(event) => {
                event.stopPropagation()
                handleKeyPress(event, row, column, id_element)
              }}
            />
            {btnSave}
          </InputGroup>
        )
      }
    }
    // } else {
    //   return (
    //     <TableSkeletonLoading
    //       key={`loading_editable_put_${row?.id}`}
    //       column={column}
    //     />
    //   )
    // }
  }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      setLoadingDelete(true)
      if (pageAttr.crudOpt?.importButton && !pageAttr.crudOpt?.endpointName) {
        dispatch(
          removeDataById({
            id: id,
            storeName: storeName
          })
        ).then((res) => {
          afterDelete(res)
        })
      } else {
        dispatch(
          deleteData({
            id: id,
            storeName: storeName,
            endpointName: pageAttr?.crudOpt?.endpointName
          })
        ).then((res) => {
          afterDelete(res)
        })
      }
    }
  }

  const afterDelete = (res) => {
    if (res.meta.requestStatus === "fulfilled") {
      if (pageAttr?.crudOpt?.afterAddReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterAddReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }

      // if (pageAttr?.crudOpt?.afterAddReloadOtherDetailPage) {
      //   pageAttr?.crudOpt?.afterAddReloadOtherDetailPage?.map((url) => {
      //     const store_to_reset = url.replaceAll("/", "_")
      //     if (store.pageData[store_to_reset]?.selectedData) {
      //       const current_id = store.pageData[store_to_reset].selectedData?.id
      //       dispatch(
      //         getDataById({
      //           id: current_id,
      //           storeName: store_to_reset,
      //           endpointName:
      //             store.pageAttributes[store_to_reset]?.crudOpt?.endpointName
      //         })
      //       )
      //     }
      //   })
      // }

      if (pageAttr?.crudOpt?.afterDeleteReloadOtherDetailPage) {
        pageAttr?.crudOpt?.afterDeleteReloadOtherDetailPage?.map((url) => {
          const store_to_reset = url.replaceAll("/", "_")
          const data_idField =
            store.pageAttributes[store_to_reset]?.crudOpt?.data_idField
          if (store.pageData[store_to_reset]?.selectedData) {
            const current_id = data_idField
              ? store.pageData[store_to_reset].selectedData?.[data_idField]
              : store.pageData[store_to_reset].selectedData?.id
            dispatch(
              getDataById({
                id: current_id,
                storeName: store_to_reset,
                endpointName:
                  store.pageAttributes[store_to_reset]?.crudOpt
                    ?.endpointName_GET ??
                  store.pageAttributes[store_to_reset]?.crudOpt?.endpointName
              })
            )
          }
        })
      }

      setLoading(false)
      setLoadingDelete(false)
      toast.success(`${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`, {
        style: {
          padding: "16px",
          color: colors.success.main,
          border: `1px solid ${colors.success.main}`
        },
        iconTheme: {
          primary: colors.success.main
        }
      })
    } else if (res.meta.requestStatus === "rejected") {
      setLoading(false)
      setLoadingDelete(false)
      toast.error(res?.payload?.message ?? t("Error"), {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      })
    } else {
      setLoading(false)
      setLoadingDelete(false)
    }
  }

  const handleDeleteSelected = async () => {
    const countSelectedCopy = selectedRows.length
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You will delete")} ${countSelectedCopy} ${t(
        "selected data. Deleted data cannot be recovered"
      )}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${countSelectedCopy} ${t(
        pageAttr.pageTitle
      )}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(
        `${t("Deleting")} ${countSelectedCopy} Data ${t(pageAttr.pageTitle)}`
      )
      setLoading(true)
      const values = selectedRows.map((a) => a.id)

      dispatch(
        deleteSelectedRowData({
          id: values,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setSelectedRows([])
          setToggleCleared(!toggleCleared)
          setLoading(false)
          toast.success(
            `${t("Successfully Deleted")} ${countSelectedCopy} ${t(
              pageAttr.pageTitle
            )}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res?.payload?.message ?? t("Error Deleting Data"), {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const handleDebounceFn = (inputValue, lastParamsOnStore) => {
    setLoading(true)
    // const storex = useSelector((state) => state.dynamicStore)
    // console.log(lastParamsOnStore)
    handleFiltersChangeToParent({
      ...lastParamsOnStore,
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir: sortDir,
      search: inputValue,
      sort,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustomAdd
    })
    setLoading(false)
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])

  const handleFilter = (event, originalEvent) => {
    if (originalEvent) {
      originalEvent?.preventDefault()
    }
    const seacrhValueText = event.toLowerCase()
    let storeData =
      (event ?? ".") === (searchTerm ?? ".").slice(0, -1)
        ? JSON.parse(JSON.stringify(store.pageData[storeName]?.data))
        : JSON.parse(
            JSON.stringify(
              store.pageData[storeName]?.filteredData ??
                store.pageData[storeName]?.data
            )
          )

    setSearchTerm(event)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      debounceFn(event, store?.pageData[storeName]?.params)
    } else {
      // console.log(storeData)
      const searchField = pageAttr?.crudOpt?.data?.dynamicColumnsSearchFields
      let filterData = []

      // console.log(event, event.length)
      if (event.length) {
        filterData = storeData.filter((item) => {
          const excludeKey = ["id", "Id_", "id_", "ID_"]
          // console.log(seacrhValueText)
          for (let key in item) {
            if (searchField && searchField.indexOf(key) > -1) {
              if (`${item[key]}`.toLowerCase().includes(seacrhValueText)) {
                return true
              }
            } else if (pageAttr?.crudOpt?.deepObjectSearch) {
              if (typeof item[key] === "object" && item[key] !== null) {
                if (Array.isArray(item[key])) {
                  for (let itemChild of item[key]) {
                    for (let keyChild in itemChild) {
                      if (
                        `${itemChild[keyChild]}`
                          .toLowerCase()
                          .includes(seacrhValueText) &&
                        !excludeKey.some((prefix) =>
                          keyChild.startsWith(prefix)
                        )
                      ) {
                        return true
                      }
                    }
                  }
                } else {
                  for (let keyChild in item[key]) {
                    if (
                      `${item[key][keyChild]}`
                        .toLowerCase()
                        .includes(seacrhValueText) &&
                      !excludeKey.some((prefix) => keyChild.startsWith(prefix))
                    ) {
                      return true
                    }
                  }
                }
              } else if (
                `${item[key]}`.toLowerCase()?.includes(seacrhValueText) &&
                !excludeKey.some((prefix) => key.startsWith(prefix))
              ) {
                return true
              }
            } else {
              if (
                `${item[key]}`.toLowerCase()?.includes(seacrhValueText) &&
                !excludeKey.some((prefix) => key.startsWith(prefix))
              ) {
                return true
              }
            }
          }
          return false
        })

        setFilteredData(filterData)
        dispatch(
          setDataListFiltered({
            data: [...filterData],
            storeName: storeName
          })
        )
      } else {
        setFilteredData([])
        dispatch(
          setDataListFiltered({
            data: [...store.pageData[storeName]?.data],
            storeName: storeName
          })
        ).then((_) => {
          handleFiltersChangeToParent({
            ...(store.pageData[storeName]?.params ?? {}),
            search: event
          })
        })
      }
    }

    dispatch(
      setParamsNoServerSide({
        ...(store.pageData[storeName]?.params ?? {}),
        storeName,
        sort_dir: sortDir,
        sort,
        search: event,
        length: rowsPerPage,
        page: 1,
        ...filtersCustomAdd
      })
    )
    // }
  }

  const handlePagination = (page) => {
    // console.log(page)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent({
        ...(store.pageData[storeName]?.params ?? {}),
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir: sortDir,
        sort,
        search: searchTerm,
        length: rowsPerPage,
        page: page.selected + 1,
        ...filtersCustomAdd
      })
      setCurrentPage(page.selected + 1)
    } else {
      dispatch(
        setParamsNoServerSide({
          ...(store.pageData[storeName]?.params ?? {}),
          storeName,
          sort_dir: sortDir,
          sort,
          search: searchTerm,
          length: rowsPerPage,
          page: page,
          ...filtersCustomAdd
        })
      )
      setCurrentPage(page)
    }
  }

  const handlePerPage = (e) => {
    if (pageAttr?.crudOpt?.serverSideGrid) {
      const value = parseInt(e.currentTarget.value)
      setCurrentPage(1)
      handleFiltersChangeToParent({
        ...(store.pageData[storeName]?.params ?? {}),
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir: sortDir,
        sort,
        search: searchTerm,
        length: value,
        page: 1,
        ...filtersCustomAdd
      })
      // console.log(value)
      setRowsPerPage(value)
    } else {
      dispatch(
        setParamsNoServerSide({
          ...(store.pageData[storeName]?.params ?? {}),
          storeName,
          sort_dir: sortDir,
          sort,
          search: searchTerm,
          length: e,
          page: currentPage,
          ...filtersCustomAdd
        })
      )
      setRowsPerPage(e)
    }
  }

  // const handlePerPage2 = (currentRowsPerPage, currentPage) => {
  //   if (pageAttr?.crudOpt?.serverSideGrid) {
  //     setCurrentPage(1)
  //     handleFiltersChangeToParent({
  //       storeName,
  //       endpointName: pageAttr?.crudOpt?.endpointName,
  //       sort_dir: sortDir,
  //       sort,
  //       search: searchTerm,
  //       length: currentRowsPerPage,
  //       page: 1,
  //       ...filtersCustomAdd
  //     })
  //   }
  //   setRowsPerPage(currentRowsPerPage)
  // }

  // if (resetState) {
  // }
  const handleFiltersChange = (value, par_name) => {
    filtersCustomAdd[par_name] = value
    handleFiltersChangeToParent({
      ...(store.pageData[storeName]?.params ?? {}),
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir: sortDir,
      sort,
      search: searchTerm,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustomAdd
    })
  }

  const handleSort = (column, sortDirection) => {
    const lastParams = {
      ...(store.pageData[storeName]?.params ?? {}),
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      sort_dir: sortDirection,
      sort: column.sortField,
      search: searchTerm,
      page: currentPage,
      length: rowsPerPage,
      ...filtersCustomAdd
    }
    if (pageAttr?.crudOpt?.serverSideGrid) {
      handleFiltersChangeToParent(lastParams)
    } else {
      dispatch(setParamsNoServerSide(lastParams))
    }
    setSortDir(sortDirection)
    setSort(column.sortField)
  }

  // const handleRowSelected = useCallback((state) => {
  //   if (state) {
  //     if (state.selectedRows) {
  //       setSelectedRows(state.selectedRows)
  //     } else {
  //       const addOrRemoveData = state
  //       // push or splice addOrRemoveData
  //       setSelectedRows(addOrRemoveData)
  //     }
  //   }

  //   if (pageAttr?.crudOpt?.addSelection) {
  //     handleFiltersChangeToParent({
  //       storeName,
  //       endpointName: pageAttr?.crudOpt?.endpointName,
  //       selectedRows: state.selectedRows
  //     })
  //   }
  // }, [])

  const handleRowSelected = useCallback(
    (state) => {
      if (state) {
        if (state.selectedRows) {
          setSelectedRows(state.selectedRows)
        } else {
          const addOrRemoveData = state
          const id = addOrRemoveData.id
          const index = selectedRows?.findIndex((row) => row.id === id)
          if (index !== -1) {
            setSelectedRows((prevSelectedRows) => {
              const selectedRowsCopy = [...prevSelectedRows]
              selectedRowsCopy.splice(index, 1)
              return selectedRowsCopy
            })
          } else {
            setSelectedRows((prevSelectedRows) => [
              ...prevSelectedRows,
              addOrRemoveData
            ])
          }
        }
      }

      if (pageAttr?.crudOpt?.addSelection) {
        handleFiltersChangeToParent({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          selectedRows: state.selectedRows
        })
      }
    },
    [selectedRows]
  )

  const handleRowSingleSelected = useCallback(
    (state, selectByRow) => {
      if (selectByRow) {
        setSelectedRows(state)
      } else {
        setSelectedRows(state.selectedRows?.selectedRows)
      }

      if (
        (pageAttr?.crudOpt?.addSelection || pageAttr?.isDropdownTable) &&
        ((state.selectedRows && state.selectedCount > 0) ||
          (state && selectByRow))
      ) {
        // console.log(pageAttr)
        if (pageAttr?.isDropdownTable && pageAttr?.crudOpt?.serverSideGrid) {
          if (state && selectByRow) {
            handleSelectedDropdownTable({
              selectedRows: [state]
            })
          } else {
            handleSelectedDropdownTable({
              selectedRows: state.selectedRows
            })
          }
        } else {
          handleFiltersChangeToParent({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            selectedRows: state.selectedRows
          })
        }
      }
    },
    [selectedRows]
  )

  const handleRowClickMapType = (state) => {
    // console.log(state)
    onRowClickedToMap(state)
  }

  const CustomPagination = (props) => {
    // console.log(props)
    if (!pageAttr.crudOpt?.hideTablePagination) {
      const count = Number(
        Math.ceil(
          pageAttr?.crudOpt?.serverSideGrid
            ? store.pageData[storeName]?.total / rowsPerPage
            : store.pageData[storeName]?.data?.length / rowsPerPage
        )
      )
      const countFitered = Number(Math.ceil(filteredData.length / rowsPerPage))
      return (
        <div className="align-items-center d-flex justify-content-between mt-1 mb-1">
          <div className="ps-1 form-label">
            {currentPage === 1 ? currentPage : rowsPerPage + currentPage} -{" "}
            {currentPage * rowsPerPage} {t("of")}{" "}
            {pageAttr?.crudOpt?.serverSideGrid
              ? store.pageData[storeName]?.total
              : store.pageData[storeName]?.data?.length}{" "}
            {t("Entries")}
          </div>
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            // pageCount={searchTerm.length ? countFitered : count || 1}
            pageCount={count || 1}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={(page) => {
              handlePagination(page)
            }}
            breakLabel="..."
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            activeClassName="active"
            pageClassName="page-item"
            breakClassName="page-item"
            nextLinkClassName="page-link"
            pageLinkClassName="page-link"
            breakLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextClassName="page-item next-item"
            previousClassName="page-item prev-item"
            containerClassName={`${
              pageAttr?.isViewOnForm
                ? "justify-content-center pe-0"
                : "justify-content-end pe-1"
            } pagination react-paginate separated-pagination pagination-sm mb-0`}
          />
        </div>
      )
    } else {
      return null
    }
  }

  const getColumnTitle = (fieldName) => {
    try {
      const columnTitle = fieldName.replace(/([A-Z])/g, " $1")

      let columnTitleEnd = columnTitle
      if (columnTitle?.includes(".")) {
        columnTitleEnd = columnTitle?.split(".")?.pop()
      }

      columnTitleEnd =
        columnTitleEnd?.charAt(0)?.toUpperCase() + columnTitleEnd?.slice(1)

      columnTitleEnd = columnTitleEnd?.replaceAll("_", " ")
      return columnTitleEnd
    } catch (error) {
      console.error(error)
      return fieldName ?? ""
    }
  }

  function clickEditableOnDisabled() {
    // handleCloseContextMenu()
    console.log("disabledEdit")
  }

  const checkCondition = (data, itemCurrent) => {
    let result = false
    let resultArr = []
    let resultCount = 0
    let operator = false
    if (data) {
      data.map((it) => {
        let fieldName = it?.fieldName
        let fieldNamePath = null
        let fieldValue = null

        if (fieldName?.includes("__userData")) {
          if (fieldName === "__userData.name__") {
            fieldValue = userData?.name
          } else if (fieldName === "__userData.isManager__") {
            fieldValue = userData?.isManager
          } else if (fieldName === "__userData.email__") {
            fieldValue = userData?.email
          } else if (fieldName === "__userData.id__") {
            fieldValue = userData?.id
          } else if (fieldName === "__userData.username__") {
            fieldValue = userData?.username
          }
        } else if (fieldName?.includes("__domainData")) {
          fieldValue = getDepthValue(
            storeDomain?.data,
            fieldName?.replace("__domainData__.", ""),
            null
          )
        } else {
          if (fieldName.includes(".") && !fieldName?.includes("__userData")) {
            let fieldNameObj = fieldName.split(".")
            if (fieldNameObj) {
              fieldName = fieldNameObj[0]
              fieldNamePath = fieldNameObj.slice(1).join(".")
              // console.log(fieldNamePath)
            }
          }

          // console.log(fieldName, fieldNamePath)
          let lastFilterData = dynamicTempState?.data?.[fieldName]
          if (lastFilterData) {
            let lastFilterValue =
              store.pageData[storeName]?.dynamicColumnsFilterData?.[fieldName]
            // console.log(lastFilterValue)
            if (lastFilterValue) {
              let lastFilterDataSelected = lastFilterData?.find(
                (i) => i?.id === lastFilterValue
              )
              fieldValue = getDepthValue(
                lastFilterDataSelected,
                fieldNamePath,
                null
              )
            }
          }

          // if (it?.fieldNameFromOriginalData) {
          //   fieldValue = getDepthValue(defaultFormValues, fieldName, null)
          //   if (Object.keys(fieldValue ?? {})?.length > 0) {
          //     fieldValue = getDepthValue(defaultFormValues, it?.fieldName, null)
          //   }
          // } else if (it?.fieldNameFromDropdownData) {
          //   const obj =
          //     tempAsyncOptValues[fieldName]?.find(
          //       (item) => item?.value === getValues(fieldName)
          //     ) ?? {}

          //   fieldValue = getDepthValue(obj, fieldNamePath)
          // } else {
          //   fieldValue = getValues(fieldName)
          // }
        }

        if (it?.operator && (it?.value || it?.value === 0)) {
          operator = true

          if (it?.valueType === "datetime" || it?.valueType === "date") {
            const newfieldValue = new Date(fieldValue)

            let newValue = null
            if (it?.value === "today") {
              newValue = new Date()
            } else {
              newValue = new Date(it?.value)
            }

            if (it?.operator === ">") {
              result = newfieldValue > newValue
            } else if (it?.operator === ">=") {
              result = newfieldValue >= newValue
            } else if (it?.operator === "<") {
              result = newfieldValue < newValue
            } else if (it?.operator === "<=") {
              result = newfieldValue <= newValue
            } else {
              result = newfieldValue === newValue
            }
          } else if (it?.valueType === "boolean") {
            if (it?.operator === "===") {
              result = fieldValue === it?.value
            } else if (it?.operator === "!==") {
              result = fieldValue !== it?.value
            }
          } else if (
            it?.fieldName === "__userData.isManager__" &&
            it?.value === "null"
          ) {
            if (it?.operator === "===") {
              result = fieldValue === null
            } else if (it?.operator === "!==") {
              result = fieldValue !== null
            }
          } else {
            // console.log(`"${fieldValue}"${it?.operator}"${it?.value}"`)
            result = eval(`"${fieldValue}"${it?.operator}"${it?.value}"`)
            // console.log(result)
            // resultArr.push(result)
          }
          resultArr.push(result)
        } else {
          let evals =
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== "undefined" &&
            fieldValue !== ""
          // console.log(fieldValue, evals)
          resultCount++
          result = result + evals
          resultArr.push(evals)
        }
      })
    }

    // console.log(result)
    return {
      result: result,
      resultCount: resultCount,
      operator: operator
    }
  }

  // const widthsColumns = useDynamicWidths(store?.pageData[storeName]?.data)

  // const [columnWidths, setColumnWidths] = useState({})
  // useEffect(() => {
  //   if (tableRefDiv.current && store.pageData[storeName]?.data?.length > 0) {
  //     const firstRowCells = tableRefDiv.current.querySelectorAll(
  //       ".rdt_TableRow:first-child .rdt_TableCell"
  //     )
  //     const newWidths = {}
  //     firstRowCells.forEach((cell, index) => {
  //       const fieldName = cell?.getAttribute("data-column-id")
  //       // if (columns[index]) {
  //       newWidths[fieldName] = cell.scrollWidth // Get the width of the cell
  //       // }
  //       console.log(cell.scrollWidth)
  //     })
  //     setColumnWidths(newWidths)
  //   }
  // }, [store.pageData[storeName]?.data])

  const renderDtColumnProp = (column) => {
    const renderCol = {
      id: column.fieldName,
      // name: t(`${column.columnName ?? getColumnTitle(column.fieldName)}`),
      name:
        // column?.searchableInput === true
        isContainsSearchableInput ? (
          <div className="w-100">
            <div className="mb-75">
              {t(`${column.columnName ?? getColumnTitle(column.fieldName)}`)}
            </div>
            <FilterComponent column={column} />
          </div>
        ) : (
          t(`${column.columnName ?? getColumnTitle(column.fieldName)}`)
        ),

      selector: (row) =>
        row.id && row?.id !== 0 ? row[column.fieldName] : null,
      cell: (row) =>
        !column?.editable ? (
          isDisplayContextMenu ? (
            <Fragment>
              <div
                ref={contextMenuRef}
                className="datatable-custom-cell-container"
                onContextMenu={(event) => handleContextMenu(event, row)}
                onClick={handleCloseContextMenu}
              >
                <DynamicRow
                  row={row}
                  column={column}
                  clickToDetailEvent={handleAddEdit}
                />
              </div>

              {contextMenu && (
                <ul
                  style={{
                    top: `${contextMenu.mouseY}px`,
                    left: `${contextMenu.mouseX}px`
                  }}
                  className="datatable-context-menu pe-25"
                  // onClick={(e) => e.stopPropagation()}
                >
                  {getActionsBtnCell(row, true)}
                  {/* <li
                  href="#"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault()
                    alert(`Edit ${contextMenu.row.name}`)
                  }}
                >
                  Edit
                </li>
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault()
                    alert(`Edit ${contextMenu.row.name}`)
                  }}
                >
                  Delete
                </a> */}
                </ul>
              )}
            </Fragment>
          ) : (
            <DynamicRow
              row={row}
              column={column}
              clickToDetailEvent={handleAddEdit}
            />
          )
        ) : !row.id ||
          row?.id === 0 ||
          String(row?.id)?.includes("xx") ||
          !(inputOnGridState[column.fieldName] ?? []).includes(row?.id) ? (
          disableBtnCondition(
            pageAttr?.crudOpt,
            row,
            "edit-grid",
            storeUnit?.data,
            pageType ?? useParams()?.pageType,
            pageAttr
          ) ? (
            <DynamicRow
              row={row}
              column={column}
              clickToDetailEvent={clickEditableOnDisabled}
              // disabledEditable={true}
            />
          ) : (loadingPutGrid?.[column?.fieldName] ?? []).indexOf(row?.id) !==
            -1 ? (
            <TableSkeletonLoading
              key={`loading_editable_put_${row?.id}`}
              column={column}
            />
          ) : (
            <DynamicRow
              row={row}
              column={column}
              clickToDetailEvent={handleEditOnRow}
            />
          )
        ) : disableBtnCondition(
            pageAttr?.crudOpt,
            row,
            "edit-grid",
            storeUnit?.data,
            pageType ?? useParams()?.pageType,
            pageAttr
          ) ? (
          <DynamicRow
            row={row}
            column={column}
            clickToDetailEvent={clickEditableOnDisabled}
            // disabledEditable={true}
          />
        ) : (
          renderInputOnGrid(row, column, inputOnGrid)
        ),
      grow: !column?.width && !column?.maxWidth ? column?.grow : true,
      hide: column?.hide ?? "",
      reorder: column?.grow ?? true,
      width: column?.width,
      // ??
      // (pageAttr?.crudOpt?.columnsAutoMaxWidth || column?.columnsAutoMaxWidth
      //   ? `${widthsColumns?.[column?.fieldName] ?? "100"}px`
      //   : null),
      maxWidth:
        column?.maxWidth ??
        (column?.type === "img_slider" && !column?.width ? "100px" : null),
      minWidth:
        column?.minWidth ??
        (column?.type === "img_slider" && !column?.width ? "100px" : null),
      right:
        ((column?.type === "currency" || column?.subtotal) &&
          !column?.center &&
          !column?.left) ??
        column?.right,
      center: column?.center,
      sortable:
        column?.sortable === undefined && column?.fieldName
          ? true
          : column?.sortable,
      sortField: column?.sortFieldName ?? column.fieldName,
      conditionalCellStyles: [
        {
          when: (row) => !row.id || row?.id === 0,
          style: {
            height: "38px",
            textAlign: "right"
          }
        },
        {
          when: () => column?.isFixedColumn,
          classNames: ["dt-fixed-column"]
        },
        {
          when: () =>
            (column?.columnsAutoMaxWidth ||
              pageAttr?.crudOpt?.columnsAutoMaxWidth) &&
            !column?.width,
          classNames: ["dt-cell-auto-max-width"]
        },
        {
          when: (row) =>
            pageAttr?.crudOpt?.gridTheme === "table_tree" &&
            (row?.[pageAttr?.crudOpt?.childrenField ?? "children"]?.length ===
              0 ??
              store.pageData[storeName]?.data?.filter((x) => {
                return x?.parent_id === row.id
              })?.length === 0),
          classNames: ["dt-disabled-expand"]
        },
        {
          when: (row) => pageAttr?.crudOpt?.gridTheme === "table_tree",
          classNames: ["dt-expandable"]
        }
      ],
      compact: column?.compact,
      ignoreRowClick: column?.ignoreRowClick,
      wrap:
        column?.wrap ??
        !column?.columnsAutoMaxWidth ??
        !pageAttr?.crudOpt?.columnsAutoMaxWidth ??
        true,
      hide: column?.hide ?? column?.isHidden,
      allowOverflow:
        column?.allowOverflow ??
        !column?.columnsAutoMaxWidth ??
        !pageAttr?.crudOpt?.columnsAutoMaxWidth ??
        false,
      format: column?.format
    }
    if (!column?.showIf) {
      return renderCol
    } else {
      const conditionsShow = checkCondition(column?.showIf, column)?.result
      // console.log(conditionsShow)
      if (conditionsShow) {
        return renderCol
      }
    }
  }

  const getAutoColumn = (type = "dt_column") => {
    let result = []
    // console.log(store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns)
    if (isAutoColumns) {
      if (store.pageData[storeName]?.data?.length > 0) {
        const columnsKeyExist = store.pageAttributes[
          storeName
        ]?.crudOpt?.data?.dynamicColumns?.map((obj) => {
          return obj?.fieldName
        })

        const globalProperties =
          store.pageAttributes[storeName]?.crudOpt
            ?.dynamicColumnsGlobalProperties

        Object.keys(store.pageData[storeName]?.data?.[0])?.map((existKey) => {
          if (!columnsKeyExist?.includes(existKey) && existKey !== "id") {
            const columnTitle = existKey.replace(/([A-Z])/g, " $1")
            let columnTitleEnd =
              columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)

            if (columnTitleEnd?.includes(".")) {
              columnTitleEnd = columnTitleEnd?.split(".")?.[-1]
            }

            const column = {
              fieldName: `${existKey}`,
              columnName: t(`${columnTitleEnd?.replaceAll("_", " ")}`),
              ...globalProperties
            }
            if (type === "pageAttr_column") {
              result.push(column)
            } else {
              result.push(renderDtColumnProp(column))
            }
          }
        })
      }
    }
    return result
  }

  // function getDepthValue(obj, path, defaultValue = null) {
  //   let props
  //   if (typeof obj === "undefined") return defaultValue
  //   if (typeof path === "string") {
  //     props = path.split(".").reverse()
  //   } else {
  //     props = path
  //   }
  //   if (path.length === 0) return obj || defaultValue
  //   let current = props.pop()
  //   return getDepthValue(obj?.[current], props, defaultValue)
  // }

  // const disableBtnEditCondition = (crudOpt, row = null, pageAttr) => {
  //   const paramss = useParams()
  //   let result = crudOpt?.disabledEdit ?? false
  //   if (crudOpt?.disabledEditCondition) {
  //     crudOpt?.disabledEditCondition?.map((item) => {
  //       let field = item?.field
  //       let operator = item?.operator
  //       let value = item?.value

  //       let currentRowValue = null
  //       let evalStr = null

  //       if (field?.includes("__userData")) {
  //         const userData = getUserData()
  //         if (field === "__userData.name__") {
  //           currentRowValue = userData?.name
  //         } else if (field === "__userData.isManager__") {
  //           currentRowValue = userData?.isManager
  //         } else if (field === "__userData.email__") {
  //           currentRowValue = userData?.email
  //         } else if (field === "__userData.id__") {
  //           currentRowValue = userData?.id
  //         } else if (field === "__userData.username__") {
  //           currentRowValue = userData?.username
  //         }

  //         evalStr = `'${currentRowValue}' ${operator} ${value}`
  //         if (
  //           [null, undefined, "", false].includes(currentRowValue) &&
  //           (value === "null" || value === "false")
  //         ) {
  //           evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
  //         }

  //         try {
  //           if (crudOpt?.disabledDeleteCondition_or === true) {
  //             result = result || eval(evalStr)
  //           } else {
  //             result = eval(evalStr)
  //           }
  //         } catch (error) {
  //           result = false
  //         }
  //         // console.log(field, evalStr, result)
  //       } else {
  //         currentRowValue = field?.includes(".")
  //           ? getDepthValue(row, field)
  //           : row?.[field]

  //         evalStr = `'${currentRowValue}' ${operator} ${value}`

  //         if (
  //           [null, undefined, "", false].includes(currentRowValue) &&
  //           (value === "null" || value === "false")
  //         ) {
  //           evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
  //         }

  //         try {
  //           if (crudOpt?.enabledViewBtnCondition_or === true) {
  //             result = result || eval(evalStr)
  //           } else {
  //             result = eval(evalStr)
  //           }
  //         } catch (error) {
  //           result = false
  //         }
  //         // console.log(field, evalStr, result)
  //       }
  //     })
  //   }

  //   if (paramss?.pageType === "view") {
  //     result = true
  //   }

  //   // console.log(result)
  //   return result
  // }

  // const disableBtnDeleteCondition = (crudOpt, row = null, pageAttr) => {
  //   const paramss = useParams()
  //   let result = crudOpt?.disabledDelete ?? false
  //   if (crudOpt?.disabledDeleteCondition) {
  //     crudOpt?.disabledDeleteCondition?.map((item) => {
  //       let field = item?.field
  //       let operator = item?.operator
  //       let value = item?.value

  //       let currentRowValue = null
  //       let evalStr = null

  //       if (field?.includes("__userData")) {
  //         const userData = getUserData()
  //         if (field === "__userData.name__") {
  //           currentRowValue = userData?.name
  //         } else if (field === "__userData.isManager__") {
  //           currentRowValue = userData?.isManager
  //         } else if (field === "__userData.email__") {
  //           currentRowValue = userData?.email
  //         } else if (field === "__userData.id__") {
  //           currentRowValue = userData?.id
  //         } else if (field === "__userData.username__") {
  //           currentRowValue = userData?.username
  //         }

  //         evalStr = `'${currentRowValue}' ${operator} ${value}`

  //         if (
  //           [null, undefined, "", false].includes(currentRowValue) &&
  //           (value === "null" || value === "false")
  //         ) {
  //           evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
  //         }

  //         if (crudOpt?.disabledDeleteCondition_or === true) {
  //           result = result || eval(evalStr)
  //         } else {
  //           result = eval(evalStr)
  //         }
  //         // console.log(field, evalStr, result)
  //       } else {
  //         currentRowValue = field?.includes(".")
  //           ? getDepthValue(row, field)
  //           : row?.[field]

  //         evalStr = `'${currentRowValue}' ${operator} ${value}`
  //         if (
  //           [null, undefined, "", false].includes(currentRowValue) &&
  //           (value === "null" || value === "false")
  //         ) {
  //           evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
  //         }

  //         try {
  //           if (crudOpt?.disabledDeleteCondition_or === true) {
  //             result = result || eval(evalStr)
  //           } else {
  //             result = eval(evalStr)
  //           }
  //         } catch (error) {
  //           result = false
  //         }

  //         // console.log(field, evalStr, result)
  //       }
  //     })
  //   }

  //   if (paramss?.pageType === "view") {
  //     result = true
  //   }

  //   return result
  // }

  const getActionsBtnCell = (row, isContextMenu) =>
    row?.id && row?.id !== 0 && !String(row?.id)?.includes("xx") ? (
      <GlobalActionsTable
        row={row}
        handleDelete={handleDelete}
        handleEdit={handleAddEdit}
        pageAttr={pageAttr}
        disabledAction={[
          (!pageAttr?.crudOpt?.saveAsNewBtn &&
            disableBtnCondition(
              pageAttr?.crudOpt,
              row,
              "edit",
              storeUnit?.data,
              pageType ?? useParams()?.pageType,
              pageAttr
            )) ||
          row[pageAttr?.crudOpt?.childrenField ?? "children"]?.length
            ? "update"
            : "",
          // disableBtnDeleteCondition(
          //   store.pageAttributes[storeName]?.crudOpt,
          //   row,
          //   store.pageAttributes[storeName]
          // )
          disableBtnCondition(
            pageAttr?.crudOpt,
            row,
            "delete",
            storeUnit?.data,
            pageType ?? useParams()?.pageType,
            pageAttr
          ) || row[pageAttr?.crudOpt?.childrenField ?? "children"]?.length
            ? "delete"
            : ""
        ]}
        rowClickEvent={pageAttr?.crudOpt?.rowClickEvent}
        storeName={storeName}
      />
    ) : isContextMenu ? null : (
      <span></span>
    )

  const columns = [
    ...(pageAttr?.crudOpt?.data?.dynamicColumns
      ? pageAttr?.crudOpt?.data?.dynamicColumns.map((column) =>
          renderDtColumnProp(column)
        )
      : []),
    ...getAutoColumn(),
    ...(((!store.pageAttributes[storeName]?.crudOpt?.disabledEdit ||
      store.pageAttributes[storeName]?.crudOpt?.disabledEditCondition ||
      store.pageAttributes[storeName]?.crudOpt
        ?.dynamicColumnsExtraActionsButton ||
      !store.pageAttributes[storeName]?.crudOpt?.disabledDelete ||
      store.pageAttributes[storeName]?.crudOpt?.rowClickEvent) &&
      (pageAttr?.onDashboard ||
        (ability.can("update", sessionStorage.getItem("current_page_perm")) &&
          ability.can(
            "delete",
            sessionStorage.getItem("current_page_perm")
          )))) ||
    (ability.can("read", sessionStorage.getItem("current_page_perm")) &&
      store.pageAttributes[storeName]?.crudOpt?.rowClickEvent) ||
    (!ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      !ability.can("post", sessionStorage.getItem("current_page_perm")) &&
      !pageAttr?.onDashboard)
      ? [
          !store.pageAttributes[storeName]?.crudOpt?.simpleActionButton
            ? {
                name:
                  store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.name ?? t("Actions"),
                center:
                  !store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.right &&
                  !store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.left,
                right:
                  store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.right,
                left:
                  store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.left &&
                  !store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.right,
                width:
                  store.pageAttributes[storeName]?.crudOpt?.actionBtnStyle
                    ?.width ??
                  (store.pageAttributes[storeName]?.crudOpt
                    ?.dynamicColumnsExtraActionsButton
                    ? ""
                    : pageAttr?.crudOpt?.gridTheme === "table_tree"
                    ? "210px"
                    : "190px"),
                minWidth: store.pageAttributes[storeName]?.crudOpt
                  ?.dynamicColumnsExtraActionsButton
                  ? "210px"
                  : "",
                sortable: false,
                conditionalCellStyles: [
                  // {
                  //   when: () => true,
                  //   classNames: ["dt-fixed-column"]
                  // },
                  {
                    when: (row) => !row.id || row?.id === 0,
                    style: {
                      height: "38px"
                    }
                  },
                  {
                    when: (row) =>
                      (row.id || row?.id !== 0) &&
                      pageAttr?.crudOpt?.gridTheme === "table_tree",
                    style: {
                      justifyContent: "flex-end"
                    }
                  }
                ],
                cell: (row) => {
                  return getActionsBtnCell(row)
                }
              }
            : {
                name: "  ",
                right: true,
                width: "50px",
                sortable: false,
                conditionalCellStyles: [
                  {
                    when: () => true,
                    style: {
                      color: "transparent"
                    }
                  }
                ],
                cell: (row) => {
                  return getActionsBtnCell(row)
                }
              }
        ]
      : [])
  ]

  const tableColumns = useMemo(
    () =>
      loading || store.pageData[storeName]?.isLoading
        ? columns
            .filter((x) => x?.name)
            .map((column, index) => ({
              name: t(column?.name),
              ...column,
              sortable: false,
              cell: () => (
                <TableSkeletonLoading
                  key={`loading_${index}`}
                  column={column}
                />
              )
            }))
        : columns
            .filter((x) => x?.name)
            .map((column) => ({
              // name: t(column?.name),
              ...column
            })),
    [loading, store.pageData[storeName]?.isLoading, columns]
  )

  const getTotals = (data, key) => {
    let total = 0
    data.map((item) => {
      total += item[key] ?? getDepthValue(item, key)
    })
    return total
  }

  const dataToRender = () => {
    // console.log(store.pageData[storeName]?.data)
    const filters = {
      search: searchTerm
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (!loading && !store.pageData[storeName]?.isLoading) {
      // console.log(filteredData, store.pageData[storeName]?.filteredData)
      let dataGrid =
        (filteredData?.length > 0
          ? filteredData
          : store.pageData[storeName]?.filteredData) ??
        store.pageData[storeName]?.data
      if (dataGrid) {
        let dataGridCopy = JSON.parse(JSON.stringify(dataGrid))
        // console.log(dataGridCopy)
        if (dataGridCopy.length > 0) {
          const autoColumnsx = getAutoColumn("pageAttr_column")
          const mixeDynColumns = [
            ...(pageAttr?.crudOpt?.data?.dynamicColumns
              ? pageAttr?.crudOpt?.data?.dynamicColumns
              : []),
            ...autoColumnsx
          ]

          const dynamicColumnsGroupByKey =
            pageAttr?.crudOpt?.dynamicColumnsGroupByKey
          const dynamicColumnsGroupByLabel =
            pageAttr?.crudOpt?.dynamicColumnsGroupByLabel

          if (dynamicColumnsGroupByKey) {
            const subtotalFieldsAttr = mixeDynColumns.filter(
              (el) => el?.subtotal
            )

            const result = dataGrid.reduce((acc, item, currentIndex) => {
              const found = acc.find(
                (el) =>
                  el[dynamicColumnsGroupByKey] ===
                  item[dynamicColumnsGroupByKey]
              )

              if (found) {
                found.children.push(item)
                subtotalFieldsAttr.forEach(
                  (it) => (found[it.fieldName] += item[it.fieldName])
                )
              } else {
                const subtotalObjKey = subtotalFieldsAttr.reduce(
                  (obj, it) => ({ ...obj, [it.fieldName]: item[it.fieldName] }),
                  {}
                )

                const label = dynamicColumnsGroupByLabel
                  .map((label) =>
                    label.startsWith("'") && label.endsWith("'")
                      ? label.substring(1, label.length - 1)
                      : item[label]
                  )
                  .join(" ")

                acc.push({
                  id: `xx_${currentIndex}`,
                  ["isGroupByKey"]: true,
                  [dynamicColumnsGroupByKey]: item[dynamicColumnsGroupByKey],
                  [mixeDynColumns[0]?.fieldName ?? "label"]: label,
                  children: [item],
                  ...subtotalObjKey
                })
              }

              return acc
            }, [])

            dataGridCopy = result
          }

          let subtotal_fields = {}

          mixeDynColumns.some((el, index) => {
            if (
              el?.isFixedColumn &&
              (index === 0 ||
                // index === mixeDynColumns.length - 1 ||
                (index !== 0 && mixeDynColumns[index - 1]?.isFixedColumn))
              // ||
              // (index < mixeDynColumns.length - 1 &&
              //   mixeDynColumns[index + 1]?.isFixedColumn &&
              //   mixeDynColumns[index + 1]?.isFixedColumn !==
              //     mixeDynColumns[index]?.isFixedColumn)
            ) {
              setTimeout(() => {
                let headerElements = document?.querySelectorAll(
                  ".rdt_TableCol[data-column-id]"
                )
                // console.log(headerElements)
                headerElements?.forEach((element) => {
                  if (element.getAttribute("data-column-id") === el.fieldName) {
                    element.classList.add("dt-fixed-column")
                  }
                })
              }, 300)

              // let headerClass = document.getElementsByClassName("rdt_TableCol")
              // if (headerClass) {
              //   for (let i = 0; i < headerClass.length; i++) {
              //     if (headerClass[i].hasAttribute("data-column-id")) {
              //       if (
              //         headerClass[i].getAttribute("data-column-id") ===
              //         el.fieldName
              //       ) {
              //         headerClass[i].classList.add("dt-fixed-column")
              //       }
              //     }
              //   }
              // }
            }

            if (el.hasOwnProperty("subtotal") && el?.subtotal) {
              const fieldName = el.fieldName ?? `subtotal_${index}`
              if (el?.calcFromOther) {
                const inputName = el.calcFromOther
                if (
                  inputName.indexOf("*") ||
                  inputName.indexOf("+") ||
                  inputName.indexOf("-") ||
                  inputName.indexOf("/")
                ) {
                  let otherValueFieldName = []
                  let operator = null
                  if (inputName.includes("*")) {
                    otherValueFieldName = inputName.split("*")
                    operator = "*"
                  } else if (inputName.includes("+")) {
                    otherValueFieldName = inputName.split("+")
                    operator = "+"
                  } else if (inputName.includes("-")) {
                    otherValueFieldName = inputName.split("-")
                    operator = "-"
                  } else if (inputName.includes("/")) {
                    otherValueFieldName = inputName.split("/")
                    operator = "/"
                  }

                  if (otherValueFieldName?.length > 1) {
                    let total = 0
                    dataGridCopy.forEach((item) => {
                      const val1 =
                        item[otherValueFieldName[0]] ??
                        getDepthValue(item, otherValueFieldName[0])
                      const val2 =
                        item[otherValueFieldName[1]] ??
                        getDepthValue(item, otherValueFieldName[1])
                      if (el?.valueType === "datetime") {
                        let input1 = new Date(Date.parse(val1))
                        let input2 = new Date(Date.parse(val2))
                        let evals
                        if (operator === "-") {
                          evals = Math.round(
                            (input1 - input2) / (1000 * 60 * 60 * 24)
                          )
                        } else if (operator === "+") {
                          evals = Math.round(
                            (input1 + input2) / (1000 * 60 * 60 * 24)
                          )
                        }

                        total = total + (evals + 1)
                      } else if (el?.valueType === "time") {
                        let evals
                        const dateNow = `01/01/2023`
                        const dateNow2 = `01/02/2023`
                        let dateTime1 = new Date(`${dateNow} ${val1}`)
                        let dateTime2 = new Date(`${dateNow2} ${val2}`)

                        if (operator === "-") {
                          if (dateTime1 < dateTime2) {
                            dateTime1 = new Date(`${dateNow2} ${val1}`)
                          }

                          evals = dateTime1.getHours() - dateTime2.getHours()
                          if (evals < 0) {
                            evals = 24 + evals
                          }
                        } else if (operator === "+") {
                          evals = dateTime1.getHours() + dateTime2.getHours()
                          if (evals < 0) {
                            evals = 24 + evals
                          }
                        }
                        total = total + (!isNaN(evals) ? evals : 0)
                      } else {
                        let regExp = /[a-zA-Z]/g
                        if (val1 && val2) {
                          if (!regExp.test(val1) && !regExp.test(val2)) {
                            let evals = eval(`${val1}${operator}${val2}`)
                            total += evals
                          }
                        }
                      }
                    })
                    subtotal_fields[fieldName] = total
                  }
                }
              } else {
                subtotal_fields[fieldName] = getTotals(dataGridCopy, fieldName)
              }
            }
          })

          if (Object.keys(subtotal_fields).length > 0) {
            let newRecord = {}

            pageAttr?.crudOpt?.data?.dynamicColumns.map((key, index) => {
              const fieldName = key?.fieldName ?? `subtotal_${index}`
              newRecord[fieldName] = subtotal_fields[fieldName] ?? ""
            })

            newRecord["id"] = 0
            newRecord["key"] = storeName

            dataGridCopy.push(newRecord)
          }
          return dataGridCopy
        } else if (dataGrid.length === 0 && isFiltered) {
          return []
        }
      }
    } else {
      return Array(5)
        .fill()
        .map((_, index) => ({
          id: index + 1
        }))
    }
  }

  const [serverSideProps, setServerSideProps] = useState({})

  const [expandableRowsProps, setExpandableRowsProps] = useState({})
  const [expandedRow, setExpandedRow] = useState(
    store.pageData[storeName]?.expandedData ?? []
  )

  const [currentRow, setCurrentRow] = useState(null)

  /////EXPAND-COLLAPSE ALL
  const expandAllRows = (mode = "expand") => {
    if (mode === "collapse") {
      setExpandedRow([])
      dispatch(
        setExpandedData({
          rowData: [],
          storeName
        })
      )
    } else {
      const array = []

      for (let i = 0; i <= 200; i++) {
        array.push(`xx_${i}`)
      }
      setExpandedRow(array)
      dispatch(
        setExpandedData({
          rowData: array,
          storeName
        })
      )
    }
  }

  const onRowExpandToggledEvent = (state, row, oldData) => {
    // console.log(
    //   row,
    //   store?.pageData[storeName]?.expandedData,
    //   store?.pageData[storeName],
    //   expandedRow
    // )
    setCurrentRow(row)

    let copyState = oldData ?? []
    const value = row?.id
    if (state) {
      copyState = [...copyState, value]
    } else {
      copyState = copyState.filter((e) => e !== row?.id)
    }
    setExpandedRow(copyState)
    // console.log(storeName, copyState)
    dispatch(
      setExpandedData({
        rowData: copyState,
        storeName
      })
    )

    pageAttr?.crudOpt?.data?.expandableRows?.map((item) => {
      if (item?.type === "relationsCrud") {
        const relationsCrudData = JSON.parse(
          JSON.stringify(item?.relationsCrud)
        )
        const initialDataNew = JSON.parse(JSON.stringify(row))
        const initialPageIdDynamic = `${relationsCrudData?.pageId}_${initialDataNew.id}`
        item?.relationsCrud?.crudOpt?.initialFiltersCondition?.map(
          (childPar) => {
            initialDataNew[childPar?.fieldNameCurrent] =
              initialDataNew[childPar?.value_from_parentFieldName]
          }
        )
        dispatch(
          setSelectedData({
            rowData: initialDataNew,
            storeName: initialPageIdDynamic
          })
        )

        dispatch(
          setcurrentExpandedData({
            rowData: initialDataNew,
            storeName
          })
        )
      }
    })
  }

  const ExpandableContents = ({ data }) => {
    if (data) {
      if (
        pageAttr?.crudOpt?.gridTheme !== "table_tree" &&
        !pageAttr?.crudOpt?.dynamicColumnsGroupByKey
      ) {
        // jika versi expandable yang lama yang hanya 1 relationsCrud
        const hasOtherTypes = pageAttr?.crudOpt?.data?.expandableRows?.some(
          function (obj) {
            return obj.type !== "relationsCrud"
          }
        )
        if (!hasOtherTypes) {
          return pageAttr?.crudOpt?.data?.expandableRows?.map((item, i) => {
            if (item?.type === "relationsCrud") {
              const relationsCrudData = JSON.parse(
                JSON.stringify(item?.relationsCrud)
              )

              let initialDataNew = JSON.parse(JSON.stringify(data))
              item?.relationsCrud?.crudOpt?.initialFiltersCondition?.map(
                (childPar) => {
                  initialDataNew[childPar?.fieldNameCurrent] =
                    initialDataNew[childPar?.value_from_parentFieldName]
                }
              )
              const initialPageIdDynamic = `${relationsCrudData?.pageId}_${initialDataNew.id}`

              // disableBtnAddFromParent
              const relationsCrudOpt = relationsCrudData?.crudOpt
              if (relationsCrudOpt) {
                const disabledCondition = disableBtnCondition(
                  relationsCrudOpt,
                  data,
                  "add",
                  storeUnit?.data,
                  "add",
                  relationsCrudData
                )
                if (disabledCondition) {
                  relationsCrudData["disableBtnAddFromParent"] =
                    disabledCondition
                  relationsCrudData["disableBtnEditFromParent"] =
                    disabledCondition
                }
              }

              const newInitialPageAttr = {
                ...relationsCrudData,
                isViewOnRow: true,
                pageId: initialPageIdDynamic,
                relationsCrudKey: initialPageIdDynamic
              }
              return (
                <div className="mb-3">
                  <DynamicPage
                    key={`expandableRows_${pageId}_${i}`}
                    initialPageId={initialPageIdDynamic}
                    initialPageAttr={newInitialPageAttr}
                    initialData={initialDataNew}
                  />
                </div>
              )
            }
          })
        } else {
          return (
            <DynamicForm
              key={`expandable_dynamicForm_${storeName}`}
              pageAttr={pageAttr}
              dynamicForm={pageAttr?.crudOpt?.data?.expandableRows}
              storeName={storeName}
              // handleReset={handleReset}
              // handleFormSubmit={handleFormSubmit}
              defaultFormValues={JSON.parse(
                JSON.stringify(data).replace(/\:null/gi, ':""')
              )}
              editForm={true}
              pageType={"edit"}
            />
          )
        }
      } else if (
        pageAttr?.crudOpt?.dynamicColumnsGroupByKey &&
        pageAttr?.crudOpt?.gridTheme !== "table_tree"
      ) {
        const childrenData = data?.children

        //  hide column jika sama dengan header
        // console.log(childrenData)
        return (
          <div
            className={`react-dataTable-expandable-children ${
              pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse
                ? "disabled_collapse"
                : ""
            }`}
          >
            <DataTable
              noHeader
              noTableHead
              keyField={pageAttr?.crudOpt?.data_idField ?? "id"}
              // noTableHead={pageAttr?.crudOpt?.hideTableHeader}
              // subHeader={
              //   !pageAttr?.crudOpt?.btnAddTextPosition &&
              //   !pageAttr?.crudOpt?.hideTableSubHeader
              //   // !pageAttr?.crudOpt?.hideTableSearchInput &&
              //   // !pageAttr?.crudOpt?.hideTablePageLengthInfo &&
              //   // !pageAttr?.crudOpt?.dynamicColumnsFilterPos
              // }
              pagination={!pageAttr?.crudOpt?.hideTablePagination ?? true}
              paginationComponentOptions={{
                ...paginationComponentOptions,
                selectAllRowsItem: false
              }}
              paginationRowsPerPageOptions={[5, 10]}
              responsive
              highlightOnHover={true}
              className={`react-dataTable-expandable-children2`}
              conditionalRowStyles={{
                when: (row) =>
                  pageAttr?.crudOpt?.dynamicColumnsGroupByPaddingLeft,
                style: {
                  paddingLeft: `${pageAttr?.crudOpt?.dynamicColumnsGroupByPaddingLeft}px !important`
                }
              }}
              customStyles={{
                ...(pageAttr?.crudOpt?.customTableStyles ?? {}),
                ...(pageAttr?.crudOpt?.dynamicColumnsFilterPos ===
                "table-header"
                  ? {
                      table: {
                        style: {
                          minHeight: "45vh"
                        }
                      }
                    }
                  : {})
              }}
              noDataComponent={
                <NoDataDisplay
                  noImage={pageAttr?.isViewOnForm || pageAttr?.isViewOnRow}
                  dataName={`detail ${pageAttr?.pageTitle}`}
                  pageAttr={pageAttr}
                />
              }
              // expandableRowsHideExpander={(row) =>
              //   pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse
              // }
              dense={true}
              columns={columns}
              data={JSON.parse(JSON.stringify(childrenData ?? []))}
            />
          </div>
        )
      } else {
        const childrenField = pageAttr?.crudOpt?.childrenField ?? "children"
        const childrenData = store.pageData[storeName]?.data?.find((x) => {
          return x?.id === data.id
        })?.[childrenField]

        return (
          childrenData && (
            <div className="react-dataTable-expandable-children">
              <DataTable
                noHeader
                // noTableHead={pageAttr?.crudOpt?.hideTableHeader}
                noTableHead
                responsive
                highlightOnHover={true}
                className="react-dataTable-expandable-children"
                noDataComponent={
                  <NoDataDisplay
                    noImage={pageAttr?.isViewOnForm || pageAttr?.isViewOnRow}
                    dataName={`detail ${pageAttr?.pageTitle}`}
                    pageAttr={pageAttr}
                  />
                }
                dense={true}
                columns={columns}
                data={[...(childrenData ?? [])]}
                expandableRows={true}
                expandOnRowClicked={true}
                expandableRowsComponent={ExpandableContentsTree}
                expandableInheritConditionalStyles={true}

                // data={JSON.parse(JSON.stringify(childrenData))}
              />
            </div>
          )
        )
      }
    }
  }

  const ExpandableContentsTree = ({ data, storeData }) => {
    // console.log(data, storeData)
    // console.log(store?.pageData[storeName]?.data)
    const childrenField = pageAttr?.crudOpt?.childrenField ?? "children"
    const childrenData =
      data?.[childrenField] ??
      storeData?.data?.filter((x) => {
        return x?.parent_id === data.id
      })
    // const childrenData = data?.[childrenField]

    // console.log(childrenData)
    return (
      childrenData && (
        <div className="react-dataTable-expandable-children">
          <DataTable
            noHeader
            noTableHead
            responsive
            // highlightOnHover={true}
            className="react-dataTable-expandable-children datatable-table-tree"
            noDataComponent={<div></div>}
            dense={true}
            columns={columns}
            data={childrenData}
            expandableRows={true}
            expandOnRowClicked={true}
            expandableRowDisabled={(row) =>
              row?.[childrenField]?.length === 0 ??
              !storeData?.data?.find((x) => {
                return x?.parent_id === row.id
              })
            }
            expandableRowsComponent={ExpandableContentsTree}
            expandableRowsComponentProps={{ storeData: storeData }}
            expandableInheritConditionalStyles={true}
            // expandableRowsHideExpander={(row) =>
            //   row?.[childrenField]?.length === 0
            // }
            expandableRowExpanded={(row) =>
              storeData?.expandedData?.includes(row?.id)
            }
            onRowExpandToggled={(bool, row) =>
              onRowExpandToggledEvent(bool, row, [
                ...(storeData?.expandedData ?? [])
              ])
            }
            // expandableIcon={{
            //   collapsed:
            // }}
          />
        </div>
      )
    )
  }

  const [tempAsyncOptLoading, setTempAsyncOptLoading] = useState({})
  const [tempAsyncOptValues, setTempAsyncOptValues] = useState({})

  const setLoadingInputs = (fieldName, value) => {
    // console.log(fieldName, value, init, defaultFormValues[fieldName])
    // if (defaultFormValues[fieldName] && init) {
    //   tempAsyncOptLoading[fieldName] = value ?? false
    //   setTempAsyncOptLoading(tempAsyncOptLoading)
    // } else {
    // tempAsyncOptLoading[fieldName] = value ?? false
    // setTempAsyncOptLoading(tempAsyncOptLoading)
    let tempAsyncOptLoadingCopy = { ...tempAsyncOptLoading }
    tempAsyncOptLoadingCopy[fieldName] = value ?? false
    setTempAsyncOptLoading(tempAsyncOptLoadingCopy)
    // }
  }

  const [contextMenu, setContextMenu] = useState(null)
  const [timeoutId, setTimeoutId] = useState(null)
  const [tableHasHorizontalScroll, setTableHasHorizontalScroll] =
    useState(false)
  const contextMenuRef = useRef(null)
  const handleContextMenu = (event, row) => {
    if (event) {
      event.preventDefault()
    }
    // if (tableHasHorizontalScroll) {
    // console.log("right-clicked")
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      row
    })
    // const id = setTimeout(() => {
    //   handleCloseContextMenu()
    // }, 10000)
    // setTimeoutId(id)
    // }
  }

  const handleClickOutside = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      handleCloseContextMenu()
    }
  }

  const handleCloseContextMenu = () => {
    if (isDisplayContextMenu) {
      setContextMenu(null)
      // clearAutoClose()
    }
  }

  // const clearAutoClose = () => {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId)
  //     setTimeoutId(null)
  //   }
  // }

  const getParamsApiUrlAttr = (url, item) => {
    const { get_params: getParams } = item?.dataFrom || {}
    if (!getParams) return url

    let queryStrGetParams = ""

    for (const par of getParams) {
      const { key, value, valueFrom } = par
      let querystrVal = null

      if (!value) continue

      const isValueDeepKey = value.includes(".")
      switch (valueFrom) {
        case "static":
          querystrVal = value
          break
        case "other":
          const targetFieldNameSuffix = `__${item.fieldName.split("__").pop()}`
          querystrVal = getValues()[`${value}${targetFieldNameSuffix}`]

          if (Object.prototype.toString.call(querystrVal) === "[object Date]") {
            querystrVal = querystrVal.toISOString().slice(0, 10)
            // console.log(querystrVal)
          }
          break
        default:
          break
      }

      if (
        // eslint-disable-next-line eqeqeq
        querystrVal != null &&
        querystrVal !== "undefined" &&
        querystrVal !== "null"
      ) {
        queryStrGetParams += `${key}=${querystrVal}&`
      }
    }

    let finUrl = url
    if (queryStrGetParams) {
      finUrl +=
        (finUrl.includes("?") ? "&" : "?") + queryStrGetParams.slice(0, -1)
    }
    // console.log(finUrl)
    return finUrl
  }

  const loadDropdownFromApi = async (fieldName, inputValue, item) => {
    let itemFieldValue = item?.dataFrom?.field_value ?? "id"
    const isValueDeepKey = itemFieldValue?.includes(".") ?? false

    console.log(`loadDropdownFromApi: BEGIN ${fieldName}`)
    let finUrl = item.dataFrom.api

    // console.log(fieldName, inputValue, item)
    /// get_params
    finUrl = getParamsApiUrlAttr(finUrl, item)

    if (
      (!dynamicTempState.data[fieldName] && !tempAsyncOptLoading[fieldName]) ||
      item.dataFrom?.api_getAlways ||
      finUrl ||
      dynamicTempState.apiPar?.[fieldName] !== finUrl // jika afterChangeFillOther value berbeda dengan input lain atau ada perubahan di input lain yang relevan
    ) {
      console.log(
        "loadDropdownFromApi: DATA NOT EXIST ON STORE OR REFRESH PAGE"
      )
      setLoadingInputs(fieldName, true)
      const baseUrlOpt = item?.dataFrom?.baseUrl

      // check jika ada afterChangeFillOtherRequestParams dari field lain yang merujuk ke field ini
      // finUrl = afterChangeFillOtherRequestParams(item, inputValue, finUrl)
      let data = await getBaseUrl(baseUrlOpt, finUrl)
        .get(finUrl)
        .then((res) => {
          return renderDropdownItem(item, res)
        })
        .catch((error) => {
          console.log("error", error)
        })

      if (data) {
        const isExist = tempAsyncOptValues[fieldName]?.some((obj) => {
          return data.find((o) => o.id === obj.value)
        })

        let mixedDatas = data
        if (isExist) {
          mixedDatas = [...(tempAsyncOptValues[fieldName] ?? []), ...data]
        }

        // console.log(mixedDatas)
        const uniqueArray = mixedDatas
          .reduce((acc, current) => {
            const existingIndex = acc.findIndex((item) =>
              isValueDeepKey
                ? getDepthValue(item, itemFieldValue) ===
                  getDepthValue(current, itemFieldValue)
                : item[itemFieldValue] === current[itemFieldValue]
            )
            if (existingIndex === -1) {
              // If the current object's id is not found in the accumulator array, add it
              return [...acc, current]
            } else {
              // If the current object's id is found in the accumulator array, compare the number of keys
              const existingKeys = Object.keys(acc[existingIndex])
              const currentKeys = Object.keys(current)
              if (existingKeys.length < currentKeys.length) {
                // If the current object has fewer keys, replace the existing object with the current object
                acc[existingIndex] = current
              }
              // Otherwise, keep the existing object
              return acc
            }
          }, [])
          .filter(function (element) {
            return element?.value !== undefined
          })

        // console.log(uniqueArray)
        tempAsyncOptValues[fieldName] = uniqueArray
        setTempAsyncOptValues(tempAsyncOptValues)

        dispatch(setDataCache({ [fieldName]: uniqueArray }))
        dispatch(
          setLastApiPar({
            [fieldName]: finUrl
          })
        )

        // DEFAULT CHANGE TRIGGER ON ADD
        const checkValue = data.find((c) => c.value === inputValue)
      }
      setLoadingInputs(fieldName, false)
    }
  }

  const storeGlobalFilter = useSelector((state) => state.global_filter)
  const getData = useCallback(
    () => {
      // console.log("here")
      if (!store.pageData[storeName]?.isLoading) {
        setLoading(true)
        setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

        const params = {
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          ...filtersCustomAdd
          // ...dynamicTempState.parent_data_relation
        }
        // console.log(filtersCustomAdd)

        // if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
        //   pageAttr?.crudOpt?.data?.dynamicColumnsFilter.map((item) => {
        //     console.log(item?.fieldName)
        //     console.log(dynamicTempState.data)
        //   })
        // }

        if (pageAttr?.crudOpt?.serverSideGrid) {
          if (!params.sort_dir) {
            params.sort_dir = sortDir
          }

          if (!params.sort) {
            params.sort = sort
          }

          params.search = searchTerm
          params.page = currentPage
          params.length = rowsPerPage
        }

        pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.map((item) => {
          if (item?.type === "global_filter_year") {
            params[item?.fieldName ?? "data_year"] = storeGlobalFilter.data_year
          }
        })

        // console.log("getData Table")
        dispatch(getDataList(params))
          .then((resp) => {
            // console.log(resp?.payload?.data)
            getTableProperties(resp?.payload?.data)
          })
          .catch((error) => {
            console.log("Failed to fetch data", error)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [
      // pageAttr,
      // storeName,
      // sortDir,
      // sort,
      // searchTerm,
      // currentPage,
      // rowsPerPage,
      // dispatch
    ]
  )

  const getTableProperties = (storeData) => {
    // console.log("getTableProperties")
    // if (pageAttr?.crudOpt?.serverSideGrid) {
    //   setServerSideProps({
    //     sortServer: true,
    //     paginationServer: true,
    //     paginationTotalRows: store.pageData[storeName]?.total,
    //     paginationComponent: CustomPagination
    //   })
    // }

    if (
      pageAttr?.crudOpt?.data?.expandableRows ||
      pageAttr?.crudOpt?.gridTheme === "table_tree" ||
      pageAttr?.crudOpt?.dynamicColumnsGroupByKey
    ) {
      setExpandableRowsProps({
        expandableRows: true,
        expandOnRowClicked: true,
        expandableRowsComponent:
          pageAttr?.crudOpt?.gridTheme !== "table_tree"
            ? ExpandableContents
            : ExpandableContentsTree,
        expandableInheritConditionalStyles: true
      })
    }

    if (pageAttr?.crudOpt?.addSelection && selectedRows) {
      // console.log(selectedRows)
      setSelectedRowsSelected({
        selectableRowSelected: (row) =>
          row?.id && row.id !== 0 && selectedRows
            ? selectedRows.some((item) => item.id === row.id)
            : false
      })
    }

    if (pageAttr?.crudOpt?.data?.dynamicColumns) {
      const inputOnGridCopy = { ...inputOnGrid }
      const dataStore = storeData ?? store.pageData[storeName]?.data

      // console.log(dataStore)
      if (dataStore && Array.isArray(dataStore)) {
        dataStore?.forEach((data) => {
          pageAttr?.crudOpt?.data?.dynamicColumns
            ?.filter((item) => item.editable)
            ?.forEach((it) => {
              const prevColumnData = inputOnGridCopy[it.fieldName] || []
              const existingColumnData = prevColumnData.find(
                (obj) => obj.id === data?.id
              )
              if (existingColumnData) {
                if (existingColumnData.value !== data[it.fieldName]) {
                  const updatedColumnData = {
                    ...existingColumnData,
                    value: data[it.fieldName]
                  }
                  const updatedColumnIndex = prevColumnData.findIndex(
                    (obj) => obj.id === data?.id
                  )
                  inputOnGridCopy[it.fieldName][updatedColumnIndex] =
                    updatedColumnData
                }
              } else {
                inputOnGridCopy[it.fieldName] = [
                  ...(prevColumnData ?? []),
                  { id: data?.id, value: data[it.fieldName] }
                ]
              }
            })
        })
      }

      setInputOnGrid(inputOnGridCopy)
      setLoading(false)
      // console.log(inputOnGrid)
    }

    if (
      pageAttr?.crudOpt?.data?.dynamicColumns?.find(
        (item) => item?.isFixedColumn === true
      )
    ) {
      setFixCoumnZIndex()
    }

    // let expandableRowsAttr = pageAttr?.crudOpt?.data?.expandableRows
    // if (expandableRowsAttr) {
    //   console.log(
    //     expandableRowsAttr?.some((item) => item?.type === "relationsCrud")
    //   )
    // }

    // if (pageAttr?.crudOpt?.data?.dynamicColumns) {
    //   pageAttr?.crudOpt?.data?.dynamicColumns
    //     ?.filter((item) => item?.editable)
    //     ?.map((it) => {
    //       if (store.pageData[storeName]?.data) {
    //         store.pageData[storeName]?.data?.map((data) => {
    //           setInputOnGrid((prevInputOnGrid) => ({
    //             ...prevInputOnGrid,
    //             [it.fieldName]: (prevInputOnGrid[it.fieldName] || [])
    //               .map((obj) =>
    //                 obj.id === data?.id
    //                   ? { ...obj, value: data[it.fieldName] }
    //                   : obj
    //               )
    //               .concat(
    //                 prevInputOnGrid[it.fieldName] &&
    //                   prevInputOnGrid[it.fieldName].some(
    //                     (obj) => obj.id === data?.id
    //                   )
    //                   ? []
    //                   : [{ id: data?.id, value: data[it.fieldName] }]
    //               )
    //           }))
    //         })
    //       }
    //     })

    //   // console.log(inputOnGrid)
    // }
  }

  useEffect(
    () => {
      // console.log(storeName)
      // console.log(store.pageData[storeName]?.data)
      let isLoadState = isContainsDynamicColumnsFilterRequired
      // console.log(isLoadState)
      if (!isLoadState) {
        if (
          (!store.pageData[storeName]?.data ||
            pageAttr?.crudOpt?.getAlwaysGrid ||
            searchParams.get("refresh") === "1" ||
            !store.pageData[storeName]?.status) &&
          !store.pageData[storeName]?.isLoading &&
          // store.pageData[storeName]?.status !== "failed" &&
          // !store.pageData[storeName]?.status &&
          pageAttr?.crudOpt?.endpointName &&
          !pageAttr?.isFromAddPage
        ) {
          // console.log("here")
          getData()
        } else {
          if (relPageId || pageAttr?.isViewOnForm) {
            // const lastParentData = storeParent?.selectedData

            // ini untuk reload state pada relationscrud yang ada di form, melihat selectedData form terakhir dibandingkan dengan initialFiltersCondition
            const lastParentData =
              store.pageData[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
                ?.selectedData
            let curPageLastParam = store.pageData[storeName]?.params
            // console.log(lastParentData, curPageLastParam)
            let curPageAttrPageFilArray =
              pageAttr?.crudOpt?.initialFiltersCondition
            let reloadState = false

            if (curPageAttrPageFilArray) {
              const curPageAttrPageFilField =
                curPageAttrPageFilArray[0]?.fieldNameCurrent

              if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
                curPageAttrPageFilArray?.map((childPar) => {
                  // console.log(
                  //   `${curPageLastParam[curPageAttrPageFilField]}`,
                  //   `${lastParentData[childPar?.value_from_parentFieldName]}`
                  // )
                  if (!childPar?.disablePageQueryParam) {
                    reloadState =
                      reloadState ||
                      `${curPageLastParam[curPageAttrPageFilField]}` !==
                        `${
                          lastParentData[childPar?.value_from_parentFieldName]
                        }`
                  }
                })
              } else {
                reloadState =
                  `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
              }
            }

            if (reloadState) {
              if (
                !store.pageData[storeName]?.isLoading &&
                // store.pageData[storeName]?.status !== "failed" &&
                // !store.pageData[storeName]?.status &&
                !pageAttr?.isFromAddPage
              ) {
                getData()
              }
            }
          }

          getTableProperties(store.pageData[storeName]?.data)
          setLoading(false)
        }
      } else {
        getTableProperties(store.pageData[storeName]?.data)
      }
    },
    [
      // dispatch,
      // // getData,
      // pageAttr,
      // storeName,
      // sortDir,
      // sort,
      // currentPage,
      // rowsPerPage
    ]
  )

  useEffect(() => {
    if (isDisplayContextMenu) {
      const tableElement = tableRefDiv.current
      if (!tableElement) return

      // const checkScroll = () => {
      //   if (tableElement) {
      //     console.log(tableElement.scrollWidth - 14, tableElement.clientWidth)
      //     const scrollCheck =
      //       tableElement.scrollWidth - 14 > tableElement.clientWidth
      //     setTableHasHorizontalScroll(scrollCheck)
      //   }
      // }
      // checkScroll()

      // const cells = tableElement.querySelectorAll(".rdt_TableRow")
      // console.log(cells)
      // // const handleContextMenu = (event, row) => {
      // //   event.preventDefault() // Prevent default context menu
      // //   console.log("Context menu should be opened here", row)
      // //   // Set state or perform actions specific to the row
      // // }

      // // const handleCloseContextMenu = () => {
      // //   console.log("Context menu should be closed")
      // //   // Close context menu logic
      // // }

      // cells.forEach((cell) => {
      //   if (cell.querySelector(".datatable-custom-cell-container")) {
      //     cell.oncontextmenu = (event) => handleContextMenu(event, cell)
      //     cell.onclick = handleCloseContextMenu
      //   }
      // })

      const handleScroll = () => {
        handleCloseContextMenu()
      }

      // window.addEventListener("resize", checkScroll)
      document.addEventListener("mousedown", handleClickOutside)
      window.addEventListener("scroll", handleScroll)

      return () => {
        // window.removeEventListener("resize", checkScroll)
        window.removeEventListener("scroll", handleScroll)
        document.removeEventListener("mousedown", handleClickOutside)
        // cells.forEach((cell) => {
        //   cell.oncontextmenu = null
        //   cell.onclick = null
        // })
      }
    }
  }, [])

  // useEffect(() => {
  //   // console.log(tableRefDiv.current)
  //   if (
  //     tableRefDiv.current &&
  //     pageAttr?.crudOpt?.data?.dynamicColumns?.some(
  //       (item) => item?.searchableInput === true
  //     )
  //   ) {
  //     const inputOnHeads = tableRefDiv.current.querySelectorAll(".rdt_TableCol")
  //     console.log(inputOnHeads)
  //   }
  // }, [isLoadedTableDom])

  const conditionalRowStyles = [
    {
      when: (row) => rowHighlight?.includes(row.id),
      style: {
        backgroundColor: "aliceblue"
      },
      classNames: ["map-marker-selected-row"]
    },
    {
      when: (row) =>
        isDisplayContextMenu && contextMenu && contextMenu.row.id === row.id,
      style: {
        backgroundColor: "aliceblue"
      },
      classNames: ["datatable-context-menu-row-style"]
    },
    {
      when: (row) => !row.id || row?.id === 0,
      style: {
        backgroundColor: pageAttr?.crudOpt?.customTableStyles?.subTotalRow
          ?.style?.backgroundColor
          ? pageAttr?.crudOpt?.customTableStyles?.subTotalRow?.style
              ?.backgroundColor
          : "#f3f2f7",
        // backgroundColor: "#f3f2f7",
        minHeight: 0,
        height: "38px",
        ...pageAttr?.crudOpt?.customTableStyles?.subTotalRow?.style
      },
      classNames: ["subtotalRow"]
    },
    {
      when: (row) => store?.pageData[storeName]?.expandedData?.includes(row.id),
      style: {
        fontWeight: "bold"
      },
      classNames: ["rdt_TableHeadRowExpanded"]
    },
    {
      when: () => pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse,
      style: !pageAttr?.crudOpt?.customTableStyles?.expandedHeader
        ? {
            fontWeight: "bold"
          }
        : { ...pageAttr?.crudOpt?.customTableStyles?.expandedHeader }
      // classNames: [
      //   !pageAttr?.crudOpt?.customTableStyles?.expandedHeader
      //     ? "rdt_TableHeadRowExpanded_disabled_collapse"
      //     : ""
      // ]
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: t(""),
    rangeSeparatorText: t("of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(pageAttr?.crudOpt?.pageTitle ?? "All")
  }

  let customHeaderSetOnlyTableHeader =
    pageAttr?.isViewOnForm &&
    pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.length > 0 &&
    !pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.every(
      (item) => item.isHidden === true
    ) &&
    pageAttr?.crudOpt?.dynamicColumnsFilterPos === "table-header"
      ? filtersComponents(true)
      : null

  // const { show: showRightClickMenu } = useContextMenu({
  //   id: "pop"
  // })

  // const ContextMenuEl = () => {
  //   return (
  //     <Menu id="pop" animation={animation.slide}>
  //       <Item onClick={() => toast.success("Clicked Option 1")}>Option 1</Item>
  //       <Item onClick={() => toast.success("Clicked Option 2")}>Option 2</Item>
  //     </Menu>
  //   )
  // }

  return (
    <Fragment>
      <UILoader
        // blocking={true}
        blocking={
          loadingDelete || store?.pageData?.[storeName]?.isLoadingDetail
          // store?.pageData?.[storeName]?.isLoading
        }
        loader={<Loader loadingText={loadingText} onTable={true} />}
      >
        <Card
          style={pageAttr?.onDashboard ? { boxShadow: "none" } : {}}
          className={`overflow-hidden`}
        >
          <div
            ref={tableRefDiv}
            onClick={handleCloseContextMenu}
            className={
              pageAttr?.crudOpt?.customTableStyles?.headCells?.style?.color
                ? ""
                : `react-dataTable ${
                    pageAttr?.crudOpt?.dynamicColumnsGroupByKey ||
                    pageAttr?.crudOpt?.dynamicColumnsGroupByLabel
                      ? " react-dataTable-groupby"
                      : ""
                  } ${
                    pageAttr?.crudOpt?.data?.dynamicColumns?.find(
                      (item) => item?.isFixedColumn === true
                    )
                      ? " react-dataTable-fixed-column"
                      : ""
                  } ${
                    pageAttr?.crudOpt?.deleteSelection
                      ? " react-dataTable-delete-selection"
                      : ""
                  }  ${
                    pageAttr?.isSeletTable ? " react-dataTable-selectTable" : ""
                  } ${
                    pageAttr?.crudOpt?.fixedHeader
                      ? " react-dataTable-fixedHeader"
                      : ""
                  }  ${
                    pageAttr?.crudOpt?.deleteSelection ||
                    isContainsExtraButtonDataFromGridSelection
                      ? " react-dataTable-selectable-rows "
                      : ""
                  } ${
                    pageAttr?.crudOpt?.gridTheme === "map" &&
                    pageAttr?.crudOpt?.map_display_table &&
                    (pageAttr?.crudOpt?.map_display_table_position === "left" ||
                      pageAttr?.crudOpt?.map_display_table_position === "right")
                      ? " react-dataTable-map-side"
                      : ""
                  } ${
                    isContainsSearchableInput
                      ? " react-dataTable-searchableInput"
                      : ""
                  }`
            }
          >
            <DataTable
              ref={tableRef}
              // onRowContextMenu={(event, row) => handleContextMenu(event, row)}
              key={`datatable_${storeName}`}
              keyField={pageAttr?.crudOpt?.data_idField ?? "id"}
              noHeader
              noTableHead={pageAttr?.crudOpt?.hideTableHeader}
              // subHeader={!pageAttr?.crudOpt?.btnAddTextPosition}
              subHeader={
                !pageAttr?.crudOpt?.btnAddTextPosition &&
                !pageAttr?.crudOpt?.hideTableSubHeader
                // !pageAttr?.crudOpt?.hideTableSearchInput &&
                // !pageAttr?.crudOpt?.hideTablePageLengthInfo &&
                // !pageAttr?.crudOpt?.dynamicColumnsFilterPos
              }
              responsive
              ////////////PAGINATION START
              pagination={
                (!pageAttr?.crudOpt?.hideTablePagination &&
                  !pageAttr?.crudOpt?.dynamicColumnsGroupByKey) ??
                true
              }
              // {...serverSideProps}
              sortServer={pageAttr?.crudOpt?.serverSideGrid}
              paginationServer={pageAttr?.crudOpt?.serverSideGrid}
              paginationTotalRows={
                pageAttr?.crudOpt?.serverSideGrid
                  ? store.pageData[storeName]?.total
                  : store.pageData[storeName]?.filteredData?.length ??
                    store.pageData[storeName]?.data?.length
              }
              // {...(pageAttr?.crudOpt?.serverSideGrid
              //   ? {
              //       paginationTotalRows: store.pageData[storeName]?.total
              //     }
              //   : {})}
              paginationDefaultPage={currentPage}
              paginationPerPage={rowsPerPage}
              paginationComponent={
                pageAttr?.crudOpt?.importButton ||
                !pageAttr?.crudOpt?.serverSideGrid
                  ? null
                  : CustomPagination
              }
              onChangePage={(page, totalRows) => {
                // console.log(page, totalRows)
                if (!pageAttr?.crudOpt?.serverSideGrid) {
                  return handlePagination(page)
                }
              }}
              // paginationComponentOptions={
              //   pageAttr?.crudOpt?.serverSideGrid
              //     ? null
              //     : paginationComponentOptions
              // }
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={
                !pageAttr?.crudOpt?.serverSideGrid
                  ? [5, 10, 25, 50, 100, 300]
                  : [5, 10, 25, 50, 100]
              }
              defaultSortFieldId={sort}
              defaultSortAsc={sortDir === "asc"}
              onSort={handleSort}
              // {...(!pageAttr?.crudOpt?.serverSideGrid
              //   ? {
              //       onChangeRowsPerPage: (currentRowsPerPage, currentPage) => {
              //         handlePerPage2(currentRowsPerPage, currentPage)
              //       }
              //     }
              //   : {})}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                if (!pageAttr?.crudOpt?.serverSideGrid) {
                  return handlePerPage(currentRowsPerPage)
                }
              }}
              ////////////PAGINATION END

              fixedHeader={
                pageAttr?.crudOpt?.fixedHeader ??
                pageAttr?.crudOpt?.tableFixedHeader ??
                false
              }
              fixedHeaderScrollHeight={
                pageAttr?.crudOpt?.fixedHeaderScrollHeight ??
                pageAttr?.crudOpt?.tableFixedHeaderScrollHeight
              }
              highlightOnHover={
                pageAttr?.crudOpt?.tableHighlightOnHover ?? true
              }
              pointerOnHover={
                pageAttr?.crudOpt?.tablePointerOnHover ||
                pageAttr?.crudOpt?.addSelection ||
                (pageAttr?.crudOpt?.map_display_table &&
                  (pageAttr?.crudOpt?.map_display_table_position === "left" ||
                    pageAttr?.crudOpt?.map_display_table_position === "right"))
              }
              dense={
                pageAttr?.crudOpt?.tableDense ||
                pageAttr?.crudOpt?.addSelection ||
                (pageAttr?.crudOpt?.map_display_table &&
                  (pageAttr?.crudOpt?.map_display_table_position === "left" ||
                    pageAttr?.crudOpt?.map_display_table_position === "right"))
              }
              selectableRows={
                (pageAttr?.crudOpt?.deleteSelection &&
                  ability.can(
                    "delete",
                    sessionStorage.getItem("current_page_perm")
                  )) ||
                pageAttr?.crudOpt?.addSelection ||
                pageAttr?.crudOpt?.updateSelection ||
                isContainsExtraButtonDataFromGridSelection
              }
              selectableRowDisabled={(row) =>
                row?.children ||
                !row.id ||
                row?.id === 0 ||
                ((pageAttr?.crudOpt?.addSelection ||
                pageAttr?.crudOpt?.updateSelection
                  ? false
                  : // disableBtnDeleteCondition(pageAttr?.crudOpt, row)
                    disableBtnCondition(
                      pageAttr?.crudOpt,
                      row,
                      "delete",
                      storeUnit?.data,
                      pageType ?? useParams()?.pageType,
                      pageAttr
                    )) &&
                  !isContainsExtraButtonDataFromGridSelection) ||
                loading ||
                store.pageData[storeName]?.isLoading
              }
              // selectableRowSelected={(row) =>
              //   row?.id && row.id !== 0 && selectedRows
              //     ? selectedRows.some((item) => item.id === row.id)
              //     : false
              // }
              // {...(pageAttr?.isDropdownTable && selectedRows?.length > 0
              //   ? selectedRowsSelected
              //   : {})}
              selectableRowsHighlight
              onSelectedRowsChange={
                pageAttr?.isDropdownTable
                  ? handleRowSingleSelected
                  : handleRowSelected
              }
              selectableRowsNoSelectAll={pageAttr?.isDropdownTable}
              selectableRowsSingle={pageAttr?.isDropdownTable}
              selectableRowsComponent={BootstrapCheckbox}
              clearSelectedRows={toggleCleared}
              noDataComponent={
                store.pageData[storeName]?.status === "failed" ? (
                  <ErrorFetchData
                    message={store.pageData[storeName]?.error ?? ""}
                  />
                ) : (
                  // !store.pageAttributes[storeName]?.crudOpt?.importButton ? (
                  //   <UILoader
                  //     // blocking={true}
                  //     blocking={
                  //       (!store.pageData[storeName]?.status ||
                  //         store.pageData[storeName]?.status === "loading") &&
                  //       (!isContainsDynamicColumnsFilterRequired ||
                  //         (pageAttr?.onDashboard &&
                  //           store.pageData[storeName]?.status === "loading"))
                  //     }
                  //     loader={<Loader dataName={pageAttr?.pageTitle} />}
                  //   >
                  //     <NoDataDisplay
                  //       noImage={pageAttr?.isViewOnForm || pageAttr?.isViewOnRow}
                  //       dataName={pageAttr?.pageTitle}
                  //       isFromAddPage={pageAttr?.isFromAddPage}
                  //       pageAttr={pageAttr}
                  //     />
                  //   </UILoader>
                  // ) : (
                  <NoDataDisplay
                    noImage={pageAttr?.isViewOnForm || pageAttr?.isViewOnRow}
                    dataName={pageAttr?.pageTitle}
                    isFromAddPage={pageAttr?.isFromAddPage}
                    pageAttr={pageAttr}
                  />
                )
                // )
              }
              // progressPending={true}
              progressPending={
                (!store.pageData[storeName]?.status ||
                  store.pageData[storeName]?.status === "loading") &&
                (!isContainsDynamicColumnsFilterRequired ||
                  (pageAttr?.onDashboard &&
                    store.pageData[storeName]?.status === "loading")) &&
                !initialData &&
                !pageAttr.crudOpt?.importButton
              }
              progressComponent={
                <div className="loader-all-table">
                  <Loader dataName={pageAttr?.pageTitle} onTable={true} />
                </div>
              }
              // progressComponent={
              //   <TableSkeletonLoadingAll pageAttr={pageAttr} />
              // }
              customStyles={{
                ...(pageAttr?.crudOpt?.customTableStyles ?? {}),
                ...(pageAttr?.crudOpt?.dynamicColumnsFilterPos ===
                "table-header"
                  ? {
                      table: {
                        style: {
                          minHeight: "45vh"
                        }
                      }
                    }
                  : {})
              }}
              // expandableRows
              // groupByKey={(row) => `${row?.priceListCategory_name}`}
              // groupLabel={(group) => {
              //   return `${group._groupKey} (${group.groupData.length} items)`
              // }}
              // groupValues={(data) => {
              //   return {
              //     count: data.length
              //   }
              // }}
              // eslint-disable-next-line no-unused-expressions
              // onRowClicked={(row, event) => {
              //   // eslint-disable-next-line no-unused-expressions
              //   pageAttr?.crudOpt?.addSelection ? handleRowSelected(row) : null
              // }}
              {...(pageAttr?.crudOpt?.map_display_table &&
              (pageAttr?.crudOpt?.map_display_table_position === "left" ||
                pageAttr?.crudOpt?.map_display_table_position === "right")
                ? {
                    onRowClicked: (row, event) => {
                      handleRowClickMapType(row)
                    }
                  }
                : {})}
              {...(pageAttr?.isDropdownTable
                ? {
                    onRowClicked: (row, event) => {
                      handleRowSingleSelected(row, true)
                    }
                  }
                : {})}
              conditionalRowStyles={conditionalRowStyles}
              ////////////EXPANDABLE START
              {...expandableRowsProps}
              onRowExpandToggled={(bool, row) =>
                onRowExpandToggledEvent(
                  bool,
                  row,
                  store?.pageData[storeName]?.expandedData ?? []
                )
              }
              expandableRowExpanded={(row) =>
                store?.pageData[storeName]?.expandedData?.includes(row?.id) ||
                pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse
              }
              expandableRowDisabled={(row) =>
                !row.id ||
                row?.id === 0 ||
                pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse ||
                loading ||
                store.pageData[storeName]?.isLoading ||
                (pageAttr?.crudOpt?.dynamicColumnsGroupByKey &&
                  searchTerm?.length)
              }
              expandableRowsHideExpander={
                pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse ||
                (pageAttr?.crudOpt?.dynamicColumnsGroupByKey &&
                  searchTerm?.length)
              }
              expandableRowsComponentProps={{
                storeData: store?.pageData[storeName]
              }}
              ////////////EXPANDABLE END

              footerBold
              columns={tableColumns}
              sortIcon={
                <ChevronDown
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: isContainsSearchableInput ? "8%" : "28%"
                  }}
                  size={10}
                />
              }
              className={
                pageAttr?.crudOpt?.customTableStyles?.headCells?.style?.color
                  ? pageAttr?.crudOpt?.gridTheme === "table_tree"
                    ? "react-dataTable datatable-table-tree"
                    : `react-dataTable-widget ${
                        pageAttr?.crudOpt?.customTableStyles ? "has-style" : ""
                      }`
                  : "react-dataTable"
              }
              // persistTableHead={Object.keys(searchTerms)?.length > 0}
              // persistTableHead
              data={
                (searchTerm.length || Object.keys(searchTerms)?.length > 0) &&
                !pageAttr?.crudOpt?.serverSideGrid
                  ? store.pageData[storeName]?.filteredData ?? filteredData
                  : pageAttr?.crudOpt?.dynamicColumnsFilterToCurrentData &&
                    store.pageData[storeName]?.filteredData
                  ? dataToRender() //store.pageData[storeName]?.filteredData
                  : dataToRender()
              }
              subHeaderComponent={
                <CustomHeader
                  pageAttr={pageAttr}
                  searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  handleAddEdit={handleAddEdit}
                  selectedRow={selectedRows}
                  handleDeleteSelected={handleDeleteSelected}
                  handleFiltersChange={handleFiltersChange}
                  filtersCustomAdd={filtersCustomAdd}
                  storeName={storeName}
                  ref={ref}
                  expandAllRows={expandAllRows}
                  customHeaderSet={
                    customHeaderSet ?? customHeaderSetOnlyTableHeader
                  }
                  headerMode={headerMode}
                />
              }
            />
          </div>
        </Card>

        {/* <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} /> */}
      </UILoader>

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </Fragment>
  )
})

export default List
