export const BUILD_DATE = "20240708-0115"
export const CHANGES_LOG = `- 2024-07-08 01:15:25 | force sklin light in login, fix some style in table & loading, input wizard still on progress, form loading column & file_image, fixed timeline row clicked twice, remove jersey font add arsenal, chat full width on mobile, etc.
- 2024-07-06 01:08:38 | remove ping-server, set lastLogout remember url, qod widget fixed with interval, announcements widget with delay, user activity widget with interval
- 2024-07-05 03:47:26 | chat test, chat not allowed send when user in oher app,
- 2024-07-05 00:26:27 | exclusive btn print loading when multiple action btn print, form loading when type is column, chat with emoji character support, add more images on greeting card, fixed some style
- 2024-07-04 02:03:42 | Update move_build.yml
- 2024-07-04 01:39:42 | expandable : fixed disableBtnAddFromParent & hide expander on groupByKey if search, homepage: add welcome widget if qod not present, chat enhancement, fixed some style
- 2024-07-03 02:56:39 | fixed some bugs on chats
- 2024-07-02 14:16:35 | Update GlobalUnitDropdownForm.js
- 2024-07-02 01:08:23 | expandable table inherit style from parent, pagination on groupBy (expandables) table
- 2024-07-01 20:11:42 | add bottom nav mobile, add some mobile style, icon with solid attr on second separator
- 2024-07-01 17:13:56 | check resultArr when disabledCondition_or
- 2024-07-01 16:37:18 | remove unit loader
- 2024-07-01 15:38:07 | fixed disabledCondition_or condition
- 2024-07-01 14:57:33 | reset on dev Url Replacer, nav hover only child, detail loading minHeight, hide JSON button on table if ciew on dashbioard, unit dropdown loading init, style on login mobile view
- 2024-07-01 03:13:37 | timeout clear on app init, Feature: Url Replacer on Unit App (by Domain),  fixed issues data loading twice,
- 2024-06-29 18:50:56 | add some translate & Fixed URL Endpoint Replacer & Fixed save btn on JSON dashboardWidget & +100 quotes
- 2024-06-29 13:27:01 | add some translate
- 2024-06-28 23:17:59 | Feature : App Dashboard Widget PUT only Developer
- 2024-06-28 15:44:47 | URL Endpoint Replacer remove condition
- 2024-06-28 15:26:06 | add value to qrcode print, New Feature for Dev: URL Endpoint Replacer`
