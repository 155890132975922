// ** React Imports
import { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/store"

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability"
import { AbilityContext } from "./utility/context/Can"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster } from "react-hot-toast"

// ** i18n
import "./configs/i18n"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/components/prism-jsx.min"
import "prismjs/themes/prism-tomorrow.css"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

// import { getDomainInfo } from "@store/domainInfo"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))
const container = document.getElementById("root")
const root = createRoot(container)

// store.dispatch(getDomainInfo({})).then((res) => {
//   // console.log(res)
//   const primaryColorBe =
//     res.payload?.unit?.unit_app_attributes?.layout?.primaryColor ??
//     res.payload?.app?.setting?.primaryColor
//   if (primaryColorBe) {
//     const rootSelect = document.querySelector(":root")
//     const elementSelect = rootSelect.style
//     // console.log(elementSelect)
//     elementSelect.setProperty("--bs-primary-rgb", `${primaryColorBe}`)
//     elementSelect.setProperty("--primary-color", `${primaryColorBe}`)

//     const navLinks = document.querySelectorAll(".nav-link")
//     console.log(navLinks)
//     navLinks.forEach((element) => {
//       console.log(element)
//       element.style.color = `${primaryColorBe}`
//     })
//   }
// })

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />

            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
