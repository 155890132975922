/* eslint-disable multiline-ternary */
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { getDepthValue, restrictedMenuLink, getUserData } from "@utils"
import "moment/locale/id"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import DynamicForm from "@src/utility/generateForm/dynamicForm"
import DynamicColumnsDev from "./dynamicColumnsDev"
import { mainProperties as fieldTypeValue } from "./mainProps"
const ModalAddEditDev = ({
  root,
  headers,
  fieldArray,
  setFieldArray,
  show,
  setShow,
  storeName,
  pageAttr,
  submitHandle
}) => {
  const { t } = useTranslation()
  const userData = getUserData()
  const [changedDynamicColumns, setChangedDynamicColumns] = useState(null)

  const genFieldParts = (fieldArrays) => {
    let result = []
    fieldArrays.map((field) => {
      const realFieldFirst = field?.includes("=") ? field.split("=")[0] : field

      const realField = realFieldFirst?.includes(".")
        ? realFieldFirst.split(".")[realFieldFirst.split(".")?.length - 1]
        : realFieldFirst
      // console.log(realField, getDepthValue(fieldTypeValue, realFieldFirst))
      let formObj = {
        fieldName: realField,
        ...(getDepthValue(fieldTypeValue, realFieldFirst) ??
          fieldTypeValue["default"])
      }
      // console.log(formObj)
      result.push(formObj)
    })
    return result
  }

  const genFieldPartsValue = (fieldArrays) => {
    let result = {}
    const endpointNameExist = fieldArrays.some((item) =>
      item.includes("endpointName")
    )

    fieldArrays.map((field) => {
      // result[field] = t(getDepthValue(pageAttr, field) ?? "")
      const realFieldFirst = field?.includes("=") ? field.split("=")[0] : field
      const realField = realFieldFirst?.includes(".")
        ? realFieldFirst.split(".")[realFieldFirst.split(".")?.length - 1]
        : realFieldFirst

      const value = field?.includes("=")
        ? (() => {
            const fieldValue = field.split("=")[1]
            const parsedValue = parseInt(fieldValue)
            console.log(parsedValue, fieldValue, typeof fieldValue)
            // Check if parsedValue is NaN or boolean string
            if (
              isNaN(parsedValue) ||
              (typeof fieldValue === "string" &&
                ["true", "false"].includes(fieldValue.toLowerCase()))
            ) {
              return Boolean(fieldValue) // Return the original value
            }

            return parsedValue
          })()
        : getDepthValue(pageAttr, realFieldFirst)
      // console.log(value, field, realFieldFirst, realField)
      if (realField === "gridTheme" && !value) {
        result[realField] = "table"
      } else if (
        realField === "sourceDataFrom" &&
        !value &&
        endpointNameExist
      ) {
        result[realField] = 1
      } else if (
        realField === "resp_key" &&
        getDepthValue(pageAttr, "crudOpt.sourceDataFrom") === 2 &&
        !getDepthValue(pageAttr, "crudOpt.resp_key") &&
        getDepthValue(pageAttr, "crudOpt.resp_key") !== "" &&
        endpointNameExist
      ) {
        result[realField] = "data"
      } else if (
        realField === "removeDefaultHttpHeader" &&
        !value &&
        endpointNameExist
      ) {
        result[realField] = true
      } else {
        result[realField] = value
      }
    })
    return result
  }

  const genDynamicForm = () => {
    let fieldObj = genFieldParts(fieldArray)
    // console.log(fieldObj)
    return fieldObj
  }

  const genDefaultFormValues = () => {
    let fieldValue = genFieldPartsValue(fieldArray)
    // console.log(fieldValue)
    return fieldValue
  }

  const handleFormSubmit = (data) => {
    // console.log(data)
    let newData = data
    if (root !== "dynamicColumns") {
      if (newData.endpointName && !newData.sourceDataFrom) {
        newData["sourceDataFrom"] = 1
      }
      if (newData.sourceDataFrom === 1) {
        delete newData["removeDefaultHttpHeader"]
        delete newData["data_idField"]
        delete newData["resp_key"]
      } else if (newData.sourceDataFrom === 4) {
        delete newData["removeDefaultHttpHeader"]
        delete newData["resp_key"]
        delete newData["endpointName"]

        if (!pageAttr?.crudOpt?.["data_idField"]) {
          delete newData["data_idField"]
        }
        if (!pageAttr?.crudOpt?.["fixedHeader"]) {
          newData["fixedHeader"] = true
          newData["fixedHeaderScrollHeight"] = "55vh"
        }
        if (!pageAttr?.crudOpt?.["hideTablePageLengthInfo"]) {
          newData["hideTablePageLengthInfo"] = false
        }

        newData["importButton"] = {
          fileType: "csv"
        }
      }

      if (!newData.detailFromGrid && !pageAttr?.crudOpt?.detailFromGrid) {
        newData["detailFromGrid"] = true
      }
      if (!newData.gridTheme && !pageAttr?.crudOpt?.gridTheme) {
        newData["gridTheme"] = "table"
      }
    }

    let pageAttrEdited = null
    if (root === "crudOpt") {
      newData = { [root]: { ...pageAttr?.crudOpt, ...newData } }
      pageAttrEdited = { ...pageAttr, ...newData }
    } else if (root === "dynamicColumns") {
      if (pageAttr?.crudOpt) {
        if (pageAttr?.crudOpt?.data) {
          newData = { ...pageAttr?.crudOpt?.data, [root]: [...newData] }
          pageAttrEdited = {
            ...pageAttr,
            crudOpt: { ...pageAttr?.crudOpt, ...{ data: { ...newData } } }
          }
        } else {
          newData = { ...pageAttr?.crudOpt, data: { [root]: [...newData] } }
          pageAttrEdited = {
            ...pageAttr,
            crudOpt: { ...pageAttr?.crudOpt, ...{ data: { ...newData } } }
          }
        }
      } else {
        newData = { [root]: [...newData] }
      }
    } else {
      pageAttrEdited = { ...pageAttr, ...newData }
    }
    // console.log(newData)

    // console.log(pageAttrEdited)
    if (pageAttrEdited) {
      submitHandle(pageAttrEdited)
    }
  }

  function handleReset() {
    setFieldArray([])
    setShow(false)
  }

  useEffect(() => {
    document.body.style.paddingRight = "0"
    return () => {
      document.body.style.paddingRight = ""
    }
  }, [])

  const [isEditedPropCurrent, setIsEditedPropCurrent] = useState(null)

  // console.log(
  //   restrictedMenuLink.includes(window.location.pathname) &&
  //     ![4, 6, 21].includes(userData.id)
  // )
  return (
    <Modal
      isOpen={show}
      scrollable={["dynamicColumns"].includes(root)}
      toggle={() => {
        handleReset()
      }}
      className={`modal-dialog-centered ${
        ["dynamicColumns"].includes(root) ? "modal-xl" : "modal-lg"
      }`}
    >
      {/* <UILoader
        blocking={loadingPostPut}
        overlayColor="rgba(115, 103, 240, .1)"
        loader={<Loader loadingText={loadingText} />}
      > */}
      <ModalHeader
        className="bg-transparent"
        toggle={() => {
          handleReset()
        }}
      ></ModalHeader>
      <ModalBody
        style={{
          minHeight: ["dynamicColumns"].includes(root) ? "70vh" : "auto"
        }}
        className="px-sm-2 pt-50 pb-5"
      >
        <div className="text-center mb-2">
          <h2 className="saba-detail-header mb-0">
            {t(headers?.title ?? "Edit Page")}
          </h2>
        </div>

        {!["dynamicColumns"].includes(root) ? (
          <DynamicForm
            dynamicForm={genDynamicForm()}
            pageAttr
            storeName
            handleReset={handleReset}
            handleFormSubmit={handleFormSubmit}
            defaultFormValues={genDefaultFormValues()}
            hideAllFotterButton={
              restrictedMenuLink.includes(window.location.pathname) &&
              ![4, 6, 21].includes(userData.id)
            }
            editForm={true}
          />
        ) : (
          <DynamicColumnsDev
            onChangedDynamicColumns={setChangedDynamicColumns}
            storeName={storeName}
            pageAttr={pageAttr}
            setIsEditedPropCurrentParent={setIsEditedPropCurrent}
          />
        )}
      </ModalBody>

      {["dynamicColumns"].includes(root) && (
        <ModalFooter>
          {((restrictedMenuLink.includes(window.location.pathname) &&
            [4, 6, 21].includes(userData.id)) ||
            !restrictedMenuLink.includes(window.location.pathname)) && (
            <Button
              color="primary"
              onClick={() => handleFormSubmit(changedDynamicColumns)}
              disabled={
                !changedDynamicColumns ||
                changedDynamicColumns?.length === 0 ||
                isEditedPropCurrent
              }
            >
              {t("Submit")}
            </Button>
          )}
          <Button color="flat-secondary" onClick={() => handleReset()}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      )}
      {/* </UILoader> */}
    </Modal>
  )
}

export default ModalAddEditDev
