// ** React Imports
import { Outlet } from "react-router-dom"
// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// ** Menu Items Array
import navigation from "@src/navigation/vertical"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const VerticalLayout = (props) => {
  const store = useSelector((state) => state.my_menu)

  const [menuData, setMenuData] = useState(navigation)

  // ** For ServerSide navigation
  useEffect(() => {
    if (store.dataMenu) {
      const mixedAllMenu = [...navigation, ...store.dataMenu]
      setMenuData(mixedAllMenu)
    }
  }, [store.dataMenu])

  return (
    <Layout menuData={menuData} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
