// ** COMMON
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import icons_store from "./icons"
import domain_claims from "./domainInfo"
import developer from "./developer"
import my_menu from "@src/views/apps/menu/store/my_menu"
import my_info_activities from "@src/views/my-info/store/my_activities"
import qod from "@src/views/my-info/store/qod"
import employees from "@src/views/my-info/store/employees"
import notifications from "./notifications"
import chat from "@src/views/chat/store"
//////INSABA ADMIN
// import users from "@src/views/apps/user/store"
// import permissions from "@src/views/apps/roles-permissions/store"
// import apps_app from "@src/views/apps/app/store"
import apps_menu from "@src/views/apps/menu/store"
// import apps_endpoint from "@src/views/apps/endpoint/store"
// import apps_permission from "@src/views/apps/permission/store"
// import apps_role from "@src/views/apps/role/store"
// import apps_role_permission from "@src/views/apps/role_permission/store"
// import apps_unit_app from "@src/views/apps/unit_app/store"
// import apps_permission_menu from "@src/views/apps/permission_menu/store"
// import apps_permission_endpoint from "@src/views/apps/permission_endpoint/store"

import units from "@src/views/apps/unit/store"
// import roles from "@src/views/apps/roles-permissions/store/role"

import apps_userActivity from "@src/views/apps/user/store/userActivity"

//////INSABA INVOICES
import invoices from "@src/views/invoices/store"

import dynamicStore from "@src/shared/dynamic_pages/store"
import calendar from "@src/shared/dynamic_pages/calendar/store"

//////DYNAMIC
import dynamic_temp_state from "./dynamicTempState"

//////GLOBAL
import global_filter from "./globalFilter"

const rootReducer = {
  auth,
  navbar,
  layout,
  domain_claims,
  icons_store,
  developer,
  my_menu,
  my_info_activities,
  qod,
  employees,
  notifications,
  chat,

  //////DYNAMIC
  dynamic_temp_state,
  //////GLOBAL
  global_filter,

  //////INSABA ADMIN
  units,
  // users,
  // permissions,
  // roles,
  // apps_app,
  apps_menu,
  // apps_endpoint,
  // apps_permission,
  // apps_role,
  // apps_role_permission,
  // apps_unit_app,
  // apps_permission_menu,
  // apps_permission_endpoint,
  apps_userActivity,

  dynamicStore,
  calendar,

  //////INSABA INVOICES
  invoices
}

export default rootReducer
