import { useEffect, useState } from "react"
import { Calendar, Database } from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap"

import {
  isUserLoggedIn,
  isLoginFilter,
  isGlobalFilter,
  genDropdownDataYear,
  getDomainInfo,
  getUserData
} from "@utils"
import { getGlobalFilterYear, setGlobalFilterYear } from "@store/globalFilter"

const DataYearBadge = () => {
  const storeGlobalFilter = useSelector((state) => state.global_filter)
  const dispatch = useDispatch()
  const [userData, setUserData] = useState(null)
  const dataYearOpt = genDropdownDataYear()
  const userDatas = getUserData()

  const storeDomain = useSelector((state) => state.domain_claims)

  // const isLoginFilter = () => {
  //   return (
  //     storeDomain?.data?.unit?.unit_app_attributes?.setting
  //       ?.login_filter_dataYear &&
  //     storeDomain?.data?.app?.setting?.login_filter_dataYear
  //   )
  // }

  // const isGlobalFilter = () => {
  //   return (
  //     !isLoginFilter() &&
  //     (storeDomain?.data?.unit?.unit_app_attributes?.setting
  //       ?.global_filter_dataYear ||
  //       storeDomain?.data?.app?.setting?.global_filter_dataYear)
  //   )
  // }

  const changeYear = (e, yearVal) => {
    e.preventDefault()
    dispatch(setGlobalFilterYear(yearVal)).then(() => {
      window.location.reload()
    })
  }

  useEffect(() => {
    if (!storeDomain?.isLoading) {
      if (isGlobalFilter()) {
        dispatch(getGlobalFilterYear())
      }
    }
  }, [storeDomain])

  return (
    !storeDomain?.isLoading &&
    (userDatas?.data_year ? (
      <NavItem className="d-lg-flex d-none">
        <NavLink className="nav-link-style">
          <Badge color={"light-secondary"}>
            <Database className="align-middle me-25" />
            <span className="align-middle">{userDatas.data_year}</span>
          </Badge>
        </NavLink>
      </NavItem>
    ) : isGlobalFilter() && storeGlobalFilter.data_year ? (
      <UncontrolledDropdown
        href="/"
        tag="li"
        className="dropdown-language nav-item"
      >
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link"
          onClick={(e) => e.preventDefault()}
        >
          <Badge xs={12} color="light-secondary">
            <Database className="align-middle me-25" />
            <span className="selected-language align-middle">
              {storeGlobalFilter.data_year ?? ""}
            </span>
          </Badge>
        </DropdownToggle>

        <DropdownMenu className="mt-0" end>
          {dataYearOpt.map((item) => (
            <DropdownItem
              key={item.value}
              href="/"
              tag="a"
              onClick={(e) => changeYear(e, item.value)}
            >
              <Database size={14} className="country-flag mb-0 mt-0" svg />
              <span className="ms-1 me-1">{item.label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null)
  )
}

export default DataYearBadge
