import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
const axiosSso = useJwt.ssoAxiosInterceptor

export const getDataList = createAsyncThunk(
  `qod/getDataList`,
  async ({ rejectWithValue }) => {
    try {
      const currentTime = new Date().getTime()
      const lastFetchQod = localStorage.getItem("qod_time")
      let quotesResult = {}

      const tenMinutes = 10 * 60 * 1000

      // if (!lastFetchQod || currentTime - lastFetchQod > tenMinutes) {
      const url = "/api/app/qod"

      try {
        const response = await axiosSso.get(url)
        const data = response.data
        quotesResult = data?.data
        // console.log(quotesResult)
        if (!quotesResult) {
          throw new Error(`No quotes data`)
        }
      } catch (error) {
        console.error("Error fetching the API:", error)
      }

      if (quotesResult) {
        localStorage.setItem("qod", JSON.stringify(quotesResult))
        localStorage.setItem("qod_time", currentTime.toString())
      } else {
        const lastQod = localStorage.getItem("qod")
        if (lastQod) {
          const lastQodObj = JSON.parse(lastQod)
          quotesResult = lastQodObj
        }
      }
      // }

      return quotesResult
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
)

// export const getDataList = createAsyncThunk(`qod/getDataList`, async () => {
//   try {
//     // const endpoint = "https://quotes.rest/qod.json"
//     const endpoint = "https://api.quotable.io/random"
//     const date = new Date()
//     date.setUTCHours(0, 0, 0, 0)
//     date.setDate(date.getDate() + 1)
//     let now = date.toISOString().split("T")[0]
//     let data = []
//     const lastFetchStr = localStorage.getItem("qod_time")
//     if (
//       lastFetchStr !== null &&
//       endpoint !== "https://api.quotable.io/random"
//     ) {
//       if (lastFetchStr === now) {
//         data = await JSON.parse(localStorage.getItem("qod"))
//       } else {
//         const response = await fetch(endpoint)
//         data = await response.json()

//         if (data?.content ?? data?.contents?.quotes?.length > 0) {
//           localStorage.setItem("qod", JSON.stringify(data))
//           localStorage.setItem("qod_time", now)
//         }
//       }
//     } else {
//       const response = await fetch(endpoint)
//       data = await response.json()
//       if (data?.content ?? data?.contents?.quotes?.length > 0) {
//         localStorage.setItem("qod", JSON.stringify(data))
//         localStorage.setItem("qod_time", now)
//       }
//     }
//     return {
//       data: data
//     }
//   } catch (error) {
//     console.log(error)
//     return rejectWithValue(error)
//   }
// })

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: "qod",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///ALL
      .addCase(getDataList.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.status = "succeeded"
        state.isLoading = false
        state.data = { ...action.payload }
      })
      .addCase(getDataList.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })
  }
})

export default StoreSlice.reducer
