// ** Icons Import
import { Home, Circle } from "react-feather"

export default [
  {
    id: "homepage",
    title: "Home",
    icon: <Home size={20} />,
    badge: "light-warning",
    navLink: "/dashboard",
    action: "read",
    resource: "dashboard"
  }
]
