// ** Dropdowns Imports
import IntlDropdown from "./IntlDropdown"
// import CartDropdown from "./CartDropdown"
import UserDropdown from "./UserDropdown"
import NavbarSearch from "./NavbarSearch"
import NotificationDropdown from "./NotificationDropdown"
import DataYearInfo from "./DataYearInfo"
import MemberOfDropdown from "./MemberOfDropdown"
import SuperOrDevBadge from "./SuperOrDevBadge"
import DataYearBadge from "./DataYearBadge"
import LayoutButton from "./LayoutButton"

// ** Third Party Components
import { Sun, Moon } from "react-feather"

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap"

const NavbarUser = (props) => {
  // ** Props
  const { skin, setSkin } = props

  const getSkinSetting = () => {
    const item = window.localStorage.getItem("skinDef")
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : "light"
  }

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      // return <Sun className="ficon" onClick={() => setSkin(getSkinSetting())} />
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      <SuperOrDevBadge />
      <DataYearBadge />
      <MemberOfDropdown />
      {/* <DataYearInfo /> */}
      <IntlDropdown />
      <LayoutButton />
      <NavItem className="">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem>
      <NavbarSearch />
      {/* <CartDropdown /> */}
      <NotificationDropdown />
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
