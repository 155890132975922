import {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  forwardRef
} from "react"

import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom"

// ** Third Party Components
import classnames from "classnames"
import { Row, Col, Card, Input, Button } from "reactstrap"

// ** Calendar App Component Imports
import Calendar from "./Calendar"
import SidebarLeft from "./SidebarLeft"
import AddEventSidebar from "./AddEventSidebar"

// ** Custom Hooks
import { useRTL } from "@hooks/useRTL"

// ** Styles
import "@styles/react/apps/app-calendar.scss"
// ** Store & Actions
import { useSelector, useDispatch } from "react-redux"
import {
  fetchEvents,
  selectEvent,
  updateEvent,
  updateFilter,
  updateAllFilters,
  addEvent,
  removeEvent
} from "./store"

import { useTranslation } from "react-i18next"
import { AbilityContext } from "@src/utility/context/Can"
import ModalAddEdit from "../modal"
import { neutralizeBack, revivalBack } from "@src/utility/Utils"
import GlobalActionsTable from "@src/shared/GlobaActionsTable"
import {
  getDataList,
  getDataById,
  setSelectedData,
  resetSelectedData,
  putData,
  setInitialDataAdd,
  deleteData,
  deleteSelectedRowData
} from "../store"

import debounce from "lodash.debounce"
import { ChevronDown } from "react-feather"
import {
  NoDataDisplay,
  Loader,
  TableSkeletonLoading
} from "@src/shared/CommonComponents"
import UILoader from "@components/ui-loader"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"

import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import DynamicRow from "@src/utility/generateForm/dynamicRow"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

// ** CalendarColors
const calendarsColor = {
  Business: "primary",
  Holiday: "success",
  Personal: "danger",
  Family: "warning",
  ETC: "info"
}

const CalendarComponent = ({
  pageAttr,
  storeName,
  filtersCustomAdd,
  handleFiltersChangeToParent
}) => {
  // ** Variables
  const dispatch = useDispatch()
  const storeCalendar = useSelector((state) => state.calendar)

  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()
  const store = useSelector((state) => state.dynamicStore)
  const storeParent = useSelector(
    (state) => state?.[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  )

  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [toggleCleared, setToggleCleared] = useState(false)
  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})
  const [modalOpen, setModalOpen] = useState(false)

  // ** states
  const [calendarApi, setCalendarApi] = useState(null)
  const [addSidebarOpen, setAddSidebarOpen] = useState(false)
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false)

  // ** Hooks
  const [isRtl] = useRTL()

  // ** AddEventSidebar Toggle Function
  const handleAddEventSidebar = () => setAddSidebarOpen(!addSidebarOpen)

  // ** LeftSidebar Toggle Function
  const toggleSidebar = (val) => setLeftSidebarOpen(val)
  const { colors } = useContext(ThemeColors)

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const [loadingPostPut, setLoadingPostPut] = useState(false)

  const updateDataToApi = (rowData, changedData) => {
    const data = { ...changedData }
    setLoadingText(`${t("Updating")} ${t(pageAttr.pageTitle)} Data`)
    setLoadingPostPut(true)
    dispatch(setSelectedData({ rowData, storeName: storeName })).then((rz) => {
      data["storeName"] = storeName
      data["endpointName"] = pageAttr?.crudOpt?.endpointName
      data["callback_page"] = window.location.href
      data["baseUrl"] = pageAttr?.crudOpt?.baseUrl
      dispatch(putData(data))
        .then((res) => {
          // console.log(res)
          setLoadingPostPut(false)
          if (res.meta.requestStatus === "fulfilled") {
            toast.success(
              `${t("Successfully Updated")} ${t(pageAttr.pageTitle)}`,
              {
                style: {
                  padding: "16px",
                  color: colors.success.main,
                  border: `1px solid ${colors.success.main}`
                },
                iconTheme: {
                  primary: colors.success.main
                }
              }
            )
          } else if (res.meta.requestStatus === "rejected") {
            errorToast(res)
          }
        })
        .catch((error) => {
          setLoadingPostPut(false)
          // console.error(error)
          errorToast(error)
        })
    })
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoadingModal(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoadingModal(false)
          setShowModal(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoadingModal(false)
          setShowModal(false)
          toast.error(res.payload?.message ?? t("Failed to delete data"), {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  // ** Blank Event Object
  const blankEvent = {
    title: "",
    start: "",
    end: "",
    allDay: false,
    url: "",
    extendedProps: {
      calendar: "",
      guests: [],
      location: "",
      description: ""
    }
  }

  // ** refetchEvents
  const refetchEvents = () => {
    if (calendarApi !== null) {
      calendarApi.refetchEvents()
      // console.log(calendarApi)
    }
  }

  const handleAddEdit = (rowData, initialDataAdd = null) => {
    const pageRedirect =
      pageAttr?.crudOpt?.rowClickEvent === "view" ? "view" : "edit"
    // console.log(rowData)
    if (rowData) {
      setPageType(pageRedirect)
      ///if Modal
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        setLoadingText(
          `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
        )
        setLoadingModal(true)
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // setLoading(false)
          setShowModal(true)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          ).then((res) => {
            setLoadingModal(false)
          })
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              setLoadingModal(false)
              setShowModal(true)
            }
          )
        }
      } else {
        if (!pageAttr?.crudOpt?.detailFromGrid) {
          // navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
          dispatch(
            getDataById({
              id: rowData.id,
              storeName: storeName,
              endpointName: pageAttr?.crudOpt?.endpointName
            })
          )
        } else {
          dispatch(setSelectedData({ rowData, storeName: storeName })).then(
            () => {
              navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
            }
          )
        }
      }
    } else {
      setPageType("add")
      if (!pageAttr?.crudOpt?.detailPage || relPageId) {
        dispatch(resetSelectedData(storeName)).then((r) => {
          if (initialDataAdd) {
            dispatch(
              setInitialDataAdd({ data: initialDataAdd, storeName })
            ).then((re) => {
              // console.log(re)
              setLoadingModal(false)
              setLoading(false)
              setShowModal(true)
            })
          }
        })
      } else {
        dispatch(resetSelectedData(storeName)).then((res) => {
          // console.log(initialDataAdd)
          if (initialDataAdd) {
            let str = []
            for (let p in initialDataAdd) {
              if (initialDataAdd.hasOwnProperty(p)) {
                str.push(
                  `${encodeURIComponent(p)}=${encodeURIComponent(
                    initialDataAdd[p]
                  )}`
                )
              }
            }
            const query = str.join("&")
            navigate(`${location.pathname}/add?${query}`)
            // dispatch(setInitialDataAdd({ data: initialDataAdd, storeName }))
          } else {
            navigate(`${location.pathname}/add`)
          }
        })
      }
    }
  }

  const handleCloseModal = () => {
    setModalOpen(!modalOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleModal = () => {
    // console.log("here")
    setPageType("add")
    setModalOpen(!modalOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  // ** Fetch Events On Mount
  useEffect(() => {
    // dispatch(fetchEvents(storeCalendar.selectedCalendars))
    if (
      (!store.pageData[storeName]?.data ||
        pageAttr?.crudOpt?.getAlwaysGrid ||
        searchParams.get("refresh") === "1" ||
        !store.pageData[storeName]?.status) &&
      !store.pageData[storeName]?.isLoading &&
      pageAttr?.crudOpt?.endpointName &&
      !pageAttr?.isFromAddPage
    ) {
      // const lastParentData = storeParent?.selectedData
      // let curPageLastParam = store.pageData[storeName]?.params
      // let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
      // let reloadState = false

      // if (curPageAttrPageFilArray) {
      //   if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
      //     curPageAttrPageFilArray?.map((childPar) => {
      //       reloadState =
      //         reloadState ||
      //         `${curPageLastParam[curPageAttrPageFilField]}` !==
      //           `${lastParentData[childPar?.value_from_parentFieldName]}`
      //     })
      //   } else {
      //     const curPageAttrPageFilField =
      //       curPageAttrPageFilArray[0]?.fieldNameCurrent
      //     reloadState =
      //       `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
      //   }
      // }

      // const lastParentData =
      //   store.pageData[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
      //     ?.selectedData
      // let curPageLastParam = store.pageData[storeName]?.params
      // // console.log(lastParentData, curPageLastParam)
      // let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
      // let reloadState = false

      // if (curPageAttrPageFilArray) {
      //   const curPageAttrPageFilField =
      //     curPageAttrPageFilArray[0]?.fieldNameCurrent

      //   if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
      //     curPageAttrPageFilArray?.map((childPar) => {
      //       if (!childPar?.disablePageQueryParam) {
      //         reloadState =
      //           reloadState ||
      //           `${curPageLastParam[curPageAttrPageFilField]}` !==
      //             `${lastParentData[childPar?.value_from_parentFieldName]}`
      //       }
      //     })
      //   } else {
      //     reloadState =
      //       `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
      //   }
      // }

      // console.log(reloadState)

      // if (reloadState) {
      if (!store.pageData[storeName]?.isLoading) {
        setLoading(true)
        setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)
        dispatch(
          getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            ...filtersCustom
          })
        ).then((x) => {
          setLoading(false)
        })
      }
    }

    // } else {
    //   setLoading(false)
    // }
  }, [dispatch, pageAttr, storeName])

  return (
    <Fragment>
      <div className="app-calendar overflow-hidden border">
        <Row className="g-0">
          <Col
            id="app-calendar-sidebar"
            className={classnames(
              "col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",
              {
                show: leftSidebarOpen
              }
            )}
          >
            <SidebarLeft
              storeCalendar={storeCalendar}
              pageAttr={pageAttr}
              storeName={storeName}
              handleFiltersChangeToParent={handleFiltersChangeToParent}
              dispatch={dispatch}
              updateFilter={updateFilter}
              toggleSidebar={toggleSidebar}
              updateAllFilters={updateAllFilters}
              handleAddEventSidebar={handleAddEventSidebar}
              handleAddEdit={handleAddEdit}
            />
          </Col>
          <Col className="position-relative">
            {!store.pageData[storeName]?.isLoading && !loadingPostPut ? (
              <Calendar
                isRtl={isRtl}
                store={store}
                storeCalendar={storeCalendar}
                dispatch={dispatch}
                blankEvent={blankEvent}
                calendarApi={calendarApi}
                selectEvent={selectEvent}
                updateEvent={updateEvent}
                toggleSidebar={toggleSidebar}
                calendarsColor={calendarsColor}
                setCalendarApi={setCalendarApi}
                handleAddEventSidebar={handleAddEventSidebar}
                pageAttr={pageAttr}
                storeName={storeName}
                handleAddEdit={handleAddEdit}
                updateDataToApi={updateDataToApi}
              />
            ) : (
              <UILoader
                blocking={true}
                loader={<Loader loadingText={loadingText} />}
              ></UILoader>
            )}
          </Col>
          <div
            className={classnames("body-content-overlay", {
              show: leftSidebarOpen === true
            })}
            onClick={() => toggleSidebar(false)}
          ></div>
        </Row>
      </div>
      <AddEventSidebar
        store={storeCalendar}
        dispatch={dispatch}
        addEvent={addEvent}
        open={addSidebarOpen}
        selectEvent={selectEvent}
        updateEvent={updateEvent}
        removeEvent={removeEvent}
        calendarApi={calendarApi}
        refetchEvents={refetchEvents}
        calendarsColor={calendarsColor}
        handleAddEventSidebar={handleAddEventSidebar}
      />

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleModal}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
          showDeleteButton={true}
          handleDelete={handleDelete}
        />
      )}
    </Fragment>
  )
}

export default CalendarComponent
