/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
import GlobalActionsTable from "@src/shared/GlobaActionsTable"
import { neutralizeBack, revivalBack } from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getDataById,
  getDataList,
  resetSelectedData,
  setSelectedData
} from "./store"

import Timeline from "@components/timeline"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import Avatar from "@components/avatar"
import { Skeleton } from "@mui/material"
import { badgeDecrement } from "@src/redux/notifications"
import { NoDataDisplay } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import { getDepthValue, getRequiredFilterState, getUserData } from "@utils"
import toast from "react-hot-toast"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Lock, PlusCircle, Trash } from "react-feather"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const TimeLine = ({
  pageAttr,
  storeName,
  filtersCustomAdd,
  lookupMode,
  lookupMode_fieldName,
  lookupMode_defaultValue,
  lookupMode_onChange,
  lookupMode_isMulti
}) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)
  const badgeDecrements = badgeDecrement
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const paramss = useParams()

  const disableBtnEditCondition = (crudOpt, row = null, pageAttr) => {
    let result = crudOpt?.disabledEdit ?? false
    if (crudOpt?.disabledEditCondition) {
      crudOpt?.disabledEditCondition?.map((item) => {
        let field = item?.field
        let operator = item?.operator
        let value = item?.value

        let currentRowValue = null
        let evalStr = null

        if (field?.includes("__userData")) {
          const userData = getUserData()
          if (field === "__userData.name__") {
            currentRowValue = userData?.name
          } else if (field === "__userData.isManager__") {
            currentRowValue = userData?.isManager
          } else if (field === "__userData.email__") {
            currentRowValue = userData?.email
          } else if (field === "__userData.id__") {
            currentRowValue = userData?.id
          } else if (field === "__userData.username__") {
            currentRowValue = userData?.username
          }

          evalStr = `'${currentRowValue}' ${operator} ${value}`
          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (crudOpt?.disabledDeleteCondition_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        } else {
          currentRowValue = field?.includes(".")
            ? getDepthValue(row, field)
            : row?.[field]

          evalStr = `'${currentRowValue}' ${operator} ${value}`

          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (crudOpt?.enabledViewBtnCondition_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }
          // console.log(field, evalStr, result)
        }
      })
    }

    if (paramss?.pageType === "view") {
      result = true
    }

    // console.log(result)
    return result
  }

  const disableBtnDeleteCondition = (crudOpt, row = null, pageAttr) => {
    let result = crudOpt?.disabledDelete ?? false
    if (crudOpt?.disabledDeleteCondition) {
      crudOpt?.disabledDeleteCondition?.map((item) => {
        let field = item?.field
        let operator = item?.operator
        let value = item?.value

        let currentRowValue = null
        let evalStr = null

        if (field?.includes("__userData")) {
          const userData = getUserData()
          if (field === "__userData.name__") {
            currentRowValue = userData?.name
          } else if (field === "__userData.isManager__") {
            currentRowValue = userData?.isManager
          } else if (field === "__userData.email__") {
            currentRowValue = userData?.email
          } else if (field === "__userData.id__") {
            currentRowValue = userData?.id
          } else if (field === "__userData.username__") {
            currentRowValue = userData?.username
          }

          evalStr = `'${currentRowValue}' ${operator} ${value}`

          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          if (crudOpt?.disabledDeleteCondition_or === true) {
            result = result || eval(evalStr)
          } else {
            result = eval(evalStr)
          }
          // console.log(field, evalStr, result)
        } else {
          currentRowValue = field?.includes(".")
            ? getDepthValue(row, field)
            : row?.[field]

          evalStr = `'${currentRowValue}' ${operator} ${value}`
          if (
            [null, undefined, "", false].includes(currentRowValue) &&
            (value === "null" || value === "false")
          ) {
            evalStr = `${currentRowValue} ${operator} ${currentRowValue}`
          }

          try {
            if (crudOpt?.disabledDeleteCondition_or === true) {
              result = result || eval(evalStr)
            } else {
              result = eval(evalStr)
            }
          } catch (error) {
            result = false
          }

          // console.log(field, evalStr, result)
        }
      })
    }

    if (paramss?.pageType === "view") {
      result = true
    }

    return result
  }

  const handleAddEdit = (rowData) => {
    if (!store.pageData[storeName]?.isLoadingDetail && !rowClicked) {
      setRowClicked(true)
      // console.log(pageAttr?.crudOpt?.rowClickEval)
      if (pageAttr?.crudOpt?.rowClickEval) {
        eval(pageAttr?.crudOpt?.rowClickEval)
      }

      const pageRedirect =
        pageAttr?.crudOpt?.rowClickEvent === "view" ? "view" : "edit"
      if (rowData) {
        setPageType(pageRedirect)
        ///if Modal
        if (!pageAttr?.crudOpt?.detailPage || relPageId) {
          setLoadingText(
            `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(
              pageAttr.pageTitle
            )}`
          )
          setLoadingModal(true)
          if (!pageAttr?.crudOpt?.detailFromGrid) {
            // setLoading(false)
            setShowModal(true)
            dispatch(
              getDataById({
                id: rowData.id,
                storeName: storeName,
                endpointName: pageAttr?.crudOpt?.endpointName
              })
            ).then((res) => {
              setRowClicked(false)
              setLoadingModal(false)
            })
          } else {
            dispatch(setSelectedData({ rowData, storeName: storeName })).then(
              () => {
                setLoadingModal(false)
                setShowModal(true)

                setRowClicked(false)
              }
            )
          }
        } else {
          if (!pageAttr?.crudOpt?.detailFromGrid) {
            // console.log("getDataById")
            // navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
            dispatch(
              getDataById({
                id: rowData.id,
                storeName: storeName,
                endpointName: pageAttr?.crudOpt?.endpointName
              })
            ).then((res) => {
              setRowClicked(false)
              navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
            })
          } else {
            dispatch(setSelectedData({ rowData, storeName: storeName })).then(
              () => {
                setRowClicked(false)
                navigate(`${location.pathname}/${pageRedirect}/${rowData.id}`)
              }
            )
          }
        }
      } else {
        setPageType("add")
        if (!pageAttr?.crudOpt?.detailPage || relPageId) {
          dispatch(resetSelectedData(storeName)).then((r) => {
            setLoadingModal(false)
            setLoading(false)
            setShowModal(true)
            setRowClicked(false)
          })
        } else {
          setRowClicked(false)
          dispatch(resetSelectedData(storeName)).then((r) => {
            navigate(`${location.pathname}/add`)
          })
        }
      }
    }
  }

  // const clickRowEvent = (rowData) => {
  //   if (pageAttr?.crudOpt?.rowClickEvent === "view") {
  //     dispatch(setSelectedData({ rowData, storeName: storeName })).then(() => {
  //       navigate(`${location.pathname}/view/${rowData.id}`)
  //     })
  //   }
  //   console.log(rowData)
  //   console.log("click row")
  // }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    // console.log("here")
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res?.payload?.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const handleDeleteSelected = async () => {
    const countSelectedCopy = selectedRows.length
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You will delete")} ${countSelectedCopy} ${t(
        "selected data. Deleted data cannot be recovered"
      )}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${countSelectedCopy} ${t(
        pageAttr.pageTitle
      )}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(
        `${t("Deleting")} ${countSelectedCopy} Data ${t(pageAttr.pageTitle)}`
      )
      setLoading(true)
      const values = selectedRows.map((a) => a.id)
      // console.log(values)
      dispatch(
        deleteSelectedRowData({
          id: values,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setSelectedRows([])
          setToggleCleared(!toggleCleared)
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${countSelectedCopy} ${t(
              pageAttr.pageTitle
            )}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  /////PAGINATION SERVERSIDE
  //search
  const handleDebounceFn = (inputValue) => {
    setLoading(true)
    dispatch(
      getDataList({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        search: inputValue,
        sort,
        page: currentPage,
        length: rowsPerPage,
        ...filtersCustom
      })
    ).then((x) => {
      setLoading(false)
    })
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])
  const handleFilter = (event) => {
    setSearchTerm(event)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      debounceFn(event)
    } else {
      if (event.length && store.pageData[storeName]?.data) {
        let storeData = JSON.parse(
          JSON.stringify(store.pageData[storeName]?.data)
        )
        if (storeData) {
          const searchField =
            pageAttr?.crudOpt?.data?.dynamicColumnsSearchFields ?? []
          let filterData = []
          filterData = storeData.filter((item) => {
            for (let key in item) {
              if (item[key]) {
                if (searchField) {
                  if (
                    (key,
                    searchField.indexOf(key) > -1 &&
                      `${item[key]}`
                        ?.toLowerCase()
                        ?.includes(event.toLowerCase()))
                  ) {
                    return item
                  }
                } else {
                  if (item[key].toLowerCase().includes(event.toLowerCase())) {
                    return item
                  }
                }
              }
            }
          })
          setFilteredData(filterData)
        }
        setSearchTerm(event)
      }
    }
  }

  const handlePagination = (page) => {
    console.log(page)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      dispatch(
        getDataList({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          sort_dir,
          sort,
          search: searchTerm,
          length: rowsPerPage,
          page: page.selected + 1,
          ...filtersCustom
        })
      )
    }
    setCurrentPage(page.selected + 1)
  }

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      dispatch(
        getDataList({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          sort_dir,
          sort,
          search: searchTerm,
          length: value,
          page: currentPage,
          ...filtersCustom
        })
      )
    }
    setRowsPerPage(value)
  }

  const handleFiltersChange = (value, par_name) => {
    filtersCustom[par_name] = value
    dispatch(
      getDataList({
        storeName,
        endpointName: pageAttr?.crudOpt?.endpointName,
        sort_dir,
        sort,
        search: searchTerm,
        page: currentPage,
        length: rowsPerPage,
        ...filtersCustom
      })
    )
  }

  const handleSort = (column, sortDirection) => {
    setSortDir(sortDirection)
    setSort(column.sortField)
    if (pageAttr?.crudOpt?.serverSideGrid) {
      dispatch(
        getDataList({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName,
          sort_dir,
          sort,
          search: searchTerm,
          page: currentPage,
          length: rowsPerPage,
          ...filtersCustom
        })
      )
    }
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const [serverSideProps, setServerSideProps] = useState({})
  const storeParent = useSelector(
    (state) => state?.[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  )

  const getData = () => {
    setLoading(true)
    setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

    dispatch(
      pageAttr?.crudOpt?.serverSideGrid
        ? getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            sort_dir,
            sort,
            search: searchTerm,
            page: currentPage,
            length: rowsPerPage,
            ...filtersCustom
          })
        : getDataList({
            storeName,
            endpointName: pageAttr?.crudOpt?.endpointName,
            ...filtersCustom
          })
    ).then((x) => {
      setLoading(false)
      if (pageAttr?.crudOpt?.serverSideGrid) {
        setServerSideProps({
          sortServer: true,
          paginationServer: true,
          paginationTotalRows: store.pageData[storeName]?.total,
          paginationComponent: CustomPagination
          // paginationComponentOptions: paginationComponentOptions
        })
      }
    })
  }
  useEffect(() => {
    if (
      (!store.pageData[storeName]?.data || pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading
    ) {
      getData()
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            reloadState =
              `${curPageLastParam[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            getData()
          }
        }
      }
      setLoading(false)
    }
  }, [dispatch, sort_dir, sort, currentPage])

  const colorsClass = [
    "primary",
    "info",
    "success",
    "warning",
    "secondary",
    "danger"
  ]

  const RenderIcon = (rows) => {
    const row = rows.row
    const iconColor =
      row[pageAttr?.crudOpt?.timeLineColorField] || row?.color
        ? `text-${row[pageAttr?.crudOpt?.timeLineColorField] ?? row?.color}`
        : null

    const avatarImg =
      row[pageAttr?.crudOpt?.timeLineImgField] || row?.img
        ? row[pageAttr?.crudOpt?.timeLineImgField] ?? row?.img
        : null
    if (avatarImg) {
      return <Avatar color="light-info" img={avatarImg} />
    } else {
      if (row.icon && !row[pageAttr?.crudOpt?.timeLineIconField]) {
        // return <Lock size={14} />
        return renderIcon(row.icon, iconColor, 28)
      } else if (!row.icon && row[pageAttr?.crudOpt?.timeLineIconField]) {
        // return <PlusCircle size={14} />
        return renderIcon(
          row[pageAttr?.crudOpt?.timeLineIconField]?.includes(":")
            ? row[pageAttr?.crudOpt?.timeLineIconField]
            : "material:event_available",
          iconColor,
          28
        )
      } else if (!row.icon && !row[pageAttr?.crudOpt?.timeLineIconField]) {
        // return renderIcon("material:flight_takeoff_outlined", iconColor, 28)
        return renderIcon("material:event_available", iconColor, 28)
        // return null
      } else {
        return null
      }
    }
  }

  const genData = (data) => {
    const defaultDateAttributes = {
      fieldName: "created_date",
      dataFormat: "datetime:"
    }

    let timeLineDateFieldCol = pageAttr?.crudOpt?.data?.dynamicColumns?.filter(
      (column) => {
        return column.fieldName === pageAttr?.crudOpt?.timeLineDateField
      }
    )?.[0]
    if (!timeLineDateFieldCol) {
      timeLineDateFieldCol = defaultDateAttributes
    }

    return [
      ...data.map((row) => ({
        // console.log(row)
        data: row,
        title: (
          <span className="fw-bolder">{`${
            row[pageAttr?.crudOpt?.timeLineTitleField] ?? row?.title
          }`}</span>
        ),
        ...(pageAttr?.crudOpt?.timeLineDescField &&
        row[pageAttr?.crudOpt?.timeLineDescField] !== null
          ? {
              content: pageAttr?.crudOpt?.timeLineDescField
                ? `${row[pageAttr?.crudOpt?.timeLineDescField]}`
                : null
            }
          : null),
        meta: (
          <span className="fw-bolder">
            {<DynamicRow row={row} column={timeLineDateFieldCol} />}
          </span>
        ),
        ...(pageAttr?.crudOpt?.timeLineIconField || row?.icon
          ? {
              icon: <RenderIcon row={row} />
            }
          : null),

        color:
          row[pageAttr?.crudOpt?.timeLineColorField] || row?.color
            ? row[pageAttr?.crudOpt?.timeLineColorField] ?? row?.color
            : colorsClass[Math.floor(Math.random() * colorsClass.length)],
        customContent: (
          <div className="d-flex justify-content-between flex-wrap text-truncate">
            {pageAttr?.crudOpt?.data?.dynamicColumns &&
              pageAttr?.crudOpt?.data?.dynamicColumns?.map(
                (column) =>
                  column.fieldName !== pageAttr?.crudOpt?.timeLineDateField && (
                    <div
                      key={`rel_field_${column.columnName}`}
                      className="d-flex align-items-start text-truncate"
                      style={{ width: column?.width }}
                    >
                      {/* <Avatar img={interview} className="me-1" /> */}
                      <div>
                        <span className="mb-0 me-2 text-muted">
                          {t(column.columnName)}
                        </span>
                        <div className="">
                          <DynamicRow row={row} column={column} />
                        </div>
                      </div>
                    </div>
                  )
              )}

            <div className="d-flex flex-wrap align-items-center cursor-pointer mt-sm-0 mt-50">
              <GlobalActionsTable
                row={row}
                handleDelete={handleDelete}
                handleEdit={handleAddEdit}
                pageAttr={store.pageAttributes[storeName]}
                withText={true}
                outline={true}
                disabledAction={[
                  (!store.pageAttributes[storeName]?.crudOpt?.saveAsNewBtn &&
                    disableBtnEditCondition(
                      store.pageAttributes[storeName]?.crudOpt,
                      row,
                      store.pageAttributes[storeName]
                    )) ||
                  row[pageAttr?.crudOpt?.childrenField ?? "children"]?.length
                    ? "update"
                    : "",
                  disableBtnDeleteCondition(
                    store.pageAttributes[storeName]?.crudOpt,
                    row,
                    store.pageAttributes[storeName]
                  ) ||
                  row[pageAttr?.crudOpt?.childrenField ?? "children"]?.length
                    ? "delete"
                    : ""
                ]}
                rowClickEvent={
                  pageAttr?.crudOpt?.rowClickEvent &&
                  !pageAttr?.crudOpt?.hideViewBtn
                }
                storeName={storeName}
              />
            </div>
          </div>
        )
      }))
    ]
  }

  const genLoading = () => {
    return [
      ...[1, 2, 3, 4, 5].map((_) => ({
        title: (
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            width="45vw"
            height={25}
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
          />
        ),
        // content: (
        //   <Skeleton
        //     sx={{ bgcolor: colors.dark.light }}
        //     animation="wave"
        //     width="40vw"
        //     height={20}
        //     style={{
        //       border: "none",
        //       marginBottom: "5px",
        //       transform: "scale(1, 1)"
        //     }}
        //   />
        // ),
        meta: (
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            width="6vw"
            height={20}
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
          />
        ),
        color: colorsClass[Math.floor(Math.random() * colorsClass.length)],
        customContent: (
          <div className="d-flex justify-content-between flex-wrap">
            {pageAttr?.crudOpt?.data?.dynamicColumns &&
              pageAttr?.crudOpt?.data?.dynamicColumns?.map((column) => (
                <div
                  key={`rel_field_${column.columnName}`}
                  className="d-flex align-items-center"
                >
                  <div>
                    <span className="mb-0 me-2 text-muted">
                      <Skeleton
                        sx={{ bgcolor: colors.dark.light }}
                        animation="wave"
                        width="6vw"
                        height={20}
                        style={{
                          border: "none",
                          marginBottom: "1px",
                          transform: "scale(1, 1)"
                        }}
                      />
                    </span>
                    <div className="">
                      <Skeleton
                        sx={{ bgcolor: colors.dark.light }}
                        animation="wave"
                        width="6vw"
                        height={20}
                        style={{
                          border: "none",
                          marginBottom: "1px",
                          transform: "scale(1, 1)"
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            <div className="d-flex flex-wrap align-items-center cursor-pointer mt-sm-0 mt-50">
              <Fragment>
                <Skeleton
                  sx={{ bgcolor: colors.dark.light }}
                  animation="wave"
                  className="ms-2 me-1"
                  width={90}
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    transform: "scale(1, 1)"
                  }}
                  height={40}
                />
                <Skeleton
                  sx={{ bgcolor: colors.dark.light }}
                  animation="wave"
                  width={90}
                  height={40}
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    transform: "scale(1, 1)"
                  }}
                />
              </Fragment>
            </div>
          </div>
        )
      }))
    ]
  }

  return (
    <Fragment>
      <Row className="text-nowrap pb-0 w-100 my-75 g-0 permission-header">
        <Col xs={12} lg={4} className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
            {pageAttr?.crudOpt?.serverSideGrid && (
              <Fragment>
                <label htmlFor="rows-per-page">{t("Show")}</label>
                <Input
                  className="mx-50"
                  type="select"
                  id="rows-per-page"
                  value={rowsPerPage}
                  onChange={handlePerPage}
                  style={{ width: "5rem" }}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Input>
                <label htmlFor="rows-per-page">{t("Entries")}</label>
              </Fragment>
            )}
          </div>
        </Col>

        <Col xs={12} lg={8}>
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center flex-md-nowrap flex-wrap mt-lg-0 mt-1">
            {!pageAttr.crudOpt?.hideTableSearchInput &&
              getRequiredFilterState(
                pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
                store.pageData[storeName]?.params
              ) &&
              store.pageData[storeName]?.status !== "failed" && (
                <div className="d-flex align-items-center ms-50">
                  <label className="mb-0" htmlFor="search-invoice">
                    {t("Search")}:
                  </label>
                  <Input
                    id="search-invoice"
                    className="ms-50 w-100"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleFilter(e.target.value)}
                  />
                </div>
              )}

            {paramss?.pageType !== "view" &&
            !pageAttr?.crudOpt?.disabledAdd &&
            ability.can("post", sessionStorage.getItem("current_page_perm")) &&
            getRequiredFilterState(
              pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
              store.pageData[storeName]?.params
            ) &&
            !pageAttr?.crudOpt?.btnAddTextPosition ? (
              <Button
                className="add-permission mt-sm-0 mt-1"
                color="primary"
                // onClick={toggleSidebar}
                onClick={(e) => {
                  handleAddEdit(null)
                }}
              >
                {t("Add New")} {t(pageAttr.pageTitle)}
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
      <Card className="overflow-hidden">
        <CardBody>
          {store.pageData[storeName]?.data &&
          !store.pageData[storeName]?.isLoading ? (
            store.pageData[storeName]?.data?.length === 0 ? (
              <NoDataDisplay />
            ) : (
              <Timeline
                clickEvent={
                  pageAttr?.crudOpt?.rowClickEvent ? handleAddEdit : null
                }
                data={genData(
                  searchTerm.length && !pageAttr?.crudOpt?.serverSideGrid
                    ? filteredData
                    : store.pageData[storeName].data
                )}
              />
            )
          ) : (
            <Timeline data={genLoading()} />
          )}
        </CardBody>
      </Card>

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </Fragment>
  )
}

export default TimeLine
