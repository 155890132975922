// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"
const axiosSso = useJwt.ssoAxiosInterceptor

// export const getBookmarks = createAsyncThunk(
//   "layout/getBookmarks",
//   async () => {
//     const response = await axios.get("/api/bookmarks/data")
//     return {
//       data: response.data.suggestions,
//       bookmarks: response.data.bookmarks
//     }
//   }
// )

// {
//   id: 1,
//   target: "users",
//   isBookmarked: true,
//   title: "Users",
//   icon: "User",
//   link: "/apps/user/list"
// },

// action: (4) ['read', 'post', 'update', 'delete']
// description: "App Setting and Management"
// icon: "material:tune"
// id: 1
// index: 1
// isBookmarked: false
// is_header: false
// is_separator: true
// parent_id: null
// routePath: null
// title: "App Setting"

export const getBookmarks = createAsyncThunk(
  "layout/getBookmarks",
  async (id = null, { getState }) => {
    // console.log(getState()["my_menu"])
    const dataBookmark = await getState()
      ["my_menu"].dataMyMenu.filter(
        (item) =>
          !item.is_separator &&
          !item.is_header &&
          !item.is_hidden &&
          item.routePath
      )
      .map((itemx) => ({
        id: itemx.id,
        target: `bookmark_${itemx.id}`,
        isBookmarked: itemx.isBookmarked,
        title: itemx.title,
        icon: itemx.icon,
        link: itemx.routePath
      }))

    // console.log(dataBookmark)
    const suggestions = [...dataBookmark]
    const bookmarks = dataBookmark.filter((item) => item.isBookmarked)

    return {
      data: suggestions,
      bookmarks: bookmarks
    }
  }
)

export const updateBookmarked = createAsyncThunk(
  "layout/updateBookmarked",
  async (id, { rejectWithValue }) => {
    try {
      await axiosSso.post("/api/userBookmark", { id_menu: id })
      return id
    } catch (error) {
      console.error(error)
      return rejectWithValue(id)
    }
  }
)

export const getGlobalSearch = createAsyncThunk(
  "layout/getGlobalSearch",
  async (query, { getState }) => {
    console.log(query)
    const response = await axiosSso.get(`/api/app/global_search?query=${query}`)
    return {
      data: response?.data?.data
    }
  }
)

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    query: "",
    bookmarks: [],
    suggestions: [],
    postLoading: false,
    postLoadingId: null
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.pending, (state, action) => {
        state.postLoading = true
        state.postLoadingId = action?.meta?.arg
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(
          (x) => x.id === action.payload
        )

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }

        state.postLoading = false
        state.postLoadingId = null

        const userData = JSON.parse(localStorage.getItem("userData"))
        userData?.menu?.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
          }
        })
        localStorage.setItem("userData", JSON.stringify(userData))
        // console.log(storageMenu)
      })
      .addCase(updateBookmarked.rejected, (state, action) => {
        state.postLoading = false
        state.postLoadingId = null
      })
  }
})

export const { handleSearchQuery } = layoutSlice.actions

export default layoutSlice.reducer
