// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_SSO_URL}/login`,
  loginGEndpoint: `${process.env.REACT_APP_SSO_URL}/login/google`,
  registerEndpoint: `${process.env.REACT_APP_SSO_URL}/register`,
  registerOtpEndpoint: `${process.env.REACT_APP_SSO_URL}/register_otp`,
  refreshEndpoint: `${process.env.REACT_APP_SSO_URL}/refresh_token`,
  logoutEndpoint: `${process.env.REACT_APP_SSO_URL}/logout`,
  forgotEndpoint: `${process.env.REACT_APP_SSO_URL}/forgot_password`,
  resetEndpoint: `${process.env.REACT_APP_SSO_URL}/reset_password`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
