// ** Third Party Components
import PropTypes from "prop-types"
import classnames from "classnames"

// ** Reactstrap Imports
import { Card, CardBody } from "reactstrap"
import { Fragment } from "react"

const StatsHorizontal = ({
  icon,
  color,
  cardColor,
  stats,
  renderStats,
  statTitle,
  className,
  countTextColor,
  countTextStyle = {},
  titleStyle = {},
  statsMargin,
  solidColor = false,
  cardStyle = {},
  titleOnTop = false,
  index
}) => {
  const cardColorFix = !solidColor ? `bg-light-${cardColor}` : `bg-${cardColor}`
  return (
    <Card
      style={{ ...cardStyle }}
      {...(cardColor
        ? { className: `${cardColorFix} card-item`, inverse: true }
        : {})}
    >
      <CardBody className={className}>
        {!titleOnTop ? (
          <div className="d-flex justify-content-between align-items-center">
            {renderStats ? (
              renderStats
            ) : (
              <Fragment>
                <div>
                  {renderStats ? (
                    renderStats
                  ) : (
                    <h3
                      className={classnames("fw-bolder", {
                        "mb-0": !statsMargin,
                        [statsMargin]: statsMargin
                      })}
                      style={{
                        color: countTextColor ?? "#5e5873",
                        ...countTextStyle
                      }}
                    >
                      {stats}
                    </h3>
                  )}

                  <p style={{ ...titleStyle }} className="card-text">
                    {statTitle}
                  </p>
                </div>
                <div
                  className={`avatar avatar-stats p-50 m-0 ${
                    color ? `bg-light-${color}` : "bg-light-primary"
                  }`}
                >
                  <div
                    style={{
                      overflowClipMargin: "border-box"
                    }}
                    className={`avatar-content icon-animate-${index ?? 1}`}
                  >
                    {icon}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        ) : (
          <Fragment>
            <div>
              <p style={{ ...titleStyle }} className="card-text">
                {statTitle}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {renderStats ? (
                renderStats
              ) : (
                <Fragment>
                  <div>
                    {renderStats ? (
                      renderStats
                    ) : (
                      <h3
                        className={classnames("fw-bolder", {
                          "mb-0": !statsMargin,
                          [statsMargin]: statsMargin
                        })}
                        style={{
                          color: countTextColor ?? "#5e5873",
                          ...countTextStyle
                        }}
                      >
                        {stats}
                      </h3>
                    )}
                  </div>
                  <div
                    className={`avatar avatar-stats p-50 m-0 ${
                      color ? `bg-light-${color}` : "bg-light-primary"
                    }`}
                  >
                    <div
                      style={{
                        overflowClipMargin: "border-box"
                      }}
                      className={`avatar-content icon-animate-${index ?? 1}`}
                    >
                      {icon}
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </CardBody>
    </Card>
  )
}

export default StatsHorizontal

// ** PropTypes
StatsHorizontal.propTypes = {
  stats: PropTypes.string,
  renderStats: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.element,
  color: PropTypes.string,
  cardColor: PropTypes.string,
  statTitle: PropTypes.string,
  index: PropTypes.number,
  statsMargin: PropTypes.oneOf([
    "mb-0",
    "mb-25",
    "mb-50",
    "mb-75",
    "mb-1",
    "mb-2",
    "mb-3",
    "mb-4",
    "mb-5"
  ])
}
