// ** React Imports
import { Link } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"

// ** Third Party Components
import { Search, Star, ChevronDown, Menu } from "react-feather"
import classnames from "classnames"

// ** Custom Component
import Autocomplete from "@components/autocomplete"

// ** Reactstrap Imports
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  Spinner
} from "reactstrap"

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux"
import {
  getBookmarks,
  updateBookmarked,
  handleSearchQuery
} from "@store/navbar"
import { useTranslation } from "react-i18next"

import { renderIcon } from "@src/views/apps/menu/store/my_menu"

const NavbarBookmarks = (props) => {
  // ** Props
  const { setMenuVisibility } = props

  // ** State
  const [value, setValue] = useState("")
  const [openSearch, setOpenSearch] = useState(false)

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state.navbar)
  const storeMyMenu = useSelector((state) => state.my_menu)
  const { t } = useTranslation()

  // ** ComponentDidMount
  useEffect(() => {
    if (storeMyMenu?.dataMenu?.length > 0) {
      dispatch(getBookmarks())
    }
  }, [storeMyMenu.dataMenu])

  function capitalize(str) {
    if (str.length === 0) return str
    return str[0].toUpperCase() + str.substr(1)
  }

  function titleCase(str) {
    return str.split(" ").map(capitalize).join(" ")
  }

  const getIconTag = (iconx) => {
    const iconStr = iconx
    let iconStrType = "feather"
    let iconStrFix = iconStr
    if (iconStr?.includes(":")) {
      iconStrType = iconStr.split(":")[0]
      if (iconStrType.includes("material")) {
        iconStrFix = iconStr.split(":")[1]
      } else {
        iconStrFix = titleCase(iconStr.split(":")[1].replace("-", " ")).replace(
          " ",
          ""
        )
      }
    }

    const IconTag =
      typeof Icon[iconStrFix] === "object" ? Icon[iconStrFix] : Icon["User"]
    return { iconTag: IconTag, iconStrFix: iconStrFix }
  }

  // ** Loops through Bookmarks Array to return Bookmarks
  const renderBookmarks = () => {
    if (store.bookmarks.length) {
      return store.bookmarks
        .map((item) => {
          // const getTagAndName = getIconTag(item.icon)
          // const IconTag = getTagAndName.iconTag
          const itemLink = `/dyn_pages${item?.link}`
            .replaceAll("/dyn_pages/dyn_pages", "/dyn_pages")
            .replaceAll("/dyn_pagesnull", "/")
          // console.log(itemLink)

          return (
            <NavItem key={item.target} className="d-none d-lg-block">
              <NavLink tag={Link} to={itemLink} id={item.target}>
                {/* {item.icon.includes("material:") ? (
                  <MatIcon
                    className="ficon bookmark-icon23"
                    // style={{ "font-size": "23px" }}
                    fontSize="23px"
                    baseClassName="material-icons-outlined bookmark-icon23"
                  >
                    {getTagAndName.iconStrFix}
                  </MatIcon>
                ) : (
                  <IconTag className="ficon" />
                )} */}
                {renderIcon(item.icon, "ficon bookmark-icon23", 23)}
                <UncontrolledTooltip target={item.target}>
                  {t(item.title)}
                </UncontrolledTooltip>
              </NavLink>
            </NavItem>
          )
        })
        .slice(0, 10)
    } else {
      return null
    }
  }

  // ** If user has more than 10 bookmarks then add the extra Bookmarks to a dropdown
  const renderExtraBookmarksDropdown = () => {
    if (store.bookmarks.length && store.bookmarks.length >= 11) {
      return (
        <NavItem className="d-none d-lg-block">
          <NavLink tag="span">
            <UncontrolledDropdown>
              <DropdownToggle tag="span">
                <ChevronDown className="ficon" />
              </DropdownToggle>
              <DropdownMenu end>
                {store.bookmarks
                  .map((item) => {
                    return (
                      <DropdownItem tag={Link} to={item.link} key={item.id}>
                        {renderIcon(item.icon, "ficon bookmark-icon23", 23)}
                        <span className="align-middle">{t(item.title)}</span>
                      </DropdownItem>
                    )
                  })
                  .slice(10)}
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavLink>
        </NavItem>
      )
    } else {
      return null
    }
  }

  // ** Removes query in store
  const handleClearQueryInStore = () => dispatch(handleSearchQuery(""))

  // ** Loops through Bookmarks Array to return Bookmarks
  const onKeyDown = (e) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setTimeout(() => {
        setOpenSearch(false)
        handleClearQueryInStore()
      }, 1)
    }
  }

  // ** Function to toggle Bookmarks
  const handleBookmarkUpdate = (id) => dispatch(updateBookmarked(id))

  // ** Function to handle Bookmarks visibility
  const handleBookmarkVisibility = () => {
    setOpenSearch(!openSearch)
    setValue("")
    handleClearQueryInStore()
  }

  // ** Function to handle Input change
  const handleInputChange = (e) => {
    setValue(e.target.value)
    // console.log(e.target.value)
    dispatch(handleSearchQuery(e.target.value))
  }

  // ** Function to handle external Input click
  const handleExternalClick = () => {
    if (openSearch === true) {
      setOpenSearch(false)
      handleClearQueryInStore()
    }
  }

  // ** Function to clear input value
  const handleClearInput = (setUserInput) => {
    if (!openSearch) {
      setUserInput("")
      handleClearQueryInStore()
    }
  }

  return (
    <Fragment>
      <ul className="navbar-nav d-xl-none">
        <NavItem className="mobile-menu me-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={() => setMenuVisibility(true)}
          >
            <Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      <ul className="nav navbar-nav bookmark-icons">
        {renderBookmarks()}
        {renderExtraBookmarksDropdown()}
        <NavItem className="nav-item d-none d-lg-block">
          <NavLink className="bookmark-star" onClick={handleBookmarkVisibility}>
            <Star className="ficon text-warning" />
          </NavLink>
          <div
            className={classnames("bookmark-input search-input", {
              show: openSearch
            })}
          >
            <div className="bookmark-input-icon">
              <Search size={14} />
            </div>
            {openSearch && store.suggestions.length ? (
              <Autocomplete
                wrapperClass={classnames("search-list search-list-bookmark", {
                  show: openSearch
                })}
                className="form-control"
                suggestions={
                  !value.length
                    ? store.bookmarks.map((itemx) => ({
                        ...itemx,
                        title: t(itemx.title)
                      }))
                    : store.suggestions.map((itemx) => ({
                        ...itemx,
                        title: t(itemx.title)
                      }))
                }
                filterKey="title"
                autoFocus={true}
                defaultSuggestions
                suggestionLimit={!value.length ? store.bookmarks.length : 6}
                placeholder={`${t("Search Menu For Bookmark")}...`}
                externalClick={handleExternalClick}
                clearInput={(userInput, setUserInput) =>
                  handleClearInput(setUserInput)
                }
                onKeyDown={onKeyDown}
                value={value}
                onChange={handleInputChange}
                customRender={(
                  item,
                  i,
                  filteredData,
                  activeSuggestion,
                  onSuggestionItemClick,
                  onSuggestionItemHover
                ) => {
                  // const IconTag = Icon[item.icon ? item.icon : "X"]
                  // const getTagAndName = getIconTag(item.icon)
                  // const IconTag = getTagAndName.iconTag

                  const itemLink = `/dyn_pages${item?.link}`
                    .replaceAll("/dyn_pages/dyn_pages", "/dyn_pages")
                    .replaceAll("/dyn_pagesnull", "/")
                  // console.log(itemLink)
                  return (
                    <li
                      key={i}
                      onMouseEnter={() =>
                        onSuggestionItemHover(filteredData.indexOf(item))
                      }
                      className={classnames(
                        "suggestion-item d-flex align-items-center justify-content-between",
                        {
                          active:
                            filteredData.indexOf(item) === activeSuggestion
                        }
                      )}
                    >
                      <Link
                        to={itemLink}
                        className="d-flex align-items-center justify-content-between p-0"
                        onClick={() => {
                          setOpenSearch(false)
                          handleClearQueryInStore()
                        }}
                        style={{
                          width: "calc(90%)"
                        }}
                      >
                        <div className="d-flex justify-content-start align-items-center overflow-hidden">
                          {/* {item.icon.includes("material:") ? (
                            <MatIcon
                              className="me-75 bookmark-icon17"
                              fontSize="17.5px"
                              // style={{ "font-size": "17.5px" }}
                              baseClassName="material-icons-outlined bookmark-icon17"
                            >
                              {getTagAndName.iconStrFix}
                            </MatIcon>
                          ) : (
                            <IconTag size={17.5} className="me-75" />
                          )} */}
                          {renderIcon(
                            item.icon,
                            "me-75 material-icons-outlined bookmark-icon17",
                            17.5
                          )}
                          <span className="text-truncate">{t(item.title)}</span>
                        </div>
                      </Link>
                      {store.postLoading && item.id === store.postLoadingId ? (
                        <Spinner
                          size="sm"
                          className="bookmark-icon float-end"
                          color="primary"
                          type="grow"
                        />
                      ) : (
                        <Star
                          size={17.5}
                          className={classnames("bookmark-icon float-end", {
                            "text-warning": item.isBookmarked
                          })}
                          onClick={() => handleBookmarkUpdate(item.id)}
                        />
                      )}
                    </li>
                  )
                }}
              />
            ) : null}
          </div>
        </NavItem>
      </ul>
    </Fragment>
  )
}

export default NavbarBookmarks
