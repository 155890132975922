// import { Badge, HStack, Image } from "@chakra-ui/react"
import Avatar from "@components/avatar"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Badge, Card, CardBody, CardHeader, CardTitle } from "reactstrap"

const StandardTooltipContent = ({
  task,
  fontSize,
  fontFamily,
  pageAttr,
  title
}) => {
  const style = {
    fontSize,
    fontFamily
  }
  const { t } = useTranslation()

  const handleAddEdit = () => {
    console.log("")
  }

  const genTooltip = () => {
    if (!pageAttr?.crudOpt?.data?.dynamicTooltipColumns) {
      return (
        <dl className="dl-gantt">
          {task?.rowData?.[pageAttr?.crudOpt?.ganttDescField ?? "desc"] && (
            <Fragment>
              <dt>{t("Description")}</dt>
              <dd>
                <b>
                  {task?.rowData?.[
                    pageAttr?.crudOpt?.ganttDescField ?? "desc"
                  ] ?? ""}
                </b>
              </dd>
            </Fragment>
          )}

          <dt>{t("Period")}</dt>
          <dd>
            <b>
              {`${task.start.getDate()}-${
                task.start.getMonth() + 1
              }-${task.start.getFullYear()} - ${task.end.getDate()}-${
                task.end.getMonth() + 1
              }-${task.end.getFullYear()}`}
            </b>
          </dd>

          {task.end.getTime() - task.start.getTime() !== 0 && (
            <Fragment>
              <dt>{t("Duration")}</dt>
              <dd>
                <b>{`${~~(
                  (task.end.getTime() - task.start.getTime()) /
                  (1000 * 60 * 60 * 24)
                )} ${t("day(s)")}`}</b>
              </dd>
            </Fragment>
          )}

          {!!task.progress && (
            <Fragment>
              <dt>{t("Progress")}</dt>
              <dd>
                <b>{`${task.progress} %`}</b>
              </dd>
            </Fragment>
          )}

          {task?.rowData?.[
            pageAttr?.crudOpt?.ganttAssigneeNameField ?? "assignee"
          ] && (
            <Fragment>
              <dt>{t("Assignee")}</dt>
              <dd>
                {task?.rowData?.[
                  pageAttr?.crudOpt?.ganttAssigneeAvatarField ?? "avatar"
                ] ? (
                  <div className="d-flex align-items-center">
                    <Avatar
                      className="me-1"
                      size="sm"
                      color="light-primary"
                      img={
                        task?.rowData?.[
                          pageAttr?.crudOpt?.ganttAssigneeAvatarField ??
                            "avatar"
                        ]
                      }
                    />
                    <div className="user-info text-truncate">
                      <span className="d-block fw-bold text-truncate">
                        {
                          task?.rowData?.[
                            pageAttr?.crudOpt?.ganttAssigneeNameField ??
                              "assignee"
                          ]
                        }
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <Avatar
                      initials
                      className="me-1"
                      color="light-primary"
                      size="sm"
                      content={
                        task?.rowData?.[
                          pageAttr?.crudOpt?.ganttAssigneeNameField ??
                            "assignee"
                        ]
                      }
                    />
                    <div className="user-info text-truncate">
                      <span className="d-block fw-bold text-truncate">
                        {
                          task?.rowData?.[
                            pageAttr?.crudOpt?.ganttAssigneeNameField ??
                              "assignee"
                          ]
                        }
                      </span>
                    </div>
                  </div>
                )}
              </dd>
            </Fragment>
          )}
        </dl>
      )
    } else {
      return (
        <dl className="dl-gantt">
          {pageAttr.crudOpt.data.dynamicTooltipColumns.map(
            (item, index) =>
              task?.rowData?.[item?.fieldName] && (
                <Fragment>
                  <dt className="dt-gantt">{t(item?.columnName ?? "")}</dt>
                  <dd className="dd-gantt">
                    {/* <b>{task?.rowData?.[item?.fieldName] ?? ""}</b> */}
                    <DynamicRow
                      row={task?.rowData}
                      column={item}
                      clickToDetailEvent={handleAddEdit}
                    />
                  </dd>
                </Fragment>
              )
          )}
        </dl>
      )
    }
  }

  return (
    <div
      key={`tooltip_gantt_${task?.rowData?.id}`}
      style={{
        boxShadow: "0 4px 24px #22292f1a"
      }}
    >
      <Card className="mb-0 gantt-tooltip">
        {task?.rowData?.[pageAttr?.crudOpt?.ganttGroupTitleField] && (
          <CardHeader className="mb-0">
            <Badge color="primary" className="badge-glow">
              {task?.rowData?.[pageAttr?.crudOpt?.ganttGroupTitleField]}
            </Badge>
          </CardHeader>
        )}
        <CardBody>
          <CardTitle
            style={{
              fontSize: "12px"
            }}
            tag="h6"
            className="mb-1"
          >{`${title}`}</CardTitle>
          <div>{genTooltip()}</div>
        </CardBody>

        {/* {task["checklist"]?.length > 0 && (
        <>
          {task["checklist"].map((item, index) => {
            let dueDate =
              item["due"] !== null
                ? `${new Date(new Date(item["due"])).toLocaleString("en-US", {
                    month: "short"
                  })} ${new Date(new Date(item["due"])).getDate().toString()}`
                : null

            return (
              <div
                className={styles.tooltipDefaultWithBoth}
                key={item + index}
                spacing="10px"
              >
                {item["state"] === "complete" ? (
                  <input
                    type="checkbox"
                    checked="checked"
                    style={{ marginRight: "5px" }}
                    readOnly={true}
                  ></input>
                ) : (
                  <input
                    type="checkbox"
                    style={{ marginRight: "5px" }}
                    readOnly={true}
                  ></input>
                )}
                <label style={{ marginRight: "5px" }}>{item["name"]}</label>
                <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                  <div>
                    {item["due"] !== null ? (
                      <Badge fontSize="10px" variant="solid" colorScheme="grey">
                        {dueDate}
                      </Badge>
                    ) : item["state"] !== "complete" ? (
                      <Badge fontSize="10px" variant="solid" colorScheme="red">
                        No due date
                      </Badge>
                    ) : (
                      ""
                    )}
                    {item.hasOwnProperty("avatar") && (
                      <Image
                        key={index + item}
                        src={item["avatar"]}
                        boxSize="25"
                        borderRadius="full"
                        style={{
                          marginLeft: "5px",
                          marginBottom: "5px"
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
      <div>
        {task["members"].map((item, key) => (
          <Image
            key={key + item}
            src={item}
            boxSize="35px"
            borderRadius="full"
            style={{
              marginLeft: "5px",
              marginBottom: "5px"
            }}
          />
        ))}
      </div> */}
      </Card>
    </div>
  )
}

export default StandardTooltipContent
