import Avatar from "@components/avatar"
import Moment from "moment"
import { components } from "react-select"
import { DefaultRoute } from "../router/routes"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import { useTranslation } from "react-i18next"
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0
// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export function isDateFormatPattern(inputString) {
  const regex =
    /^(?:(?:YYYY|YY|MM|DD|HH|mm|ss)(?:-(?:YYYY|YY|MM|DD|HH|mm|ss))*)|(?:HH:mm)$/
  return regex.test(inputString)
}

export function getValueFormated(value, formatType) {
  let result = value
  if (formatType) {
    if (isDateFormatPattern(formatType)) {
      result = Moment(value).format(formatType)
    }
  }
  return result
}

export function parseStrAndField(row, field) {
  let result = field
  if (Array.isArray(field)) {
    let resultArr = []
    field?.map((item) => {
      let itemKey = item
      let itemFormatType = null
      if (item?.includes(":") && !item?.includes("'")) {
        const itemSplitArr = item.split(":")
        itemKey = itemSplitArr[0]
        if (itemSplitArr.length > 1) {
          const formatPart = itemSplitArr.slice(1).join(":").trim()
          itemFormatType = formatPart
        }
      }

      if (itemKey?.includes(".") && !itemKey?.includes("'")) {
        let newValueField = getValueFormated(
          getDepthValue(row, itemKey),
          itemFormatType
        )
        resultArr.push(newValueField)
      } else if (itemKey?.includes("'") && !itemKey?.includes(".")) {
        resultArr.push(itemKey?.replaceAll("'", ""))
      } else {
        let newValueField = getValueFormated(row?.[itemKey], itemFormatType)
        resultArr.push(newValueField)
      }
    })
    result = resultArr.join("")
  } else {
    let itemKey = field
    let itemFormatType = null

    if (field?.includes(":") && !field?.includes("'")) {
      const itemSplitArr = field.split(":")
      itemKey = itemSplitArr[0]
      if (itemSplitArr.length > 1) {
        const formatPart = itemSplitArr.slice(1).join(":").trim()
        itemFormatType = formatPart
      }
    }

    result =
      itemKey.includes(".") && !itemKey.includes("'")
        ? getValueFormated(getDepthValue(row, itemKey), itemFormatType)
        : itemKey.includes("'")
        ? itemKey?.replaceAll("'", "")
        : getValueFormated(row?.[itemKey], itemFormatType)
  }
  return result
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  // if (userRole === "super_user") return DefaultRoute
  // if (userRole === "admin") return DefaultRoute
  // if (userRole === "editor") return DefaultRoute
  // if (userRole === "manager") return DefaultRoute
  // if (userRole === "viewer") return DefaultRoute
  // return "/login"
  return DefaultRoute
}

export const setLastLogout = () => {
  const currentTime = new Date().getTime()
  localStorage.setItem("lastLogout", currentTime)
  if (
    location.pathname !== "/login" ||
    location.pathname !== "/error" ||
    location.pathname !== "/forgot-password" ||
    location.pathname !== "/reset-password" ||
    location.pathname !== "/register" ||
    location.pathname !== "/logout" ||
    location.pathname !== "/dashboard"
  ) {
    localStorage.setItem("lastLogoutUrl", location.pathname)
    const last_current_page_perm = sessionStorage.getItem("current_page_perm")
    localStorage.setItem("lastLogoutSubject", last_current_page_perm)
  }
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const getAppLogo = () =>
  JSON.parse(localStorage.getItem("domainClaim"))?.unit?.unit_app_attributes
    ?.appLogo ??
  JSON.parse(localStorage.getItem("domainClaim"))?.app?.logo?.appLogo
export const getAppLoginBg = () =>
  JSON.parse(localStorage.getItem("domainClaim"))?.unit?.unit_app_attributes
    ?.login_img ??
  JSON.parse(localStorage.getItem("domainClaim"))?.app?.logo?.login_img
export const getAppName = () =>
  JSON.parse(localStorage.getItem("domainClaim"))?.app?.name
export const getFavIcon = () =>
  JSON.parse(localStorage.getItem("domainClaim"))?.unit?.unit_app_attributes
    ?.favicon ??
  JSON.parse(localStorage.getItem("domainClaim"))?.app?.logo?.favicon

export const getDomainInfo = () => {
  const item = localStorage.getItem("domainClaim")
  return item ? JSON.parse(item) : {}
}

export const isLoginFilter = () => {
  const domainInfo = getDomainInfo()
  return (
    domainInfo?.unit?.unit_app_attributes?.setting?.login_filter_dataYear &&
    domainInfo?.app?.setting?.login_filter_dataYear
  )
}

export const isGlobalFilter = () => {
  const domainInfo = getDomainInfo()
  return (
    !isLoginFilter() &&
    (domainInfo?.unit?.unit_app_attributes?.setting?.global_filter_dataYear ||
      domainInfo?.app?.setting?.global_filter_dataYear)
  )
}

export const genDropdownDataYear = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  let yearsArray = []
  for (let i = currentYear + 1; i >= currentYear - 5; i--) {
    yearsArray.push({ label: i, value: i })
  }
  yearsArray.push({
    value: 1900,
    label: t("All Years")
  })
  return yearsArray
}

export const generateDateFormat = (date) => {
  const p = new Intl.DateTimeFormat("id-ID", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  })
    .formatToParts(date)
    .reduce((acc, part) => {
      acc[part.type] = part.value
      return acc
    }, {})

  return `${p.year}-${p.month}-${p.day}`
}

export const neutralizeBack = (callback) => {
  window.history.pushState(null, "", window.location.href)
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href)
    callback()
  }
}

export const revivalBack = () => {
  window.onpopstate = undefined
  window.history.back()
}

export const sortedObject = (unordered) => {
  return Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key]
      return obj
    }, {})
}

export const getRequiredFilterState = (
  dynamicColumnsFilters = [],
  mixParams
) => {
  // console.log(store.pageData[storeName]?.params)
  // let mixParams = store.pageData[storeName]?.params
  // console.log(mixParams)
  let isLoadState = true

  const dynamicColumnsFilter = !Array.isArray(dynamicColumnsFilters)
    ? [dynamicColumnsFilters]
    : dynamicColumnsFilters
  /////prevent load data if filter include required attr and undefined
  if (dynamicColumnsFilter?.length > 0) {
    dynamicColumnsFilter
      ?.filter((x) => x?.rules?.required === true)
      .map((y) => {
        // eslint-disable-next-line no-unneeded-ternary
        isLoadState = mixParams?.[y.fieldName] ? true : false
      })
  }

  return isLoadState
}

// export const getDepthValue = (obj, path, defaultValue) => {
//   if (path?.includes(".")) {
//     let props
//     if (typeof obj === "undefined") return defaultValue
//     if (typeof path === "string") {
//       // console.log(path.split("."))
//       props = path?.split(".")
//       props = props?.reverse()
//     } else {
//       props = path
//     }
//     if (path.length === 0) return obj || defaultValue
//     let current = props.pop()
//     return getDepthValue(obj?.[current], props, defaultValue)
//   } else {
//     return obj?.[path]
//   }
// }

export const getDepthValue = (obj, path, defaultValue) => {
  if (path?.includes(".")) {
    if (typeof path === "string") {
      path = path.split(".")
    }

    for (let i = 0; i < path?.length; i++) {
      if (!obj || typeof obj !== "object") {
        return defaultValue
      }
      obj = obj[path[i]]
    }

    return obj !== undefined ? obj : defaultValue
  } else {
    return obj?.[path]
  }
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden"
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()]
}

export const urlReplacer = (oldPageAttr, reverse = false) => {
  let newPageAttr = oldPageAttr

  const charReplacerStorage = localStorage.getItem("char_replacer")
  if (charReplacerStorage) {
    let charReplacerStorageObj
    try {
      charReplacerStorageObj = JSON.parse(charReplacerStorage)
    } catch (error) {
      console.log(error)
    }
    if (
      charReplacerStorageObj &&
      Array.isArray(charReplacerStorageObj) &&
      charReplacerStorageObj?.length > 1
    ) {
      if (charReplacerStorageObj[0] && charReplacerStorageObj[0] !== "") {
        try {
          let paramsStr
          paramsStr = JSON.stringify(oldPageAttr)
          newPageAttr = JSON.parse(
            !reverse
              ? paramsStr.replaceAll(
                  charReplacerStorageObj[0],
                  charReplacerStorageObj[1]
                )
              : paramsStr.replaceAll(
                  charReplacerStorageObj[1],
                  charReplacerStorageObj[0]
                )
          )
        } catch (error) {
          console.log(error)
        }
      }
      // console.log(newPageAttr)
    }
  }

  const endpointUrlReplacerStorage = localStorage.getItem("endpointUrlReplacer")
  if (endpointUrlReplacerStorage) {
    let endpointUrlReplacerStorageObj
    try {
      endpointUrlReplacerStorageObj = JSON.parse(endpointUrlReplacerStorage)
    } catch (error) {
      console.log(error)
    }
    if (
      endpointUrlReplacerStorageObj &&
      Array.isArray(endpointUrlReplacerStorageObj) &&
      endpointUrlReplacerStorageObj?.length > 1
    ) {
      if (
        endpointUrlReplacerStorageObj[0] &&
        endpointUrlReplacerStorageObj[0] !== ""
      ) {
        try {
          let paramsStr
          paramsStr = JSON.stringify(oldPageAttr)
          newPageAttr = JSON.parse(
            !reverse
              ? paramsStr.replaceAll(
                  endpointUrlReplacerStorageObj[0],
                  endpointUrlReplacerStorageObj[1]
                )
              : paramsStr.replaceAll(
                  endpointUrlReplacerStorageObj[1],
                  endpointUrlReplacerStorageObj[0]
                )
          )
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  return newPageAttr
}

export const disableBtnCondition = (
  crudOpt,
  row = null,
  mode,
  storeMyUnit = null,
  pageType = null,
  pageAttr,
  detailBtnEdit = false
) => {
  if (pageType === "view" && !detailBtnEdit) {
    return true
  }

  let disabledState
  let disabledCondition
  let disabledCondition_or
  if (mode === "add") {
    disabledState =
      crudOpt?.disabledAdd === true ? crudOpt.disabledAdd : row?._disabled_add_
    disabledCondition = crudOpt?.disabledAddCondition
    disabledCondition_or = crudOpt?.disabledAddCondition_or
  } else if (mode === "delete") {
    disabledState =
      crudOpt?.disabledDelete === true
        ? crudOpt.disabledDelete
        : row?._disabled_delete_
    disabledCondition = crudOpt?.disabledDeleteCondition
    disabledCondition_or = crudOpt?.disabledDeleteCondition_or
  } else if (mode === "view") {
    disabledState =
      crudOpt?.enabledViewBtn === true
        ? crudOpt.enabledViewBtn
        : row?._enabled_view_
    disabledCondition = crudOpt?.enabledViewBtnCondition
    disabledCondition_or = crudOpt?.enabledViewBtnCondition_or
  } else if (mode === "add-child") {
    disabledState =
      crudOpt?.disabledAddChild === true
        ? crudOpt.disabledAddChild
        : row?._disabled_add_child_
    disabledCondition = crudOpt?.disabledAddChildCondition
    disabledCondition_or = crudOpt?.disabledAddCondition_or
  } else if (mode === "edit-grid") {
    disabledState =
      crudOpt?.disabledEditGrid === true
        ? crudOpt.disabledEditGrid
        : row?._disabled_edit_grid_
    disabledCondition = crudOpt?.disabledEditGridCondition
    disabledCondition_or = crudOpt?.disabledEditGridCondition_or
  } else {
    disabledState =
      crudOpt?.disabledEdit === true
        ? crudOpt.disabledEdit
        : row?._disabled_edit_
    disabledCondition = crudOpt?.disabledEditCondition
    disabledCondition_or = crudOpt?.disabledEditCondition_or
  }

  let result = disabledState ?? false
  let strArr = []
  let evalStrArr = []
  let resultArr = []
  // console.log(pageAttr)
  if (disabledCondition) {
    disabledCondition?.map((item) => {
      let field = `${item?.field}`
      let operator = item?.operator
      let value = item?.value

      if (typeof row?.[item?.field] === "boolean") {
        field = `${item?.field}`
      } else if (typeof item?.value === "boolean") {
        value = `${item?.value}`
      } else if (
        typeof item?.value === "number" &&
        !Number.isNaN(item?.value)
      ) {
        value = `${item?.value}`
      } else if (
        typeof item?.field === "number" &&
        !Number.isNaN(item?.field)
      ) {
        field = `${item?.field}`
      }

      let currentRowValue = null
      let evalStr = null

      if (field?.includes("__userData") || value?.includes("__userData")) {
        const userData = getUserData()
        if (
          field?.includes("__userData") &&
          !field?.includes("__userData.member_of__")
        ) {
          // console.log(userData)
          switch (field) {
            case "__userData.name__":
              currentRowValue = userData?.name
              break
            case "__userData.isManager__":
              currentRowValue = userData?.isManager
              break
            case "__userData.email__":
              currentRowValue = userData?.email
              break
            case "__userData.id__":
              currentRowValue = userData?.id
              break
            case "__userData.username__":
              currentRowValue = userData?.username
              break
            default:
              currentRowValue = undefined // or any other default value
          }
        } else if (
          value?.includes("__userData") &&
          !value?.includes("__userData.member_of__")
        ) {
          switch (value) {
            case "__userData.name__":
              value = userData?.name
              break
            case "__userData.isManager__":
              value = userData?.isManager
              break
            case "__userData.email__":
              value = userData?.email
              break
            case "__userData.id__":
              value = userData?.id
              break
            case "__userData.username__":
              value = userData?.username
              break
            default:
              value = undefined
          }
        } else if (
          field?.includes("__userData.member_of__") ||
          value?.includes("__userData.member_of__")
        ) {
          const memberOfData = storeMyUnit
          if (value === "__userData.member_of__") {
            currentRowValue = field?.includes(".")
              ? getDepthValue(row, field)
              : row?.[field]
            value = memberOfData?.find((obj) => obj?.id === currentRowValue)?.id
          } else if (field === "__userData.member_of__") {
            const id_unit_row = value?.includes(".")
              ? getDepthValue(row, value)
              : row?.[value]
            let findObj = memberOfData?.find((obj) => obj?.id === id_unit_row)
            currentRowValue = findObj?.id
          }
        }
      } else {
        currentRowValue = field?.includes(".")
          ? getDepthValue(row, field)
          : row?.[field]
      }

      // EVALUATE ALL TO ARRAY
      const nullCollection = [
        null,
        "null",
        "'null'",
        undefined,
        "undefined",
        "'undefined'",
        false,
        "false",
        "'false'",
        ""
        // "''"
      ]

      if (nullCollection.includes(value)) {
        value = null
      }

      if (nullCollection.includes(currentRowValue)) {
        currentRowValue = null
      }

      // if (typeof currentRowValue === "boolean") {
      //   currentRowValue = `${currentRowValue}`
      // } else if (typeof value === "boolean") {
      //   value = `${value}`
      // } else if (typeof value === "number" && !Number.isNaN(value)) {
      //   value = `${value}`
      // } else if (
      //   typeof currentRowValue === "number" &&
      //   !Number.isNaN(currentRowValue)
      // ) {
      //   currentRowValue = `${currentRowValue}`
      // } else if (typeof currentRowValue === "string") {
      //   currentRowValue = `'${currentRowValue}'`
      // }
      if (typeof currentRowValue === "boolean") {
        currentRowValue = currentRowValue.toString()
      } else if (
        typeof currentRowValue === "number" &&
        !Number.isNaN(currentRowValue)
      ) {
        currentRowValue = currentRowValue.toString()
      } else if (typeof currentRowValue === "string") {
        currentRowValue = `'${currentRowValue}'`
      }
      // console.log(currentRowValue, value)

      evalStr = `${currentRowValue} ${operator} ${value}`
      strArr.push(`${item?.field} ${item?.operator} ${item?.value}`)
      evalStrArr.push(evalStr)

      try {
        resultArr.push(eval(evalStr))
      } catch (error) {
        // result = false
        resultArr.push(false)
      }
    })

    // EVALUATE ARRAY TO FIND RESULT
    // || crudOpt?.disabledEditGridCondition_or
    if (resultArr.length > 0) {
      if (disabledCondition_or === true) {
        result = resultArr?.some((value) => value === true)
      } else {
        result = resultArr?.every((value) => value === true)
      }
    }

    // console.log(strArr)
    // console.log(evalStrArr)
    // console.log(resultArr)
  }

  // console.log(result)

  return result
}

export function isValidInteger(str) {
  // Use a regular expression to check if the string contains only digits (possibly with a '-' at the beginning)
  // This will match positive and negative integers
  // You can also use /^[0-9]+$/ to only match positive integers
  const integerPattern = /^-?\d+$/

  if (integerPattern.test(str)) {
    // Use parseInt to check if the parsed value is equal to the original string
    // This will ensure that the string can be safely converted to an integer
    const parsedValue = parseInt(str, 10)
    return str === parsedValue.toString()
  }

  return false
}

export function checkAndCorrectDateValue(value) {
  // Check if the value is in datetime format 'YYYY-MM-DDTHH:mm:ss'
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
    const datetimeParts = value.split("T")
    const datePart = datetimeParts[0]
    const timePart = datetimeParts[1].slice(0, 5)
    return `${datePart} ${timePart}`
  }

  // Check if the value is in date format 'DD-MM-YYYY'
  if (/^\d{2}-\d{2}-\d{4}$/.test(value)) {
    const dateParts = value.split("-")
    const day = dateParts[0]
    const month = dateParts[1]
    const year = dateParts[2]
    return `${year}-${month}-${day}`
  }

  // Check if the value is in format 'YYYY/MM/DD'
  if (/^\d{4}\/\d{2}\/\d{2}$/.test(value)) {
    const dateParts = value.split("/")
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]
    return `${year}-${month}-${day}`
  }

  // Check if the value is in format 'YYYY-MM-DDTHH:mm:ss.SSSSSS'
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}$/.test(value)) {
    const datetimeParts = value.split("T")
    const datePart = datetimeParts[0]
    const timePart = datetimeParts[1].slice(0, 5)
    return `${datePart} ${timePart}`
  }

  // Return the value as is if it doesn't match any of the formats
  return value
}

const formatLabel = (value, type) => {
  let newValue = value
  if (type && value) {
    if (type === "datetime") {
      newValue = moment(value).format("DD MMM YYYY HH:mm").toString()
    } else if (type === "date") {
      newValue = moment(value).format("DD MMM YYYY").toString()
    } else if (type === "time") {
      newValue = moment(value).format("HH:mm").toString()
    } else if (type === "year") {
      newValue = moment(value).format("YYYY").toString()
    } else if (type === "currency") {
      const storeageLang = localStorage.getItem("i18nextLng")
      const currentLang = storeageLang === "id" ? "id-ID" : "en-US"
      const currentCurrency = storeageLang === "id" ? "IDR" : "USD"

      newValue = new Intl.NumberFormat(currentLang, {
        style: "currency",
        currency: currentCurrency
      }).format(value ?? 0)
    }
  }
  return newValue
}

export const renderDropdownItem = async (item, res) => {
  let keySelector = item.dataFrom?.resp_key
    ? res?.data?.[item.dataFrom?.resp_key]
    : res?.data?.data ?? null
  if (item.dataFrom?.resp_key === "") {
    keySelector = res?.data
    // console.log(keySelector)
  }
  if (keySelector) {
    if (!Array.isArray(keySelector)) {
      keySelector = [keySelector]
    }
    if (item.dataFrom?.field_label || item.dataFrom?.field_value) {
      return await keySelector.map((r) => {
        let container = { ...r }
        if (Array.isArray(item?.dataFrom?.field_label)) {
          let objSelector = ""

          item?.dataFrom?.field_label.map((lbl, i, row) => {
            const isLabelFormated = lbl.includes(":") ?? false
            const labelFormatType = isLabelFormated ? lbl?.split(":")[1] : null
            const labelFormat = isLabelFormated ? lbl?.split(":")[0] : lbl
            const isLabelDeepKey = labelFormat.includes(".") ?? false

            if (i + 1 === row.length) {
              if (isLabelDeepKey) {
                objSelector = objSelector + getDepthValue(r, lbl)
              } else {
                objSelector = objSelector + (r[lbl] ?? "")
              }
            } else {
              if (isLabelDeepKey) {
                objSelector =
                  objSelector +
                    getDepthValue(r, lbl) +
                    item?.dataFrom?.field_label_separator ?? " "
              } else {
                objSelector =
                  objSelector +
                    (r?.[lbl] ?? "") +
                    item?.dataFrom?.field_label_separator ?? " "
              }
            }

            objSelector = formatLabel(
              objSelector?.replaceAll("null", ""),
              labelFormatType
            )
          })
          container["label"] = objSelector?.replaceAll("null", "")
        } else {
          const label = item.dataFrom?.field_label
          const isLabelFormated = label.includes(":") ?? false
          const labelFormatType = isLabelFormated ? label?.split(":")[1] : null
          const labelFormat = isLabelFormated ? label?.split(":")[0] : label
          const isLabelDeepKey = labelFormat.includes(".") ?? false
          let labelValue = isLabelDeepKey
            ? getDepthValue(r, labelFormat)?.replaceAll("null", "")
            : r[labelFormat]?.replaceAll("null", "")

          container["label"] = formatLabel(labelValue, labelFormatType)
        }

        if (item.dataFrom?.field_img) {
          container["img"] = item?.dataFrom?.field_img.includes(".")
            ? getDepthValue(r, item?.dataFrom?.field_img)
            : r[item.dataFrom.field_img]
        }

        container["value"] = item.dataFrom?.field_value.includes(".")
          ? getDepthValue(r, item.dataFrom?.field_value)
          : r[item.dataFrom.field_value]
        return container
      })
      // console.log(newDataMap)
      // newDataMap
    } else {
      return await keySelector
    }
  } else {
    return []
  }
}

// let isLoadingBtnAddCondition = false
// // const [isLoadingBtnAddCondition, setIsLoadingBtnAddCondition] = useState(false)
// export const getBtnConditionAttr = (
//   type,
//   condition,
//   btnAddTextConditionDataFrom
// ) => {
//   let result = true
//   if (condition) {
//     if (type === "add") {
//       let valueToCompare
//       if (btnAddTextConditionDataFrom?.api) {
//         let finUrl = btnAddTextConditionDataFrom?.api
//         const baseUrlOpt = btnAddTextConditionDataFrom?.baseUrl
//         getBaseUrl(baseUrlOpt, finUrl)
//           .get(`${finUrl}`)
//           .then((res) => {
//             let keySelector = btnAddTextConditionDataFrom?.resp_key
//               ? res?.data?.[btnAddTextConditionDataFrom?.resp_key]
//               : res?.data ?? null
//             if (keySelector) {
//               if (Array.isArray(keySelector)) {
//                 valueToCompare =
//                   keySelector[0]?.[btnAddTextConditionDataFrom?.field_value]
//               } else {
//                 valueToCompare =
//                   keySelector?.[btnAddTextConditionDataFrom?.field_value]
//               }
//             }
//           })
//       } else if (btnAddTextConditionDataFrom?.currentTime) {
//         const nowTime = new Date()
//         const nowTimeStr = `${
//           (nowTime.getHours() < 10 ? "0" : "") + nowTime.getHours()
//         }:${(nowTime.getMinutes() < 10 ? "0" : "") + nowTime.getMinutes()}`
//         valueToCompare = new Date(`2023-01-01 ${nowTimeStr}`).getTime()
//       }

//       if (valueToCompare) {
//         condition?.map((item) => {
//           let test = false
//           let valueToCompare2 = item?.value
//           if (btnAddTextConditionDataFrom?.currentTime) {
//             valueToCompare2 = new Date(
//               `2023-01-01 ${valueToCompare2}`
//             ).getTime()
//           }

//           if (item?.operator === "<") {
//             test = valueToCompare < valueToCompare2
//           } else if (item?.operator === ">") {
//             test = valueToCompare > valueToCompare2
//           } else if (item?.operator === "===") {
//             test = valueToCompare === valueToCompare2
//           } else if (item?.operator === "!==") {
//             test = valueToCompare !== valueToCompare2
//           }
//           console.log(item, test)
//           if (test) {
//             result = item
//           }
//         })
//       }
//     }
//   }
//   return result
// }

const checkKeyPresenceInArray = (data, key) =>
  data.some((obj) => Object.keys(obj).includes(key))

export const isAdminApp = () => {
  // console.log(window.location, window.location.host)
  return (
    window.location.host === "localhost:3000" ||
    window.location.host === "admin.insaba.co.id" ||
    window.location.host === "adminsaba.web.app"
  )
}

export const isErpApp = () => {
  // console.log(window.location, window.location.host)
  return (
    window.location.host === "localhost:3001" ||
    window.location.host === "saba-erp.web.app" ||
    window.location.host === "thermochem.insaba.co.id" ||
    window.location.host === "localhost:3004"
  )
}

export const isErpDemoApp = () => {
  // console.log(window.location, window.location.host)
  return (
    window.location.host === "localhost:3004" ||
    window.location.host === "saba-erp.web.app"
  )
}

export const restrictedMenuLink = [
  "/dashboard",
  "/dyn_pages/playground/dataMahasiswa",
  "/dyn_pages/playground/dataPegawai",
  "/dyn_pages/playground/dataBerita",
  "/dyn_pages/playground/dataOther1",
  "/dyn_pages/playground/dataOther2",
  "/dyn_pages/playground/dataOther3",
  "/dyn_pages/playground/dataOther4",
  "/dyn_pages/playground/dataOther5",
  "/dyn_pages/playground/dataOther6",
  "/dyn_pages/playground/dataOther7",
  "/dyn_pages/playground/dataOther8",
  "/dyn_pages/playground/dataOther9",
  "/dyn_pages/playground/dataOther10"
]

export const convertToEmbedUrl = (url) => {
  // console.log(url)
  // Jika URL adalah jenis pertama ("https://www.youtube.com/watch?v=ziDDZdeObvA")
  if (url?.length > 0) {
    if (url.includes("youtube.com/watch?v=")) {
      const videoId = url.split("v=")[1].split("&")[0] // Ambil ID video tanpa parameter lainnya
      return `https://www.youtube.com/embed/${videoId}`
    }

    // Jika URL adalah jenis kedua ("ziDDZdeObvA")
    if (!url.includes("youtube.com") && !url.includes("youtu.be")) {
      return `https://www.youtube.com/embed/${url}`
    }

    // Jika URL adalah jenis ketiga ('https://www.youtube.com/watch?v=eGChOUgnpis&list=PLt_GKfBvBhLd59QVK527ofH9zbdwqVtwI&index=3A')
    if (url.includes("youtube.com/watch")) {
      const videoIdMatch = url.match(/[?&]v=([^&]+)/)
      console.log(url)
      if (videoIdMatch) {
        const videoId = videoIdMatch[1]
        return `https://www.youtube.com/embed/${videoId}`
      }
    }

    // Jika URL adalah jenis keempat ('https://www.youtube.com/embed/ziDDZdeObvA?si=YULD9xXYUCm2faJZ')
    if (url.includes("youtube.com/embed/")) {
      const videoId = url.split("youtube.com/embed/")[1].split("?")[0] // Ambil ID video dari URL embed
      return `https://www.youtube.com/embed/${videoId}`
    }
  }

  return null
}

export const processHtmlIndent = (str) => {
  let div = document.createElement("div")
  div.innerHTML = str.trim()

  return formatHtml(div, 0).innerHTML
}

function formatHtml(node, level) {
  let indentBefore = new Array(level++ + 1).join("  "),
    indentAfter = new Array(level - 1).join("  "),
    textNode

  for (let i = 0; i < node.children.length; i++) {
    textNode = document.createTextNode(`\n${indentBefore}`)
    node.insertBefore(textNode, node.children[i])

    formatHtml(node.children[i], level)

    if (node.lastElementChild === node.children[i]) {
      textNode = document.createTextNode(`\n${indentAfter}`)
      node.appendChild(textNode)
    }
  }

  return node
}

// export const setLastMenuCached = () => {
//   let lastMenuChachedStorage = sessionStorage.getItem("lastMenuChached") ?? "[]"
//   let lastMenuChachedArr = JSON.parse(lastMenuChachedStorage)

//   if (lastMenuChachedArr.length) {
//     const location = window.location.pathname
//     // console.log(lastMenuChachedArr, location)

//     let locationRoute = location?.includes("/edit/")
//       ? location?.split("/edit/")?.[0]
//       : location?.includes("/add")
//       ? location?.split("/add")?.[0]
//       : location
//     console.log(locationRoute)
//     const index = lastMenuChachedArr.findIndex((itemChild) => {
//       let navLink = itemChild.navLink?.includes("/edit/")
//         ? itemChild.navLink?.split("/edit/")?.[0]
//         : itemChild.navLink?.includes("/add")
//         ? itemChild.navLink?.split("/add")?.[0]
//         : itemChild.navLink
//       // console.log(navLink)
//       return navLink === locationRoute
//     })
//     // console.log(lastMenuChachedArr, index)
//     if (index !== -1) {
//       lastMenuChachedArr[index].navLink = location
//     } else {
//       // lastMenuChachedArrExist
//     }

//     if (lastMenuChachedArr.length > 7) {
//       lastMenuChachedArr.pop()
//     }

//     sessionStorage.setItem(
//       "lastMenuChached",
//       JSON.stringify(lastMenuChachedArr)
//     )
//   }
// }

export const isFileURL = (url) => {
  try {
    const parsedURL = new URL(url)

    // Jika protokol adalah 'http' atau 'https'
    if (parsedURL.protocol === "http:" || parsedURL.protocol === "https:") {
      // Jika path adalah '/', itu adalah domain
      if (parsedURL.pathname === "/") {
        return false
      }
      // Jika path berisi ekstensi file (seperti '.jpg', '.mp3', '.pdf'), itu adalah file
      if (parsedURL.pathname.includes(".")) {
        return true
      }
    }
    return false
  } catch (err) {
    // Kesalahan parsing URL, dianggap sebagai tautan ke file
    return true
  }
}

export const renderAvatarImgIcon = (item) => {
  let componentsInput = {}
  const { value, field_img, field_icon, field_color } = item?.dataFrom ?? item

  const itemHasIcon = value
    ? checkKeyPresenceInArray(value, "icon")
    : !field_img && field_icon

  const itemHasImg = value ? checkKeyPresenceInArray(value, "img") : field_img

  if (field_img || field_icon || itemHasIcon || itemHasImg) {
    const getColor = (data) => {
      let color =
        data.color ??
        data?.[field_color] ??
        getDepthValue(data, field_color, null)
      return color ? `text-${color}` : ""
    }

    const getIcon = (data) => {
      return renderIcon(
        data.icon ??
          data?.[field_icon] ??
          getDepthValue(data, field_icon, null),
        `me-1 ${data.class ?? data.className} ${getColor(data)}`,
        20
      )
    }

    const getImgValue = (data) => {
      let img =
        // itemHasImg ??
        data?.[field_img ?? "img"] ??
        getDepthValue(data, field_img, null) ??
        data.img
      return img
    }

    const getIconValue = (data) => {
      let icon =
        // itemHasIcon ??
        data?.[field_icon ?? "icon"] ??
        getDepthValue(data, field_icon, null) ??
        data.icon
      return icon
    }

    const getImg = (data) => {
      let img =
        data.img ?? data?.[field_img] ?? getDepthValue(data, field_img, null)
      let color =
        data.color ??
        data?.[field_color] ??
        getDepthValue(data, field_color, null)
      return img ? (
        <img
          src={img}
          style={{
            height: "26px",
            width: "26px",
            borderRadius: "10%",
            marginRight: "10px"
          }}
        />
      ) : (
        <Avatar
          initials
          color={color}
          className="me-1"
          size="sm"
          content={data.label}
        />
      )
    }

    ////IF DROPDOWN HAS IMAGE FIELD
    const { Option, SingleValue, MultiValue } = components
    const IconOption = (props) => (
      <Option {...props}>
        {getImgValue(props.data) || (!field_icon && !itemHasIcon)
          ? getImg(props.data)
          : getIcon(props.data)}
        {props.data.label}
      </Option>
    )

    const IconSingleValue = (props) => (
      <SingleValue {...props}>
        {getImgValue(props.data) || (!field_icon && !itemHasIcon)
          ? getImg(props.data)
          : getIcon(props.data)}
        {props.data.label}
      </SingleValue>
    )

    const IconMultiValue = (props) => {
      return (
        <MultiValue {...props}>
          {getImgValue(props.data) ? getImg(props.data) : getIcon(props.data)}
          {props.data.label}
        </MultiValue>
      )
    }

    componentsInput = {
      components: {
        SingleValue: IconSingleValue,
        Option: IconOption
      }
    }
    if (item?.isMultiSelect) {
      componentsInput = {
        components: {
          SingleValue: IconSingleValue,
          MultiValue: IconMultiValue,
          Option: IconOption
        }
      }
    }

    // if (itemHasImg) {

    //   const IconOption = (props) => <Option {...props}>{props.data.label}</Option>

    //   const IconMultiValue = (props) => {
    //     return (
    //       <MultiValue {...props}>
    //         {getImg(props.data)}
    //         {props.data.label}
    //       </MultiValue>
    //     )
    //   }

    //   const IconSingleValue = (props) => (
    //     <SingleValue {...props}>
    //       {getImg(props.data)}
    //       {props.data.label}
    //     </SingleValue>
    //   )

    //   componentsInput = {
    //     components: {
    //       SingleValue: IconSingleValue,
    //       Option: IconOption
    //     }
    //   }
    //   if (item?.isMultiSelect) {
    //     componentsInput = {
    //       components: {
    //         SingleValue: IconSingleValue,
    //         MultiValue: IconMultiValue,
    //         Option: IconOption
    //       }
    //     }
    //   }
    // } else if (!itemHasImg && itemHasIcon) {
    //   // const { Option, SingleValue, MultiValue } = components

    //   const IconOption = (props) => (
    //     <Option {...props}>
    //       {getIcon(props.data)}
    //       {props.data.label}
    //     </Option>
    //   )
    //   const IconSingleValue = (props) => (
    //     <SingleValue {...props}>
    //       {getIcon(props.data)}
    //       {props.data.label}
    //     </SingleValue>
    //   )
    //   const IconMultiValue = (props) => {
    //     return (
    //       <MultiValue {...props}>
    //         <img
    //           src={props.data.img}
    //           style={{
    //             height: "26px",
    //             width: "26px",
    //             borderRadius: "10%",
    //             marginRight: "10px"
    //           }}
    //         />
    //         {props.data.label}
    //       </MultiValue>
    //     )
    //   }
    //   componentsInput = {
    //     components: {
    //       SingleValue: IconSingleValue,
    //       Option: IconOption
    //     }
    //   }
    //   if (item?.isMultiSelect) {
    //     componentsInput = {
    //       components: {
    //         SingleValue: IconSingleValue,
    //         MultiValue: IconMultiValue,
    //         Option: IconOption
    //       }
    //     }
    //   }
    // }
    // return componentsInput
  }
  return componentsInput
}

export const renderInputOnGrid = (row, column, inputOnGridChild) => {
  const fieldName = column?.fieldName
  const fieldLabel = column?.columnName
  const fieldNameEl = column?.fieldName?.replace(/\./g, "_")
  const id = row?.id
  const value = row[fieldName]
  const item = column?.editableInputOpt

  let placeholder = `Enter ${column?.columnName ?? column?.fieldName}`
  // console.log(inputOnGrid)
  const currentStateVal = (inputOnGrid[fieldName] ?? []).find(
    (c) => c.id === id
  )?.value
  const id_element = `grid_input_${fieldNameEl}_${id}`
  const id_element_save = `grid_input_btn_save_${fieldNameEl}_${id}`
  const id_element_cancel = `grid_input_btn_cancel_${fieldNameEl}_${id}`
  // console.log(value, inputOnGridChild, inputOnGrid, currentStateVal)
  let btnSave =
    value !== currentStateVal ? (
      <Fragment>
        <Button.Ripple
          id={id_element_save}
          className="btn-icon"
          outline
          color="secondary"
          onClick={(event) => handleSaveOnRow(event, row, column)}
        >
          <Save size={16} />
        </Button.Ripple>
        <UncontrolledTooltip placement="bottom" target={id_element_save}>
          {t("Save Changes")}
        </UncontrolledTooltip>
      </Fragment>
    ) : (
      <Fragment>
        <Button.Ripple
          id={id_element_save}
          className="btn-icon"
          outline
          color="secondary"
          onClick={(event) => handleCancelSaveOnRow(event, row, column)}
        >
          <X size={16} />
        </Button.Ripple>
        <UncontrolledTooltip placement="bottom" target={id_element_save}>
          {t("Cancel Edit")}
        </UncontrolledTooltip>
      </Fragment>
    )
  const defaultValuex = !column?.editableInputOpt
    ?.afterEditNextRowSameInputClearValue
    ? row[column?.fieldName]
    : ""

  const handleKeyPress = (event, row, column, id_element) => {
    if (event.key === "Enter") {
      // console.log("Enter key pressed")
      const afterChangeEventType = "nextRow"
      const afterChangeEventInputId = id_element
      handleSaveOnRow(
        event,
        row,
        column,
        afterChangeEventType,
        afterChangeEventInputId
      )
    }
  }

  if (column?.editableInputOpt?.type === "number") {
    return (
      <InputGroup>
        <Input
          type="number"
          // value={defaultValuex}
          defaultValue={defaultValuex}
          // defaultValue={inputOnGrid[column?.fieldName][row?.id]?.value}
          // value={inputOnGrid[column?.fieldName][row?.id]?.value}
          onChange={(event) => {
            // console.log(event.target.value)
            const newValue =
              column?.editableInputOpt?.valueType === "int"
                ? parseInt(event.target.value ?? 0)
                : event.target.value ?? 0
            changeInputOnGrid(row?.id, column?.fieldName, newValue)
            // let index = inputOnGrid[column?.fieldName]?.findIndex(
            //   (c) => c.id === row?.id
            // )

            // if (
            //   index !== undefined &&
            //   inputOnGrid[column?.fieldName] &&
            //   inputOnGrid[column.fieldName][index]
            // ) {
            //   inputOnGrid[column.fieldName][index]["value"] = newValue
            //   setInputOnGrid((prevState) => {
            //     const newState = { ...prevState }
            //     const index = newState[column.fieldName].findIndex(
            //       (c) => c.id === row.id
            //     )
            //     if (index !== undefined && newState[column.fieldName]) {
            //       newState[column.fieldName][index]["value"] = newValue
            //     }
            //     return newState
            //   })
            // } else {
            //   // if id does not exist in inputOnGrid[column?.fieldName], push newValue
            //   inputOnGrid[column.fieldName] = [
            //     { id: row?.id, value: newValue }
            //   ]
            //   setInputOnGrid((prevState) => {
            //     const newState = { ...prevState }
            //     newState[column.fieldName] = [
            //       ...(newState[column.fieldName] || []),
            //       { id: row?.id, value: newValue }
            //     ]
            //     return newState
            //   })
            // }
            // console.log(index, inputOnGrid)
          }}
          id={id_element}
          placeholder={placeholder}
        />
        {btnSave}
      </InputGroup>
    )
  } else if (
    column?.editableInputOpt?.type === "boolean" ||
    column?.type === "boolean"
  ) {
    return (
      <InputGroup
        style={{
          justifyContent: "flex-end"
        }}
      >
        <InputGroupText>
          <Input
            type="checkbox"
            defaultChecked={defaultValuex === true}
            onChange={(event) => {
              // console.log(event, event.target.checked)
              const newValue = event.target.checked
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            id={id_element}
          />
        </InputGroupText>
        {btnSave}
      </InputGroup>
    )
  } else if (column?.editableInputOpt?.type === "currency") {
    return (
      <InputGroup>
        {/* <InputGroupText>
          {column?.editableInputOpt?.icon ? (
            renderIcon(column?.editableInputOpt?.icon, "", 14)
          ) : localStorage.getItem("i18nextLng") === "id" ? (
            <FontAwesomeIcon className="align-middle" icon="rupiah-sign" />
          ) : (
            <Paid size={14} />
          )}
        </InputGroupText> */}
        <Cleave
          className="form-control"
          value={defaultValuex}
          placeholder={
            column?.editableInputOpt?.placeholder
              ? t(column?.editableInputOpt?.placeholder)
              : `10.000`
          }
          options={{
            numeral: true
            // numeralDecimalMark: ",",
            // numeralDecimalScale: 2,
            // delimiter: ".",
            // numeralPositiveOnly: true
          }}
          id={id_element}
          onChange={(event) => {
            const newValue =
              event.target.rawValue !== ""
                ? parseFloat(event.target.rawValue)
                : 0
            changeInputOnGrid(row?.id, column?.fieldName, newValue)
          }}
        />
        {btnSave}
      </InputGroup>
    )
  } else {
    if (item?.dataFrom?.api && item?.type === "dropdown") {
      return (
        <InputGroup>
          <Select
            id={id_element}
            isClearable={!item?.rules?.required}
            className={classnames("react-select react-select-on-grid")}
            styles={{
              width: "89%"
            }}
            autoFocus
            placeholder={
              item.placeholder
                ? t(item.placeholder)
                : `${t("Select")} ${t(fieldLabel)}`
            }
            noOptionsMessage={() => t("No Options Found")}
            loadingMessage={() => t("Loading...")}
            classNamePrefix="select"
            onChange={(event) => {
              const newValue = event?.value
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            onFocus={(e) => {
              e.preventDefault()
              return loadDropdownFromApi(fieldName, e, item)
            }}
            isLoading={tempAsyncOptLoading[fieldName] ?? false}
            options={tempAsyncOptValues[fieldName]}
            defaultValue={defaultValuex}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
          {btnSave}
        </InputGroup>
      )
    } else if (item?.dataFrom?.value && item?.type === "dropdown") {
      let componentsInput = renderAvatarImgIcon(item)
      return (
        <InputGroup>
          <Select
            id={id_element}
            isClearable={!item?.rules?.required}
            className={classnames("react-select react-select-on-grid")}
            styles={{
              width: "89%"
            }}
            autoFocus
            placeholder={
              item.placeholder
                ? t(item.placeholder)
                : `${t("Select")} ${t(fieldLabel)}`
            }
            noOptionsMessage={() => t("No Options Found")}
            loadingMessage={() => t("Loading...")}
            classNamePrefix="select"
            onChange={(event) => {
              const newValue = event?.value
              // console.log(newValue)
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            isLoading={false}
            options={item?.dataFrom?.value ?? []}
            defaultValue={item?.dataFrom?.value?.find((item) => item.selected)}
            value={item?.dataFrom?.value.find((c) => c.value === defaultValuex)}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            {...componentsInput}
          />
          {btnSave}
        </InputGroup>
      )
    } else {
      return (
        <InputGroup>
          <Input
            type="text"
            autoFocus
            defaultValue={defaultValuex}
            id={id_element}
            placeholder={placeholder}
            onChange={(event) => {
              const newValue = event.target.value
              changeInputOnGrid(row?.id, column?.fieldName, newValue)
            }}
            onKeyPress={(event) => {
              handleKeyPress(event, row, column, id_element)
            }}
          />
          {btnSave}
        </InputGroup>
      )
    }
  }
}

////CHARTS FUNCTION
export const getRespData = (item, type = "categories", store) => {
  const { t } = useTranslation()
  const id = item.chart_id ?? item.cardId ?? item?.pageId
  const idEl = `dashboard_card_${item?.chart_id ?? item?.cardId ?? index}`

  const data = store.data?.[id]
  const resp_format = item?.chartOpt?.resp_format

  const fieldName_categories = resp_format?.fieldName_categories ?? "title"
  const fieldName_value = resp_format?.fieldName_value ?? "count"
  const fieldName_color =
    resp_format?.fieldName_categories_color ?? "cardStyle.background"

  let result = []
  data?.map((val, i) => {
    // console.log(val)
    let valueFix = ""
    if (type === "categories") {
      valueFix = t(getDepthValue(val, fieldName_categories))
    } else if (type === "value") {
      valueFix = parseInt(getDepthValue(val, fieldName_value))
    } else if (type === "color") {
      const valueBef = getDepthValue(val, fieldName_color)
      if (valueBef) {
        const colorRegex = /#[0-9A-Fa-f]{6}|#[0-9A-Fa-f]{3}$/g // Regex untuk menemukan kode warna HEX
        const matches = valueBef.match(colorRegex)
        if (matches && matches.length > 0) {
          const lastColor = matches[matches.length - 1]
          valueFix = lastColor
        }
      } else {
        valueFix = valueBef ?? "#a4f8cd"
      }
    }
    result.push(valueFix)
  })
  // console.log(result)
  return result
}

export const getSeries = (item, store, onlyData = false) => {
  const { t } = useTranslation()
  const id = item.chart_id ?? item.cardId ?? item?.pageId
  const idEl = `dashboard_card_${item?.chart_id ?? item?.cardId ?? index}`
  const resp_format = item?.chartOpt?.resp_format
  let result = []
  if (!resp_format) {
    const data = store.data?.[id]
    if (data?.[0]?.["name"]) {
      result = store.data?.[item.chart_id]?.map((row) => {
        return {
          name: `${row["name"]}`,
          data: row["data"]?.split(",")?.map((e) => parseInt(e))
        }
      })
    } else {
      result = [{ name: "", data: getRespData(item, "value", store) }]
    }
  } else {
    const newData = {
      name: "",
      data: getRespData(item, "value", store)
    }
    result.push(newData)
  }

  if (onlyData) {
    result = result?.map((row) => {
      return row.data
    })[0]
  }
  // console.log(result)
  return result
}

export function replaceLogo(obj, newValue, id) {
  // console.log(id, obj)
  if (obj && newValue && id) {
    if (obj?.id === id) {
      if (obj.attributes) {
        obj.attributes["logo"] = newValue
      } else {
        obj["attributes"]["logo"] = newValue
      }
    } else {
      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((child) => replaceLogo(child, newValue, id))
      }
    }
  }
  return obj
}

export const currencyCodes = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "FOK",
  "GBP",
  "GEL",
  "GGP",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "IMP",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JEP",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KID",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SPL",
  "SRD",
  "STN",
  "SVC",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TVD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XDR",
  "XOF",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
  "ZWD"
]

export function flattenArray(arr) {
  let flatArray = []
  function flatten(obj, parent_id = null, fieldId, parentIdField) {
    let newObj = { ...obj }
    if (parent_id !== null) {
      newObj[parentIdField] = parent_id
      delete newObj.children
    } else {
      delete newObj.children
    }
    flatArray.push(newObj)

    if (obj.children && obj.children.length > 0) {
      for (let child of obj.children) {
        flatten(child, obj[fieldId])
      }
    }
  }

  for (let item of arr) {
    flatten(item)
  }

  return flatArray
}

export function arrayToNested(arr, fieldId, parentIdField) {
  let nestedObject = {}
  let mapping = {}
  arr.forEach((obj) => {
    let id = obj[fieldId]
    let parentId = obj[parentIdField]
    nestedObject[id] = { ...obj, children: nestedObject[id]?.children || [] }

    if (parentId === null || obj?.isRoot) {
      mapping[id] = nestedObject[id]
    } else {
      if (!nestedObject[parentId]) {
        nestedObject[parentId] = { children: [] }
      }
      nestedObject[parentId].children.push(nestedObject[id])
    }
  })

  let roots = Object.values(mapping).filter(
    (obj) => obj[parentIdField] === null || obj?.isRoot
  )
  return roots.length === 1 ? roots[0] : {}
}

export function updateExpandedToFalse(obj, value) {
  if (!obj) {
    return null
  }

  if (obj?.children && obj?.children.length > 0) {
    obj.children.forEach((child) => updateExpandedToFalse(child, value))
  }

  obj["expanded"] = value
  return obj
}

export function isNestedObjectArray(arr) {
  for (const element of arr) {
    if (
      typeof element === "object" &&
      element !== null &&
      !Array.isArray(element)
    ) {
      // Check if the element is an object (not null) and not an array
      return true // Array of objects is nested
    }
  }
  return false // Array of objects is not nested
}

export const convertToClickableLinks = (message) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  const parts = message.split(urlPattern)

  return parts.map((part, index) => {
    if (urlPattern.test(part)) {
      return `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`
      //  (<a key={index} href={part} target="_blank" rel="noopener noreferrer">
      //   {part}
      // </a>)
    }
    return part
  })
}

export const convertHtmlToEmoji = (message, toHtml = true) => {
  // Pola untuk mencocokkan karakter emoji HTML (&#XXXXX;)
  const emojiPattern = /&#(\d+);/g

  // Gantikan karakter emoji HTML dengan emoji yang sesuai
  const convertedMessage = message.replace(emojiPattern, (match, dec) => {
    const emoji = String.fromCodePoint(dec)
    // return <span style={{ fontSize: "1.5rem" }}>${emoji}</span>
    if (toHtml) {
      return `<span style="font-size: 1.5rem;">${emoji}</span>`
    } else {
      return emoji
    }
  })

  return convertedMessage
}
