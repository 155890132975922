import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const pageTitle = "Cetak Laporan" ////
export const storeName = "incoices" ////
export const endpointName = "invoices/export" ////

export const serverSideGrid = true ////
export const detailFromGrid = true //// GET DETAIL DATA FROM EXISTING DATA GRID (FASTER PEFORMANCE)
export const getAlwaysGrid = false //// GET ALWAYS GRID DATA EVERY CLICK MENU OR OPEN PAGE
export const getApiAfterPut = true ////
export const getApiAfterPost = true ////
export const getApiAfterDelete = true ////
export const getApiSummaryData = true //// GET SUMMARY DATA FROM BACKEND API
export const detailPage = true //// IF TRUE { WILL BE ACTIVATED DETAIL PAGE AND MENU ACTION BUTTON } ELSE { DETAIL PAGE SHOW ON MODAL }

export const getInvoice = createAsyncThunk(
  `invoices/getInvoice`,
  async (params) => {
    // console.log("getInvoice")
    // console.log(params)
    const module_name = params.module_name
    const rpt_name = params.rpt_name
    const output_type = params.output_type
    const print = params.print
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    delete params.module_name
    delete params.rpt_name
    delete params.output_type
    delete params.print

    const response = await axios
      .create()
      .get(
        `https://api-danadesa.insaba.co.id/invoices/export/${module_name}/${rpt_name}/${output_type}`,
        {
          params,
          responseType: "blob"
        }
      )
      .then((response) => {
        if (print) {
          window.open(URL.createObjectURL(response.data)).print()
        } else {
          window.open(URL.createObjectURL(response.data))
        }
      })
      .catch((error) => console.log(error))
    return {
      params,
      data: response.data.data
    }
  }
)

export const getInvoicePlain = createAsyncThunk(
  `invoices/getInvoicePlain`,
  async (params) => {
    // console.log(params)
    try {
      const { url, print, ...restParams } = params
      // console.log(url, print, restParams)
      if (restParams) {
        Object.keys(restParams).forEach((key) => {
          if (restParams[key] === null || restParams[key] === "") {
            delete restParams[key]
          }
        })
      }

      const response = await axios
        .create()
        .get(url, {
          params: restParams,
          responseType: "blob"
        })
        .then((response2) => {
          // if (response2.status !== 200) {
          //   throw new Error("Failed to fetch data")
          // }
          if (print) {
            window.open(URL.createObjectURL(response2.data)).print()
          } else {
            window.open(URL.createObjectURL(response2.data))
          }
        })
        .catch((error) => console.log(error))

      // const blobUrl = URL.createObjectURL(response.data)
      // if (print) {
      //   const printWindow = window.open(blobUrl)
      //   printWindow.print()
      // } else {
      //   window.open(blobUrl)
      // }

      return {
        params: restParams,
        data: response?.data?.data ?? response?.data
      }
    } catch (error) {
      let errorData
      if (error.response) {
        // Server merespon dengan status di luar kisaran 2xx
        errorData = error.response.data
      } else if (error.request) {
        // Permintaan dibuat tapi tidak ada respon
        errorData = { message: "No response received from server" }
      } else {
        // Ada kesalahan dalam mengatur permintaan
        errorData = { message: error.message }
      }

      return rejectWithValue(errorData)
    }
  }
)

export const resetData = createAsyncThunk(
  `invoices/resetData`,
  async ({ rejectWithValue }) => {
    console.log("resetData activity")
    return rejectWithValue({})
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: storeName,
  initialState: {
    pageTitle,

    // ALL
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: [],

    params: {},
    selectedData: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///ALL
      .addCase(getInvoice.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false
        state.data = action?.payload?.data
        state.params = action.payload.params
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })
      .addCase(getInvoicePlain.pending, (state, action) => {
        console.log("asdsad")
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getInvoicePlain.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false
        state.data = action?.payload?.data
        state.params = action.payload.params
      })
      .addCase(getInvoicePlain.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })

      ///RESET
      .addCase(resetData.rejected, (state) => {
        state.data = []
      })
  }
})

export default StoreSlice.reducer
