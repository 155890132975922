// ** React Imports
import { Fragment } from "react"

// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"

// ** Route Components
import PrivateRoute from "@components/routes/PrivateRoute"
import PublicRoute from "@components/routes/PublicRoute"

// ** Utils
import { isObjEmpty, isUserLoggedIn } from "@utils"

import { generateRouter } from "@src/views/apps/menu/store/my_menu"
import { useSelector } from "react-redux"

// ** Routes Imports
import AuthenticationRoutes from "./Authentication"
import DashboardRoutes from "./Dashboards"
import PagesRoutes from "./Pages"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "%s - "

// ** Default Route
const DefaultRoute = "/dashboard"

// ** Merge Routes
let Routes = [...AuthenticationRoutes, ...PagesRoutes, ...DashboardRoutes]

const getRouteMeta = (route) => {
  // console.log(route)
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []
  if (Routes) {
    // console.log(Routes)
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment
          // console.log(route.element)
          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const storeMenu = useSelector((state) => state.my_menu)
  if (isUserLoggedIn() !== null) {
    if (storeMenu.dataMyMenu.length > 0) {
      const genRoutes = generateRouter(storeMenu.dataMyMenu)
      const paths = new Set(Routes.map((d) => d.path))
      const newRoute = genRoutes.filter((d) => !paths.has(d.path))
      Routes = [...Routes, ...newRoute]
    }
  }

  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })

  return AllRoutes
}

export { DefaultRoute, Routes, TemplateTitle, getRoutes }
