import {
  disableBtnCondition,
  neutralizeBack,
  revivalBack
} from "@src/utility/Utils"
import { Gantt, ViewMode } from "gantt-task-react"
import "gantt-task-react/dist/index.css"
import { Fragment, useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"
import ModalAddEdit from "../modal"
import StandardTooltipContent from "./tooltip"
import { ViewSwitcher } from "./view-switcher"
import TaskListHeader from "./TaskListHeader"
import TaskListColumn from "./TaskListColumn"

import {
  ErrorFetchData,
  Loader,
  NoDataDisplay
} from "@src/shared/CommonComponents"

import { AbilityContext } from "@src/utility/context/Can"

import UILoader from "@components/ui-loader"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"
import {
  deleteData,
  filterCurrentData,
  getDataById,
  getDataList,
  putData,
  resetSelectedData,
  setDataById,
  setInitialDataAdd,
  setSelectedData
} from "../store"

import DynamicForm from "@src/utility/generateForm/dynamicForm"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import { getUserData } from "@utils"
import { CornerDownRight, Edit, Trash2 } from "react-feather"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const GanttChartCrud = ({
  store,
  pageAttr,
  storeName,
  filtersCustomAdd,
  handleFiltersChangeToParent
}) => {
  const currentDate = new Date()
  const [view, setView] = useState(pageAttr?.crudOpt?.ganttViewMode ?? "Day")
  const [tasks, setTasks] = useState([])
  const [isChecked, setIsChecked] = useState(
    pageAttr?.crudOpt?.ganttTaskSideBarShow ?? false
  )

  let columnWidth = 60
  if (view === ViewMode.Year) {
    columnWidth = 430
  } else if (view === ViewMode.Month) {
    columnWidth = 380
  } else if (view === ViewMode.Week) {
    columnWidth = 250
  }

  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingModalPrompt, setLoadingModalPrompt] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const { colors } = useContext(ThemeColors)
  const [showModal, setShowModal] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [taskSelected, setTaskSelected] = useState({})
  const [loading, setLoading] = useState(true)
  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [lastDateChanged, setLastDateChanged] = useState({})
  const [taskClicked, setTaskClicked] = useState(false)
  const [taskChanged, setTaskChanged] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const paramss = useParams()
  const userData = getUserData()
  const storeMyUnit = useSelector((state) => state.units)

  const getStartEndDateForProject = (projectTasks, projectonly, projectId) => {
    const st = pageAttr?.crudOpt?.ganttStartField ?? "start"
    const en = pageAttr?.crudOpt?.ganttEndField ?? "end"
    let start = new Date(
      Math.min(
        ...projectTasks.map((element) => {
          return new Date(element[st])
        })
      )
    )

    let end = new Date(
      Math.max(
        ...projectTasks.map((element) => {
          return new Date(element[en])
        })
      )
    )

    const headerStartProjectDate = new Date(projectonly?.[st])
    const headerEndProjectDate = new Date(projectonly?.[en])
    if (headerStartProjectDate < start) {
      start = headerStartProjectDate
    }

    if (headerEndProjectDate > end) {
      end = headerEndProjectDate
    }

    return [start, end]
  }

  const getColor = (value) => {
    let color = colors.secondary.light
    if (value < 10) {
      color = colors.secondary.main
    } else if (value < 30) {
      color = colors.danger.main
    } else if (value < 50) {
      color = colors.warning.main
    } else if (value < 70) {
      color = colors.primary.main
    } else if (value < 90) {
      color = colors.info.main
    } else {
      color = colors.success.main
    }
    return color
  }

  const getTitle = (item) => {
    if (!Array.isArray(pageAttr?.crudOpt?.ganttTitleField)) {
      return pageAttr?.crudOpt?.ganttGroupTitleField
        ? `${item?.[pageAttr?.crudOpt?.ganttTitleField ?? "name"]}`
        : item?.[pageAttr?.crudOpt?.ganttTitleField ?? "name"]
    } else {
      let result = ""
      const separator = pageAttr?.crudOpt?.ganttTitleSeparator ?? ""
      pageAttr?.crudOpt?.ganttTitleField?.map((lbl) => {
        // console.log(lbl)
        if (item?.[lbl]) {
          result = (result !== "" ? result + separator : result) + item[lbl]
        }
      })
      return result
    }
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  function handleReset() {
    dispatch(resetSelectedData(storeName))
    // setPageType("add")
    setShow(false)
  }

  const [loadingPostPut, setLoadingPostPut] = useState(false)

  const updateDataToApi = async (
    rowData,
    changedData,
    newDataTemp,
    task = null
  ) => {
    const data = { ...changedData }

    setLoadingText(`${t("Updating")} ${t(pageAttr.pageTitle)} Data`)
    setLoadingPostPut(true)
    // console.log(newDataTemp)
    dispatch(
      setDataById({
        rowData: newDataTemp,
        storeName: storeName
      })
    ).then((_) => {
      // RE-CALCULATE START END PROJECT / PROGRESS BEGIN
      if (newDataTemp?.project) {
        const projectTasksbef = store.pageData[storeName]?.data.map((t) =>
          t.id === newDataTemp?.id ? newDataTemp : t
        )

        const projectTasks = projectTasksbef.filter(
          (t) =>
            t?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"] ===
            newDataTemp?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"]
        )

        const projectonly =
          projectTasksbef[
            projectTasksbef.findIndex(
              (t) =>
                t.id ===
                newDataTemp?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"]
            )
          ]

        ////PROGRESS CHANGE
        const totalProgressSum = projectTasks.reduce((accumulator, object) => {
          return (
            accumulator +
            object?.[pageAttr?.crudOpt?.ganttProgressField ?? "progress"]
          )
        }, 0)

        const idealPercent = projectTasks.length * 100

        // console.log(totalProgressSum)
        const changedProgress = (totalProgressSum / idealPercent) * 100
        const changedProject = {
          ...projectonly,
          [pageAttr?.crudOpt?.ganttProgressField ?? "progress"]: Math.round(
            changedProgress ?? 0
          )
        }
        dispatch(
          setDataById({
            rowData: changedProject,
            storeName: storeName
          })
        )
        console.log(newDataTemp, "cascadeToParent begin")
        /////////////// UPDATE HEADER CASCADE BEGIN
        if (newDataTemp?.cascadeToParent) {
          console.log("cascadeToParent begin")
          const projectTasksHeader = projectTasksbef.filter(
            (t) =>
              t?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"] ===
                newDataTemp?.[
                  pageAttr?.crudOpt?.ganttProjectField ?? "project"
                ] &&
              t?.[pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"] ===
                newDataTemp?.[
                  pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"
                ] &&
              t?.cascadeToParent
          )

          const headeronly =
            projectTasksbef[
              projectTasksbef.findIndex(
                (t) =>
                  t.id ===
                  newDataTemp?.[
                    pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"
                  ]
              )
            ]

          const totalProgressSumHeader = projectTasksHeader.reduce(
            (accumulator, object) => {
              return (
                accumulator +
                object?.[pageAttr?.crudOpt?.ganttProgressField ?? "progress"]
              )
            },
            0
          )

          const idealPercentHeader = projectTasksHeader.length * 100
          const changedProgressHeader =
            (totalProgressSumHeader / idealPercentHeader) * 100
          const changedProjectHeader = {
            ...headeronly,
            [pageAttr?.crudOpt?.ganttProgressField ?? "progress"]: Math.round(
              changedProgressHeader ?? 0
            )
          }

          // console.log(newDataTemp)
          // console.log(projectTasksHeader)
          // console.log(headeronly)
          // console.log(totalProgressSumHeader, idealPercentHeader)
          // console.log(changedProjectHeader)

          dispatch(
            setDataById({
              rowData: changedProjectHeader,
              storeName: storeName
            })
          )
        }
        /////////////// UPDATE HEADER CASCADE END

        //////////UPDATE PROGESS GLOBAL PROJECT BEGIN
        const [start, end] = getStartEndDateForProject(
          projectTasks,
          projectonly,
          newDataTemp?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"]
        )
        if (
          new Date(
            projectonly[pageAttr?.crudOpt?.ganttStartField ?? "start"]
          ).getTime() !== start.getTime() ||
          new Date(
            projectonly[pageAttr?.crudOpt?.ganttEndField ?? "end"]
          ).getTime() !== end.getTime()
        ) {
          const changedProject = {
            ...projectonly,
            [pageAttr?.crudOpt?.ganttStartField ?? "start"]:
              start.toISOString(),
            [pageAttr?.crudOpt?.ganttEndField ?? "end"]: end.toISOString()
          }
          dispatch(
            setDataById({
              rowData: changedProject,
              storeName: storeName
            })
          )
        }
        // RE-CALCULATE START END PROJECT END
      }
      //////////UPDATE PROGESS GLOBAL PROJECT END

      setTaskChanged(false)
    })

    dispatch(setSelectedData({ rowData, storeName: storeName })).then((rz) => {
      data["storeName"] = storeName
      data["endpointName"] = pageAttr?.crudOpt?.endpointName
      data["callback_page"] = window.location.href
      data["baseUrl"] = pageAttr?.crudOpt?.baseUrl
      data["updateCurrentStore"] = false
      dispatch(putData(data))
        .then((res) => {
          localStorage.removeItem("task_changed")
          // console.log(res)
          setLoadingPostPut(false)
          if (res.meta.requestStatus === "fulfilled") {
            toast.success(
              `${t("Successfully Updated")} ${t(pageAttr.pageTitle)}`,
              {
                style: {
                  padding: "16px",
                  color: colors.success.main,
                  border: `1px solid ${colors.success.main}`
                },
                iconTheme: {
                  primary: colors.success.main
                }
              }
            )
          } else if (res.meta.requestStatus === "rejected") {
            errorToast(res)
          }
        })
        .catch((error) => {
          setLoadingPostPut(false)
          // console.error(error)
          errorToast(error)
        })
    })
  }

  const handleTaskChange = async (task) => {
    // if (!taskChanged && !taskClicked) {
    const allData = task.rowData

    if (
      !localStorage.getItem("task_changed") &&
      !localStorage.getItem("task_clicked")
    ) {
      localStorage.setItem("task_changed", "1")
      // setTaskChanged(true)
      setTaskSelected(task)
      console.log(`On date change Id:${task.id}`)

      let oldData = {
        [pageAttr?.crudOpt?.ganttStartField ?? "start"]:
          allData[pageAttr?.crudOpt?.ganttStartField ?? "start"],
        [pageAttr?.crudOpt?.ganttEndField ?? "end"]:
          allData[pageAttr?.crudOpt?.ganttEndField ?? "start"]
      }
      oldData.id = allData?.id

      let data = { ...oldData }
      const startNewStr = `${task.start.getFullYear()}-${
        task.start.getMonth() + 1
      }-${task.start.getDate()} ${task.start.getHours()}:${task.start.getMinutes()}`
      const endNewStr = `${task.end.getFullYear()}-${
        task.end.getMonth() + 1
      }-${task.end.getDate()} ${task.end.getHours()}:${task.end.getMinutes()}`

      const startNew =
        pageAttr?.crudOpt?.ganttEnableTime === true
          ? startNewStr
          : startNewStr.substring(0, 10)
      const endNew =
        pageAttr?.crudOpt?.ganttEnableTime === true
          ? endNewStr
          : endNewStr.substring(0, 10)
      data[pageAttr?.crudOpt?.ganttStartField ?? "start"] = startNew
      data[pageAttr?.crudOpt?.ganttEndField ?? "end"] = endNew

      // console.log(JSON.stringify(lastDateChanged))
      // console.log(JSON.stringify(data))
      if (JSON.stringify(lastDateChanged) !== JSON.stringify(data)) {
        setLastDateChanged({ ...data })

        await updateDataToApi(oldData, data, { ...allData, ...data }, task)

        //  if (task.project && (task.start || task.end)) {
        //   const project =
        //   store.pageData[storeName]?.data?.[store.pageData[storeName]?.data?.findIndex((t) => t.id === task.project)]

        //   if (
        //     project[pageAttr?.crudOpt?.ganttStartField ?? "start"] !== task.start.getTime() ||
        //     project.end.getTime() !== end.getTime()
        //   ) {
        //     const changedProject = { ...project, start, end }
        //     newTasks = newTasks.map((t) =>
        //       t.id === task.project ? changedProject : t
        //     )
        //   }
        // }

        // let newTasks = store.pageData[storeName]?.data?.map((t) =>
        //   t.id === task.id ? task : t
        // )

        //   const newTasks = store.pageData[storeName]?.data.map((t) =>
        //   t.id === task?.rowData?.id
        //     ? { ...task?.rowData, hideChildren: task.hideChildren }
        //     : t
        // )
        //   if (task.project) {
        //     const [start, end] = getStartEndDateForProject(
        //       tasks,
        //       task.project
        //     )
        //     const project =
        //       newTasks[newTasks.findIndex((t) => t.id === task.id_Projects)]
        //     if (
        //       project.start.getTime() !== start.getTime() ||
        //       project.end.getTime() !== end.getTime()
        //     ) {
        //       const changedProject = { ...project, start, end }
        //       newTasks = newTasks.map((t) =>
        //         t.id === task.project ? changedProject : t
        //       )
        //     }
        //   }
      } else {
        console.log("same with last changed!")
      }
    }
  }

  const handleTaskDelete = (task) => {
    const conf = window.confirm(`Are you sure about ${task.name} ?`)
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id))
    }
    return conf
  }

  const handleProgressChange = (task) => {
    if (
      !localStorage.getItem("task_changed") &&
      !localStorage.getItem("task_clicked")
    ) {
      localStorage.setItem("task_changed", "1")
      setTaskSelected(task)
      console.log(`On progress change Id:${task.id}`)

      const allData = { ...task.rowData }
      let oldData = {
        [pageAttr?.crudOpt?.ganttProgressField ?? "progress"]:
          allData[pageAttr?.crudOpt?.ganttProgressField ?? "progress"]
      }
      oldData.id = allData?.id

      let data = { ...oldData }
      data[pageAttr?.crudOpt?.ganttProgressField ?? "progress"] = task.progress
      // console.log(task)
      if (JSON.stringify(lastDateChanged) !== JSON.stringify(data)) {
        setLastDateChanged({ ...data })
        updateDataToApi(oldData, data, { ...allData, ...data })
        //   setTaskChanged(false)
      } else {
        console.log("same with last changed!")
      }
    }
  }

  const handleDblClick = (task) => {
    alert(`On Double Click event Id:${task.id}`)
  }

  const handleClick = (task) => {
    // console.log(task)
    // disableBtnCondition(pageAttr?.crudOpt, task)
    const task_changed = localStorage.getItem("task_changed")
    if (!task_changed) {
      localStorage.setItem("task_clicked", "1")
      // console.log(`On Click event Id:${task.id}`)
      setPageType("view")
      if (task?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"]) {
        setTaskSelected(task)
        setShowPrompt(!showPrompt)
      }
      localStorage.removeItem("task_clicked")
    }

    if (task?.type === "project") {
      task.hideChildren = !task.hideChildren
      handleExpanderClick(task)
    }
  }

  const handleEdit = (task) => {
    setShowPrompt(!showPrompt)
    const startNewStr = `${task.start.getFullYear()}-${
      task.start.getMonth() + 1
    }-${task.start.getDate()} ${task.start.getHours()}:${task.start.getMinutes()}`
    const endNewStr = `${task.end.getFullYear()}-${
      task.end.getMonth() + 1
    }-${task.end.getDate()} ${task.end.getHours()}:${task.end.getMinutes()}`

    const startNew =
      pageAttr?.crudOpt?.ganttEnableTime === true
        ? startNewStr
        : startNewStr.substring(0, 10)
    const endNew =
      pageAttr?.crudOpt?.ganttEnableTime === true
        ? endNewStr
        : endNewStr.substring(0, 10)
    setPageType("edit")
    handleAddEdit(task?.rowData)
  }

  const handleSelect = (task, isSelected) => {
    console.log(`${task.name} has ${isSelected ? "selected" : "unselected"}`)
    // if (isSelected) {
    // setTaskSelected(task)
    // setShowPrompt(!showPrompt)
    // }
  }

  const handleExpanderClick = (task) => {
    // console.log(task)
    // console.log(store.pageData[storeName]?.data)
    const filteredData = store.pageData[storeName]?.data.map((t) =>
      t.id === task?.id
        ? { ...task?.rowData, hideChildren: task.hideChildren }
        : t
    )
    // console.log(filteredData)
    dispatch(
      filterCurrentData({
        storeName: storeName,
        data: filteredData
      })
    )
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoadingModalPrompt(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoadingModalPrompt(false)
          setShowPrompt(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoadingModalPrompt(false)
          setShowPrompt(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const getData = useCallback(() => {
    setLoading(true)
    setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

    const params = {
      storeName,
      endpointName: pageAttr?.crudOpt?.endpointName,
      ...filtersCustom
    }

    dispatch(getDataList(params))
      .then((resp) => {})
      .catch((error) => {
        console.log("Failed to fetch data", error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [pageAttr, storeName, filtersCustom, dispatch])

  // const loadingFirstOnce = useRef(false)
  useEffect(() => {
    localStorage.removeItem("task_changed")
    localStorage.removeItem("task_clicked")
    let isLoadState = pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.some(
      (item) => item?.rules?.required === true
    )
    if (!isLoadState) {
      if (
        (!store.pageData[storeName]?.data ||
          pageAttr?.crudOpt?.getAlwaysGrid ||
          !store.pageData[storeName]?.status) &&
        !store.pageData[storeName]?.isLoading &&
        // store.pageData[storeName]?.status !== "failed" &&
        // !store.pageData[storeName]?.status &&
        pageAttr?.crudOpt?.endpointName &&
        !pageAttr?.isFromAddPage
      ) {
        getData()
      } else {
        if (relPageId || pageAttr?.isViewOnForm) {
          const lastParentData = storeParent?.selectedData
          let curPageLastParam = store.pageData[storeName]?.params
          let curPageAttrPageFilArray =
            pageAttr?.crudOpt?.initialFiltersCondition
          let reloadState = false

          if (curPageAttrPageFilArray) {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent

            if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
              curPageAttrPageFilArray?.map((childPar) => {
                reloadState =
                  reloadState ||
                  `${curPageLastParam[curPageAttrPageFilField]}` !==
                    `${lastParentData[childPar?.value_from_parentFieldName]}`
              })
            } else {
              reloadState =
                `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
            }
          }

          if (reloadState) {
            if (
              !store.pageData[storeName]?.isLoading &&
              // store.pageData[storeName]?.status !== "failed" &&
              !store.pageData[storeName]?.status &&
              !pageAttr?.isFromAddPage
            ) {
              getData()
            }
          }
        }
        // setLoading(false)
      }
    }
  }, [dispatch, getData, pageAttr, storeName])

  const dataCanBeDelete = () => {
    let obj = store.pageData[storeName]?.data?.find(
      (o) => o.parent_id === taskSelected.id
    )

    return !obj
  }

  const handleAddEdit = (rowData, initialDataAdd = null) => {
    // console.log(rowData)
    if (rowData) {
      ///if Modal

      setLoadingText(
        `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
      )
      setLoadingModal(true)
      if (!pageAttr?.crudOpt?.detailFromGrid) {
        // setLoading(false)
        setShowModal(true)
        dispatch(
          getDataById({
            id: rowData.id,
            storeName: storeName,
            endpointName: pageAttr?.crudOpt?.endpointName
          })
        ).then((res) => {
          setLoadingModal(false)
        })
      } else {
        dispatch(setSelectedData({ rowData, storeName: storeName })).then(
          () => {
            setLoadingModal(false)
            setShowModal(true)
          }
        )
      }
    } else {
      dispatch(resetSelectedData(storeName)).then((r) => {
        if (initialDataAdd) {
          dispatch(setInitialDataAdd({ data: initialDataAdd, storeName })).then(
            (re) => {
              // console.log(re)
              setLoadingModal(false)
              setLoading(false)
              setShowModal(true)
            }
          )
        } else {
          setLoadingModal(false)
          setLoading(false)
          setShowModal(true)
        }
      })
    }
  }

  const handleCloseModal = () => {
    // setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const TaskListColumnBefore = (tasks) => {
    // console.log(tasks)
    // const newTask = {
    //   ...tasks,
    //   pageAttr: pageAttr
    //   // tasks: getTaskData()
    // }
    // tasks["pageAttr"] = pageAttr
    return TaskListColumn(tasks, pageAttr)
  }

  const TaskListHeaderBefore = (tasks) => {
    const newTask = {
      ...tasks,
      pageAttr: pageAttr
    }
    return TaskListHeader(newTask)
  }

  return (
    <Fragment>
      <Card className="overflow-hidden">
        <div className="react-dataTable react-dataTable-selectable-rows">
          <ViewSwitcher
            onViewModeChange={setView}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            pageAttr={pageAttr}
            store={store}
            storeName={storeName}
            handleAddEdit={handleAddEdit}
            setPageType={setPageType}
            currentViewFirst={pageAttr?.crudOpt?.ganttViewMode}
          />
          {store.pageData[storeName]?.data?.length > 0 ? (
            <Fragment>
              {/* <UILoader
                blocking={store?.pageData?.[storeName]?.isLoadingAddEdit}
                loader={<Loader />}
              > */}
              <Gantt
                viewMode={view}
                headerHeight={65}
                todayColor={colors.primary.light}
                arrowColor={colors.danger.main}
                // barCornerRadius={10}

                // {...(view !== "Day" && view !== "Week"
                //   ? { preStepsCount: 0 }
                //   : {})}

                ganttHeight={
                  store.pageData[storeName]?.data?.length <= 5 ? 400 : 0
                }
                fontSize="12px"
                // fontFamily="Montserrat"
                // handleWidth={100}
                tasks={store.pageData[storeName]?.data?.map((item, index) => ({
                  id: `${item.id}`,
                  type:
                    item?.[pageAttr?.crudOpt?.ganttTypeField ?? "type"] ??
                    "task",
                  styles: {
                    rowHeight:
                      item?.[pageAttr?.crudOpt?.ganttTypeField ?? "type"] ===
                      "project"
                        ? 65
                        : 43,
                    barBackgroundColor:
                      item?.[pageAttr?.crudOpt?.ganttTypeField ?? "type"] ===
                      "project"
                        ? "#161d31"
                        : colors.secondary.main,

                    progressColor: getColor(
                      item?.[
                        pageAttr?.crudOpt?.ganttProgressField ?? "progress"
                      ] ?? 0
                    ),
                    progressSelectedColor: getColor(
                      item?.[
                        pageAttr?.crudOpt?.ganttProgressField ?? "progress"
                      ] ?? 0
                    )
                  },
                  isDisabled:
                    disableBtnCondition(
                      pageAttr?.crudOpt,
                      item,
                      "edit",
                      storeMyUnit.data,
                      paramss?.pageType
                    ) || item?.cascadeFromChild,
                  name: getTitle(item),
                  start: new Date(
                    item?.[pageAttr?.crudOpt?.ganttStartField ?? "start"]
                  ),
                  end: new Date(
                    item?.[pageAttr?.crudOpt?.ganttEndField ?? "end"]
                  ),
                  ...(item?.[pageAttr?.crudOpt?.ganttProjectField ?? "project"]
                    ? {
                        project:
                          item[
                            pageAttr?.crudOpt?.ganttProjectField ?? "project"
                          ]
                      }
                    : {}),
                  ...(item?.[
                    pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"
                  ]
                    ? {
                        dependencies: [
                          `${
                            item?.[
                              pageAttr?.crudOpt?.ganttParentIdField ??
                                "parent_id"
                            ]
                          }`
                        ]
                      }
                    : {}),
                  progress:
                    item?.[
                      pageAttr?.crudOpt?.ganttProgressField ?? "progress"
                    ] ?? 0,
                  hideChildren:
                    item?.[
                      pageAttr?.crudOpt?.ganttHideChildrenField ??
                        "hideChildren"
                    ] ?? pageAttr?.crudOpt?.ganttTaskListExpanded === false,
                  rowData: item
                  // displayOrder: new Date(
                  //   item?.[pageAttr?.crudOpt?.ganttStartField ?? "start"]
                  // ).getTime()
                }))}
                listCellWidth={isChecked ? "125px" : ""}
                columnWidth={columnWidth}
                onDateChange={
                  !pageAttr?.crudOpt?.disabledEdit ||
                  !pageAttr?.crudOpt?.disabledEditCondition
                    ? (task, children) => {
                        handleTaskChange(task)
                      }
                    : null
                }
                // onDelete={handleTaskDelete}
                onProgressChange={
                  !pageAttr?.crudOpt?.disabledEdit ? handleProgressChange : null
                }
                // onDoubleClick={handleDblClick}

                onClick={(task) => {
                  handleClick(task)
                }}
                // onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                locale={
                  localStorage.getItem("i18nextLng") === "id" ? "ind" : "eng"
                }
                TooltipContent={(task) => (
                  <StandardTooltipContent
                    task={task.task}
                    fontSize={task.fontSize}
                    fontFamily={task.fontFamily}
                    pageAttr={pageAttr}
                    title={getTitle(task.task?.rowData)}
                  />
                )}
                TaskListTable={TaskListColumnBefore}
                TaskListHeader={TaskListHeaderBefore}
              />
              {/* </UILoader> */}
            </Fragment>
          ) : store.pageData[storeName]?.status === "failed" ? (
            <ErrorFetchData message={store.pageData[storeName]?.error} />
          ) : (
            <NoDataDisplay />
          )}
        </div>
      </Card>

      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}

      {showPrompt && (
        <Modal
          isOpen={showPrompt}
          toggle={() => {
            setShowPrompt(!showPrompt)
          }}
          className="modal-dialog-centered modal-lg"
        >
          <UILoader
            blocking={loadingModalPrompt}
            overlayColor="rgba(115, 103, 240, .1)"
            loader={<Loader loadingText={loadingText} />}
          >
            <ModalHeader
              className="bg-transparent"
              toggle={() => {
                setShowPrompt(!showPrompt)
              }}
            >
              <span className="mt-1">
                {t("Task")} {t("Detail")}
              </span>

              {/* {
              taskSelected?.rowData?.[
                pageAttr?.crudOpt?.ganttGroupTitleField ?? ""
              ]
            } */}
            </ModalHeader>
            <ModalBody className="px-sm-2 pt-50">
              <DynamicForm
                dynamicForm={pageAttr?.crudOpt?.data?.dynamicForm}
                pageAttr={pageAttr}
                storeName={storeName}
                handleReset={handleReset}
                editForm={false}
                pageType="view"
                defaultFormValues={JSON.parse(
                  JSON.stringify(taskSelected?.rowData).replace(
                    /\:null/gi,
                    ':""'
                  )
                )}
                hideAllFotterButton={true}
              />
            </ModalBody>
            <ModalFooter>
              {dataCanBeDelete() &&
                !taskSelected?.rowData?.cascadeFromChild &&
                !disableBtnCondition(
                  pageAttr?.crudOpt,
                  taskSelected?.rowData,
                  "delete",
                  storeMyUnit.data,
                  paramss?.pageType
                ) &&
                ability.can(
                  "delete",
                  sessionStorage.getItem("current_page_perm")
                ) && (
                  <Button
                    color="danger"
                    onClick={() => handleDelete(taskSelected.id)}
                    outline
                  >
                    <Trash2 size={14} />
                    <span className="align-middle ms-25">{t("Delete")}</span>
                  </Button>
                )}
              {ability.can(
                "update",
                sessionStorage.getItem("current_page_perm")
              ) &&
                !disableBtnCondition(
                  pageAttr?.crudOpt,
                  taskSelected?.rowData,
                  "edit",
                  storeMyUnit.data,
                  paramss?.pageType
                ) &&
                !taskSelected?.rowData?.cascadeFromChild && (
                  <Button
                    onClick={() => {
                      handleEdit(taskSelected)
                    }}
                    className="ms-1"
                    color="primary"
                    outline
                  >
                    <Edit size={14} />
                    <span className="align-middle ms-25">{t("Edit")}</span>
                  </Button>
                )}
              {ability.can(
                "post",
                sessionStorage.getItem("current_page_perm")
              ) &&
                !disableBtnCondition(
                  pageAttr?.crudOpt,
                  taskSelected?.rowData,
                  "add-child",
                  storeMyUnit.data,
                  "add-child"
                ) && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      setPageType("add-child")
                      setShowPrompt(!showPrompt)
                      const endNewStr = `${taskSelected.end.getFullYear()}-${
                        taskSelected.end.getMonth() + 1
                      }-${taskSelected.end.getDate()} ${taskSelected.end.getHours()}:${taskSelected.end.getMinutes()}`
                      const endNewStrAdd = `${taskSelected.end.getFullYear()}-${
                        taskSelected.end.getMonth() + 1
                      }-${
                        taskSelected.end.getDate() + 1
                      } ${taskSelected.end.getHours()}:${taskSelected.end.getMinutes()}`

                      const startNew =
                        pageAttr?.crudOpt?.ganttEnableTime === true
                          ? endNewStr
                          : endNewStr.substring(0, 10)
                      const endNew =
                        pageAttr?.crudOpt?.ganttEnableTime === true
                          ? endNewStrAdd
                          : endNewStrAdd.substring(0, 10)

                      handleAddEdit(null, {
                        [pageAttr?.crudOpt?.ganttParentIdField ?? "parent_id"]:
                          taskSelected.rowData?.id,
                        [pageAttr?.crudOpt?.ganttGroupIdField]:
                          taskSelected.rowData?.[
                            pageAttr?.crudOpt?.ganttGroupIdField
                          ],
                        [pageAttr?.crudOpt?.ganttStartField ?? "start"]:
                          startNew,
                        [pageAttr?.crudOpt?.ganttEndField ?? "end"]: endNew
                      })
                    }}
                    className="ms-1"
                    color="primary"
                    outline
                  >
                    <CornerDownRight size={14} />
                    <span className="align-middle ms-25">
                      {t("Add Sub Task")}
                    </span>
                  </Button>
                )}
              <Button
                onClick={() => {
                  setShowPrompt(!showPrompt)
                }}
                className="ms-1"
                color="secondary"
                outline
              >
                {t("Cancel")}
              </Button>
            </ModalFooter>
          </UILoader>
        </Modal>
      )}
    </Fragment>
  )
}

export default GanttChartCrud
