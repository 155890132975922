import { useTranslation } from "react-i18next"
import { Fragment, useContext } from "react"
import Spinner from "@components/spinner/Loading-spinner"
import FallbackSpinner from "@components/spinner/Fallback-spinner"
import { CardText, Alert } from "reactstrap"
import { Skeleton } from "@mui/material"
import { ThemeColors } from "@src/utility/context/ThemeColors"

import noDataDisplayImg from "@src/assets/images/pages/404.png"
import { ReactComponent as NoDataAnim } from "@src/assets/images/illustration/no-data-not-css.svg"
import errorDataDisplayImg from "@src/assets/images/pages/500.png"
import { ReactComponent as ErrorDataAnim } from "@src/assets/images/illustration/504-error-gateway-timeout-not-css.svg"

import RandomQuoteLoader from "./RandomQuoteLoader"
import { useSelector } from "react-redux"
import logo from "@src/assets/images/logo/logo.png"
export const NoDataDisplay = ({
  noImage = false,
  dataName,
  isFromAddPage,
  customStyles = {},
  titleCustom = null,
  messageCustom = null,
  pageAttr
}) => {
  const { t } = useTranslation()

  let messageText = !isFromAddPage
    ? t(
        messageCustom ??
          (pageAttr?.crudOpt?.disabledAdd
            ? pageAttr?.isViewOnRow
              ? "There are no records to display."
              : "There are no records to display. Try changing the filter"
            : "There are no records to display. Try changing the filter or adding new data")
      )
    : t("Add one or more data here...")

  let dataName2 =
    pageAttr?.crudOpt?.btnAddText
      ?.replaceAll("Add", ``)
      ?.replaceAll("Tambah", ``) ?? dataName
  return (
    <div
      className="align-items-center w-100"
      style={{
        padding: "24px",
        textAlign: "center",
        // minHeight: !noImage ? "370px" : "auto",
        minHeight: "auto",
        ...customStyles
      }}
    >
      {!noImage && (
        // <img
        //   style={{ maxWidth: "50%", minWidth: "35%" }}
        //   className="mb-1 no-data-grid"
        //   src={noDataDisplayImg}
        // />
        // <img
        //   style={{ height: "250px" }}
        //   className="mb-1 no-data-grid"
        //   src={noDataAnim}
        // />

        <div
          style={{
            // maxHeight: "210px",
            //  maxWidth: "16%",
            width: "25%",
            margin: "auto"
          }}
        >
          <NoDataAnim />
        </div>
      )}
      <h4 className="text-secondary">{titleCustom ?? t("No Data")}</h4>
      <div className="text-secondary">
        {!dataName2
          ? messageText
          : messageText
              .replaceAll("data", `data ${t(dataName2)}`)
              .replaceAll("records", `${t(dataName2)} records`)}
      </div>
      {/* <Alert color="info">
        <h4 className="alert-heading">{t("No Records Find")}</h4>
        <div className="alert-body">
          {t(
            "There are no records to display. Try changing the filter or adding new data"
          )}
        </div>
      </Alert> */}
    </div>
  )
}

export const ErrorFetchData = ({ message }) => {
  const { t } = useTranslation()
  return (
    <div
      className="align-items-center w-100"
      style={{ padding: "24px", textAlign: "center", minHeight: "370px" }}
    >
      {/* <img
        style={{ maxHeight: "210px", maxWidth: "50%" }}
        className="mb-1"
        src={errorDataDisplayImg}
      /> */}
      {/* <img
        style={{ maxHeight: "210px", maxWidth: "50%" }}
        className="mb-1"
        src={errorDataAnim}
      /> */}

      <div
        className="error-data-animation"
        style={{
          // maxHeight: "210px",
          //  maxWidth: "16%",
          // width: window.innerWidth < 600 ? "50%" : "22%",
          margin: "auto"
        }}
      >
        <ErrorDataAnim />
      </div>

      {/* <Alert className="mt-2" color="danger"> */}
      <div
        // style={{
        //   marginTop: "-3.5rem !important"
        // }}
        className="mb-1 error-data-animation-text"
      >
        <h4 className="text-danger">{t("Error")}</h4>
        <div className="text-secondary">
          {message !== undefined &&
          message !== null &&
          message !== "undefined" &&
          message !== ""
            ? t(message)
            : t("An error has occurred in fetching data.")}
        </div>
      </div>
      {/* </Alert> */}
    </div>
  )
}

export const Loader = ({ loadingText, dataName, style }) => {
  const { colors } = useContext(ThemeColors)
  const storeDomainClaims = useSelector((state) => state.domain_claims)
  const { t } = useTranslation()
  if (!loadingText) {
    loadingText = t("Please Wait")
  }
  if (dataName) {
    loadingText = `${t(`Loading`)} ${t(dataName)} Data`
  }
  return (
    <Fragment>
      {/* <Spinner /> */}
      {/* <FallbackSpinner /> */}
      <img
        className="fallback-logo"
        src={
          storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoLoginBig ??
          storeDomainClaims.data?.unit?.unit_app_attributes?.appLogo ??
          storeDomainClaims.data.app?.logo?.appLogoLoginBig ??
          storeDomainClaims.data.app?.logo?.appLogo ??
          logo
        }
        style={{
          opacity: 0.5,
          // filter: "grayscale(1)",
          width:
            storeDomainClaims.data?.unit?.unit_app_attributes
              ?.appLogoLoginBig ||
            storeDomainClaims.data.app?.logo?.appLogoLoginBig
              ? "200px"
              : "85px"
        }}
        alt="logo"
        height={
          (storeDomainClaims.data?.unit?.unit_app_attributes?.appLogoLoginBig ||
            storeDomainClaims.data.app?.logo?.appLogoLoginBig) &&
          "auto"
        }
      />
      <CardText
        style={{
          fontSize: "0.9rem"
        }}
        className="mb-0 mt-50 text-secondary text-center"
      >
        <div className="d-flex align-items-center text-center justify-content-center">
          {/* <div class="me-2 dot-flashing-reverse"></div> */}
          <div>{loadingText}</div>
          <div style={{ marginTop: "1px" }} className="ms-2 dot-flashing"></div>
        </div>
        {/* {loadingText}  */}
      </CardText>
      <RandomQuoteLoader />
    </Fragment>
  )
}

export const TableSkeletonLoading = ({ column }) => {
  const { colors } = useContext(ThemeColors)
  return column.name === "Actions" ? (
    <Fragment>
      <Skeleton
        // sx={{ bgcolor: colors.dark.light }}
        animation="wave"
        className="ms-2 me-1"
        width={30}
        height={40}
      />
      <Skeleton
        // sx={{ bgcolor: colors.dark.light }}
        animation="wave"
        width={30}
        height={40}
      />
    </Fragment>
  ) : (
    <Skeleton
      // sx={{ bgcolor: colors.dark.light }}
      animation="wave"
      width="100%"
      height={40}
    />
  )
}

export const TableSkeletonLoadingAll = ({ pageAttr }) => {
  const { colors } = useContext(ThemeColors)
  let columns = [...pageAttr?.crudOpt?.data?.dynamicColumns]
  let isHide
  if (columns) {
    columns.push({
      isGlobalAction: true
    })
  }
  let columnFix = columns ?? Array.from({ length: 8 }, (_, index) => index)

  const rowLengthDef = Array.from({ length: 5 }, (_, index) => index)
  if (columnFix) {
    const cells = columnFix.map((column, index) => (
      <div
        // style={{ width: column?.width ?? column?.minWidth }}
        className="sc-AxhCb sc-AxhUy sc-fzplWN hYgckR rdt_TableCol"
        key={index}
      >
        {index === columnFix.length - 1 ? (
          <>
            <Skeleton
              animation="wave"
              className="ms-2 me-1"
              width={30}
              height={40}
            />
            <Skeleton animation="wave" width={30} height={40} />
          </>
        ) : (
          <Skeleton
            className=""
            style={{ margin: "9px" }}
            animation="wave"
            width="100%"
            height={40}
          />
        )}
      </div>
    ))

    const rows = rowLengthDef.map((_, rowIndex) => (
      <div
        key={rowIndex}
        style={{
          background: "transparent"
        }}
        className="sc-AxiKw fvxlqO rdt_TableHeadRow"
        role="row"
      >
        {cells}
      </div>
    ))
    return (
      <div
        style={{
          display: "table"
        }}
        className="sc-AxirZ enUFtr rdt_TableHead"
        role="rowgroup"
      >
        {rows}
      </div>
    )
  }
}
