// ** Third Party Components
import Avatar from "@components/avatar"
import AvatarGroup from "@components/avatar-group"
import { Calendar, MapPin, Video } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

// ** Reactstrap Imports
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap"

import illustration from "@src/assets/images/illustration/email.svg"
import { getDataList, postDataWidget } from "@src/shared/dynamic_pages/store"
import "moment-timezone"
import "moment/locale/id"
import { useContext, useState } from "react"
import Moment from "react-moment"

import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined"
import useJwt from "@src/auth/jwt/useJwt"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { getDataList as getDataListMyActivity } from "@src/views/my-info/store/my_activities"
import { setDataCache } from "@store/dynamicTempState"
import toast from "react-hot-toast"
import { components } from "react-select"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

const axiosSso = useJwt.ssoAxiosInterceptor
const axiosHR = useJwt.hrAxiosInterceptor

const params = {
  // navigation: true
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false
  },
  slideToClickedSlide: true
}

const TaskSlider = ({
  pageAttr,
  currentAttr,
  item,
  handleChangeFilterFromChild
}) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { colors } = useContext(ThemeColors)
  const [loading, setLoading] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const storeName = item?.cardId
  const [loadingGetManager, setLoadingGetManager] = useState(false)
  const [managerData, setManagerData] = useState([])
  const [managerDataSelected, setManagerDataSelected] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const [openAcc, setOpenAcc] = useState(null)
  const toggleAcc = (id) => {
    openAcc === id ? setOpenAcc() : setOpenAcc(id)
  }
  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const postDataToApi = (data) => {
    setLoadingPostPut(true)
    setLoadingText("Sending Training Request to Manager")
    data["storeName"] = storeName
    data["endpointName"] = endpointName
    data["baseUrl"] = "hr"
    data["callback_page"] = window.location.href
    dispatch(postDataWidget(data))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            getDataList({
              storeName: storeName,
              endpointName: endpointName,
              baseUrl: "hr",
              type: "for_me",
              Id_AttendingState: 0
            })
          )
            .then((xxx) => {
              setLoadingPostPut(false)
              toggle()
              toast.success(
                `${t("Successfully Sending Training Request to Manager")}}`,
                {
                  style: {
                    padding: "16px",
                    color: colors.success.main,
                    border: `1px solid ${colors.success.main}`
                  },
                  iconTheme: {
                    primary: colors.success.main
                  }
                }
              )
            })
            .then((xx) => {
              dispatch(
                getDataList({
                  storeName: "TrainingEmployee_assignToMe",
                  endpointName: "TrainingEmployee/assignment",
                  baseUrl: "hr",
                  type: "to_me",
                  Id_AttendingState: 1
                })
              )
              setTimeout(() => {
                dispatch(getDataListMyActivity({}))
              }, 8000)
            })
        } else if (res.meta.requestStatus === "rejected") {
          setLoadingPostPut(false)
          errorToast(res)
        }
      })
      .catch((error) => {
        // console.error(error)
        setLoadingPostPut(false)
        errorToast(error)
      })
  }

  const getManager = async () => {
    toggle()

    const storeInputName = "Id_Manager"
    if (!dynamicTempState.data[storeInputName] && !loadingGetManager) {
      setLoadingGetManager(true)
      const finUrl = "DepartmentManager"
      await axiosHR.get(finUrl).then((res) => {
        // console.log(res)
        const data = res?.data?.data
        setManagerData(data)
        setLoadingGetManager(false)
        dispatch(setDataCache({ [storeInputName]: data }))
      })
    } else {
      setLoadingGetManager(true)
      setManagerData(dynamicTempState.data[storeInputName])
      setLoadingGetManager(false)
    }
  }

  const renderAvatarImgIcon = (data) => {
    let componentsInput = {}
    const { Option, SingleValue } = components
    const IconOption = (props) => (
      <Option {...props}>
        <img
          src={props.data.img}
          style={{
            height: "30px",
            width: "30px",
            borderRadius: "10%",
            marginRight: "10px"
          }}
        />
        {props.data.label} <small>({props.data.position})</small>
      </Option>
    )

    const IconSingleValue = (props) => (
      <SingleValue {...props}>
        <img
          src={props.data.img}
          style={{
            height: "30px",
            width: "30px",
            borderRadius: "10%",
            marginRight: "10px"
          }}
        />
        {props.data.label} <small>({props.data.position})</small>
      </SingleValue>
    )

    componentsInput = {
      components: {
        SingleValue: IconSingleValue,
        Option: IconOption
      }
    }

    return componentsInput
  }

  const calendarStrings = {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "L"
  }

  const colorsBadge = [
    "primary",
    "success",
    "danger",
    "warning",
    "info",
    "secondary"
  ]

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase()
  }

  const beforeHandleChangeFilterFromChild = (params) => {
    // console.log(params)
    handleChangeFilterFromChild({
      item: currentAttr,
      ...params
    })
  }

  const renderAvatarGroup = (data) => {
    if (data) {
      const minCount = 10
      const avatarData = data.map((item, i) => {
        let name = `${item?.FirstName} ${item?.LastName}`
        if (i <= minCount) {
          return {
            title: `${name.replaceAll(".", "")}`,
            placement: "bottom",
            img:
              item?.PhotoPath ??
              require("@src/assets/images/avatars/avatar-blank.png").default,
            imgHeight: 33,
            imgWidth: 33
          }
        }
      })
      const remainCount = (data.length ?? 0) - minCount
      if (remainCount > 0 && avatarData) {
        avatarData.push({
          meta: `+${remainCount}`
        })
      } else {
        avatarData.push({
          meta: `${avatarData.length} ${t("Employee ready to attend")}`
        })
      }
      return <AvatarGroup data={avatarData} />
    } else {
      return null
    }
  }

  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <EventAvailableOutlinedIcon className="user-timeline-title-icon me-1" />
          {!store.data[storeName]?.[0]?.isManager ? (
            <CardTitle tag="h4">{t(item?.title ?? "")}</CardTitle>
          ) : (
            <CardTitle tag="h4">
              {t(item?.title ?? "")} ({t("include staff")})
            </CardTitle>
          )}
        </div>
        {store.data[storeName]?.length > 1 && (
          <Badge className="ms-1" tag="div" color="danger" pill>
            {store.data[storeName]?.length}
          </Badge>
        )}
      </CardHeader>

      <CardBody className="px-0 pb-0">
        <Swiper {...params} {...(item.sliderOpt ?? {})}>
          {store.data[storeName]?.map((row) => {
            return (
              <SwiperSlide key={`swiper_${row.id}`}>
                <Card className="card-developer-meetup mb-0 cursor-pointer">
                  <div className="meetup-img-wrapper rounded-top text-center">
                    <img
                      src={
                        row?.TrainingData?.Image
                          ? row.TrainingData.Image
                          : illustration
                      }
                      height="170"
                    />
                  </div>
                  <CardBody>
                    <div className="meetup-header mb-1 d-flex align-items-center">
                      <Accordion className="" open={openAcc} toggle={toggleAcc}>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <div className="meetup-day">
                              <h6 className="mb-0">
                                <Moment filter={toUpperCaseFilter} format="ddd">
                                  {row?.TrainingData?.StartDate}
                                </Moment>
                              </h6>
                              <h3 className="mb-0">
                                <Moment format="DD">
                                  {row?.TrainingData?.StartDate}
                                </Moment>
                              </h3>
                            </div>
                            <div className="my-auto">
                              <CardTitle
                                tag="h4"
                                style={{
                                  fontSize:
                                    row?.TrainingData?.Name?.length > 40
                                      ? "1rem"
                                      : "1.53rem"
                                }}
                                className="mb-25"
                              >
                                {row?.TrainingData?.Name}
                              </CardTitle>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <p
                              style={{
                                fontSize:
                                  row?.TrainingData?.Description?.length > 40
                                    ? "0.8rem"
                                    : "1.53rem"
                              }}
                            >
                              {row?.TrainingData?.Description}
                            </p>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <div className="d-flex">
                      <Avatar
                        color="light-primary"
                        className="rounded me-1"
                        icon={<Calendar size={18} />}
                      />
                      <div>
                        {/* <h6 className="mb-0">Sat, May 25, 2020</h6>
                                <small>10:AM to 6:PM</small> */}
                        <h6 className="mb-0">
                          {
                            <Moment calendar={calendarStrings}>
                              {row?.TrainingData?.StartDate}
                            </Moment>
                          }
                        </h6>
                        <small>
                          {
                            <Moment format="[Start at] LT">
                              {row?.TrainingData?.StartDate}
                            </Moment>
                          }
                        </small>
                      </div>
                    </div>
                    {!row?.TrainingData?.IsOnline ? (
                      <div className="d-flex mt-2">
                        <Avatar
                          color="light-primary"
                          className="rounded me-1"
                          icon={<MapPin size={18} />}
                        />
                        <div>
                          {/* <h6 className="mb-0">Central Park</h6> */}
                          <small>{row?.TrainingData?.Address}</small>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex mt-2">
                        <Avatar
                          color="light-primary"
                          className="rounded me-1"
                          icon={<Video size={18} />}
                        />
                        <div>
                          <h6 className="mb-0">{t("Online Training")}</h6>
                          <small>
                            {row?.TrainingData?.LinkRef
                              ? row?.TrainingData?.LinkRef
                              : "The link will be sent before the event starts"}
                          </small>
                        </div>
                      </div>
                    )}
                    {renderAvatarGroup(row?.Employee)}
                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </CardBody>
    </Card>
  )
}
export default TaskSlider
