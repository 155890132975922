// ** React Imports
import {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  forwardRef,
  lazy,
  Suspense
} from "react"
import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
// ** Custom Components
import classnames from "classnames"

// ** Reactstrap Imports
import { CardBody, Button, Input, Label } from "reactstrap"

// ** illustration import
import illustration from "@src/assets/images/pages/calendar-illustration.png"

import { useTranslation } from "react-i18next"
import { AbilityContext } from "@src/utility/context/Can"
import { neutralizeBack, revivalBack } from "@src/utility/Utils"
import GlobalActionsTable from "@src/shared/GlobaActionsTable"
import {
  getDataList,
  getDataById,
  setSelectedData,
  resetSelectedData,
  deleteData,
  deleteSelectedRowData
} from "./store"
import { useDispatch, useSelector } from "react-redux"
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import debounce from "lodash.debounce"
import { ChevronDown } from "react-feather"

import {
  NoDataDisplay,
  Loader,
  TableSkeletonLoading
} from "@src/shared/CommonComponents"
import UILoader from "@components/ui-loader"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"
import DynamicForm from "@src/utility/generateForm/dynamicForm"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import DynamicRow from "@src/utility/generateForm/dynamicRow"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

// ** Filters Checkbox Array
const filters = [
  { label: "Personal", color: "danger", className: "form-check-danger mb-1" },
  { label: "Business", color: "primary", className: "form-check-primary mb-1" },
  { label: "Family", color: "warning", className: "form-check-warning mb-1" },
  { label: "Holiday", color: "success", className: "form-check-success mb-1" },
  { label: "ETC", color: "info", className: "form-check-info" }
]

const SidebarLeft = (props) => {
  // ** Props
  const {
    handleAddEventSidebar,
    toggleSidebar,
    updateFilter,
    updateAllFilters,
    storeCalendar,
    dispatch,
    pageAttr,
    storeName,
    handleFiltersChangeToParent,
    handleAddEdit
  } = props

  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  // ** Function to handle Add Event Click
  const handleAddEventClick = () => {
    // toggleSidebar(false)
    // handleAddEventSidebar()
  }

  return (
    <Fragment>
      <div className="sidebar-wrapper">
        {!pageAttr?.crudOpt?.disabledAdd &&
        ability.can("post", sessionStorage.getItem("current_page_perm")) ? (
          <CardBody className="card-body d-flex justify-content-center my-sm-0 mb-3">
            <Button
              block
              color="primary"
              onClick={(e) => {
                handleAddEdit(null)
              }}
            >
              <span className="align-middle">
                {t("Add New")} {t(pageAttr.pageTitle)}
              </span>
            </Button>

            {/* <Button color="primary" block onClick={handleAddEventClick}>
            <span className="align-middle">Add Event</span>
          </Button> */}
          </CardBody>
        ) : null}
        <CardBody>
          {/* <h5 className="section-label mb-1">
            <span className="align-middle">Filter</span>
          </h5> */}
          {/* <div className="form-check mb-1">
            <Input
              id="view-all"
              type="checkbox"
              label="View All"
              className="select-all"
              checked={
                storeCalendar.selectedCalendars.length === filters.length
              }
              onChange={(e) => dispatch(updateAllFilters(e.target.checked))}
            />
            <Label className="form-check-label" for="view-all">
              View All
            </Label>
          </div> */}
          {pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.length > 0 && (
            <DynamicForm
              dynamicForm={pageAttr?.crudOpt?.data?.dynamicColumnsFilter}
              storeName={storeName}
              pageAttr={pageAttr}
              handleFiltersChangeToParent={handleFiltersChangeToParent}
              filterMode={true}
              filterModeCalendar={true}
              defaultFormValues={store.pageData[storeName]?.params ?? {}}
            />
          )}
          {/* <div className="calendar-events-filter">
            {filters.length &&
              filters.map((filter) => {
                return (
                  <div
                    key={`${filter.label}-key`}
                    className={classnames("form-check", {
                      [filter.className]: filter.className
                    })}
                  >
                    <Input
                      type="checkbox"
                      key={filter.label}
                      label={filter.label}
                      className="input-filter"
                      id={`${filter.label}-event`}
                      checked={storeCalendar.selectedCalendars.includes(
                        filter.label
                      )}
                      onChange={() => {
                        dispatch(updateFilter(filter.label))
                      }}
                    />
                    <Label
                      className="form-check-label"
                      for={`${filter.label}-event`}
                    >
                      {filter.label}
                    </Label>
                  </div>
                )
              })}
          </div> */}
        </CardBody>
      </div>
      <div className="mt-auto">
        <img className="img-fluid" src={illustration} alt="illustration" />
      </div>
    </Fragment>
  )
}

export default SidebarLeft
