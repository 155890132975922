import { useRTL } from "@hooks/useRTL"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/charts/apex-charts.scss"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  createRef,
  lazy
} from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Col,
  Row,
  CardSubtitle
} from "reactstrap"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import UILoader from "@components/ui-loader"
import { Loader } from "@src/shared/CommonComponents"
import {
  getBaseUrl,
  getDataList,
  resetData
} from "@src/shared/dynamic_pages/store"

import { AbilityContext } from "@src/utility/context/Can"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  setDataCache,
  setLastFilters,
  setWidgetAutoRefresh
} from "../../redux/dynamicTempState"

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal"
import Icon from "@mui/material/Icon"
import { renderIcon } from "../../views/apps/menu/store/my_menu"

// CHART
import AreaChart from "../generateCharts/AreaChart"
import BarChart from "../generateCharts/BarChart"
import BarStackedChart from "../generateCharts/BarStackedChart"
import LineChart from "../generateCharts/LineChart"

// SLIDER
import EventSlider from "../generateSliders/EventSlider"
import TaskSlider from "../generateSliders/TaskSlider"
import InvitationSlider from "../generateSliders/InvitationSlider"
import ApprovalSlider from "../generateSliders/ApprovalSlider"

import CountDown from "../generateSliders/CountDown"

// TABLE
// import Table from "@src/shared/dynamic_pages/Table"

//LIST
import ListText from "../generateList/ListText"
import ListGroupBy from "../generateList/ListGroupBy"

import DynamicForm from "@src/utility/generateForm/dynamicForm"

const DynamicPage = lazy(() => import("@src/shared/dynamic_pages"))
import { useSkin } from "@hooks/useSkin"

import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  Grid,
  Lazy,
  Navigation,
  Pagination,
  Virtual
} from "swiper"

// ** Styles
import "@styles/react/libs/swiper/swiper.scss"
import { Fullscreen, FullscreenExit, Refresh } from "@mui/icons-material"
import { Skeleton } from "@mui/material"
import "chart.js/auto"
import DonurChart from "../generateCharts/DonutChart"
import { Circle } from "react-feather"

// ** Init Swiper Functions
SwiperCore.use([
  Navigation,
  Grid,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  Autoplay,
  Lazy,
  Virtual
])

const DynamicCard = ({
  dynamicCard,
  storeName,
  pageAttr,
  mainDashboard = false
}) => {
  const { t } = useTranslation()
  const { skin } = useSkin()
  const ability = useContext(AbilityContext)
  const { moduleId, pageId, id } = useParams()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamic_temp_state)
  const storeAll = useSelector((state) => state.dynamicStore)
  const [isRtl] = useRTL()
  const { colors } = useContext(ThemeColors)
  const [tempLoading, setTempLoading] = useState({})
  const navigate = useNavigate()
  const location = useLocation()

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")

  const requestFullscreen = (items, idEl) => {
    const element = document.getElementById(idEl)
    setOldStyleBackgroundColor(element.style.backgroundColor)
    if (element) {
      element.style.backgroundColor = "#FFF"
    }
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = (items, idEl) => {
    if (oldStyleBackgroundColor) {
      const element = document.getElementById(idEl)
      if (element) {
        element.style.backgroundColor = oldStyleBackgroundColor
      }
    }

    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    setIsFullscreen(false)
  }

  const resetDataByStore = (store_to_reset, params, item) => {
    // console.log(params, {
    //   ...(storeAll?.pageData?.[store_to_reset]?.params ?? {}),
    //   ...params,
    //   storeName: store_to_reset,
    //   endpointName:
    //     storeAll.pageAttributes[store_to_reset]?.crudOpt?.endpointName ??
    //     item?.crudOpt?.endpointName ??
    //     params?.endpointName,
    //   baseUrl: item?.crudOpt?.baseUrl ?? params?.baseUrl
    // })

    let tempLoadingCopy = { ...tempLoading }
    tempLoadingCopy[store_to_reset] = true
    setTempLoading(tempLoadingCopy)

    if (storeAll?.pageData?.[store_to_reset]?.data) {
      dispatch(resetData(store_to_reset))
    }

    dispatch(
      getDataList({
        ...(storeAll?.pageData?.[store_to_reset]?.params ?? {}),
        ...params,
        storeName: store_to_reset,
        endpointName:
          storeAll.pageAttributes[store_to_reset]?.crudOpt?.endpointName ??
          item?.crudOpt?.endpointName ??
          params?.endpointName,
        baseUrl: item?.crudOpt?.baseUrl ?? params?.baseUrl
      })
    )
      .then(() => {
        // console.log(store_to_reset, storeAll?.pageData)
        let tempLoadingCopy = { ...tempLoading }
        tempLoadingCopy[store_to_reset] = false
        setTempLoading(tempLoadingCopy)
      })
      .catch((error) => {
        console.error(error)
      })

    // console.log(storeAll?.pageData?.[store_to_reset])
  }

  const handleChangeFilterFromChildGlobal = async (
    params,
    currentCard = null
  ) => {
    // console.log(params, storeName)
    let isLinkedToChilds = false
    await storeAll.pageAttributes[storeName]?.options?.data?.dynamicCard?.map(
      (x, index) => {
        // console.log(x)
        if (x?.children) {
          x.children?.map((child, index) => {
            // console.log(storeAll)
            // console.log(store)
            const linkToGlobalFilterName =
              child?.linkToGlobalFilter ??
              child?.crudOpt?.linkToGlobalFilter ??
              child?.dataFrom?.linkToGlobalFilter
            if (linkToGlobalFilterName) {
              const widget_id =
                child?.chart_id ??
                child?.cardId ??
                child?.pageId ??
                child?.crudOpt?.pageId ??
                index
              // console.log(params?.storeName, linkToGlobalFilterName)
              if (params?.storeName === linkToGlobalFilterName) {
                isLinkedToChilds = true

                if (child?.crudOpt) {
                  const store_to_reset = `${widget_id}`
                  // console.log(store_to_reset)
                  // if (storeAll?.pageData?.[store_to_reset]?.data) {
                  return resetDataByStore(store_to_reset, params, child)
                  // }
                } else {
                  const store_to_reset = `${widget_id}`
                  const copyParams = { ...params }
                  delete copyParams.storeName
                  copyParams["item"] = child
                  handleChangeFilterFromChild(copyParams)
                }
              }
            }
          })
        } else {
          const linkToGlobalFilterName =
            x?.linkToGlobalFilter ??
            x?.crudOpt?.linkToGlobalFilter ??
            x?.dataFrom?.linkToGlobalFilter
          if (linkToGlobalFilterName) {
            const widget_id = x?.chart_id ?? x?.cardId
            // console.log(params?.storeName, linkToGlobalFilterName)
            if (params?.storeName === linkToGlobalFilterName) {
              isLinkedToChilds = true

              if (x?.crudOpt) {
                const store_to_reset = `${
                  x?.crudOpt?.baseUrl ?? x?.dataFrom?.baseUrl ?? x?.baseUrl
                }_${widget_id}`
                // console.log(store_to_reset)
                // if (storeAll?.pageData?.[store_to_reset]?.data) {
                resetDataByStore(store_to_reset, params)
                // }
              } else {
                const store_to_reset = `${widget_id}`
                const copyParams = { ...params }
                delete copyParams.storeName
                copyParams["item"] = x
                handleChangeFilterFromChild(copyParams)
              }
            }
          }
        }
      }
    )
  }

  const renderLoading = (item, index) => {
    const id = item.chart_id ?? item.cardId ?? index
    const idEl = `dashboard_card_${item?.chart_id ?? item?.cardId ?? index}`
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < 4 ? 6 : 12
    }

    let returnVal = (
      <Col id={`widget_${id}`} key={`col_${id}`} {...colSizeFix}>
        <Card
          className="align-items-center"
          id={`${idEl}`}
          style={item?.type !== "page" ? item?.cardStyle : {}}
        >
          {/* <UILoader blocking={true} loader={<Loader />}> */}
          {/* <CardHeader className="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-md-center align-items-start"></CardHeader> */}
          <CardBody
            style={{
              minHeight:
                item?.cardStyle?.height ??
                item?.cardStyle?.minHeight ??
                "200px",
              textAlign: "center"
            }}
            className="align-items-center w-100 align-content-center"
          >
            {/* <Spinner /> */}
            <Loader />
          </CardBody>
          {/* </UILoader> */}
        </Card>
      </Col>
    )
    if (item?.type === "summary") {
      returnVal = renderSummary(item, true)
    }
    return returnVal
  }

  const renderChart = (item) => {
    const id = item?.chart_id ?? item?.cardId ?? index
    let keyEl = `chart_${id}`

    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < 4 ? 6 : 12
    }

    const globalAttr = {
      item: item,
      pageAttr: pageAttr,
      currentAttr: item
      // handleChangeFilterFromChild: handleChangeFilterFromChild
    }

    const beforeHandleChangeFilterFromChild = (params) => {
      // console.log(params)
      handleChangeFilterFromChild({
        item,
        ...params
      })
    }

    let components = <BarChart {...globalAttr} />

    if (item?.chartOpt?.type === "area") {
      keyEl = `chart_area_${id}`
      components = <AreaChart {...globalAttr} />
    } else if (item?.chartOpt?.type === "bar") {
      keyEl = `chart_bar_${id}`
      components = <BarChart {...globalAttr} />
    } else if (item?.chartOpt?.type === "bar-stacked") {
      keyEl = `chart_barstacked_${id}`
      components = <BarStackedChart {...globalAttr} />
    } else if (item?.chartOpt?.type === "line") {
      keyEl = `chart_line_${id}`
      components = <LineChart {...globalAttr} />
    } else if (item?.chartOpt?.type === "donut") {
      keyEl = `chart_donut_${id}`
      components = <DonurChart {...globalAttr} />
    }

    let idEl = `dashboard_card_${keyEl}`
    return (
      <Col key={keyEl} {...colSizeFix}>
        <Card
          id={`${idEl}`}
          style={{ ...item?.cardStyle, ...{ height: "auto" } }}
        >
          <CardHeader className="d-flex justify-content-md-between justify-content-start align-items-start">
            <Col xs={8} md={8} className="mb-1">
              <CardTitle
                style={item?.cardTitleStyle}
                className="mb-75"
                tag="h4"
              >
                {t(item?.title ?? item?.cardTitle ?? "")}
              </CardTitle>

              <CardSubtitle className="text-muted">
                {t(item?.subTitle ?? item?.cardSubTitle ?? "")}
              </CardSubtitle>
            </Col>
            <Col
              style={{
                marginTop: "-6px"
              }}
              xs={4}
              md={4}
              className="text-end mb-1"
            >
              {!item?.hideRefreshButton && (
                <Button.Ripple
                  onClick={(e) =>
                    handleChangeFilterFromChild({
                      item
                    })
                  }
                  className="btn-icon btn-sm btn-announcement-fs p-25"
                  color="flat-secondary"
                >
                  <Refresh size={16} />
                </Button.Ripple>
              )}
              {!item?.hideFullscreenButton && (
                <Button.Ripple
                  onClick={(e) =>
                    !isFullscreen
                      ? requestFullscreen(item, idEl)
                      : exitFullscreen(item, idEl)
                  }
                  className="btn-icon btn-sm btn-announcement-fs p-25"
                  color="flat-secondary"
                >
                  {!isFullscreen ? (
                    <Fullscreen size={16} />
                  ) : (
                    <FullscreenExit size={16} />
                  )}
                </Button.Ripple>
              )}
            </Col>
            {(pageAttr?.options?.data?.dynamicColumnsFilter?.length > 0 ||
              item?.dynamicColumnsFilter?.length > 0) &&
              (item?.dynamicColumnsFilterExtendDiv ? (
                <Row className="ps-1 w-100">
                  <DynamicForm
                    dynamicForm={
                      item?.dynamicColumnsFilter ??
                      pageAttr?.options?.data?.dynamicColumnsFilter
                    }
                    storeName={id}
                    pageAttr={pageAttr}
                    handleFiltersChangeToParent={
                      beforeHandleChangeFilterFromChild
                    }
                    filterMode={true}
                    chartFilter={true}
                    defaultFormValues={store.params[id]}
                  />
                </Row>
              ) : (
                <DynamicForm
                  dynamicForm={
                    item?.dynamicColumnsFilter ??
                    pageAttr?.options?.data?.dynamicColumnsFilter
                  }
                  storeName={id}
                  pageAttr={pageAttr}
                  handleFiltersChangeToParent={
                    beforeHandleChangeFilterFromChild
                  }
                  filterMode={true}
                  chartFilter={true}
                  defaultFormValues={store.params[id]}
                />
              ))}
          </CardHeader>
          <CardBody
            style={{
              minHeight: item?.size === 12 ? "350px" : "260px"
            }}
          >
            {components}
          </CardBody>
        </Card>
      </Col>
    )
  }

  const renderSliderInvitation = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    return (
      store.data[id]?.length > 0 && (
        <Col
          id={`widget_${id}`}
          key={`card_slider_invitation_${id}`}
          {...colSizeFix}
        >
          <InvitationSlider
            item={item}
            pageAttr={pageAttr}
            currentAttr={item}
            handleChangeFilterFromChild={handleChangeFilterFromChild}
          ></InvitationSlider>
        </Col>
      )
    )
  }

  const renderSliderEvent = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    return (
      store.data[id]?.length > 0 && (
        <Col
          id={`widget_${id}`}
          key={`card_slider_event_${id}`}
          {...colSizeFix}
        >
          <EventSlider
            item={item}
            pageAttr={pageAttr}
            currentAttr={item}
            handleChangeFilterFromChild={handleChangeFilterFromChild}
          ></EventSlider>
        </Col>
      )
    )
  }

  const renderSliderTask = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    return (
      store.data[id]?.length > 0 && (
        <Col id={`widget_${id}`} key={`card_slidertask_${id}`} {...colSizeFix}>
          <TaskSlider
            item={item}
            pageAttr={pageAttr}
            currentAttr={item}
            handleChangeFilterFromChild={handleChangeFilterFromChild}
          ></TaskSlider>
        </Col>
      )
    )
  }

  const renderSliderAprroval = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < 4 ? 6 : 12
    }

    return (
      store.data[id]?.length > 0 && (
        <Col
          id={`widget_${id}`}
          key={`card_sliderapproval_${id}`}
          style={{
            ...{ display: "block" }
          }}
          {...colSizeFix}
        >
          <ApprovalSlider
            item={item}
            pageAttr={pageAttr}
            currentAttr={item}
            handleChangeFilterFromChild={handleChangeFilterFromChild}
          ></ApprovalSlider>
        </Col>
      )
    )
  }

  const renderCountDown = (item, index) => {
    const id =
      item?.chart_id ??
      item?.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      `__app_dashboard_widget_countdown__${index}`
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    return (
      <Col
        id={`widget_${id}`}
        key={`card_countdown_${id}`}
        // style={{
        //   ...{ display: "block" }
        // }}
        {...colSizeFix}
      >
        <CountDown
          item={item}
          pageAttr={pageAttr}
          currentAttr={item}
        ></CountDown>
      </Col>
    )
  }

  const renderTable = (item, index) => {
    const id =
      item?.chart_id ??
      item?.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      index
    const idEl = `card_table_${item?.chart_id ?? item?.cardId ?? index ?? 0}`
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: "12"
    }

    // console.log(id)
    const childRef = createRef()
    return (
      // storeAll.pageData?.[id]?.data?.length > 0 &&
      // !tempLoading[id] &&
      <Col id={`widget_${id}`} key={idEl} {...colSizeFix}>
        <DynamicPage
          key={id}
          initialPageId={id}
          initialPageAttr={{
            ...item,
            // isViewOnForm: true,
            onDashboard: true,
            relationsCrudKey: id
          }}
        />
      </Col>
    )
  }

  const renderSummary = (items, isLoading = false) => {
    const id =
      items?.chart_id ??
      items?.cardId ??
      items?.pageId ??
      items?.crudOpt?.pageId ??
      `__app_dashboard_widget__${index2}`

    const colSizeFix = {
      sm: items?.size ?? true,
      md: items?.size ?? true,
      xs: items?.size < 4 ? 6 : 12
    }

    let lastDataFound = [1, 2]
    if ((isLoading || tempLoading[id]) && store.data[id]) {
      // lastDataFound = Array.from(store.data[id]).map((item, index) => index)
      lastDataFound = [...store.data[id]]
      // console.log(lastDataFound)
    }

    let result = (
      <Fragment>
        {(store.data[id] ?? lastDataFound)?.map((item, index) => {
          const colSizeItem = item.size
            ? {
                sm: item?.size ?? items?.size ?? true,
                md: item?.size ?? items?.size ?? true,
                xs: item?.size ?? items?.size < 4 ? 6 : 12
              }
            : {}

          const clickUrl = item?.clickUrl ?? item?.clickLinkUrl
          const clickEvent = clickUrl
            ? {
                onClick: (e) => {
                  navigate(`${clickUrl}`)
                }
              }
            : {}

          const iconStyle = item?.iconStyle ?? items.iconStyle ?? {}
          const iconSize = item?.iconSize ?? items.iconSize ?? 20
          const iconClassName =
            item.iconColor ?? item.class ?? items.iconColor ?? items.class
          return (
            <Col
              id={`widget_${id}`}
              key={`card_summary_${id}_${index}`}
              {...clickEvent}
              // className={`card-summary-crud align-items-center ${
              //   isFullscreen ? " h-100 pb-1" : ""
              // }`}
              style={{
                ...(isFullscreen
                  ? {
                      zoom: items?.fullscreenZoomSize ?? 2
                    }
                  : {}),
                ...(clickUrl ? { cursor: "pointer" } : {})
                // ...{ display: "flex", flexFlow: "column" }
              }}
              className={`card-summary-crud align-items-center`}
              {...(colSizeItem ?? colSizeFix)}
            >
              <UILoader
                blocking={(isLoading && tempLoading[id]) || !store.data[id]}
                // blocking={true}
                loader={
                  <Skeleton
                    style={{
                      borderRadius: "8px"
                    }}
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                  >
                    <StatsHorizontal
                      index={index ?? 1}
                      stats={"00"}
                      statTitle="Load"
                      className={`${
                        item?.className ?? items?.className ?? ""
                      } h-100 mb-auto`}
                      countTextStyle={
                        item.countTextStyle ?? items.countTextStyle
                      }
                      titleStyle={item.titleStyle ?? items.titleStyle}
                      titleOnTop={true}
                      icon={<Circle size={20} />}
                      cardStyle={{
                        height: "100%",
                        ...(skin === "dark"
                          ? item?.cardStyleDark ??
                            items.cardStyleDark ??
                            item?.cardStyle ??
                            items.cardStyle
                          : item?.cardStyle ?? items.cardStyle),
                        background: "rgba(34, 41, 47, 0.2)",
                        marginBottom: "auto"
                      }}
                    ></StatsHorizontal>
                  </Skeleton>
                }
              >
                <StatsHorizontal
                  index={index ?? 1}
                  color={item.color ?? items.color ?? colors[index]}
                  cardColor={item.cardColor ?? items.cardColor}
                  solidColor={item?.solidColor ?? items.solidColor}
                  className={`${
                    item?.className ?? items.className ?? ""
                  } h-100`}
                  countTextStyle={item.countTextStyle ?? items.countTextStyle}
                  countTextColor={item.countTextColor ?? items.countTextColor}
                  titleStyle={item.titleStyle ?? items.titleStyle}
                  statTitle={`${t(item.title)}`}
                  cardStyle={{
                    height: "100%",
                    ...(skin === "dark"
                      ? item?.cardStyleDark ??
                        items.cardStyleDark ??
                        item?.cardStyle ??
                        items.cardStyle
                      : item?.cardStyle ?? items.cardStyle)
                  }}
                  titleOnTop={true}
                  icon={
                    item.icon ? (
                      item.icon.startsWith("http://") ||
                      item.icon.startsWith("https://") ? (
                        <img
                          className={`${iconClassName}`}
                          src={item.icon}
                          style={{
                            ...iconStyle,
                            width: `${iconSize ? iconSize * 2 : 40}px`
                          }}
                          alt={`Image ${item.title}`}
                        />
                      ) : (
                        renderIcon(
                          item.icon,
                          `${iconClassName}`,
                          iconSize,
                          iconStyle
                        )
                      )
                    ) : (
                      <Icon
                        baseClassName="material-icons-outlined"
                        fontSize="20"
                      >
                        lightbulb
                      </Icon>
                    )
                  }
                  stats={`${item.count_format ?? item.count ?? ""}`}
                />
              </UILoader>
            </Col>
          )
        })}

        {/* <Progress className="progress-bar-secondary" value={35} /> */}
      </Fragment>
    )
    return result
    // return !isLoading && !tempLoading[id]
    //   ? result
    //   : (lastDataFound ?? [1, 2])?.map((item, index) => {
    //       const colSizeItem = item.size
    //         ? {
    //             sm: item?.size ?? items?.size ?? 3,
    //             md: item?.size ?? items?.size ?? 3,
    //             xs: item?.size ?? items?.size < 4 ? 6 : 12
    //           }
    //         : {}
    //       // console.log(colSizeItem)
    //       // return renderLoading(item, index)
    //       return (
    //         <Col
    //           key={`${items?.cardId}_${index}`}
    //           className="card-summary-crud align-items-center"
    //           {...(colSizeItem ?? colSizeFix)}
    //           style={{
    //             ...(isFullscreen
    //               ? {
    //                   zoom: items?.fullscreenZoomSize ?? 2
    //                 }
    //               : {})
    //           }}
    //         >
    //           <Skeleton
    //             className="card"
    //             variant="rectangular"
    //             animation="wave"
    //             width="100%"
    //           >
    //             {/* <Card style={{ background: "rgba(34, 41, 47, 0.2)" }}> */}
    //             <CardBody
    //               style={{
    //                 minHeight: "100px"
    //               }}
    //             >
    //               {/* <Spinner /> */}
    //             </CardBody>
    //             {/* </Card> */}
    //           </Skeleton>
    //         </Col>
    //       )
    //     })
    // renderLoading(items)
  }

  const renderText = (item) => {
    const id =
      item?.chart_id ?? item?.cardId ?? item?.pageId ?? item?.crudOpt?.pageId

    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    return (
      <Col id={`widget_${id}`} className="card-summary-crud" {...colSizeFix}>
        <h5 style={item?.style}>{item?.text}</h5>
      </Col>
    )
  }

  const renderListGroup = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    const { colors } = useContext(ThemeColors)
    const trackBgColor = "#e9ecef"

    return (
      store.data[id]?.length > 0 && (
        <Col id={`widget_${id}`} key={`card_${id}`} {...colSizeFix}>
          <ListGroupBy
            item={item}
            colors={colors}
            trackBgColor={trackBgColor}
            handleChangeFilterFromChild={handleChangeFilterFromChild}
          />
        </Col>
      )
    )
  }

  const renderListText = (item) => {
    const id = item.cardId ?? item.chart_id
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }

    const { colors } = useContext(ThemeColors)
    const trackBgColor = "#e9ecef"

    return (
      store.data[id]?.length > 0 && (
        <Col id={`widget_${id}`} key={`card_${id}`} {...colSizeFix}>
          <ListText item={item} colors={colors} trackBgColor={trackBgColor} />
        </Col>
      )
    )
  }

  const renderColumn = (items, index, parent) => {
    const idEl = `dashboard_column_${items?.chart_id ?? items?.cardId ?? index}`
    const colSizeFix = {
      sm: items?.size ?? true,
      md: items?.size ?? true,
      xs: items?.size < "4" ? "6" : "12"
    }

    let result = (
      <Col
        id={`${idEl}`}
        className={!parent ? "card-column" : "card-column"}
        {...colSizeFix}
      >
        <h5 style={items?.cardTitleStyle}>{t(items?.cardTitle)}</h5>

        {!items?.hideFullscreenButton && (
          <Button.Ripple
            onClick={(e) =>
              !isFullscreen
                ? requestFullscreen(items, idEl)
                : exitFullscreen(items, idEl)
            }
            className="btn-icon btn-sm btn-announcement-fs p-25"
            color="flat-secondary"
          >
            {!isFullscreen ? (
              <Fullscreen size={16} />
            ) : (
              <FullscreenExit size={16} />
            )}
          </Button.Ripple>
        )}
        <Row className="">
          {items?.children?.map((item, index2) => {
            // console.log(item)
            const id = item?.chart_id ?? item?.cardId

            if (item?.type === "text") {
              return renderText(item)
            } else if (item?.type === "column") {
              return renderColumn(item, index2, items)
            } else if (item?.type === "global_filter") {
              return renderGlobalFilter(item)
            } else if (item?.type === "table" || item?.pageType === "crud") {
              return renderTable(item, index2)
            } else {
              if (store.data[id] && !tempLoading[id]) {
                return renderCard(item)
              } else {
                return renderLoading(item)
              }
            }
          })}
        </Row>
      </Col>
    )

    if (items?.cardTheme === "style1") {
      const id =
        items?.chart_id ??
        items?.cardId ??
        items?.pageId ??
        items?.crudOpt?.pageId
      result = (
        <Col
          style={{
            ...(skin === "dark" && isFullscreen
              ? { backgroundColor: "#161D31" }
              : { backgroundColor: "inherit" })
          }}
          className="card-summary-crud px-1"
          {...colSizeFix}
        >
          <Card
            id={`${idEl}`}
            style={{
              ...(skin === "dark"
                ? items.cardStyleDark ?? items.cardStyle
                : items.cardStyle),
              ...(isFullscreen &&
                skin === "dark" && { backgroundColor: "#161D31" })
            }}
            className="card-statistics"
          >
            <CardHeader className="dynamic-card-header-style1">
              {/* <CardTitle style={items?.cardTitleStyle} tag="h4">
                {t(items?.cardTitle)}
              </CardTitle>
              
              <CardText className="card-text font-small-2 me-25 mb-0">
                <Button.Ripple
                  onClick={(e) => {
                    items?.children?.map((item, index) => {
                      handleChangeFilterFromChild({
                        item: item
                      })
                    })
                  }}
                  className="btn-icon btn-sm btn-announcement-fs p-25"
                  color="flat-secondary"
                >
                  <Refresh size={16} />
                </Button.Ripple>
                <Button.Ripple
                  onClick={(e) =>
                    !isFullscreen
                      ? requestFullscreen(items, idEl)
                      : exitFullscreen(items, idEl)
                  }
                  className="btn-icon btn-sm btn-announcement-fs p-25"
                  color="flat-secondary"
                >
                  {!isFullscreen ? (
                    <Fullscreen size={16} />
                  ) : (
                    <FullscreenExit size={16} />
                  )}
                </Button.Ripple>
              </CardText> */}
              <Row className="w-100 d-flex justify-content-between align-items-center">
                <Col md={9} xs={9}>
                  <CardTitle
                    style={{
                      ...(isFullscreen
                        ? {
                            zoom: items?.fullscreenZoomSize ?? 2
                          }
                        : {}),
                      ...items?.cardTitleStyle
                    }}
                    tag="h4"
                  >
                    {t(items?.cardTitle)}
                  </CardTitle>
                </Col>
                <Col md={3} xs={3} className="px-0 text-md-end text-center">
                  <CardText className="card-text font-small-2 me-25 mb-0">
                    {!items?.hideRefreshButton && (
                      <Button.Ripple
                        onClick={(e) => {
                          items?.children?.map((item, index) => {
                            handleChangeFilterFromChild({
                              item: item
                            })
                          })
                        }}
                        className="btn-icon btn-sm btn-announcement-fs p-25 me-25"
                        color="flat-secondary"
                      >
                        <Refresh size={16} />
                      </Button.Ripple>
                    )}
                    {!items?.hideFullscreenButton && (
                      <Button.Ripple
                        onClick={(e) =>
                          !isFullscreen
                            ? requestFullscreen(items, idEl)
                            : exitFullscreen(items, idEl)
                        }
                        className="btn-icon btn-sm btn-announcement-fs p-25"
                        color="flat-secondary"
                      >
                        {!isFullscreen ? (
                          <Fullscreen size={16} />
                        ) : (
                          <FullscreenExit size={16} />
                        )}
                      </Button.Ripple>
                    )}
                  </CardText>
                </Col>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                padding: "2rem 2rem 2rem !important",
                flex: "none !important"
              }}
              className="dynamic-card-body-style1 align-items-center"
            >
              <Row className="h-100 align-items-center">
                {items?.children?.map((item, index) => {
                  // console.log(item)
                  const id = item?.chart_id ?? item?.cardId

                  if (item?.type === "text") {
                    return renderText(item)
                  } else if (item?.type === "column") {
                    return renderColumn(item)
                  } else if (item?.type === "global_filter") {
                    return renderGlobalFilter(item)
                  } else if (
                    item?.type === "table" ||
                    item?.pageType === "crud"
                  ) {
                    // if (
                    //   (store.data[id]?.length > 0 ||
                    //     storeAll?.pageData[id]?.data?.length > 0) &&
                    //   !tempLoading[id]
                    // ) {
                    return renderTable(item, index)
                    // } else {
                    //   return <div></div>
                    // }
                  } else {
                    if (
                      (store.data[id] || storeAll?.pageData?.[id]?.data) &&
                      !tempLoading[id]
                    ) {
                      return renderCard(item)
                    } else {
                      return renderLoading(item)
                    }
                  }
                })}
              </Row>
            </CardBody>
          </Card>
        </Col>
      )
    }

    return result
  }

  const renderGlobalFilter = (item) => {
    const id =
      item?.chart_id ?? item?.cardId ?? item?.pageId ?? item?.crudOpt?.pageId
    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }
    return (
      <Col
        style={{
          display: item?.isHidden ? "none" : "block"
        }}
        id={`widget_${id}`}
        storename={`${storeName}`}
        key={`card_global_filter_${id}`}
        {...colSizeFix}
      >
        <DynamicForm
          // key={resetGridAndFilters}
          dynamicForm={item?.dynamicColumnsFilter.map((obj) => ({
            ...obj,
            isFromFilter: true
          }))}
          storeName={id}
          pageAttr={pageAttr}
          handleFiltersChangeToParent={handleChangeFilterFromChildGlobal}
          filterMode={true}
        />
      </Col>
    )
  }

  const renderDynamicPage = (item, index) => {
    const id =
      item?.chart_id ??
      item?.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      index
    // const widget_id = id
    const widget_id = `${
      item?.crudOpt?.baseUrl ?? item?.dataFrom?.baseUrl ?? item?.baseUrl
    }_${id}`

    const colSizeFix = {
      sm: item?.size ?? true,
      md: item?.size ?? true,
      xs: item?.size < "4" ? "6" : "12"
    }
    return (
      <Col
        // style={item?.cardStyle}
        id={`widget_${id}`}
        key={widget_id}
        {...colSizeFix}
      >
        <DynamicPage
          initialPageId={widget_id}
          initialPageAttr={{
            ...item,
            // isViewOnForm: true,
            onDashboard: true
            // relationsCrudKey: id
          }}
        />
      </Col>
    )
  }

  // const [widgetAutoRefresh, setWidgetAutoRefresh] = useState([])

  const loadDropdownFromApi = async (item, params, index) => {
    // Normalize parameters by removing 'item' if present.
    if (params) {
      delete params.item
    }

    // Generate a unique ID for this item.
    const id =
      item?.chart_id ??
      item?.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      `__app_dashboard_widget__${index}`

    setTempLoading((prevLoading) => ({
      ...prevLoading,
      [id]: (prevLoading[id] || 0) + 1
    }))

    // Define base URL and endpoint.
    const baseUrlOpt =
      item?.dataFrom?.baseUrl ??
      pageAttr?.crudOpt?.baseUrl ??
      pageAttr?.options?.baseUrl
    const endpointName = item?.dataFrom?.api

    // Fetch data from the API.
    try {
      const data = await getBaseUrl(baseUrlOpt, endpointName)
        .get(endpointName, { params })
        .then((res) => {
          // Extract data using a key if specified, otherwise default to direct data.
          let keySelector = item.dataFrom?.resp_key
            ? res.data[item.dataFrom?.resp_key]
            : res.data
          return Array.isArray(keySelector) ? keySelector : [keySelector]
        })

      // Dispatch data to Redux store and update filters.
      if (data) {
        dispatch(setDataCache({ [id]: data }))
        dispatch(setLastFilters({ [id]: params }))
      }
    } catch (error) {
      console.error("Failed to load data:", error)
    } finally {
      setTempLoading((prevLoading) => ({
        ...prevLoading,
        [id]: Math.max(0, (prevLoading[id] || 1) - 1)
      }))
    }
  }

  async function loadDataForCards(cards, params, refresh) {
    const promises = cards.map(async (item, index) => {
      if (item.type === "column" && item.children) {
        return await loadDataForCards(item.children, params)
      } else {
        const id = resolveId(item, index)
        if (canLoadData(item, id) || refresh) {
          if (!refresh && item.dataFrom.autoRefreshInterval) {
            // console.log(item)
            const refreshWidgets = []
            refreshWidgets.push({
              item: item,
              id: id,
              interval: item.dataFrom.autoRefreshInterval * 1000
            }) // Convert seconds to milliseconds
            // setWidgetAutoRefresh(refreshWidgets)
            dispatch(setWidgetAutoRefresh(refreshWidgets))
          }

          return await loadDropdownFromApi(item, params, index)
        }
      }
    })

    return Promise.all(promises)
  }

  function resolveId(item, index) {
    return (
      item.chart_id ??
      item.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      `__app_dashboard_widget__${index}`
    )
  }

  function canLoadData(item, id) {
    const { dataFrom, crudOpt, pageType, type } = item
    const notLoading = !store.data?.[id] && !tempLoading?.[id]
    const needsData =
      dataFrom?.api ||
      (crudOpt?.endpointName &&
        pageType !== "page" &&
        (type === "table" || pageType === "crud"))
    return notLoading && needsData
  }

  const handleChangeFilterFromChild = async (params) => {
    // console.log(params?.item)

    const copyParams = { ...params }
    if (copyParams.item?.type === "column" && copyParams.item?.children) {
      loadDataForCards(copyParams.item?.children, copyParams, true)
        // .then(() => console.log("Data loaded for refreshed cards"))
        .catch((err) =>
          console.error("Error loading data for refreshed cards", err)
        )
    } else {
      delete copyParams.item
      await loadDropdownFromApi(params?.item, copyParams)
    }
  }

  useEffect(() => {
    loadDataForCards(dynamicCard)
      // .then(() => console.log("Data loaded for all cards"))
      .catch((err) => console.error("Error loading data for cards", err))

    // dynamicCard?.map(async (item, index) => {
    // let id =
    //   item.chart_id ??
    //   item.cardId ??
    //   item?.pageId ??
    //   item?.crudOpt?.pageId ??
    //   `__app_dashboard_widget__${index}`
    // if (!store.data?.[id] && !tempLoading?.[id]) {
    //   if (item?.type === "column" && item?.children) {
    //     item.children?.map(async (child, index2) => {
    //       let id_child =
    //         child.chart_id ??
    //         child.cardId ??
    //         child?.pageId ??
    //         child?.crudOpt?.pageId ??
    //         `__app_dashboard_widget__${index2}`
    //       // console.log(child)
    //       if (!store.data?.[id_child] && !tempLoading?.[id_child]) {
    //         if (
    //           child?.dataFrom?.api ||
    //           (child?.crudOpt?.endpointName &&
    //             child?.pageType !== "page" &&
    //             (item?.type === "table" || item?.pageType === "crud"))
    //         ) {
    //           await loadDropdownFromApi(child, index)
    //         }
    //       }
    //     })
    //   } else {
    //     if (
    //       item?.dataFrom?.api ||
    //       (item?.crudOpt?.endpointName &&
    //         item?.pageType !== "page" &&
    //         (item?.type === "table" || item?.pageType === "crud"))
    //     ) {
    //       await loadDropdownFromApi(item, index)
    //     }
    //   }
    // }
    // })
    const handleKeydown = (event) => {
      if (document.fullscreenElement === null) {
        exitFullscreen()
      }
    }

    document.addEventListener("fullscreenchange", handleKeydown)
    return () => {
      document.removeEventListener("fullscreenchange", handleKeydown)
    }
  }, [isFullscreen])

  useEffect(() => {
    if (store.widget_auto_refresh && store.widget_auto_refresh.length > 0) {
      const intervals = store.widget_auto_refresh.map((widget) => {
        return setInterval(() => {
          loadDropdownFromApi(widget.item) // Ensure this function is ready to handle this structure
        }, widget.interval)
      })

      // Cleanup function to clear all intervals
      return () => intervals.forEach(clearInterval)
    }
  }, [store.widget_auto_refresh]) // Dependency on the store array

  const renderCard = (item, index) => {
    if (item?.type === "chart") {
      return renderChart(item)
    } else if (item?.type === "slider_event_can_follow") {
      return renderSliderEvent(item)
    } else if (item?.type === "slider_event_invitation") {
      return renderSliderInvitation(item)
    } else if (item?.type === "slider_task") {
      return renderSliderTask(item)
    } else if (item?.type === "summary") {
      return renderSummary(item)
    } else if (item?.type === "text") {
      return renderText(item)
    } else if (item?.type === "list_group") {
      return renderListGroup(item, index)
    } else if (item?.type === "column") {
      return renderColumn(item)
    } else if (item?.type === "slider_approval") {
      return renderSliderAprroval(item)
    } else if (item?.type === "countdown") {
      return renderCountDown(item, index)
    } else if (item?.type === "table" || item?.pageType === "crud") {
      return renderTable(item)
    } else if (item?.type === "global_filter") {
      return renderGlobalFilter(item)
    }
  }

  // Helper function to derive ID
  const getId = (item, index) => {
    return (
      item?.chart_id ??
      item?.cardId ??
      item?.pageId ??
      item?.crudOpt?.pageId ??
      `__app_dashboard_widget__${index}`
    )
  }

  // Mapping of types to render functions
  const typeToRenderer = {
    text: renderText,
    column: renderColumn,
    global_filter: renderGlobalFilter,
    page: renderDynamicPage,
    table: renderTable,
    countdown: renderCountDown
  }

  // Common render function that handles the mapping
  const renderByType = (item, index) => {
    const renderFunc =
      typeToRenderer[item.type] || typeToRenderer[item.pageType]
    if (renderFunc) {
      return renderFunc(item, index)
    }
    return renderLoading(item) // Default to loading if no match found
  }

  return !mainDashboard ? (
    <Row className="match-height">
      {dynamicCard?.map((item, index) => {
        const id = getId(item, index)
        if (store.data[id] && !tempLoading[id]) {
          return renderCard(item, index)
        } else {
          return renderByType(item, index)
        }
      })}
    </Row>
  ) : (
    dynamicCard?.map((item, index) => {
      const id = getId(item, index)
      if (
        (store.data[id] || storeAll?.pageData[id]?.data) &&
        !tempLoading[id]
      ) {
        return renderCard(item)
      } else {
        return renderByType(item, index)
      }
    })
  )

  // return !mainDashboard ? (
  //   <Row className="match-height">
  //     {dynamicCard?.map((item, index) => {
  //       const id =
  //         item?.chart_id ??
  //         item?.cardId ??
  //         item?.pageId ??
  //         item?.crudOpt?.pageId ??
  //         `__app_dashboard_widget__${index}`
  //       if (item?.type === "text") {
  //         return renderText(item)
  //       } else if (item?.type === "column") {
  //         return renderColumn(item, index)
  //       } else if (item?.type === "global_filter") {
  //         return renderGlobalFilter(item)
  //       } else if (item?.type === "page") {
  //         return renderDynamicPage(item, index)
  //       } else if (item?.type === "table" || item?.pageType === "crud") {
  //         return renderTable(item, index)
  //       } else if (item?.type === "countdown") {
  //         return renderCountDown(item, index)
  //       } else {
  //         if (store.data[id] && !tempLoading[id]) {
  //           return renderCard(item, index)
  //         } else {
  //           return renderLoading(item)
  //         }
  //       }
  //     })}
  //   </Row>
  // ) : (
  //   dynamicCard?.map((item, index) => {
  //     const id =
  //       item?.chart_id ??
  //       item?.cardId ??
  //       item?.pageId ??
  //       item?.crudOpt?.pageId ??
  //       `__app_dashboard_widget__${index}`
  //     // console.log(id)
  //     if (
  //       (store.data[id] || storeAll?.pageData[id]?.data) &&
  //       !tempLoading[id]
  //     ) {
  //       return renderCard(item)
  //     } else {
  //       if (item?.type === "text") {
  //         return renderText(item)
  //       } else if (item?.type === "column") {
  //         return renderColumn(item)
  //       } else if (item?.type === "global_filter") {
  //         return renderGlobalFilter(item)
  //       } else if (item?.type === "page") {
  //         return renderDynamicPage(item, index)
  //       } else if (item?.type === "table" || item?.pageType === "crud") {
  //         return renderTable(item, index)
  //       } else if (item?.type === "countdown") {
  //         return renderCountDown(item, index)
  //       } else {
  //         return renderLoading(item)
  //       }
  //     }
  //   })
  // )
}

export default DynamicCard
