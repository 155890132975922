// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"
import useJwt from "@src/auth/jwt/useJwt"

export const getMyNotifications = createAsyncThunk(
  "getMyNotifications",
  async (params, { rejectWithValue, dispatch }) => {
    // console.log("GET MY NOTIFICATION START...")
    try {
      const response = await useJwt.ssoAxiosInterceptor.get(
        `api/my_info/notifications`,
        { params }
      )
      if (response.data?.count_unseen_chat) {
        dispatch(setUnseenChat(response.data?.count_unseen_chat))
      }
      // console.log("GET MY NOTIFICATION SUCCESS")
      return response.data.data
    } catch (error) {
      // console.error("GET MY NOTIFICATION FAILED!!!")
      return rejectWithValue(error.response.data)
    }
  }
)

export const setNewNotification = createAsyncThunk(
  "setNewNotification",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const badgeDecrement = createAsyncThunk(
  "badgeDecrement",
  async (data, { rejectWithValue }) => {
    // console.log("badgeDecrement START...")
    try {
      // console.log("badgeDecrement SUCCESS")
      return data
    } catch (error) {
      // console.error("badgeDecrement FAILED!!!")
      return rejectWithValue(error.response.data)
    }
  }
)

export const setUnseenChat = createAsyncThunk(
  "setUnseenChat",
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const badgeChatDecrement = createAsyncThunk(
  "badgeChatDecrement",
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const badgeChatIncrement = createAsyncThunk(
  "badgeChatIncrement",
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    dataAll: [],
    data: [],
    count_unseen_chat: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyNotifications.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getMyNotifications.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false
        state.data = action.payload
        state.dataAll = action.payload
        // console.log(action.payload)
      })
      .addCase(getMyNotifications.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.message
      })

      .addCase(badgeDecrement.fulfilled, (state, action) => {
        const objIndex = state.data.findIndex(
          (obj) => obj.id === action.payload?.data?.id
        )
        if (objIndex !== -1) {
          if (!state.data[objIndex].read_date) {
            state.data[objIndex].read_date = new Date()
            state.data[0].count_unread = state.data[0].count_unread - 1
          }
        }
      })
      .addCase(badgeDecrement.rejected, (state, action) => {
        // state.status = "failed"
        // state.isLoading = false
        // state.error = action.error.message
      })

      .addCase(setUnseenChat.fulfilled, (state, action) => {
        state.count_unseen_chat = action?.payload
      })
      .addCase(badgeChatDecrement.fulfilled, (state, action) => {
        const count = action?.payload?.count
        if (!count && state.count_unseen_chat > 0) {
          state.count_unseen_chat = state.count_unseen_chat - 1
        } else {
          state.count_unseen_chat = state.count_unseen_chat - count
        }
      })

      .addCase(badgeChatIncrement.fulfilled, (state, action) => {
        state.count_unseen_chat = state.count_unseen_chat + 1
      })

      .addCase(setNewNotification.fulfilled, (state, action) => {
        const stateNew = current(state)
        // console.log(action)
        // console.log(stateNew.data)
        // console.log(stateNew.dataAll)
        // let newObj = {

        // }
        // state.data = [...state.data, action.payload]
      })
  }
})

export default NotificationsSlice.reducer
