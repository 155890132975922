import { useEffect, useState, useRef, Fragment } from "react"
import Stepper from "bs-stepper"
import classnames from "classnames"
import { PropTypes } from "prop-types"
import { ArrowLeft, ArrowRight, ChevronRight } from "react-feather"
import { useTranslation } from "react-i18next"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"

// ** Styles
import "bs-stepper/dist/css/bs-stepper.min.css"
import "../../../@core/scss/base/plugins/forms/form-wizard.scss"
import { Button, Row } from "reactstrap"

const InputWizard = ({
  pageAttr,
  dynamicFormWizard = [],
  className = "",
  type = "horizontal",
  headerClassName = "",
  contentClassName = "",
  separator = <ChevronRight size={17} />,
  contentWrapperClassName = "",
  options = {
    linear: false,
    animation: true
  },
  form,
  formId,
  submitEvent
}) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [stepperInstance, setStepperInstance] = useState(null)

  const [formCurrent, setFormCurrent] = useState()

  useEffect(() => {
    const stepper = new Stepper(ref.current, options)
    setStepperInstance(stepper)

    setFormCurrent(form)
    ref.current.addEventListener("shown.bs-stepper", function (event) {
      setActiveIndex(event.detail.indexStep ?? 0)
      showStep(event.detail.indexStep ?? 0)
    })

    const stepZeroElement = document.getElementById("step-0")

    if (stepZeroElement) {
      stepZeroElement.classList.remove("dstepper-none", "fade")
    }
  }, [])

  const handleNext = () => {
    if (activeIndex < dynamicFormWizard.length - 1) {
      const nextIndex = activeIndex + 1
      setActiveIndex(nextIndex)
      stepperInstance.next()
      // showStep(nextIndex)
    }
  }

  const handlePrevious = () => {
    if (activeIndex > 0) {
      const prevIndex = activeIndex - 1
      setActiveIndex(prevIndex)
      stepperInstance.previous()
      // showStep(prevIndex)
    }
  }

  const hideElementsWithPrefixInForm = (formIdx, prefix) => {
    const form = document.getElementById(formIdx)
    if (form) {
      const elements = form.querySelectorAll(`[id^="${prefix}"]`)
      elements.forEach((element) => {
        // element.style.display = "none"
        if (!element.classList.contains("hidden")) {
          element.classList.add("d-none-wizard")
        }
      })
    } else {
      console.error(`Form with ID "${formIdx}" not found.`)
    }
  }

  const showStep = (index) => {
    // hideElementsWithPrefixInForm(formId, "col_")

    dynamicFormWizard.forEach((step, stepIndex) => {
      const contents = step?.content ?? []
      console.log(contents)
      contents.forEach((fieldName) => {
        const idEl = `col_${fieldName}`
        const element = document.getElementById(idEl)
        // console.log(element)
        if (element) {
          if (!element.classList.contains("hidden")) {
            if (stepIndex === index) {
              // element.style.display = "block"
              element.classList.remove("d-none-wizard")
              element.classList.add("d-block-wizard")
            } else {
              // element.style.display = "none"
              element.classList.remove("d-block-wizard")
              element.classList.add("d-none-wizard")
            }
          }
        }
      })
    })
  }

  useEffect(() => {
    if (stepperInstance) {
      // setFormCurrent(null)
      // setFormCurrent(form)
      hideElementsWithPrefixInForm(formId, "col_")
      showStep(activeIndex)
    }
  }, [activeIndex, stepperInstance])

  // ** Renders Wizard Header
  const renderHeader = () => {
    return dynamicFormWizard.map((step, index) => (
      <Fragment key={index}>
        {index !== 0 && index !== dynamicFormWizard.length ? (
          <div className="line">{separator}</div>
        ) : null}
        <div
          className={classnames("step", {
            active: index === activeIndex
          })}
          data-target={`#step-${index}`}
          key={`step_header_${index}`}
        >
          <button type="button" className="step-trigger">
            <span className="bs-stepper-box">
              {step.icon ? renderIcon(step.icon, "", 20, {}) : index + 1}
            </span>
            <span className="bs-stepper-label">
              <span className="bs-stepper-title">{step.title}</span>
              {step.subtitle && (
                <span className="bs-stepper-subtitle">{step.subtitle}</span>
              )}
            </span>
          </button>
        </div>
      </Fragment>
    ))
  }

  const renderContent = () => {
    return dynamicFormWizard.map((step, index) => (
      <div
        className={classnames("content", {
          "active dstepper-block": activeIndex === index,
          "dstepper-none": activeIndex !== index
        })}
        id={`step-${index}`}
        key={`step_content_${index}`}
      >
        {(step.showContentHeader === undefined ||
          step.showContentHeader === true) && (
          <div className="content-header mb-2">
            <h5 className="mb-0">{step.title}</h5>
            {step.description && <small>{step.description}</small>}
          </div>
        )}

        {activeIndex === index && form}

        <div className="d-flex justify-content-between mt-2">
          <Button.Ripple
            type="button"
            disabled={activeIndex === 0}
            onClick={handlePrevious}
            color="secondary"
            outline
          >
            <ArrowLeft
              size={14}
              className="align-middle me-sm-25 me-0"
            ></ArrowLeft>
            <span className="align-middle d-sm-inline-block d-none">
              {t("Previous")}
            </span>
          </Button.Ripple>

          {activeIndex < dynamicFormWizard.length - 1 && (
            <Button.Ripple type="button" onClick={handleNext} color="primary">
              <span className="align-middle d-sm-inline-block d-none">
                {t("Next")}
              </span>
              <ArrowRight
                size={14}
                className="align-middle ms-sm-25 ms-0"
              ></ArrowRight>
            </Button.Ripple>
          )}
          {activeIndex === dynamicFormWizard.length - 1 && (
            <Button.Ripple type="submit" color="success">
              {t("Submit")}
            </Button.Ripple>
          )}
        </div>
      </div>
    ))
  }

  return (
    <div className="horizontal-wizard mb-0">
      <div
        ref={ref}
        style={{ boxShadow: "none" }}
        className={classnames("bs-stepper", {
          vertical: type === "vertical",
          "vertical wizard-modern": type === "modern-vertical",
          "wizard-modern": type === "modern-horizontal"
        })}
      >
        <div className={classnames("bs-stepper-header px-0")}>
          {renderHeader()}
        </div>

        <div className={classnames("bs-stepper-content px-0 pb-0")}>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

InputWizard.propTypes = {
  pageAttr: PropTypes.object,
  dynamicFormWizard: PropTypes.array,
  className: PropTypes.string,
  type: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  separator: PropTypes.node,
  contentWrapperClassName: PropTypes.string,
  options: PropTypes.object
}

export default InputWizard
