// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const setIconsComponents = createAsyncThunk(
  "setIconsComponents",
  async (data) => {
    return data
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const IconsStore = createSlice({
  name: "icons_store",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: {
      feather: [],
      material: []
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIconsComponents.fulfilled, (state, action) => {
      state.status = "succeeded"
      // console.log(action)
      state.isLoading = false
      if (action.payload?.material) {
        state.data.material = action.payload.material
      }
    })
  }
})

export default IconsStore.reducer
