/* eslint-disable multiline-ternary */
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import "moment/locale/id"
import { useContext, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setStorePageAttributes } from "@src/shared/dynamic_pages/store"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import DynamicForm from "@src/utility/generateForm/dynamicForm"

import { renameMenu } from "@src/views/apps/menu/store/my_menu"

const ModalAddEdit = ({ data, show, setShow }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  // const store = useSelector((state) => state.dynamicStore)
  // const { colors } = useContext(ThemeColors)
  // const [loadingPostPut, setLoadingPostPut] = useState(false)
  // const [loadingText, setLoadingText] = useState("Please Wait")

  const dynamicStore = useSelector((state) => state.dynamicStore)

  // const successToast = () => {
  //   const msg =
  //     pageType !== "add" ? t("Successfully Updated") : t("Successfully Created")
  //   return toast.success(`${msg} ${t(pageAttr?.pageTitle)}`, {
  //     style: {
  //       padding: "16px",
  //       color: colors.success.main,
  //       border: `1px solid ${colors.success.main}`
  //     },
  //     iconTheme: {
  //       primary: colors.success.main
  //     }
  //   })
  // }

  // const errorToast = (res) => {
  //   let errorFieldsMsg = ""
  //   if (res?.payload?.error?.errors) {
  //     const errorFields = res?.payload?.error?.errors
  //     for (let errorx in res?.payload?.error?.errors) {
  //       errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
  //       // console.log(errorx, errorFields[errorx])
  //     }
  //   }
  //   return toast.error(
  //     `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
  //     {
  //       style: {
  //         padding: "16px",
  //         color: colors.danger.main,
  //         border: `1px solid ${colors.danger.main}`
  //       },
  //       iconTheme: {
  //         primary: colors.danger.main
  //       }
  //     }
  //   )
  // }

  const handleFormSubmit = (payload) => {
    const editedRoute = payload?.routePath
    if (!editedRoute) {
      payload["routePath"] = data?.routePath
    }

    // console.log(payload)

    if (!payload?.id) {
      payload["id"] = data?.id
    }

    dispatch(renameMenu(payload)).then((res) => {
      // console.log(res)
      if (editedRoute) {
        const oldStoreName = data?.routePath
          ?.replace("/dyn_pages/", "")
          ?.replace("/", "_")
        const oldPageAttrr = { ...dynamicStore?.pageAttributes[oldStoreName] }
        const newStoreName = editedRoute
          ?.replace("/dyn_pages/", "")
          ?.replace("/", "_")
        console.log(
          oldStoreName,
          newStoreName,
          oldPageAttrr,
          dynamicStore?.pageAttributes[newStoreName]
        )
        if (!dynamicStore?.pageAttributes[newStoreName]) {
          dispatch(
            setStorePageAttributes({
              storeName: newStoreName,
              data: oldPageAttrr
            })
          )
        }
      }
      handleReset()
    })
  }

  const handleReset = () => {
    setShow(!show)
  }

  return (
    <Modal
      isOpen={show}
      toggle={() => setShow(!show)}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={() => setShow(!show)}>{t("Edit Menu")}</ModalHeader>
      <ModalBody>
        <DynamicForm
          dynamicForm={[
            { fieldName: "title", required: true, size: 12 },
            {
              fieldName: "icon",
              type: "icon",
              manualInputMode: true,
              size: 12,
              tooltip:
                "example: material:info / fontAwesome:user / feather:Activity",
              fieldDesc: "click right icon button for search icon list"
            },
            { fieldName: "routePath", fieldLabel: "URL Path", required: true }
          ]}
          // pageAttr={store.pageAttributes}
          // storeName={storeName}
          handleReset={handleReset}
          handleFormSubmit={handleFormSubmit}
          defaultFormValues={JSON.parse(
            JSON.stringify(data).replace(/\:null/gi, ':""')
          )}
          editForm={true}
          showDeleteButton={false}
          pageType={"edit"}
        />
      </ModalBody>
      {/* <ModalFooter>
        <Button color="primary" onClick={() => setShow(!show)}>
          Accept
        </Button>{" "}
      </ModalFooter> */}
    </Modal>
  )
}

export default ModalAddEdit
