// ** React Imports
import { Fragment } from "react"

// ** Third Party Components
import Proptypes from "prop-types"
import classnames from "classnames"

// ** Reactstrap Imports
import {
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip
} from "reactstrap"

// ** Custom Components Imports
import Avatar from "@components/avatar"
import { Link } from "react-router-dom"

const AvatarGroup = (props) => {
  // ** Props
  const { data, tag, className } = props

  // ** Conditional Tag
  const Tag = tag ? tag : "div"

  // ** Render Data
  const renderData = () => {
    return data.map((item, i) => {
      let id = item?.id
      let genId = `avatar_${i}_${Date.now()}`
      // ? `avatar_${i}_${item.id}`
      // : item.title.split(" ").join("-")
      const linkUrl = item?.linkUrl
      delete item?.linkUrl
      const ItemTag = item.tag ? item.tag : "div"

      const Element = (
        <Fragment key={i}>
          {!item.meta ? (
            <Fragment>
              {item?.img ? (
                <Avatar
                  tag={ItemTag}
                  id={genId}
                  className={classnames("pull-up", {
                    [item.className]: item.className
                  })}
                  {...item}
                  imgStyle={item.imgStyle}
                  title={undefined}
                  meta={undefined}
                />
              ) : (
                <Avatar
                  tag={ItemTag}
                  initials
                  className={classnames("pull-up", {
                    [item.className]: item.className
                  })}
                  id={genId}
                  color="light-primary"
                  content={`${item.title}`}
                />
              )}
              {!item?.tooltipPopOver ? (
                <UncontrolledTooltip
                  placement={item?.placement ?? "bottom"}
                  target={genId}
                >
                  {item.title}
                </UncontrolledTooltip>
              ) : (
                <UncontrolledPopover
                  placement={item?.placement ?? "bottom"}
                  target={genId}
                >
                  <PopoverHeader>{item.title}</PopoverHeader>
                  <PopoverBody>{item.title}</PopoverBody>
                </UncontrolledPopover>
              )}
            </Fragment>
          ) : null}

          {item.meta ? (
            <ItemTag className="d-flex align-items-center ps-1">
              {item.meta}
            </ItemTag>
          ) : null}
        </Fragment>
      )

      if (linkUrl) {
        return (
          <Link key={`avatar_${i}`} to={linkUrl}>
            {Element}
          </Link>
        )
      } else {
        return Element
      }
    })
  }

  return (
    <Tag
      className={classnames("avatar-group", {
        [className]: className
      })}
    >
      {renderData()}
    </Tag>
  )
}

export default AvatarGroup

// ** PropTypes
AvatarGroup.propTypes = {
  data: Proptypes.array.isRequired,
  tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string])
}
