// ** React Imports
import { Fragment } from "react"
import { Link } from "react-router-dom"

// ** Third Party Components
import Proptypes from "prop-types"
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
// ** Reactstrap Imports
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { getUserData, isAdminApp } from "@utils"

const BreadCrumbs = (props) => {
  const { t } = useTranslation()
  // ** Props
  const { data, title, mb, subtitle } = props
  const storeDeveloper = useSelector((state) => state.developer)
  const userData = getUserData()
  const devModeEnabled =
    storeDeveloper.editMode &&
    ((!window.location.pathname.includes("/dyn_pages/_admin_") &&
      !window.location.pathname.includes("/apps/menu")) ||
      isAdminApp())

  const renderBreadCrumbs = () => {
    return data.map((item, index) => {
      const Wrapper = item.link ? Link : Fragment
      const isLastItem = data.length - 1 === index
      return (
        <BreadcrumbItem
          tag="li"
          key={index}
          active={!isLastItem}
          className={classnames({ "text-primary": !isLastItem })}
        >
          <Wrapper {...(item.link ? { to: item.link } : {})}>
            {item.title}
          </Wrapper>
        </BreadcrumbItem>
      )
    })
  }

  return (
    <div
      dev-mode-header={'{"title": "Set Page Title"}'}
      dev-mode-data={["pageTitle", "pageModuleTitle", "pageDesc"]}
      className={`content-header-left col-md-9 col-8 ${
        mb || mb === 0 ? `mb-${mb}` : "mb-2"
      } ${devModeEnabled ? "editmode_hover_edit" : ""}`}
    >
      <div className="row breadcrumbs-top">
        <div className="col-12">
          {title ? (
            <h2 className="content-header-title float-start mb-0">{title}</h2>
          ) : (
            ""
          )}
          <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
            <Breadcrumb>
              <BreadcrumbItem tag="li">
                <Link to="/">{t("Home")}</Link>
              </BreadcrumbItem>
              {renderBreadCrumbs()}
            </Breadcrumb>
          </div>
        </div>
        <div className="col-12">{subtitle ?? ""}</div>
      </div>
    </div>
  )
}
export default BreadCrumbs

// ** PropTypes
BreadCrumbs.propTypes = {
  title: Proptypes.string.isRequired,
  data: Proptypes.arrayOf(
    Proptypes.shape({
      link: Proptypes.string,
      title: Proptypes.string.isRequired
    })
  )
}
