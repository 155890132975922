// ** React Imports
import { forwardRef } from "react"

// ** Third Party Components
import Proptypes from "prop-types"
import classnames from "classnames"

// ** Reactstrap Imports
import { Badge } from "reactstrap"

const Avatar = forwardRef((props, ref) => {
  // ** Props
  const {
    img,
    size,
    icon,
    color,
    status,
    badgeUp,
    content,
    tag: Tag,
    initials,
    imgWidth,
    className,
    badgeText,
    imgHeight,
    badgeColor,
    imgClassName,
    imgStyle,
    avatarStyle,
    contentStyles,
    length,
    tooltipPopOver,
    ...rest
  } = props

  const colors = [
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]

  // let backColor = color || colors[Math.floor(Math.random() * colors.length)]

  let backColor = color || "light-primary"

  // ** Function to extract initials from content
  // const getInitials = (str) => {
  //   const results = []

  //   if (str) {
  //     let wordArray = ""
  //     str = str.trimStart().trimEnd()
  //     if (str.includes(" ")) {
  //       wordArray = str.split(" ")
  //     }
  //     if (str.includes(" & ")) {
  //       wordArray = str.split(" & ")
  //     } else {
  //       wordArray = [str]
  //     }
  //
  //     if (wordArray.length > 1) {
  //       wordArray.forEach((e, i) => {
  //         if (i < 2) {
  //           results?.push(e[0]?.toUpperCase())
  //         }
  //       })
  //     } else if (wordArray.length === 1) {
  //       if (wordArray[0].length > 1) {
  //         // console.log(wordArray[0])
  //         results.push(wordArray[0][0]?.toUpperCase())
  //         results.push(wordArray[0][1]?.toUpperCase())
  //       }
  //     }
  //   }
  //   if (length === 1) {
  //     return results?.[0] ?? ""
  //   }
  //   return results.join("")
  // }

  // const getInitials = (str) => {
  //   const results = []

  //   if (str) {
  //     let wordArray = ""
  //     str = str.trim().replace(/[-\/]/g, " ").trim() // Mengganti '-' dan '/' dengan spasi
  //     if (str.includes(" & ")) {
  //       wordArray = str.split(" & ")
  //     } else if (str.includes(" ")) {
  //       wordArray = str.split(" ")
  //     } else {
  //       wordArray = [str]
  //     }

  //     if (wordArray.length > 1) {
  //       wordArray.forEach((e, i) => {
  //         if (i < 2) {
  //           results.push(e[0]?.toUpperCase())
  //         }
  //       })
  //     } else if (wordArray.length === 1) {
  //       if (wordArray[0].length > 1) {
  //         results.push(wordArray[0][0]?.toUpperCase())
  //         results.push(wordArray[0][1]?.toUpperCase())
  //       }
  //     }
  //   }

  //   if (results.length === 1) {
  //     return results[0] ?? ""
  //   }
  //   return results.join("")
  // }

  const getInitials = (str) => {
    if (!str) return ""

    // Mengganti '-' dan '/' dengan spasi, serta menghapus spasi di awal dan akhir
    str = str.trim().replace(/[-\/]/g, " ").trim()

    // Memisahkan kata berdasarkan '&' atau spasi
    let wordArray = str.includes(" & ") ? str.split(" & ") : str.split(" ")

    // Mengambil inisial berdasarkan kondisi yang diberikan
    const results = []

    for (let i = 0; i < wordArray.length; i++) {
      if (results.length >= 2) break
      const initial = wordArray[i][0]?.toUpperCase()
      // Memeriksa apakah inisial adalah huruf
      if (initial && /^[A-Z]$/.test(initial)) {
        results.push(initial)
      }
    }

    // Jika hanya ada satu inisial, tambahkan huruf kedua dari kata pertama (jika ada)
    if (results.length === 1 && wordArray[0].length > 1) {
      const secondChar = wordArray[0][1]?.toUpperCase()
      // Memeriksa apakah karakter kedua adalah huruf
      if (secondChar && /^[A-Z]$/.test(secondChar)) {
        results.push(secondChar)
      }
    }

    // Gabungkan inisial menjadi satu string
    return results.join("")
  }

  // console.log(avatarStyle)
  return (
    <Tag
      className={classnames("avatar", {
        [className]: className,
        [`bg-${backColor}`]: backColor,
        [`avatar-${size}`]: size
      })}
      ref={ref}
      {...rest}
    >
      {img === false || img === undefined ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}

          {icon ? icon : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : "primary"}
              className="badge-sm badge-up"
              pill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <span
          // className="avatar-img"
          className={classnames(
            {
              [imgClassName]: imgClassName
            },
            "avatar-img"
          )}
          style={{
            height: imgHeight && !size ? `${imgHeight}px` : "32px",
            width: imgWidth && !size ? `${imgWidth}px` : "32px",
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(34, 41, 47, 0.10)",
            backgroundPosition: "center center",
            boxShadow:
              "0 2px 4px 0 rgba(34, 41, 47, 0.12), 0 1px 3px 0 rgba(34, 41, 47, 0.08)",
            ...imgStyle
          }}
        ></span>
        // <img
        //   className={classnames({
        //     [imgClassName]: imgClassName
        //   })}
        //   src={img}
        //   alt="avatarImg"
        //   height={imgHeight && !size ? imgHeight : 32}
        //   width={imgWidth && !size ? imgWidth : 32}
        // />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        ></span>
      ) : null}
    </Tag>
  )
})

export default Avatar

// ** PropTypes
Avatar.propTypes = {
  icon: Proptypes.node,
  src: Proptypes.string,
  badgeUp: Proptypes.bool,
  content: Proptypes.string,
  badgeText: Proptypes.string,
  className: Proptypes.string,
  imgClassName: Proptypes.string,
  contentStyles: Proptypes.object,
  imgStyle: Proptypes.object,
  avatarStyle: Proptypes.object,
  size: Proptypes.oneOf(["sm", "lg", "xl"]),
  length: Proptypes.number,
  tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string]),
  status: Proptypes.oneOf(["online", "offline", "away", "busy"]),
  imgHeight: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  imgWidth: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  badgeColor: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  color: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  initials(props) {
    if (props["initials"] && props["content"] === undefined) {
      return new Error("content prop is required with initials prop.")
    }
    if (props["initials"] && typeof props["content"] !== "string") {
      return new Error("content prop must be a string.")
    }
    if (
      typeof props["initials"] !== "boolean" &&
      props["initials"] !== undefined
    ) {
      return new Error("initials must be a boolean!")
    }
  }
}

// ** Default Props
Avatar.defaultProps = {
  tag: "div"
}
