import { useEffect, useState } from "react"
import { Home } from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap"

// ** Utils
import { isUserLoggedIn } from "@utils"
import { Domain } from "@mui/icons-material"

const MemberOfDropdown = () => {
  // ** State
  const [userData, setUserData] = useState(null)

  const [userMember, setUserMember] = useState([])

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")))
      setUserMember(JSON.parse(localStorage.getItem("userData")).member_of)
    }
  }, [])

  //** Vars
  const userDataMemberOf = (userData && userData.member_of) || {}

  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
  }

  const dropdownItem = userMember.map((item) => (
    <DropdownItem
      key={item.id}
      href="/"
      tag="a"
      onClick={(e) => handleLangUpdate(e, "id")}
    >
      {/* <Home size={14} className="country-flag mb-0 mt-0" svg /> */}
      <span className="ms-1 me-1">{item.name}</span>
    </DropdownItem>
  ))
  if (userDataMemberOf.length > 0) {
    return (
      <UncontrolledDropdown
        href="/"
        tag="li"
        className="dropdown-notification nav-item"
      >
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link d-flex align-items-center"
          onClick={(e) => e.preventDefault()}
        >
          {/* <Badge xs={12} color="light-secondary"> */}
          <Domain size={18} className="align-middle me-25" />
          <span
            style={{ maxWidth: "330px" }}
            className="selected-language align-middle text-truncate"
          >
            {userDataMemberOf[0]?.name}
          </span>
          {/* </Badge> */}
        </DropdownToggle>

        <DropdownMenu className="mt-0" end>
          {dropdownItem}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  } else {
    return null
  }
}

export default MemberOfDropdown
